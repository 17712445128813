// Payments

export const PAYMENT_STATUS_DUE = 'due';
export const PAYMENT_STATUS_PAID = 'paid';
export const PAYMENT_STATUS_NOT_APPLICABLE = 'not_applicable';

export const PAYMENT_ELIGIBILITY_STATUS_ELIGIBILE = 'eligible';
export const PAYMENT_ELIGIBILITY_STATUS_NOT_ELIGIBILE = 'not_eligible';
export const PAYMENT_ELIGIBILITY_STATUS_SELF_ASSESSED = 'self_assessed';
export const PAYMENT_INTENT_STATUS_SUCCEEDED = 'succeeded';
export const PAYMENT_INTENT_STATUS_FAILED = 'failed';
