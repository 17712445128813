/**
 *
 * Logo
 *
 */

import { QUIP_THEME_ID } from '@oraleye/frontend-modules-core';
import { LD_UI_THEME_FLAG } from 'constants/launchDarklyFlags';
import toothpic from 'images/Toothpic-2021-green.svg';
import toothpicwhite from 'images/Toothpic-2021-white.svg';
import quipwhite from 'images/quip-logo-white.svg';
import quip from 'images/quip-logo.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const LogoSVG = styled.img`
  ${({ center }) => center && 'display:block; margin: 0 auto;'};
  ${({ lg }) => lg && 'width: 280px;'};
`;

const imports = {
  toothpic,
  toothpicwhite,
  quip,
  quipwhite,
};

function Logo({ white, className }) {
  const flags = useFlags();

  const [logo, setLogo] = useState({});
  useEffect(() => {
    let key = flags[LD_UI_THEME_FLAG] === QUIP_THEME_ID ? 'quip' : 'toothpic';
    if (white) key += 'white';
    setLogo(imports[key]);
  }, [flags, white]);

  return <LogoSVG src={logo} alt="Logo" className={className} />;
}

Logo.propTypes = {
  className: PropTypes.any,
  white: PropTypes.any,
};

export default Logo;
