import { INTERCOM_ZINDEX } from 'constants/zIndex';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

  :root {
    --toastify-color-success: var(--primary02);
    --toastify-color-error: var(--error);
    --toastify-color-info: var(--primary01);
  }
  
  h1, h2 {
    color: var(--primary01);
  }
  h3, h4, h5 {
    color: var(--neutral08);
  }

  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1;
  }
 
  #app {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
  }
  
  p,
  label {
    line-height: 1.5em;
  }
  
  /*react-phone-number overrides*/
  .react-phone-number-input__input {
    border-radius: 8px !important;
    padding-left: 8px !important;
    height: 3em !important;
    border: 1px solid var(--primary02) !important;
  }
  
  :focus {
    outline: none;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--primary01) !important;
  }
  
  small {
    display: block;
    /*margin: 0.5em 0;*/
  }
  
  h4,
  h5 {
    margin: 0.5em 0;
  }
  
  .animate-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  button:disabled,
  button:disabled > * {
    cursor: not-allowed;
    pointer-events: auto;
  }
  
  small,label {
    display: block;
  }
  
  .intercom-lightweight-app {
    z-index: ${INTERCOM_ZINDEX} !important;
  }
  .intercom-lightweight-app-launcher {
    // this was 2 bigger in the original code...
    z-index: ${INTERCOM_ZINDEX + 2} !important;
  }
  
  // https://www.warrenchandler.com/2019/04/02/stop-iphones-from-zooming-in-on-form-fields/
  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px; }
  }
  
  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px; }
  }
  
  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"], 
      input[type="tel"], input[type="url"]{ font-size: 16px; }
  }
  
  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="tel"], input[type="url"]{ font-size: 16px; }
  }
  .modal-in {
    & > div {
      z-index: 9999;
    }
  }
`;

export default () => {
  return <Global />;
};
