import Config from 'config';
import styled from 'styled-components';

export const Card = styled.div`
  min-height: 132px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  margin: 12px 0;
  padding: 17px 21px 21px 21px;

  .fa-chevron-right {
    float: right;
    margin-top: 12px;
  }
`;

export const CardTitle = styled.h4`
  color: var(--neutral08);
  font-weight: ${Config.isQuip || Config.isDelta ? 'bold' : 400};
`;

export const CardContent = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--neutral08);
`;
