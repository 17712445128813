/* eslint-disable import/prefer-default-export */
/*
 *
 * LanguageProvider actions
 *
 */

import {
  CHANGE_CURRENCY,
  UPDATE_LOCALE_DATA,
} from 'containers/LanguageProvider/constants';
import { CHANGE_COUNTRY, CHANGE_LOCALE } from './constants';

export function updateLocaleDataAction({ locale, country, currency }) {
  return {
    type: UPDATE_LOCALE_DATA,
    locale,
    country,
    currency,
  };
}

export function changeLocaleAction(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}

export function changeCountryAction(languageLocale) {
  return {
    type: CHANGE_COUNTRY,
    country: languageLocale,
  };
}

export function changeCurrencyAction(currency) {
  return {
    type: CHANGE_CURRENCY,
    currency: currency,
  };
}
