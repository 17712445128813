import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the LDUpdater state domain
 */

const selectLDUpdaterDomain = (state) => state.ldUpdater || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by LaunchDarklyUpdater
 */

const makeSelectLDUpdater = () =>
  createSelector(selectLDUpdaterDomain, (substate) => substate);

export const selectCampaignCopyMap = () =>
  createSelector(
    selectLDUpdaterDomain,
    ({ campaignCopyMap }) => campaignCopyMap,
  );

export const selectCompanyCopy = () =>
  createSelector(selectLDUpdaterDomain, (substate) => substate.companyCopy);

export const selectCampaignId = () =>
  createSelector(selectLDUpdaterDomain, ({ campaignId }) => campaignId);

export const selectCampaignIsUrl = () =>
  createSelector(selectLDUpdaterDomain, ({ campaignIsUrl }) => campaignIsUrl);

export default makeSelectLDUpdater;
export { selectLDUpdaterDomain };
