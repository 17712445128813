import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendLogEvent } from 'services/LogEvents/actions';

export const useLogzEvent = (payload) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (payload) dispatch(sendLogEvent(payload));
  }, [dispatch, payload]);
};
