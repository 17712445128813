/**
 *
 * Button.js
 *
 * Same as a material button, but with added tracking
 */

import { Button } from '@oraleye/frontend-modules-core';
import { withTrackingId } from '@oraleye/frontend-modules-utils';
import styled from 'styled-components';

export const TrackedButton = styled(Button)`
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
`;

TrackedButton.defaultProps = {
  color: 'cta01',
  variant: 'contained',
};

export default withTrackingId(TrackedButton);
