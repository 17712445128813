// Case Types
export const DIRECT_CARE_CASE_TYPE = 'direct_care';
export const COMPLETE_CARE_CASE_TYPE = 'complete_care';
export const TRIAGE_CASE_TYPE = 'triage';
export const VIDEO_CASE_TYPE = 'video';
export const FOLLOW_UP_CASE_TYPE = 'follow_up';
export const FOLLOW_UP_CASE_TYPE_OLD = 'follow_up';
export const DIRECT_CARE_TEMPLATE_BASE_NAME = 'direct_care';
export const COMPLETE_CARE_TEMPLATE_BASE_NAME = 'complete_care';
export const SPECIFIC_ISSUE_TEMPLATE_BASE_NAME = 'specific_issue';
export const TRIAGE_TEMPLATE_BASE_NAME = 'triage';
export const VIDEO_TEMPLATE_BASE_NAME = 'video';
export const SCHEDULED_VIDEO_TEMPLATE_BASE_NAME = 'scheduled_video';
export const FOLLOW_UP_TEMPLATE_BASE_NAME_OLD = 'follow_up_professional';
export const FOLLOW_UP_TEMPLATE_BASE_NAME = 'follow_up';

export const PATIENT_CASE_STATUS_PENDING = 'pending';
export const PATIENT_CASE_STATUS_ASSIGNED = 'assigned';
export const PATIENT_CASE_STATUS_LOCKED = 'locked';
export const PATIENT_CASE_STATUS_FLAGGED = 'flagged';
export const PATIENT_CASE_STATUS_REPORT_READ = 'report_read';
export const PATIENT_CASE_STATUS_COMPLETED = 'completed';
export const PATIENT_CASE_STATUS_INCOMPLETE = 'incomplete';
export const PATIENT_CASE_STATUS_UPLOAD_ERROR = 'upload_error';
export const PATIENT_CASE_STATUS_S3_UPLOAD_COMPLETE = 's3_upload_complete';
export const PATIENT_CASE_STATUS_LATE = 'late';
export const PATIENT_CASE_STATUS_DELAYED = 'delayed';
export const PATIENT_CASE_STATUS_VIDEO_CALL_ENDED = 'video_call_ended';
export const PATIENT_CASE_STATUS_CANCELLED = 'canceled';

export const ACCOUNT_PROFILE_REQUIRED_STAGE = 'account_questions';
export const CASE_QUESTIONS_REQUIRED_STAGE = 'case_questions';
export const MEMBER_PROFILE_REQUIRED_STAGE = 'member_questions';
export const PHOTOS_REQUIRED_STAGE = 'photos';
export const PRESCRIPTION_INFO_REQUIRED_STEP = 'prescription_info';
export const SCHEDULE_VIDEO_REQUIRED_STEP = 'schedule_video';
export const PHARMACY_STEP_REQUIRED = 'pharmacy';
export const VIDEO_CALL_REQUIRED_STAGE = 'video_call';
export const VIDEO_CALL_SCHEDULE_STAGE = 'video_call_schedule';
export const CHECKOUT_STAGE = 'checkout';

// Patient case template statuses
export const CASE_TEMPLATE_COMPLETE_STATUS = 'complete';
export const CASE_TEMPLATE_COMING_SOON_STATUS = 'coming_soon';

export const DEFAULT_TEMPLATE_TYPE = 'default';
