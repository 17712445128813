//url redirect
module.exports.LD_FEATURE_URL_REDIRECTS = 'feature--url-redirects';
module.exports.LD_FEATURE_CASE_RESUME_REDIRECT =
  'feature--case-resume-redirect';
// triage
module.exports.LD_FEATURE_TRIAGE = 'feature-triage-cases';

// video
module.exports.LD_FEATURE_VIDEO_CALLING = 'feature--video-calling';
module.exports.LD_UI_GET_CARE_VIDEO_CONSULT = 'ui--get-care-video-consult-card';
module.exports.LD_UI_GET_CARE_SCHEDULED_VIDEO_CONSULT =
  'ui--get-care-scheduled-video-consult-card';
module.exports.LD_UI_STATE_SELECTION_MODAL = 'ui--state-selection-modal';
module.exports.LD_DEBUG_SCHEDULE_VIDEO_WINDOW_SIZE =
  'debug--video-schedule-window-size';
module.exports.LD_DEBUG_VIDEO_SCHEDULE_BUFFER = 'debug--video-schedule-buffer';
module.exports.LD_FEATURE_CANCEL_SCHEDULED_CONSULTATION =
  'feature--cancel-scheduled-consultation';

// case creation
module.exports.LD_FEATURE_CASE_CREATION = 'feature--case-creation';

// account setup
module.exports.LD_FEATURE_ACCOUNT_SETUP = 'feature--account-setup';

// case submission
module.exports.LD_FEATURE_CASE_SUBMISSION = 'feature-case-submission';
module.exports.LD_FEATURE_SKIP_PHOTOS = 'feature--enable-skip-photo-submission';
module.exports.LD_UI_PHOTO_INSTRUCTIONS_SKIP_PHOTOS =
  'ui--photo-instructions-show-skip-photos';

// home page
module.exports.LD_UI_GET_CARE_PAGE_PHOTO_SUBMISSION_CARD =
  'ui--get-care-page-photo-submission-card';
module.exports.LD_UI_GET_CARE_PAGE_TRIAGE_CARD =
  'ui--get-care-page-triage-card';
module.exports.LD_UI_DELTA_PHOTO_SUBMISSION_CARD =
  'ui--delta-photo-submission-card';
module.exports.LD_UI_BCBS_PHOTO_SUBMISSION_CARD =
  'ui--bcbs-photo-submission-card';
module.exports.LD_UI_GET_CARE_SHOW_PRICING = 'ui--get-care-show-pricing';
module.exports.LD_UI_GET_CARE_SHOW_ALTERNATIVE_PRICE =
  'ui--get-care-show-alternative-price';

// Case reports
module.exports.LD_FEATURE_CASE_REPORTS = 'feature--case-reports';

module.exports.LD_UI_HOME_GET_CARE_CARD = 'ui--home-get-care-card';
module.exports.LD_UI_HOME_TRIAGE_CARD = 'ui--home-triage-card';

module.exports.LD_UI_WALMART_HOME_DESCRIPTION = 'ui--walmart-home-description';

// Services
module.exports.LD_SERVICE_INTERCOM = 'service--intercom';
module.exports.LD_SERVICE_SEGMENT = 'service--segment';
module.exports.LD_SERVICE_AMPLITUDE = 'service--amplitude';
module.exports.LD_SERVICE_VWO = 'service--vwo';

// Complete care
module.exports.LD_FEATURE_COMPLETE_CARE = 'feature--complete-care-cases';
module.exports.LD_UI_CASE_STEPS_PAGE = 'ui--case-steps-page';
module.exports.LD_UI_CASE_STEPS_FINE_PRINT = 'ui--case-steps-fine-print';
module.exports.LD_UI_BCBS_CASE_STEPS_COPY = 'ui--bcbs-case-steps-copy';
module.exports.LD_UI_DELTA_CASE_STEPS_COPY = 'ui--delta-case-steps-copy';
module.exports.LD_UI_WALMART_CASE_STEPS_COPY = 'ui--walmart-case-steps-copy';
module.exports.LD_UI_CHECKOUT_CASE_STEP = 'ui--checkout-case-step';

// Navigation
module.exports.LD_FEATURE_PAGE_BREADCRUMBS = 'feature--page-breadcrumbs';
module.exports.LD_UI_GET_CARE_PAGE_BREADCRUMB = 'ui--get-care-page-breadcrumb';
module.exports.LD_UI_CASE_STEPS_PAGE_BREADCRUMB =
  'ui--case-steps-page-breadcrumb';
module.exports.LD_UI_CASE_QUESTIONS_PAGE_BREADCRUMB =
  'ui--case-questions-page-breadcrumb';
module.exports.LD_UI_PHOTO_INSTRUCTIONS_PAGE_BREADCRUMB =
  'ui--photo-instructions-page-breadcrumb';
module.exports.LD_UI_PHOTO_SELECTION_PAGE_BREADCRUMB =
  'ui--photo-selection-page-breadcrumb';

// General
module.exports.LD_UI_SIDE_MENU = 'ui--side-menu';
module.exports.LD_UI_PROFILE_MENU = 'ui--profile-menu';
module.exports.LD_UI_AUTH_PAGES_ONBOARDING_CONTAINER =
  'ui--auth-pages-onboarding-container';

// Follow Ups
module.exports.LD_FEATURE_FOLLOW_UP = 'feature--follow-up-cases';
module.exports.LD_FEATURE_PATIENT_CASE_LANDING_PAGE =
  'feature--patient-case-landing-page';

// Toothpic Consumer
module.exports.LD_FEATURE_CONSUMER_SIGNIN_SIGNUP =
  'feature--consumer-signin-signup';

// SSO
module.exports.LD_FEATURE_GOOGLE_SSO = 'feature--google-sso';

// Member Profile
module.exports.LD_FEATURE_PROFILE_MENU = 'feature--profile-menu';
module.exports.LD_FEATURE_MEMBER_PROFILE = 'feature-member-profile';
module.exports.LD_UI_DELTA_ENROLLEE_ID = 'ui--delta-enrollee-id';
module.exports.LD_UI_DELTA_COVERAGE_PROVIDER_PLAN_CODES =
  'ui--delta-coverage-provider-plan-codes';
module.exports.LD_UI_DELTA_SUBSCRIBER_TYPE = 'ui--delta-subscriber-type';
module.exports.LD_UI_BCBS_MEMBER_ID_NUMBER = 'ui--bcbs-member-id-number';

// EDI
module.exports.LD_FEATURE_ELIGIBILITY_CHECK = 'feature-eligibility-check';

// Feedback banner
module.exports.LD_FEATURE_FEEDBACK_BANNER = 'feature--feedback-banner';

module.exports.LD_FEATURE_HIFI_PHOTO_FLOW = 'feature--hifi-photo-taking';
module.exports.LD_FEATURE_PHOTO_UPLOAD = 'feature--photo-upload';
module.exports.LD_UI_UPLOAD_PHOTO_BUTTON = 'ui--photo-upload-button';
module.exports.LD_UI_DONT_FORGET_FLASH = 'ui--dont-forget-flash';
module.exports.LD_UI_FACE_MIRROR = 'ui--face-the-mirror-instruction';
module.exports.LD_UI_PHOTO_HELP_LINKS = 'ui--photo-help-links';

// Checkout
module.exports.LD_FEATURE_CHECKOUT = 'feature--checkout';
module.exports.LD_UI_PAY_OUT_OF_POCKET = 'ui--pay-out-of-pocket';
module.exports.LD_FEATURE_PAYMENT_ELIGIBILITY = 'feature--eligibility-check';
module.exports.LD_UI_DELTA_INSURANCE_CARD = 'ui--delta-insurance-card';
module.exports.LD_UI_BCBS_INSURANCE_CARD = 'ui--bcbs-insurance-card';
module.exports.LD_FEATURE_PAYMENT_REQUEST_BUTTON =
  'feature--payment-request-button';
module.exports.LD_UI_DELTA_HOW_TO_PAY_FINE_PRINT =
  'ui--delta-how-to-pay-fine-print';
module.exports.LD_FEATURE_INSURANCE_CARD = 'feature--insurance-card';

module.exports.LD_UI_WALMART_CHECKOUT_COPY = 'ui--walmart-checkout-copy';

// Report feedback modal
module.exports.LD_FEATURE_REPORT_FEEDBACK_MODAL =
  'feature--report-feedback-modal';

// Specific issue
module.exports.LD_FEATURE_SPECIFIC_ISSUE_CARD = 'feature--specific-issue';

// Report feedback
module.exports.LD_VARIABLE_REPORT_FEEDBACK_TIMEOUT =
  'variable--report-feedback-modal-timeout';

// Home screen
module.exports.LD_UI_GET_CARE_HOME_BUTTON_CARD = 'ui--button-card-home-screen';
module.exports.LD_HOME_SCREEN_RESUME_CARDS = 'ui--home-screen-resume-cards';

// Prescriptions
module.exports.LD_FEATURE_PRESCRIPTIONS = 'feature--prescriptions';

// Login and sign up
module.exports.LD_FEATURE_SIGNUP = 'feature--sign-up';
module.exports.LD_FEATURE_SIGN_IN = 'feature--sign-in';
module.exports.LD_FEATURE_RESET_PASSWORD = 'feature--reset-password';

// Invoices
module.exports.LD_FEATURE_INVOICES = 'feature--invoices';

// Theme
module.exports.LD_UI_THEME_FLAG = 'ui--theme-flag';
