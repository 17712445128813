/*
 * GetCareView Messages
 *
 * This contains all the text for the GetCareView component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.GetCareView';

export default defineMessages({
  cardPrice: {
    id: `${scope}.cardPrice`,
    defaultMessage: '<a>{originalPrice, number, :: .00/w currency/USD}</a>',
  },

  cardPriceWithAlternative: {
    id: `${scope}.cardPriceWithAlternative`,
    defaultMessage:
      '<a>{originalPrice, number, :: .00/w currency/USD}</a><b> or from {alternativePrice, number, :: .00/w currency/USD} with insurance</b>',
  },
});
