import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the questions state domain
 */

const selectQuestionsDomain = (state = initialState()) => state.questions;
/**
 * Other specific selectors
 */

/**
 * Default selector used by Questions
 */

const makeSelectQuestions = () =>
  createSelector(selectQuestionsDomain, (substate) => substate);

const getQuestionSetType = (state = initialState()) => {
  return state.questions[state.questions?.currentType];
};
const getQuestionSetForType = (type) =>
  createSelector(selectQuestionsDomain, (state) => state[type]);

const selectQuestionSetsOfType = createSelector(
  getQuestionSetType,
  (questionSetOfType) => {
    return questionSetOfType;
  },
);

const selectQuestionSetsForType = (type) =>
  createSelector(getQuestionSetForType(type), (questionSetOfType) => {
    return questionSetOfType;
  });

const selectCaseQuestionSet = (patientCaseId) =>
  createSelector(getQuestionSetForType('case'), (questionSetOfType) => {
    return Object.values(questionSetOfType?.sets || {}).find(
      (qs) => qs.patient_case_id === patientCaseId,
    );
  });

const selectMemberQuestionSet = createSelector(
  getQuestionSetForType('member'),
  (questionSetOfType) => {
    return Object.values(questionSetOfType?.sets || {})[0];
  },
);

const selectCurrentQuestionSet = createSelector(getQuestionSetType, (set) => {
  if (set?.currentSetId) {
    return set.sets[set.currentSetId];
  }
  return null;
});

const selectCurrentQuestionSetCards = createSelector(
  getQuestionSetType,
  (substate) => substate?.cards || null,
);

const selectCurrentType = createSelector(
  selectQuestionsDomain,
  (substate) => substate?.currentType,
);
const selectQuestionSetRedirect = createSelector(
  selectQuestionsDomain,
  (substate) => substate?.redirect,
);

export default makeSelectQuestions;

export {
  selectQuestionsDomain,
  selectQuestionSetsOfType,
  selectCurrentType,
  selectCurrentQuestionSet,
  selectCurrentQuestionSetCards,
  selectQuestionSetRedirect,
  selectCaseQuestionSet,
  selectMemberQuestionSet,
};
