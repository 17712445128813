// From the wild Toothpic users use this
export const baseFeatures = {
  videoCall: false,
  caseSubmission: false,
  caseCreation: false,
  reports: false,
  accountSetup: false,
  logging: process.env.NODE_ENV !== 'production',
};

const bcbsConfig = {
  videoCall: false,
  caseSubmission: false,
  caseCreation: false,
  reports: false,
  accountSetup: false,
  triageCase: false,
};

const professionalConfig = {
  videoCall: false,
  caseSubmission: true,
  caseCreation: false,
  reports: true,
  accountSetup: true,
  triageCase: false,
};

const videoConfig = {
  videoCall: true,
  caseSubmission: true,
  caseCreation: false,
  reports: true,
  accountSetup: true,
  triageCase: false,
};

const deltaConfig = {
  videoCall: false,
  caseSubmission: true,
  caseCreation: true,
  reports: true,
  accountSetup: true,
  triageCase: false,
};

const featureConfigs = {
  default: baseFeatures,
  isVideo: videoConfig,
  isProfessional: professionalConfig,
  isBCBS: bcbsConfig,
  isDelta: deltaConfig,
};

export default featureConfigs;
