/*
 * MemberProfileView Messages
 *
 * This contains all the text for the MemberProfileView component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.components.MemberProfileView';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Manage profile',
  },
  personalDetails: {
    id: `${scope}.personalDetails`,
    defaultMessage: 'Personal details',
  },
  insuranceDetails: {
    id: `${scope}.insuranceDetails`,
    defaultMessage: 'Insurance details',
  },
  verificationModalHeader: {
    id: `${scope}.verificationModalHeader`,
    defaultMessage: 'Verifying your details',
  },
  verificationModalBody: {
    id: `${scope}.verificationModalBody`,
    defaultMessage:
      'Please wait while we verify your membership and check your benefit allowance.',
  },
  eligibleModalHeader: {
    id: `${scope}.eligibleModalHeader`,
    defaultMessage: 'Details verified',
  },
  eligibleModalBody: {
    id: `${scope}.eligibleModalBody`,
    defaultMessage:
      'We have verified your membership. You can now use your benefits to connect with a dentist.',
  },
  eligiblePrimaryButton: {
    id: `${scope}.eligiblePrimaryButton`,
    defaultMessage: 'Continue',
  },
  ineligibleModalHeader: {
    id: `${scope}.ineligibleModalHeader`,
    defaultMessage: 'Details could not be verified',
  },
  ineligibleModalBody: {
    id: `${scope}.ineligibleModalBody`,
    defaultMessage:
      'Please re-check the details provided or continue as a non-member (charges will be incurred)',
  },
  ineligiblePrimaryButton: {
    id: `${scope}.ineligiblePrimaryButton`,
    defaultMessage: 'Continue',
  },
  ineligibleSecondaryButton: {
    id: `${scope}.ineligibleSecondaryButton`,
    defaultMessage: 'Check details',
  },
  saveButton: {
    id: `${scope}.saveButton`,
    defaultMessage: 'Save',
  },
});
