import React from 'react';
import { Route } from 'react-router-dom';
import { getCampaignId } from 'utils/localStorageHelper';

export const QuipSignIn = (props) => {
  const redirect = `${window.location.origin}${
    props.location.state?.redirectTo || '/'
  }`;
  const ci = getCampaignId() || 'quip';

  return (
    <Route
      render={() => {
        window.location = `${
          window.env.QUIP_LOGIN_URL
        }?redirectParams=${encodeURIComponent(
          `callback=${redirect}&ci=${ci}`,
        )}`;
      }}
    />
  );
};
