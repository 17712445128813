/* eslint-disable camelcase */
import Config from 'config';
import { LD_SERVICE_INTERCOM } from 'constants/launchDarklyFlags';
import {
  fetchCompanyConfigSuccessAction,
  fetchMemberFailureAction,
  fetchMemberSuccessAction,
  loginRequestFailureAction,
  loginRequestSuccessAction,
  patchMemberMedicalInformationFailure,
  patchMemberMedicalInformationSuccess,
} from 'containers/Auth/AuthRouter/actions';
import {
  FETCH_MEMBER,
  FETCH_MEMBER_SUCCESS,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_SIGNUP_REQUEST,
  GOOGLE_SSO_REQUEST,
  LOAD_PROVIDER_OPTIONS,
  LOAD_PROVIDER_OPTIONS_FAILURE,
  LOAD_PROVIDER_OPTIONS_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  PATCH_MEMBER_MEDICAL_INFORMATION,
  SIGNUP_REQUEST,
  SIGNUP_REQUEST_FAILURE,
  SIGNUP_REQUEST_SUCCESS,
  SIGN_OUT_USER,
  SIGN_OUT_USER_FAILURE,
  SIGN_OUT_USER_SUCCESS,
} from 'containers/Auth/AuthRouter/constants';
import {
  selectAccountQuestionSet,
  selectMember,
} from 'containers/Auth/AuthRouter/selectors';
import {
  fetchRegionsAction,
  patchMembersStateAction,
} from 'containers/GetCare/actions';
import {
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_SUCCESS,
} from 'containers/GetCare/constants';
import { getCompanyCopy } from 'containers/LaunchDarklyUpdater/actions';
import {
  GET_COMPANY_COPY_FAILURE,
  GET_COMPANY_COPY_SUCCESS,
} from 'containers/LaunchDarklyUpdater/constants';
import { getQuestionSet } from 'containers/Questions/actions';
import { ACCOUNT_QUESTION_SET_TYPE } from 'containers/Questions/constants';
import identifyExternalUser from 'external/identify';
import { boot } from 'external/intercom/intercom';
import { COUNTRY_IE, LOCALE_IE } from 'locale-constants';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { eventSend } from 'services/EventsService';
import events from 'services/EventsService/events.json';
import { BOOT_INTERCOM } from 'services/FlaggedServices/constants';
import {
  updateConfigForCopy,
  updateThemeName,
} from 'services/InitialisationService/companyCopy';
import { getMemberWithToken } from 'services/InitialisationService/member';
import { clearIndexedDB } from 'utils/indexedDB';
import {
  clearRegionId,
  clearUserLocalStorageData,
  getAuthToken,
  getCampaignId,
  getCompanyId,
  getCompanyCopy as getCopy,
  getDRedirect,
  getLocale,
  getRegionId,
  getSMSId,
  removeDRedirect,
  removeSMSId,
  setAuthToken,
  setCampaignOrCompanyIdCopyMap,
  setCompanyCopy,
  setCompanyId,
  setEdiEnabled,
  setLastSeen,
  setMemberEligibilityExpired,
  setMemberEmail,
} from 'utils/localStorageHelper';
import request from 'utils/request';
import {
  CURRENT_STATE,
  clearUserSessionStorageData,
  saveToSessionStorage,
} from 'utils/sessionStorageHelper';
import {
  createAccountUrl,
  googleCallbackUrl,
  loginUrl,
  memberUrl,
} from 'utils/urls';
import { FETCH_COMPANY_CONFIG_SUCCESS } from './constants';
import messages from './messages';
import { FETCH_MEMBER_ELIGIBILITIES_SUCCESS } from 'containers/MemberProfile/constants';

function* getRegionByCountry(country) {
  const headers = new Headers();
  const options = {
    method: 'GET',
    headers,
    mode: 'cors',
  };
  const regions = yield call(request, `v3/regions?country=${country}`, options);
  return regions[0];
}

export function* checkForRegionId() {
  const locale = getLocale();
  if (locale === LOCALE_IE) {
    const member = yield select(selectMember());
    const ieRegion = yield getRegionByCountry(COUNTRY_IE);
    if (member.region_id !== ieRegion.id) {
      yield put(
        patchMembersStateAction({ memberId: member.id, regionId: ieRegion.id }),
      );
    }
  }
}

/**
 * Create a new member
 * @param action
 */
export function* createMember(action) {
  const { email, password, terms } = action.payload;
  const campaignId = getCampaignId();
  const companyId = getCompanyId();
  const smsId = getSMSId();
  const dRedir = getDRedirect();

  const regionId = getRegionId();

  if (!(email && password && terms)) {
    const missingValue =
      (!email && 'email') || (!password && 'password') || (!terms && 'terms');
    return yield put({
      type: SIGNUP_REQUEST_FAILURE,
      error: {
        intlMessage: messages['missing_' + missingValue],
      },
    });
  }

  const body = {
    email,
    password,
    terms_and_conditions: terms,
    campaign_identifier: campaignId,
    companyId: companyId,
    sms_identifier: smsId,
    url_identifier: dRedir,
    region_id: regionId,
    timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const headers = new Headers();
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };
  try {
    const response = yield call(request, createAccountUrl, options);
    if (response?.id) identifyExternalUser(response.id);
    yield put({ type: LOGIN_REQUEST, payload: { email, password } });
    yield take(LOGIN_REQUEST_SUCCESS);
    yield checkForRegionId();
    yield put({ type: SIGNUP_REQUEST_SUCCESS, payload: response });
  } catch (e) {
    yield put({
      type: SIGNUP_REQUEST_FAILURE,
      error: {
        intlMessage: {
          ...messages.unableToSignUp,
          values: { email, supportEmail: Config.supportEmail },
        },
      },
    });
  }
}

/**
 * Authenticate user
 * @param email
 * @param password
 * @returns {IterableIterator<*>}
 */
function* authenticateUser(email, password) {
  const sms_identifier = getSMSId();
  const campaign_identifier = getCampaignId();
  const dRedir = getDRedirect();
  const body = {
    email,
    password,
    auth_type: 'oe_user',
    sms_identifier,
    campaign_identifier,
    url_identifier: dRedir,
  };
  const headers = new Headers();
  const options = {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify(body),
  };
  return yield call(request, loginUrl, options);
}

function* signoutUser() {
  const url = 'v2/auth';
  clearUserLocalStorageData();
  clearUserSessionStorageData();

  if (!getAuthToken()) {
    return;
  }
  const options = {
    method: 'POST',
    headers: new Headers(),
    mode: 'cors',
    body: JSON.stringify({ auth_type: 'oe_logout' }),
  };

  try {
    yield call(request, url, options);
    yield put({ type: SIGN_OUT_USER_SUCCESS });
  } catch (e) {
    console.error(e);
    yield put({ type: SIGN_OUT_USER_FAILURE, error: e });
  }
}

function signoutUserSuccess() {
  // Clear local storage
  clearUserLocalStorageData();
  // Clear session storage
  clearUserSessionStorageData();
  // Clear indexedDB photos database
  clearIndexedDB().catch((e) => console.error(e));
}

function* getMember(id) {
  const url = `v3/members/${id}`;
  const options = {
    method: 'GET',
    headers: new Headers(),
    mode: 'cors',
  };
  return yield call(request, url, options);
}

function* ensureCompanyCampaign({ payload }) {
  const member = payload;
  if (!member) return;

  const companyId = member.company_id;
  setCompanyId(companyId);
  yield put(getCompanyCopy(null, companyId));

  const { copySuccess, copyFail } = yield race({
    copySuccess: take(GET_COMPANY_COPY_SUCCESS),
    copyFail: take(GET_COMPANY_COPY_FAILURE),
  });

  if (copyFail) {
    throw copyFail;
  }
  const copy = copySuccess.payload?.copy;
  if (copy && copy?.id !== getCopy()?.id) {
    updateThemeName(copy);
    setCompanyCopy(copy);
    updateConfigForCopy(copy);
    setCampaignOrCompanyIdCopyMap(member.company_id, copy);
  }

  return copy;
}

function* fetchCompanyConfigs() {
  const method = 'GET';
  const endpoint = `v3/company_configs`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return yield call(request, endpoint, requestData);
}

function* checkAndUpdateRegionId(regionId, member) {
  yield put(fetchRegionsAction());
  const { regionsSuccess, regionsFailure } = yield race({
    regionsSuccess: take(FETCH_REGIONS_SUCCESS),
    regionsFailure: take(FETCH_REGIONS_FAILURE),
  });
  if (regionsFailure) return;
  if (regionsSuccess) {
    const validRegion = regionsSuccess.payload.find(
      (item) => item.id === regionId,
    );
    if (validRegion) {
      saveToSessionStorage(CURRENT_STATE, validRegion);
      clearRegionId();
      if (!member?.region_id) {
        yield put(patchMembersStateAction({ regionId, memberId }));
      }
    }
  }
}

function* loginMember({ payload }) {
  const { email, password } = payload;
  try {
    const response = yield call(authenticateUser, email, password);
    setAuthToken(response.auth_token, response.ttl * 1000);
    const member = yield getMember(response.member_id);
    const configs = yield fetchCompanyConfigs();

    const regionId = getRegionId();
    if (regionId) {
      yield checkAndUpdateRegionId(regionId, member);
    }

    yield ensureCompanyCampaign({ payload: member });

    // ensure we have the right company copies
    yield put(fetchMemberSuccessAction(member));
    yield put(fetchCompanyConfigSuccessAction(configs[0]));
    yield put(loginRequestSuccessAction({ ...response, email, member }));
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    yield put(
      loginRequestFailureAction({ intlMessage: messages.incorrectDetails }),
    );
  }
}

function* setSegmentMember(action) {
  const member = action.payload;

  if (!member) {
    return;
  }

  analytics.ready(() => {
    const currentUser = window.analytics?.user();
    if (currentUser.id() === member?.id) {
      return;
    }
    eventSend({
      event_name: events.identify.event_name,
      user_id: member.id,
      user_klass: 'member',
    });
  });
}

function* removeSegmentMember() {
  window.analytics?.reset();
}

function* setIntercomMember() {
  const member = yield select(selectMember());
  if (!member) {
    return;
  }

  const flags = yield window.ldClient?.allFlags();
  if (flags && flags[LD_SERVICE_INTERCOM]) {
    boot(member);
  }
}

function* loadProviderOptions() {
  const url = 'v3/registration_company_options';
  const options = {
    method: 'GET',
    headers: new Headers(),
    mode: 'cors',
  };

  try {
    const response = yield call(request, url, options);
    yield put({ type: LOAD_PROVIDER_OPTIONS_SUCCESS, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: LOAD_PROVIDER_OPTIONS_FAILURE, error: e });
  }
}

function* onLoginRequestSuccess({ payload }) {
  const { email } = payload;
  setMemberEmail(email);
  setLastSeen();
  removeSMSId();
  removeDRedirect();
}

export function getCompanyConfigsWithToken() {
  const url = `v3/company_configs`;
  const headers = new Headers();
  const options = {
    method: 'GET',
    headers,
  };
  return request(url, options).then((data) =>
    data.length ? data[0] : { edi_enabled: false },
  );
}

function* fetchMember() {
  try {
    const member = yield getMemberWithToken();
    if (!member) {
      throw new Error('Member not found');
    } else {
      yield put(fetchMemberSuccessAction(member));
    }
  } catch (e) {
    yield put(fetchMemberFailureAction(e));
  }
}

function* getAccountQuestionSetOnMemberFetch() {
  const accountQuestionSet = yield select(selectAccountQuestionSet());
  if (accountQuestionSet) return;
  yield put(getQuestionSet({ type: ACCOUNT_QUESTION_SET_TYPE }));
}

function* googleSSO(action) {
  const sms_identifier = getSMSId();
  const campaign_identifier = getCampaignId();
  const dRedir = getDRedirect();
  const { access_token, authType, email } = action.payload;
  const isSignUp = authType === 'signup';
  const regionId = getRegionId();
  if (access_token) {
    const headers = new Headers();
    const body = {
      access_token,
      auth_type: authType,
      sms_identifier,
      campaign_identifier,
      url_identifier: dRedir,
      terms_and_conditions: true,
      region_id: regionId,
    };

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
      mode: 'cors',
    };

    try {
      yield put({ type: GOOGLE_SSO_REQUEST });
      const response = yield call(request, googleCallbackUrl, options);
      setAuthToken(response.auth_token, response.ttl * 1000);

      const member = yield getMember(response.member_id);

      if (regionId) {
        yield checkAndUpdateRegionId(regionId, member);
      }

      yield ensureCompanyCampaign({ payload: member });

      // ensure we have the right company copies
      yield put(fetchMemberSuccessAction(member));
      yield put(
        loginRequestSuccessAction({
          ...response,
          member,
          email: email || member.email,
        }),
      );
    } catch (e) {
      console.error(e);
      if (isSignUp) {
        yield put({
          type: SIGNUP_REQUEST_FAILURE,
          error: {
            intlMessage: {
              ...messages.unableToSignUp,
              values: { email, supportEmail: Config.supportEmail },
            },
          },
        });
      } else {
        yield put(loginRequestFailureAction({ message: 'Incorrect Details' }));
      }
    }
  }
}

function* patchMemberMedicalInformation({ payload }) {
  const { body = {}, memberId } = payload;
  const method = 'PATCH';
  const endpoint = `${memberUrl}/${memberId}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(patchMemberMedicalInformationSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(patchMemberMedicalInformationFailure(e));
  }
}

function* setMemberEligibility({ payload }) {
  const memberEligibility = payload;
  if (!memberEligibility) {
    return;
  }
  setMemberEligibilityExpired(memberEligibility.expired);
}

function* setCompanyConfig({ payload }) {
  const companyConfig = payload;
  setEdiEnabled(companyConfig.edi_enabled);
}

export default function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, loginMember);
  yield takeLatest(SIGN_OUT_USER, signoutUser);
  yield takeLatest(SIGN_OUT_USER_SUCCESS, signoutUserSuccess);
  yield takeLatest(SIGNUP_REQUEST, createMember);
  yield takeLatest(GOOGLE_LOGIN_REQUEST, googleSSO);
  yield takeLatest(GOOGLE_SIGNUP_REQUEST, googleSSO);
  yield takeLatest(LOGIN_REQUEST_SUCCESS, onLoginRequestSuccess);
  yield takeLatest(FETCH_MEMBER_SUCCESS, getAccountQuestionSetOnMemberFetch);
  yield takeLatest(FETCH_MEMBER_SUCCESS, setSegmentMember);
  yield takeLatest(SIGN_OUT_USER_SUCCESS, removeSegmentMember);
  yield takeLatest(FETCH_MEMBER_SUCCESS, setIntercomMember);
  yield takeLatest(BOOT_INTERCOM, setIntercomMember);
  yield takeLatest(FETCH_MEMBER_SUCCESS, ensureCompanyCampaign);
  yield takeLatest(FETCH_MEMBER_ELIGIBILITIES_SUCCESS, setMemberEligibility);
  yield takeLatest(FETCH_COMPANY_CONFIG_SUCCESS, setCompanyConfig);
  // refresh member after account profile complete
  yield takeLatest(LOAD_PROVIDER_OPTIONS, loadProviderOptions);
  yield takeLatest(FETCH_MEMBER, fetchMember);
  yield takeLatest(
    PATCH_MEMBER_MEDICAL_INFORMATION,
    patchMemberMedicalInformation,
  );
}
