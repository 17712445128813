import keys from 'utils/localStorageKeys.json';

export const LOCALE_IE = 'en-IE';
export const COUNTRY_IE = 'Ireland';

export const LOCALE_US = 'en-US';
export const COUNTRY_US = 'US';
export const CURRENCY_US = 'USD';

export const DEFAULT_LOCALE = localStorage.getItem(keys.locale) || LOCALE_US;
export const DEFAULT_COUNTRY = localStorage.getItem(keys.country) || COUNTRY_US;
export const DEFAULT_CURRENCY =
  localStorage.getItem(keys.currency) || CURRENCY_US;
