/*
 *
 * MemberProfile constants
 *
 */

export const DEFAULT_ACTION = 'app/MemberProfile/DEFAULT_ACTION';

export const MEMBER_INSURANCE_DETAILS_ENDPOINT = 'v3/member_insurance_details';

export const FETCH_MEMBER_INSURANCE_DETAILS =
  'app/MemberProfile/FETCH_MEMBER_INSURANCE_DETAILS';
export const FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS =
  'app/MemberProfile/FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS';
export const FETCH_MEMBER_INSURANCE_DETAILS_FAILURE =
  'app/MemberProfile/FETCH_MEMBER_INSURANCE_DETAILS_FAILURE';

export const PATCH_MEMBER_INSURANCE_DETAILS =
  'app/MemberProfile/PATCH_MEMBER_INSURANCE_DETAILS';
export const PATCH_MEMBER_INSURANCE_DETAILS_SUCCESS =
  'app/MemberProfile/PATCH_MEMBER_INSURANCE_DETAILS_SUCCESS';
export const PATCH_MEMBER_INSURANCE_DETAILS_FAILURE =
  'app/MemberProfile/PATCH_MEMBER_INSURANCE_DETAILS_FAILURE';

export const COVERAGE_PROVIDER_PLAN_CODES_ENDPOINT =
  'v3/coverage_provider_plan_codes';

export const FETCH_COVERAGE_PROVIDER_PLAN_CODES =
  'app/MemberProfile/FETCH_COVERAGE_PROVIDER_PLAN_CODES';
export const FETCH_COVERAGE_PROVIDER_PLAN_CODES_SUCCESS =
  'app/MemberProfile/FETCH_COVERAGE_PROVIDER_PLAN_CODES_SUCCESS';
export const FETCH_COVERAGE_PROVIDER_PLAN_CODES_FAILURE =
  'app/MemberProfile/FETCH_COVERAGE_PROVIDER_PLAN_CODES_FAILURE';

export const MEMBERS_ENDPOINT = 'v3/members';

export const PATCH_MEMBER = 'app/MemberProfile/PATCH_MEMBER';
export const PATCH_MEMBER_SUCCESS = 'app/MemberProfile/PATCH_MEMBER_SUCCESS';
export const PATCH_MEMBER_FAILURE = 'app/MemberProfile/PATCH_MEMBER_FAILURE';

export const REGIONS_ENDPOINT = 'v3/regions';

export const FETCH_REGIONS = 'app/MemberProfile/FETCH_REGIONS';
export const FETCH_REGIONS_SUCCESS = 'app/MemberProfile/FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'app/MemberProfile/FETCH_REGIONS_FAILURE';

export const MEMBER_ELIGIBILITIES_ENDPOINT = 'v3/member_eligibilities';

export const FETCH_MEMBER_ELIGIBILITIES =
  'app/MemberProfile/FETCH_MEMBER_ELIGIBILITIES';
export const FETCH_MEMBER_ELIGIBILITIES_SUCCESS =
  'app/MemberProfile/FETCH_MEMBER_ELIGIBILITIES_SUCCESS';
export const FETCH_MEMBER_ELIGIBILITIES_FAILURE =
  'app/MemberProfile/FETCH_MEMBER_ELIGIBILITIES_FAILURE';

export const MEMBER_ELIGIBILITIES_POLLING_TIME = 5000;

export const ELIGIBILITY_REQUEST_ENDPOINT = 'v3/eligibility_requests';

export const CREATE_ELIGIBILITY_REQUEST =
  'app/MemberProfile/CREATE_ELIGIBILITY_REQUEST';
export const CREATE_ELIGIBILITY_REQUEST_SUCCESS =
  'app/MemberProfile/CREATE_ELIGIBILITY_REQUEST_SUCCESS';
export const CREATE_ELIGIBILITY_REQUEST_FAILURE =
  'app/MemberProfile/CREATE_ELIGIBILITY_REQUEST_FAILURE';
