import {
  selectMember,
  selectMemberLoading,
} from 'containers/Auth/AuthRouter/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuthenticated from './useAuthenticated';

// member can come in from 2 paths
// 1. loaded here after login, or
// 2. already loaded, set in localstorage on return with valid token
const useMember = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useAuthenticated();

  const memberLoading = useSelector(selectMemberLoading());
  const reduxMember = useSelector(selectMember());

  const [member, setMember] = useState(reduxMember || null);

  // update if there are changes
  useEffect(() => {
    if (!member && reduxMember) {
      setMember(reduxMember);
    } else if (
      member &&
      reduxMember &&
      +new Date(reduxMember.updated_at) > +new Date(member.updated_at)
    ) {
      setMember(reduxMember);
    }
  }, [member, reduxMember]);

  useEffect(() => {
    if (!isAuthenticated) {
      setMember(null);
    }
  }, [isAuthenticated]);
  return member;
};

export default useMember;
