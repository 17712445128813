import { initialState } from 'containers/Auth/AuthRouter/initialState';
import { createSelector } from 'reselect';

/**
 * Direct selector to the login state domain
 */

const selectAuthDomain = (state) => state?.auth || initialState;

/**
 * Other specific selectors
 */

const selectToken = () =>
  createSelector(selectAuthDomain, (authState) => authState.authToken);

const selectProviderOptions = () =>
  createSelector(selectAuthDomain, (authState) => authState.providerOptions);

const selectEmail = () =>
  createSelector(selectAuthDomain, (authState) => authState?.email);

const selectItem = (item) =>
  createSelector(selectAuthDomain, (state) => state && state[item]);

const selectMember = () =>
  createSelector(selectAuthDomain, (state) => state.member);

const selectMemberLoading = () =>
  createSelector(selectAuthDomain, (state) => state.memberLoading);

const selectAccountQuestionSet = () =>
  createSelector(selectAuthDomain, (authState) =>
    authState ? authState.accountQuestionSet : null,
  );

const selectLoadingSSO = () =>
  createSelector(selectAuthDomain, (state) => state.loadingSSO);

const selectAccountProfileComplete = () =>
  createSelector(
    selectAuthDomain,
    (authState) => authState.accountProfileComplete,
  );

/**
 * Default selector used by Login
 */

const makeSelectLogin = () =>
  createSelector(selectAuthDomain, (substate) => substate);

export default makeSelectLogin;
export {
  selectAccountProfileComplete,
  selectAuthDomain,
  selectToken,
  selectItem,
  selectMember,
  selectMemberLoading,
  selectLoadingSSO,
  selectEmail,
  selectProviderOptions,
  selectAccountQuestionSet,
};
