import { DATABASE_VERSION, PHOTO_DB_NAME, PHOTO_STORE_NAME } from './constants';

export const DBConfig = {
  name: PHOTO_DB_NAME,
  version: DATABASE_VERSION,
  objectStoresMeta: [
    {
      store: PHOTO_STORE_NAME,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        {
          name: 'patientCaseId',
          keypath: 'patientCaseId',
          options: { unique: false },
        },
        {
          name: 'photoCategoryName',
          keypath: 'photoCategoryName',
          options: { unique: false },
        },
        {
          name: 'photoCategoryId',
          keypath: 'photoCategoryId',
          options: { unique: false },
        },
        { name: 'image', keypath: 'image', options: { unique: false } },
        {
          name: 'contentType',
          keypath: 'contentType',
          options: { unique: false },
        },
      ],
    },
  ],
};
