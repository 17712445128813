/*
 *
 * VideoCall actions
 *
 */

import {
  END_VIDEO_CALL,
  END_VIDEO_CALL_FAILURE,
  END_VIDEO_CALL_SUCCESS,
  FETCH_ASSESSOR,
  FETCH_ASSESSOR_FAILURE,
  FETCH_ASSESSOR_SUCCESS,
  GET_VIDEO_CALL_ACTION,
  GET_VIDEO_CALL_FAILURE_ACTION,
  GET_VIDEO_CALL_SUCCESS_ACTION,
  RESET_ASSESSOR,
  RESET_START_VIDEO_CALL_STATUS,
  RESET_VIDEO_CALL_DETAILS,
  START_VIDEO_CALL_ACTION,
  START_VIDEO_CALL_ACTION_FAILURE,
  START_VIDEO_CALL_ACTION_SUCCESS,
} from './constants';

export const fetchAssessorAction = (payload) => {
  return {
    type: FETCH_ASSESSOR,
    payload,
  };
};

export const fetchAssessorSuccessAction = (payload) => {
  return {
    type: FETCH_ASSESSOR_SUCCESS,
    payload,
  };
};

export const fetchAssessorFailureAction = (error) => {
  return {
    type: FETCH_ASSESSOR_FAILURE,
    error,
  };
};

export const startVideoCallAction = (payload) => {
  return {
    type: START_VIDEO_CALL_ACTION,
    payload,
  };
};

export const startVideoCallSuccessAction = (payload) => {
  return {
    type: START_VIDEO_CALL_ACTION_SUCCESS,
    payload,
  };
};

export const startVideoCallFailureAction = (error) => {
  return {
    type: START_VIDEO_CALL_ACTION_FAILURE,
    error,
  };
};

export const endVideoCallAction = (payload) => {
  return {
    type: END_VIDEO_CALL,
    payload,
  };
};

export const endVideoCallSuccessAction = (payload) => {
  return {
    type: END_VIDEO_CALL_SUCCESS,
    payload,
  };
};

export const endVideoCallFailureAction = (error) => {
  return {
    type: END_VIDEO_CALL_FAILURE,
    error,
  };
};

export const resetAssessorAction = () => {
  return {
    type: RESET_ASSESSOR,
  };
};

export const resetStartVideoCallValueAction = () => {
  return {
    type: RESET_START_VIDEO_CALL_STATUS,
  };
};

export const resetVideoCallDetails = () => {
  return {
    type: RESET_VIDEO_CALL_DETAILS,
  };
};

export const getVideoCallAction = (payload) => {
  return {
    type: GET_VIDEO_CALL_ACTION,
    payload,
  };
};

export const getVideoCallSuccessAction = (payload) => {
  return {
    type: GET_VIDEO_CALL_SUCCESS_ACTION,
    payload,
  };
};

export const getVideoCallFailureAction = (error, payload) => {
  return {
    type: GET_VIDEO_CALL_FAILURE_ACTION,
    payload,
  };
};
