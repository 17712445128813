/*
 *
 * Member Profile View constants
 *
 */

export const VIDEO_SETTINGS = {
  label: 'Video settings',
  value: 'video',
};
export const PERSONAL_DETAILS = {
  label: 'Personal details',
  value: 'personal',
};
export const INSURANCE_DETAILS = {
  label: 'Insurance details',
  value: 'insurance',
};

export const UNVERIFIED = 'unverified';
export const ELIGIBLE = 'eligible';
export const INELIGIBLE = 'ineligible';
