/*
 *
 * Consultation actions
 *
 */

import {
  LOAD_CONSULTATION_DATA,
  LOAD_CONSULTATION_DATA_FAILURE,
  LOAD_CONSULTATION_DATA_SUCCESS,
  RESET_UPSERT_VIDEO_CALL,
  SET_CONSULTATION_STAGES,
  UPDATE_CONSULTATION_STAGE_DATA,
  UPSERT_VIDEO_CALL,
  UPSERT_VIDEO_CALL_FAILURE,
  UPSERT_VIDEO_CALL_SUCCESS,
} from './constants';

export const loadConsultationDataAction = (payload) => ({
  type: LOAD_CONSULTATION_DATA,
  payload,
});

export const loadConsultationDataSuccessAction = (payload) => ({
  type: LOAD_CONSULTATION_DATA_SUCCESS,
  payload,
});

export const updateConsultationStageAction = (payload) => ({
  type: UPDATE_CONSULTATION_STAGE_DATA,
  payload,
});

export const loadConsultationDataFailureAction = (error, payload) => ({
  type: LOAD_CONSULTATION_DATA_FAILURE,
  payload,
  error,
});

export const setConsultationStages = (payload) => ({
  type: SET_CONSULTATION_STAGES,
  payload,
});

export const resetVideoCallAction = (payload) => ({
  type: RESET_UPSERT_VIDEO_CALL,
  payload,
});
export const upsertVideoCallAction = (payload) => ({
  type: UPSERT_VIDEO_CALL,
  payload,
});
export const upsertVideoCallSuccessAction = (payload) => ({
  type: UPSERT_VIDEO_CALL_SUCCESS,
  payload,
});
export const upsertVideoCallFailureAction = (error, payload) => ({
  type: UPSERT_VIDEO_CALL_FAILURE,
  error,
  payload,
});
