import { updateLocaleDataAction } from 'containers/LanguageProvider/actions';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { makeSelectLocaleData } from './selectors';

export function LanguageProvider({
  children,
  companyCopy,
  dispatch,
  locale,
  country,
  currency,
  messages,
}) {
  useEffect(() => {
    // have to update based on the passed-in company copy action
    // as the reducer is initialised with the localstorage/default
    if (
      (companyCopy?.locale && companyCopy.locale !== locale) ||
      (companyCopy?.currency && companyCopy.currency !== currency) ||
      (companyCopy?.country && companyCopy.country !== country)
    ) {
      dispatch(updateLocaleDataAction(companyCopy));
    }
  }, [companyCopy, locale, country, currency]);

  // stop initial re-render if this is different
  const _locale = useMemo(() => {
    return companyCopy?.locale || locale;
  }, [companyCopy, locale]);

  return (
    <IntlProvider locale={_locale} key={_locale} messages={messages[_locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  companyCopy: PropTypes.any,
  country: PropTypes.string,
  currency: PropTypes.string,
  dispatch: PropTypes.func,
  locale: PropTypes.string,
  messages: PropTypes.object,
};

const mapStateToProps = createSelector(
  makeSelectLocaleData(),
  ({ locale, country, currency }) => ({
    locale,
    country,
    currency,
  }),
);

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
