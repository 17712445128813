/*
 *
 * Photos constants
 *
 */

export const SUBMIT_PHOTOS = 'app/Photos/SUBMIT_PHOTOS';
export const SUBMIT_PHOTOS_SUCCESS = 'app/Photos/SUBMIT_PHOTOS_SUCCESS';
export const SUBMIT_PHOTOS_FAILURE = 'app/Photos/SUBMIT_PHOTOS_FAILURE';

export const SKIP_PHOTOS = 'app/Photos/SKIP_PHOTOS';
export const SKIP_PHOTOS_SUCCESS = 'app/Photos/SKIP_PHOTOS_SUCCESS';
export const SKIP_PHOTOS_FAILURE = 'app/Photos/SKIP_PHOTOS_FAILURE';

export const GET_FLOW_MESSAGE = 'app/Photos/GET_FLOW_MESSAGE';
export const GET_FLOW_MESSAGE_SUCCESS = 'app/Photos/GET_FLOW_MESSAGE_SUCCESS';
export const GET_FLOW_MESSAGE_FAILURE = 'app/Photos/GET_FLOW_MESSAGE_FAILURE';

export const GET_CASE_PHOTOS = 'app/Photos/GET_CASE_PHOTOS';
export const GET_CASE_PHOTOS_SUCCESS = 'app/Photos/GET_CASE_PHOTOS_SUCCESS';
export const GET_CASE_PHOTOS_FAILURE = 'app/Photos/GET_CASE_PHOTOS_FAILURE';

export const GET_PATIENT_CASE_TEMPLATE = 'app/Photos/GET_PATIENT_CASE_TEMPLATE';
export const GET_PATIENT_CASE_TEMPLATE_SUCCESS =
  'app/Photos/GET_PATIENT_CASE_TEMPLATE_SUCCESS';
export const GET_PATIENT_CASE_TEMPLATE_FAILURE =
  'app/Photos/GET_PATIENT_CASE_TEMPLATE_FAILURE';

export const LOAD_PHOTOS_FROM_INDEXED_DB =
  'app/Photos/LOAD_PHOTOS_FROM_INDEXED_DB';
export const LOAD_PHOTOS_FROM_INDEXED_DB_FAILURE =
  'app/Photos/LOAD_PHOTOS_FROM_INDEXED_DB_FAILURE';
export const LOAD_PHOTOS_FROM_INDEXED_DB_SUCCESS =
  'app/Photos/LOAD_PHOTOS_FROM_INDEXED_DB_SUCCESS';
export const COUNT_PHOTOS_FROM_INDEXED_DB =
  'app/Photos/COUNT_PHOTOS_FROM_INDEXED_DB';
export const COUNT_PHOTOS_FROM_INDEXED_DB_FAILURE =
  'app/Photos/COUNT_PHOTOS_FROM_INDEXED_DB_FAILURE';
export const COUNT_PHOTOS_FROM_INDEXED_DB_SUCCESS =
  'app/Photos/COUNT_PHOTOS_FROM_INDEXED_DB_SUCCESS';

export const DELETE_CASE_PHOTOS_FROM_DATABASE =
  'app/Photos/DELETE_CASE_PHOTOS_FROM_DATABASE';
export const DELETE_CASE_PHOTOS_FROM_DATABASE_SUCCESS =
  'app/Photos/DELETE_CASE_PHOTOS_FROM_DATABASE_SUCCESS';
export const DELETE_CASE_PHOTOS_FROM_DATABASE_FAILURE =
  'app/Photos/DELETE_CASE_PHOTOS_FROM_DATABASE_FAILURE';

export const CONFIRM_PHOTO = 'app/Photos/CONFIRM_PHOTO';
export const RESET_CASE_SUBMISSION_SUCCESS =
  'app/Photos/RESET_CASE_SUBMISSION_SUCCESS';

// Endpoints
export const PATIENT_CASE_TEMPLATES_ENDPOINT = 'v3/patient_case_templates';
export const CASE_PHOTOS_ENDPOINT = 'v3/case_photos?patient_case_id=:id';
export const FLOW_MESSAGES_ENDPOINT = 'v3/flow_messages';

export const FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES =
  'app/Photos/FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES';
export const FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS =
  'app/Photos/FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS';
export const FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE =
  'app/Photos/FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE';

export const CASE_PHOTO_STATUS_MISSING_DETAILS = 'missing_details';
export const CASE_PHOTOS_STATUS_COMPLETE = 'complete';
export const CASE_PHOTO_STATUS_S3_UPLOAD_ERROR = 's3_upload_error';
export const CASE_PHOTO_STATUS_S3_UPLOAD_COMPLETE = 's3_upload_complete';

export const UPLOAD_PHOTOS_PROGRESS = 'app/Photos/UPLOAD_PHOTOS_PROGRESS';

export const PATCH_CASE_PHOTO_SUCCESS = 'app/Photos/PATCH_CASE_PHOTO_SUCCESS';
