import {
  fetchCheckoutDataFailureAction,
  fetchCheckoutDataSuccessAction,
  fetchPaymentIntentsAction,
  fetchPaymentIntentsFailure,
  fetchPaymentIntentsSuccess,
  fetchPaymentsAction,
  fetchPaymentsFailure,
  fetchPaymentsSuccess,
  patchPaymentsFailure,
  patchPaymentsSuccess,
} from 'containers/Consultation/Checkout/actions';
import {
  FETCH_CHECKOUT_DATA,
  FETCH_PAYMENT_INTENTS,
  FETCH_PAYMENT_INTENTS_FAILURE,
  FETCH_PAYMENT_INTENTS_SUCCESS,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAILURE,
  FETCH_PAYMENTS_SUCCESS,
  PATCH_PAYMENT_FETCH_INTENT,
  PATCH_PAYMENTS,
  PAYMENT_INTENTS_ENDPOINT,
  PAYMENTS_ENDPOINT,
} from 'containers/Consultation/Checkout/constants';
import { fetchMemberInsuranceDetailsAction } from 'containers/MemberProfile/actions';
import {
  FETCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
} from 'containers/MemberProfile/constants';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { retryRequest } from 'utils/retryRequest';

const checkPayment = () => (data) => !!data?.length;

export const fetchPaymentRequest = async (patientCaseId) => {
  const method = 'GET';
  const endpoint = `${PAYMENTS_ENDPOINT}?patient_case_id=${patientCaseId}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return await retryRequest(endpoint, requestData, checkPayment(), 3);
};

function* fetchPayments({ payload }) {
  try {
    const response = yield call(fetchPaymentRequest, payload.patientCaseId);
    yield put(fetchPaymentsSuccess(response[0]));
    return response[0] || null;
  } catch (e) {
    console.error(e);
    window.Rollbar?.error(e);
    yield put(fetchPaymentsFailure(e));
  }
}

function* patchPayments({ payload }) {
  const { paymentId, stripeId, promotionCode, outOfPocket } = payload;
  const method = 'PATCH';
  const endpoint = `${PAYMENTS_ENDPOINT}/${paymentId}`;
  const headers = new Headers();
  const body = {
    promotion_code: promotionCode,
    stripe_id: stripeId,
    out_of_pocket: outOfPocket,
  };
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(patchPaymentsSuccess(response));
    return response;
  } catch (e) {
    console.error(e);
    window.Rollbar?.error(e);
    yield put(patchPaymentsFailure(e));
  }
}

function* patchPaymentFetchIntent({ payload }) {
  const payment = yield patchPayments({ payload });
  yield fetchPaymentIntents({ payload: { paymentId: payment.id } });
}

function* fetchPaymentIntents({ payload }) {
  const { paymentId } = payload;
  const method = 'GET';
  const endpoint = `${PAYMENT_INTENTS_ENDPOINT}?payment_id=${paymentId}&status=requires_source`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  const paymentIntents = yield call(request, endpoint, requestData);
  if (paymentIntents.length === 0) {
    yield put(fetchPaymentIntentsSuccess({}));
    return {};
  } else {
    try {
      const response = yield call(
        fetchPaymentIntentRequest,
        paymentIntents[0].id,
      );
      yield put(fetchPaymentIntentsSuccess(response));
      return response;
    } catch (e) {
      console.error(e);
      window.Rollbar?.error(e);
      yield put(fetchPaymentIntentsFailure(e));
    }
  }
}

const fetchPaymentIntentRequest = async (paymentIntentId) => {
  const method = 'GET';
  const endpoint = `${PAYMENT_INTENTS_ENDPOINT}/${paymentIntentId}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return request(endpoint, requestData);
};

function* getPayment(patientCaseId) {
  yield put(fetchPaymentsAction({ patientCaseId }));
  const [{ payload: payment }, e] = yield race([
    take(FETCH_PAYMENTS_SUCCESS),
    take(FETCH_PAYMENTS_FAILURE),
  ]);
  if (e) {
    throw e;
  }
  return payment;
}

function* getPaymentIntent(paymentId) {
  yield put(fetchPaymentIntentsAction({ paymentId }));
  const [{ payload: paymentIntent }, e] = yield race([
    take(FETCH_PAYMENT_INTENTS_SUCCESS),
    take(FETCH_PAYMENT_INTENTS_FAILURE),
  ]);
  if (e) {
    throw e;
  }
  return paymentIntent;
}

function* getPaymentAndIntent(patientCaseId) {
  const payment = yield getPayment(patientCaseId);
  const paymentIntent = yield getPaymentIntent(payment.id);
  return { payment, paymentIntent };
}

function* getMemberInsuranceDetails() {
  yield put(fetchMemberInsuranceDetailsAction());
  const [{ payload: memberInsuranceDetails }, e] = yield race([
    take(FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS),
    take(FETCH_MEMBER_INSURANCE_DETAILS_FAILURE),
  ]);

  if (e) {
    throw e;
  }

  return memberInsuranceDetails;
}

function* fetchCheckoutData({ payload }) {
  try {
    const { patientCaseId } = payload;
    const [{ payment, paymentIntent }, memberInsuranceDetails] = yield all([
      getPaymentAndIntent(patientCaseId),
      getMemberInsuranceDetails(),
    ]);
    yield put(
      fetchCheckoutDataSuccessAction({
        payment,
        paymentIntent,
        memberInsuranceDetails,
      }),
    );
  } catch (e) {
    window.Rollbar?.error(e);
    console.error(e);
    yield put(fetchCheckoutDataFailureAction(e, payload));
  }
}

export default function* checkoutSaga() {
  yield takeLatest(FETCH_PAYMENTS, fetchPayments);
  yield takeLatest(FETCH_PAYMENT_INTENTS, fetchPaymentIntents);
  yield takeLatest(PATCH_PAYMENTS, patchPayments);
  yield takeLatest(PATCH_PAYMENT_FETCH_INTENT, patchPaymentFetchIntent);
  yield takeLatest(FETCH_CHECKOUT_DATA, fetchCheckoutData);
}
