import Loader from 'components/Loader';
import Config from 'config';
import React from 'react';
import { Helmet } from 'react-helmet';

const SignInContainer = React.lazy(() =>
  Promise.all([import('./index')]).then(([moduleExports]) => moduleExports),
);

export default (props) => (
  <React.Suspense fallback={<Loader />}>
    <Helmet title={`${Config.companyName} | Sign in`} />
    <SignInContainer {...props} />
  </React.Suspense>
);
