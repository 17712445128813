/*
 *
 * GetCare reducer
 *
 */

import { SIGN_OUT_USER } from 'containers/Auth/AuthRouter/constants';
import {
  CREATE_PATIENT_CASE,
  CREATE_PATIENT_CASE_FAILURE,
  CREATE_PATIENT_CASE_SUCCESS,
  DELETE_PATIENT_CASE_SUCCESS,
  FETCH_PATIENT_CASE_TEMPLATE,
  FETCH_PATIENT_CASE_TEMPLATE_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATE_SUCCESS,
  FETCH_PATIENT_CASE_TEMPLATES,
  FETCH_PATIENT_CASE_TEMPLATES_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATES_SUCCESS,
  FETCH_PATIENT_CASES,
  FETCH_PATIENT_CASES_FAILURE,
  FETCH_PATIENT_CASES_SUCCESS,
  FETCH_REGIONS,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_SUCCESS,
  GET_PATIENT_CASE,
  GET_PATIENT_CASE_FAILURE,
  GET_PATIENT_CASE_SUCCESS,
  PATCH_MEMBERS_STATE,
  PATCH_MEMBERS_STATE_FAILURE,
  PATCH_MEMBERS_STATE_SUCCESS,
  PATCH_PATIENT_CASE_STATUS_SUCCESS,
  RESET_NEW_PATIENT_CASE,
} from 'containers/GetCare/constants';
import { addData } from 'utils/reducerHelpers';

export const initialState = () => ({
  patientCases: {
    loading: false,
    data: [],
    fetched: false,
  },
  caseTypes: {
    loading: false,
    data: [],
    fetched: false,
  },
  patientCaseTemplates: {
    loading: false,
    data: [],
    fetched: false,
    error: null,
  },
  regions: {
    loading: false,
    data: [],
    fetched: false,
  },
});

function getCareReducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_PATIENT_CASES:
      return {
        ...state,
        patientCases: { ...state.patientCases, loading: true },
      };
    case FETCH_PATIENT_CASES_SUCCESS:
      return fetchPatientCasesSuccess(state, action);
    case FETCH_PATIENT_CASES_FAILURE:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          loading: false,
          error: action.error,
          fetched: true,
        },
      };
    case CREATE_PATIENT_CASE:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          newCaseError: null,
          newCaseLoading: true,
        },
      };
    case CREATE_PATIENT_CASE_SUCCESS:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          data: [...state.patientCases.data, action.payload],
          newCaseLoading: false,
        },
      };
    case CREATE_PATIENT_CASE_FAILURE:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          newCaseError: action.error,
          newCaseLoading: false,
        },
      };

    case PATCH_PATIENT_CASE_STATUS_SUCCESS:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          data: [
            ...state.patientCases.data.filter(
              (pc) => pc.id !== action.payload.id,
            ),
            action.payload,
          ],
        },
      };
    case RESET_NEW_PATIENT_CASE:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          newCreatedCase: null,
        },
      };

    case DELETE_PATIENT_CASE_SUCCESS:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          data: state.patientCases.data.filter(
            (patientCase) => patientCase.id !== action.payload.id,
          ),
        },
      };
    case GET_PATIENT_CASE:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          loading: true,
          error: null,
        },
      };
    case GET_PATIENT_CASE_FAILURE:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          loading: false,
          error: action.error,
        },
      };
    case GET_PATIENT_CASE_SUCCESS:
      return {
        ...state,
        patientCases: {
          ...state.patientCases,
          loading: false,
          data: addData(state.patientCases.data, [action.payload]),
          error: null,
        },
      };
    case FETCH_PATIENT_CASE_TEMPLATES:
      return {
        ...state,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          loading: true,
          error: null,
        },
      };
    case FETCH_PATIENT_CASE_TEMPLATES_FAILURE:
      return {
        ...state,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          loading: false,
          error: 'Could not find patient case templates',
        },
      };
    case FETCH_PATIENT_CASE_TEMPLATES_SUCCESS:
      return {
        ...state,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          data: addData(state.patientCaseTemplates.data, action.payload),
          loading: false,
          fetched: true,
          error: null,
        },
      };
    case FETCH_PATIENT_CASE_TEMPLATE:
      return {
        ...state,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          loading: true,
          error: null,
        },
      };
    case FETCH_PATIENT_CASE_TEMPLATE_FAILURE:
      return {
        ...state,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          loading: false,
          error: 'Could not find patient case template',
        },
      };
    case FETCH_PATIENT_CASE_TEMPLATE_SUCCESS:
      return {
        ...state,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          data: addData(state.patientCaseTemplates.data, action.payload),
          loading: false,
          fetched: true,
          error: null,
        },
      };
    case FETCH_REGIONS:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: true,
        },
      };
    case FETCH_REGIONS_FAILURE:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: false,
        },
      };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: {
          ...state.regions,
          data: action.payload,
          loading: false,
          fetched: true,
        },
      };
    case PATCH_MEMBERS_STATE:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: true,
        },
      };
    case PATCH_MEMBERS_STATE_FAILURE:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: false,
        },
      };
    case PATCH_MEMBERS_STATE_SUCCESS:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: false,
        },
      };
    case SIGN_OUT_USER:
      return initialState();
    default:
      return state;
  }
}

const fetchPatientCasesSuccess = (state, action) => {
  const data = [...state.patientCases.data];
  if (!action.payload?.length) {
    return {
      ...state,
      patientCases: {
        ...state.patientCases,
        loading: false,
        fetched: true,
      },
    };
  }
  action.payload?.forEach((pc) => {
    const exists = data.findIndex((d) => d.id === pc.id);
    if (exists > -1) {
      data[exists] = pc;
    } else {
      data.push(pc);
    }
  });
  return {
    ...state,
    patientCases: {
      ...state.patientCases,
      loading: false,
      data: addData(state.patientCases.data, action.payload),
      fetched: true,
    },
  };
};

export default getCareReducer;
