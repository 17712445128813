/**
 *
 * FormattedHelmetTitle
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const FormattedHelmetTitle = ({ values, defaultMessage, id, ...props }) => {
  const intl = useIntl();
  return <Helmet title={intl.formatMessage({ defaultMessage, id }, values)} />;
};

FormattedHelmetTitle.propTypes = {
  defaultMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default FormattedHelmetTitle;
