/*
 *
 * MedicalInformation reducer
 *
 */

import {
  PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE,
  PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS,
} from 'containers/Auth/AuthRouter/constants';
import {
  GET_MEMBER_MEDICAL_INFORMATION,
  GET_MEMBER_MEDICAL_INFORMATION_SUCCESS,
  POST_RESOURCE,
  POST_RESOURCE_FAILURE,
  POST_RESOURCE_SUCCESS,
} from './constants';

export const initialState = () => ({
  selected_medications: [],
  selected_allergies: [],
  selected_adverse_reactions: [],
  loadedMedications: [],
  loadedAllergies: [],
  loadedAdverseReactions: [],
  loading: false,
  error: null,
});

function medicalInformationReducer(state = initialState(), action) {
  switch (action.type) {
    case POST_RESOURCE:
      return {
        ...state,
        loading: true,
      };
    case POST_RESOURCE_SUCCESS:
      return postResourceSuccess(state, action);
    case POST_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS:
    case PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_MEMBER_MEDICAL_INFORMATION:
      return {
        ...state,
        loading: true,
      };
    case GET_MEMBER_MEDICAL_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loadedMedications: action.payload.medications,
        loadedAllergies: action.payload.allergies.filter(
          (item) => item.reaction_type === 'allergy',
        ),
        loadedAdverseReactions: action.payload.allergies.filter(
          (item) => item.reaction_type === 'adverse_reaction',
        ),
      };
    default:
      return state;
  }
}

const postResourceSuccess = (state, action) => {
  const { resourceName, response } = action.payload;
  let resource =
    resourceName === 'member_medications'
      ? 'selected_medications'
      : response.reaction_type === 'allergy'
      ? 'selected_allergies'
      : 'selected_adverse_reactions';
  return {
    ...state,
    [resource]: [...state[resource], response],
  };
};

export default medicalInformationReducer;
