import { getCompanyConfigsWithToken } from 'containers/Auth/AuthRouter/saga';
import {
  getAuthToken,
  getEdiEnabled,
  setEdiEnabled,
} from 'utils/localStorageHelper';

export function checkCompanyConfig() {
  const token = getAuthToken();
  if (!token) {
    return { ediEnabled: false };
  }

  return getCompanyConfigsWithToken()
    .then((companyConfig) => {
      setEdiEnabled(companyConfig.edi_enabled);
      return { ediEnabled: getEdiEnabled() };
    })
    .catch(() => {
      return { ediEnabled: false };
    });
}
