import { InputError, Label } from '@oraleye/frontend-modules-components';
import React, { useCallback, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { eventSend } from 'services/EventsService';
import events from 'services/EventsService/events.json';
import styled from 'styled-components';

const TwoInputContainer = styled.div`
  display: flex;
  gap: 8px;
`;
const InputContainer = styled.div`
  max-width: calc(50% - 4px);
  flex: 1;
  input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius-input);
    color: var(--neutral07);
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
    min-height: 16px;
    box-shadow: 0 0 0 1px transparent;
    padding: 14px 54px 14px 18px;

    &::placeholder {
      color: var(--neutral07);
    }

    // hover, active, focus
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1px var(--input-border-color);
      outline: none !important;
      background-color: rgba(255, 255, 255, 0.9);
    }

    // error state
    ${({ error }) =>
      error &&
      css`
        & {
          border-color: var(--error);
          color: var(--error);
        }

        &:hover,
        &:active,
        &:focus {
          border-color: var(--error);
          box-shadow: 0 0 0 1px var(--error);
        }

        &::placeholder {
          color: var(--error);
          opacity: 0.5;
        }
      `};

    &:disabled {
      background: var(--neutral01);
      border: solid 1px var(--neutral05);
      box-shadow: 0 0 0 1px transparent;

      &::placeholder {
        color: var(--neutral07);
        opacity: 0.5;
      }

      cursor: not-allowed;
    }
  }
  position: relative;

  span {
    position: absolute;
    right: 16px;
    top: calc(50% - 18px);
    color: var(--neutral08);
  }
`;
const HeightContainer = styled.div``;

export const HeightInput = ({ input, meta, label, onChange, ...props }) => {
  const { form } = useForm();
  const [value, setValue] = useState({
    ft: parseInt(input.value / 12) || 0,
    in: parseInt(input.value % 12) || 0,
  });

  const _onChange = (e) => {
    const newValue = { ...value, [e.target.name]: e.target.value };
    setValue({ ...value, [e.target.name]: e.target.value });
    const v = parseInt(newValue.ft) * 12 + parseInt(newValue.in);
    input.onChange({
      target: { name: input.name, value: v },
    });
    onChange &&
      onChange({
        target: { name: input.name, value: v },
      });
  };

  const errorShownEvent = useCallback(
    (data) => {
      eventSend({
        field_name: name || input.name,
        error_message_shown: data,
        user_klass: 'member',
        event_name: events.formValidationError.event_name,
        form_name: form?.name,
      });
    },
    [input.name, form?.name],
  );

  return (
    <HeightContainer>
      <Label>{label}</Label>
      <TwoInputContainer>
        <InputContainer>
          <input
            type="number"
            min={1}
            max={9}
            step={1}
            placeholder="ft"
            name="ft"
            onChange={_onChange}
            value={value.ft}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
          />
          <span>ft</span>
        </InputContainer>
        <InputContainer>
          <input
            type="number"
            min={0}
            max={11}
            step={1}
            placeholder="in"
            name="in"
            onChange={_onChange}
            value={value.in}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
          />
          <span>in</span>
        </InputContainer>
      </TwoInputContainer>
      <InputError
        input={input}
        name={input.name}
        meta={meta}
        onErrorShown={errorShownEvent}
      />
    </HeightContainer>
  );
};

export default ({ name, ...props }) => {
  return <Field name={name} component={HeightInput} {...props} />;
};
