import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the photos state domain
 */

const selectPhotosDomain = (state = initialState()) => state.photos;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Photos
 */

const makeSelectPhotos = () =>
  createSelector(selectPhotosDomain, (substate) => substate);

export default makeSelectPhotos;
export { selectPhotosDomain };
/**
 * Other specific selectors
 */

const selectCasePhotos = (patientCaseId) =>
  createSelector(
    selectPhotosDomain,
    (substate) => substate.casePhotos[patientCaseId],
  );

const selectTakenCasePhotos = (patientCaseId) =>
  createSelector(
    selectPhotosDomain,
    (substate) => substate.takenPhotos[patientCaseId] || {},
  );

const selectCaseSubmissionSuccess = () =>
  createSelector(
    selectPhotosDomain,
    (substate) => substate.caseSubmissionSuccess,
  );

const selectCaseSubmissionError = () =>
  createSelector(
    selectPhotosDomain,
    (substate) => substate.caseSubmissionError,
  );
const selectPatientCasePatching = () =>
  createSelector(
    selectPhotosDomain,
    (substate) => substate.patientCasePatching,
  );

const selectPatientCasePatchingError = () =>
  createSelector(selectPhotosDomain, (substate) => substate.patchError);

const selectSubmittingPhotos = () =>
  createSelector(selectPhotosDomain, (substate) => substate.isSubmittingPhotos);

const selectIsSkippingPhotos = createSelector(
  selectPhotosDomain,
  (substate) => substate.isSkippingPhotos,
);

const selectFlowMessageLoading = () =>
  createSelector(selectPhotosDomain, (substate) => substate.flowMessageLoading);

const selectFlowMessage = () =>
  createSelector(selectPhotosDomain, (substate) => substate.flowMessage);

const selectPatientCaseTemplatePhotoCategoriesLoading = (
  patientCaseTemplateId,
) =>
  createSelector(selectPhotosDomain, (substate) => {
    return substate.patientCaseTemplatePhotoCategoriesLoading;
  });
const selectPatientCaseTemplateSelectedPhotos = (patientCaseTemplateId) =>
  createSelector(selectPhotosDomain, (substate) => {
    return (
      substate.patientCaseTemplatePhotoCategories
        .filter((category) => {
          return (
            category.patient_case_template_id === patientCaseTemplateId &&
            category.selected
          );
        })
        .sort((a, b) => (a.order - b.order < 1 ? -1 : 1)) || []
    );
  });

const selectPatientCaseTemplate = (id) =>
  createSelector(selectPhotosDomain, (substate) => {
    return substate.patientCaseTemplates[id];
  });

export const selectPatientCaseTemplatesLoading = () =>
  createSelector(selectPhotosDomain, (substate) => {
    return substate.patientCaseTemplatesLoading;
  });

const selectCasePhotoCounts = () =>
  createSelector(selectPhotosDomain, (substate) => substate.counts);

const selectProgress = createSelector(
  selectPhotosDomain,
  (substate) => substate.uploadProgress,
);

export {
  selectCasePhotos,
  selectTakenCasePhotos,
  selectSubmittingPhotos,
  selectFlowMessageLoading,
  selectFlowMessage,
  selectIsSkippingPhotos,
  selectPatientCaseTemplateSelectedPhotos,
  selectCaseSubmissionSuccess,
  selectCaseSubmissionError,
  selectCasePhotoCounts,
  selectPatientCaseTemplate,
  selectProgress,
  selectPatientCaseTemplatePhotoCategoriesLoading,
  selectPatientCasePatching,
  selectPatientCasePatchingError,
};
