/**
 *
 * ProfileMenu
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LD_FEATURE_CASE_CREATION,
  LD_FEATURE_CASE_REPORTS,
  LD_FEATURE_MEMBER_PROFILE,
  LD_FEATURE_VIDEO_CALLING,
} from 'constants/launchDarklyFlags';
import {
  GET_CARE_PAGE,
  LOGOUT_PAGE,
  MEMBER_PROFILE,
  RECORDS_PAGE,
  VIDEO_PREFERENCES_PAGE,
} from 'constants/pageIdentifiers';
import File from 'images/file-alt.svg.js';
import HandMedical from 'images/hand-holding-medical.svg.js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { usePopper } from 'react-popper';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import tracking from 'tracking';
import messages from './messages';
import {
  ClickAwayListener,
  Menu,
  MenuItem,
  MenuItemLogout,
  MenuOpenButton,
} from './styled';

const ProfileMenu = () => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [showing, setShowing] = useState(false);
  const flags = useFlags();
  const { styles } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [-20, 16] },
      },
    ],
  });
  const { trackEvent } = useTracking();
  const toggleShowing = (event) => {
    trackEvent({ event_name: events.profileMenuClicked.event_name });
    setShowing(!showing);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClose = () => {
    setShowing(false);
  };

  const onProfileMenuItemClicked = React.useCallback(
    (menuItem) => {
      trackEvent({
        event_name: events.profileMenuItemClicked.event_name,
        menu_item_name: menuItem.name,
        menu_item_destination: menuItem.destination,
      });
    },
    [trackEvent],
  );

  return (
    <>
      <MenuOpenButton ref={setReferenceElement} onClick={toggleShowing}>
        <FontAwesomeIcon icon={['fa', 'user-circle']} width={35} height={35} />
      </MenuOpenButton>
      {showing && (
        <ClickAwayListener tabIndex={-1} onClick={handleClose}>
          <Menu
            ref={setPopperElement}
            style={{
              ...styles.popper,
              visibility: showing ? 'visible' : 'hidden',
            }}
          >
            {flags[LD_FEATURE_MEMBER_PROFILE] && (
              <MenuItem
                to={MEMBER_PROFILE}
                onClick={() =>
                  onProfileMenuItemClicked({
                    name: messages.profile.defaultMessage,
                    destination: MEMBER_PROFILE,
                  })
                }
              >
                {File}
                <FormattedMessage {...messages.profile} />
              </MenuItem>
            )}
            {!isMobile && flags[LD_FEATURE_CASE_CREATION] && (
              <MenuItem
                to={GET_CARE_PAGE}
                trackingId={tracking.header.getCare}
                onClick={() =>
                  onProfileMenuItemClicked({
                    name: messages.connectToADentist.defaultMessage,
                    destination: GET_CARE_PAGE,
                  })
                }
              >
                {HandMedical}
                <FormattedMessage {...messages.connectToADentist} />
              </MenuItem>
            )}
            {!isMobile && flags[LD_FEATURE_CASE_REPORTS] && (
              <MenuItem
                to={RECORDS_PAGE}
                trackingId={tracking.header.records}
                onClick={() =>
                  onProfileMenuItemClicked({
                    name: messages.records.defaultMessage,
                    destination: RECORDS_PAGE,
                  })
                }
              >
                {File}
                <FormattedMessage {...messages.records} />
              </MenuItem>
            )}
            {!isMobile && flags[LD_FEATURE_VIDEO_CALLING] && (
              <MenuItem
                to={VIDEO_PREFERENCES_PAGE}
                trackingId={tracking.header.videoPreferences}
                onClick={() =>
                  onProfileMenuItemClicked({
                    name: messages.videoPreferences.defaultMessage,
                    destination: VIDEO_PREFERENCES_PAGE,
                  })
                }
              >
                <FontAwesomeIcon icon="video" />
                <FormattedMessage {...messages.videoPreferences} />
              </MenuItem>
            )}
            <MenuItemLogout
              to={LOGOUT_PAGE}
              trackingId={tracking.header.logout}
              onClick={() =>
                onProfileMenuItemClicked({
                  name: messages.logout.defaultMessage,
                  destination: LOGOUT_PAGE,
                })
              }
            >
              <FontAwesomeIcon icon={['fa', 'sign-out-alt']} />
              <FormattedMessage {...messages.logout} />
            </MenuItemLogout>
          </Menu>
        </ClickAwayListener>
      )}
    </>
  );
};

ProfileMenu.propTypes = {};
ProfileMenu.defaultProps = {};

export default ProfileMenu;
