import { parseUrl } from 'query-string';
import { getSMSId, setSMSId } from 'utils/localStorageHelper';

/**
 * Sets the SMS identifier, or uses the SMS identifier that is currently stored in localstorage
 * @return {null|  string}
 */
export function checkSMSId() {
  const qs = parseUrl(window.location.href).query;
  const sid = qs.sms || getSMSId();
  if (sid) {
    setSMSId(sid, true);
    return sid;
  }
  return null;
}
