import { Modal } from '@oraleye/frontend-modules-core';
import { Card } from 'components/common/Card';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Container from 'components/video/Container';
import styled, { css } from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Title = styled.h3`
  color: var(--neutral08);
`;

export const SubTitle = styled.p`
  color: var(--neutral08);
  font-weight: 300;
`;

export const BoldSubTitle = styled.span`
  color: var(--neutral08);
  font-weight: bolder;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  line-height: 41px;
  border-radius: 50%;
  color: white;
  background-color: ${({ color }) => color || 'var(--primary01)'};
  display: inline-block;
  margin-right: 16px;
  text-align: center;
`;

export const ModalHeader = styled.div`
  color: var(--primary01);
  font-size: 22px;
  line-height: 1.5;
  padding: 12px;
  button {
    color: var(--neutral07) !important;
    top: 12px;
    right: 12px;
  }
  button > svg {
    color: var(--neutral07) !important;
  }
`;

export const ModalBody = styled.div`
  text-align: left;
`;

export const StyledCard = styled(Card)`
  ${({ isLoading, disabled }) =>
    isLoading || disabled
      ? css`
          pointer-events: none;
          background-color: var(--neutral01);
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};
`;

export const StyledStateModal = styled(Modal)`
  padding: 40px 0;
`;

export const SetStateModalHeading = styled.div`
  font-size: 24px;
  line-height: 1.5;
  color: var(--primary01);
  margin-bottom: 16px;
`;

export const SetStateModalText = styled.div`
  font-size: 16px;
  line-height: 1.63;
  color: var(--neutral07);
  margin-bottom: 24px;
`;

export const StyledLoader = styled(Loader)`
  img {
    margin: auto;
  }
`;

export const GetCareContainer = styled(Container)`
  padding-left: 16px;
  padding-right: 16px;
`;

export const PricingContainer = styled.div`
  display: block;
  margin-bottom: 12px;

  ${({ theme }) =>
    theme.breakpoints.minMd(css`
      align-items: center;
      display: flex;
      gap: 10px;
    `)};
`;

export const StyledPrice = styled.h2`
  color: var(--neutral08);
  display: inline;
  margin: 0;
`;

export const StyledAlternativePrice = styled.p`
  color: var(--neutral08);
  display: inline;
  position: relative;
  bottom: 5px;
`;
