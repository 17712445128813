/*
 *
 * GetCare constants
 *
 */
export const PATIENT_CASES_ENDPOINT = 'v3/patient_cases';

export const DEFAULT_ACTION = 'app/GetCare/DEFAULT_ACTION';

export const FETCH_PATIENT_CASES = 'app/GetCare/FETCH_PATIENT_CASES';
export const FETCH_PATIENT_CASES_SUCCESS =
  'app/GetCare/FETCH_PATIENT_CASES_SUCCESS';
export const FETCH_PATIENT_CASES_FAILURE =
  'app/GetCare/FETCH_PATIENT_CASES_FAILURE';

export const GET_PATIENT_CASE = 'app/GetCare/GET_PATIENT_CASE';
export const GET_PATIENT_CASE_SUCCESS = 'app/GetCare/GET_PATIENT_CASE_SUCCESS';
export const GET_PATIENT_CASE_FAILURE = 'app/GetCare/GET_PATIENT_CASE_FAILURE';

export const GET_PATIENT_CASES = 'app/GetCare/GET_PATIENT_CASES';
export const GET_PATIENT_CASES_SUCCESS =
  'app/GetCare/GET_PATIENT_CASES_SUCCESS';
export const GET_PATIENT_CASES_FAILURE =
  'app/GetCare/GET_PATIENT_CASES_FAILURE';

export const CREATE_PATIENT_CASE = 'app/GetCare/CREATE_PATIENT_CASE';
export const CREATE_PATIENT_CASE_SUCCESS =
  'app/GetCare/CREATE_PATIENT_CASE_SUCCESS';
export const CREATE_PATIENT_CASE_FAILURE =
  'app/GetCare/CREATE_PATIENT_CASE_FAILURE';

export const NEW_PATIENT_CASE = 'app/GetCare/NEW_PATIENT_CASE';
export const NEW_PATIENT_CASE_SUCCESS = 'app/GetCare/NEW_PATIENT_CASE_SUCCESS';
export const NEW_PATIENT_CASE_FAILURE = 'app/GetCare/NEW_PATIENT_CASE_FAILURE';

export const DELETE_PATIENT_CASE = 'app/GetCare/DELETE_PATIENT_CASE';
export const DELETE_PATIENT_CASE_SUCCESS =
  'app/GetCare/DELETE_PATIENT_CASE_SUCCESS';
export const DELETE_PATIENT_CASE_FAILURE =
  'app/GetCare/DELETE_PATIENT_CASE_FAILURE';

export const FETCH_PATIENT_CASE_TEMPLATES =
  'app/GetCare/FETCH_PATIENT_CASE_TEMPLATES';
export const FETCH_PATIENT_CASE_TEMPLATES_SUCCESS =
  'app/GetCare/FETCH_PATIENT_CASE_TEMPLATES_SUCCESS';
export const FETCH_PATIENT_CASE_TEMPLATES_FAILURE =
  'app/GetCare/FETCH_PATIENT_CASE_TEMPLATES_FAILURE';

export const FETCH_PATIENT_CASE_TEMPLATE =
  'app/GetCare/FETCH_PATIENT_CASE_TEMPLATE';
export const FETCH_PATIENT_CASE_TEMPLATE_SUCCESS =
  'app/GetCare/FETCH_PATIENT_CASE_TEMPLATE_SUCCESS';
export const FETCH_PATIENT_CASE_TEMPLATE_FAILURE =
  'app/GetCare/FETCH_PATIENT_CASE_TEMPLATE_FAILURE';

export const FETCH_REGIONS = 'app/GetCare/FETCH_REGIONS';
export const FETCH_REGIONS_SUCCESS = 'app/GetCare/FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'app/GetCare/FETCH_REGIONS_FAILURE';

export const PATCH_MEMBERS_STATE = 'app/GetCare/PATCH_MEMBERS_STATE';
export const PATCH_MEMBERS_STATE_SUCCESS =
  'app/GetCare/PATCH_MEMBERS_STATE_SUCCESS';
export const PATCH_MEMBERS_STATE_FAILURE =
  'app/GetCare/PATCH_MEMBERS_STATE_FAILURE';

export const RESET_NEW_PATIENT_CASE = 'app/GetCare/RESET_NEW_PATIENT_CASE';

export const PATCH_PATIENT_CASE_STATUS =
  'app/GetCare/PATCH_PATIENT_CASE_STATUS';
export const PATCH_PATIENT_CASE_STATUS_SUCCESS =
  'app/GetCare/PATCH_PATIENT_CASE_STATUS_SUCCESS';
export const PATCH_PATIENT_CASE_STATUS_FAILURE =
  'app/GetCare/PATCH_PATIENT_CASE_STATUS_FAILURE';
