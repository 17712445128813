/* eslint-disable import/prefer-default-export */
/*
 *
 * LanguageProvider constants
 *
 */

export const UPDATE_LOCALE_DATA = 'app/LanguageToggle/UPDATE_LOCALE_DATA';
export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const CHANGE_COUNTRY = 'app/LanguageToggle/CHANGE_COUNTRY';
export const CHANGE_CURRENCY = 'app/LanguageToggle/CHANGE_CURRENCY';
