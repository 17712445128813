import featureConfigs from 'config/features';

const ConfigProxy = {
  set: function (obj, prop, val) {
    obj[prop] = val;
    // take all features from featureConfigs
    if (val === true && featureConfigs[prop]) {
      obj = Object.assign(obj, featureConfigs[prop]);
    }
    // obj.logging &&
    //   console.info(
    //     `config ${prop}: ${val} \n ${JSON.stringify(obj, null, 2).replace(
    //       /[{}"]/g,
    //       '',
    //     )}`,
    //   );
    return true;
  },
};
export default ConfigProxy;
