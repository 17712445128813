import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the memberProfile state domain
 */

const selectMemberProfileDomain = (state = initialState()) =>
  state.memberProfile;

/**
 * Other specific selectors
 */

const selectMemberInsuranceDetails = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberInsuranceDetails.data,
  );

const selectMemberInsuranceDetailsLoading = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberInsuranceDetails.loading,
  );

const selectMemberInsuranceDetailsError = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberInsuranceDetails.error,
  );

const selectMemberInsuranceDetailsUpdated = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberInsuranceDetails.updated,
  );

const selectMemberInsuranceDetailsFetched = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberInsuranceDetails.fetched,
  );

const selectCoverageProviderPlanCodes = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.coverageProviderPlanCodes.data,
  );

const selectMember = () =>
  createSelector(selectMemberProfileDomain, (substate) => substate.member.data);

const selectRegions = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.regions.data,
  );

const selectMemberEligibility = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberEligibility.data,
  );

const selectMemberEligibilityFetched = () =>
  createSelector(
    selectMemberProfileDomain,
    (substate) => substate.memberEligibility.fetched,
  );

/**
 * Default selector used by MemberProfile
 */

const makeSelectMemberProfile = () =>
  createSelector(selectMemberProfileDomain, (substate) => substate);

export default makeSelectMemberProfile;
export {
  selectMemberProfileDomain,
  selectMemberInsuranceDetails,
  selectMemberInsuranceDetailsLoading,
  selectMemberInsuranceDetailsError,
  selectMemberInsuranceDetailsUpdated,
  selectMemberInsuranceDetailsFetched,
  selectCoverageProviderPlanCodes,
  selectMember,
  selectRegions,
  selectMemberEligibility,
  selectMemberEligibilityFetched,
};
