import { useEffect, useRef } from 'react';
import { useTracking } from 'react-tracking';

/**
 * Send a page event on mount
 * Can be used to send a page event when a component is mounted
 * Can send multiple events if the event name changes
 * Does not change when the data changes
 * If any keys passed with data are undefined, then the event will not be sent
 */
const usePageEvent = (event, data = {}) => {
  const { trackEvent } = useTracking();
  const sent = useRef();

  useEffect(() => {
    if (data && Object.values(data).some((d) => d === undefined)) {
      return;
    }

    if (sent.current?.event?.event_name !== event.event_name) {
      trackEvent({
        event_name: event.event_name,
        page: event.event_name,
        ...data,
      });
    }
    sent.current = { data, event };
  }, [data, event, trackEvent]);
};

export default usePageEvent;
