import { call, takeLatest } from 'redux-saga/effects';
import { SEND_LOG_EVENT } from 'services/LogEvents/constants';
import { getAuthToken } from 'utils/localStorageHelper';
import request from 'utils/request';

export function sendLogForwarder(body) {
  if (!getAuthToken()) return;
  const method = 'POST';
  const endpoint = `v3/internals/log_forwarder`;
  const requestHeaders = new Headers();
  const requestData = {
    method,
    headers: requestHeaders,
    mode: 'cors',
    body: JSON.stringify({ message: body }),
  };
  return request(endpoint, requestData);
}

function* sendLogEvent({ payload }) {
  return yield call(sendLogForwarder, payload);
}

export default function* logEventSagaSaga() {
  yield takeLatest(SEND_LOG_EVENT, sendLogEvent);
}
