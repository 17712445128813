import { PATIENT_CASE_STATUS_INCOMPLETE } from 'constants/cases';
import { LD_FEATURE_CASE_RESUME_REDIRECT } from 'constants/launchDarklyFlags';
import {
  CONSULTATION_BASE,
  CONSULTATION_CHECKOUT,
} from 'constants/pageIdentifiers';
import { PATIENT_CASES_ENDPOINT } from 'containers/GetCare/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import keys from 'utils/localStorageKeys.json';
import request from 'utils/request';

export const CASE_RESUME_KEY = keys.caseResume;
export const CASE_RESUME_TIMEOUT = 1000 * 60 * 20; // 20 mins

export const saveStartupCaseResumeLocationAndTimestamp = () => {
  // do not save on submitted page
  if (window.location.href.indexOf(CONSULTATION_SUBMITTED) > -1) {
    window.localStorage.removeItem();
    return;
  }
  window.localStorage.setItem(
    CASE_RESUME_KEY,
    JSON.stringify({ url: window.location.href, t: Date.now() }),
  );
};

export const clearStartupCaseResumeLocation = () => {
  window.localStorage.removeItem(CASE_RESUME_KEY);
};

export const getStartupCaseResumeLocation = () => {
  const resumeKey = window.localStorage.getItem(CASE_RESUME_KEY);
  if (resumeKey) {
    try {
      return JSON.parse(resumeKey);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const checkStartupCaseResume = () => {
  const resume = getStartupCaseResumeLocation();
  if (!resume) {
    return null;
  }
  if (Date.now() - resume.t > CASE_RESUME_TIMEOUT) {
    return null;
  } else return resume.url;
};

export const redirectToResumeCase = () => {
  const url = checkStartupCaseResume();
  if (url && url !== window.location.href) {
    return url;
  }
  return null;
};

export const useResumeCase = () => {
  const flags = useFlags();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (!flags[LD_FEATURE_CASE_RESUME_REDIRECT]) {
      return;
    }
    //if case route, save timestamp and url to localstorage
    const match = matchPath(window.location.pathname, {
      path: [
        CONSULTATION_BASE,
        `${CONSULTATION_BASE}/${CONSULTATION_CHECKOUT}`,
      ],
      exact: false,
      strict: false,
    });
    if (match) {
      saveStartupCaseResumeLocationAndTimestamp();
    }
    return () => {
      clearStartupCaseResumeLocation();
    };
  }, [flags, location]);
};

export const isCaseStillInProgress = (id) => {
  const method = 'GET';
  const endpoint = `${PATIENT_CASES_ENDPOINT}/${id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return request(endpoint, requestData)
    .then((data) => data.status == PATIENT_CASE_STATUS_INCOMPLETE)
    .catch(() => false);
};

export const getCaseIdFromUrl = (url) => {
  const _url = new URL(url);
  const match = matchPath(_url.pathname, {
    path: CONSULTATION_BASE,
    exact: false,
    strict: false,
  });
  if (match) {
    return match.params.id;
  }
  return null;
};

export const checkResumeCaseRedirect = (isAuthenticated) => {
  const checkResumeCase = checkStartupCaseResume();
  if (isAuthenticated && checkResumeCase) {
    const caseId = getCaseIdFromUrl(checkResumeCase);
    if (caseId) {
      return isCaseStillInProgress(caseId)
        .then((isInProgress) => {
          if (isInProgress) {
            return redirectToResumeCase();
          }
          return null;
        })
        .catch((e) => null);
    }
  }
  return Promise.resolve(null);
};
