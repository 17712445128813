import messages from 'components/HomeView/messages';
import { HighlightedText } from 'components/HomeView/styled';
import Config from 'config';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  getOpenPatientCase,
  isFollowUpCase,
  isResumeCase,
} from 'utils/caseHelpers';

export const getCompanyName = (name = '') => {
  return name.replace(/ - \d{10}/, '');
};
export const getWelcomeMessage = (member, companyCopy, patientCases) => {
  if (patientCases.length === 0) {
    return (
      <FormattedMessage
        {...messages[
          Config.isQuip || Config.isDelta
            ? 'quipWelcome'
            : member?.firstName
            ? 'hiMemberWelcome'
            : 'hiMemberWelcomeNoName'
        ]}
        values={{
          firstName: member?.firstName || '',
          company: Config.companyName,
          b: (text) => <HighlightedText>{text}</HighlightedText>,
        }}
      />
    );
  }
  const openCase = getOpenPatientCase(patientCases);

  if (openCase && isResumeCase(member, openCase)) {
    return (
      <FormattedMessage
        {...messages.welcomeBackName}
        values={{ firstName: member?.first_name || '' }}
      />
    );
  }

  if (openCase && isFollowUpCase(openCase)) {
    return getCompanyName(companyCopy?.welcome_message);
  }

  if (Config.isQuip || Config.isDelta) {
    return (
      <FormattedMessage
        {...messages.quipWelcome}
        values={{
          firstName: member?.firstName || '',
          company: Config.companyName,
          b: (text) => <HighlightedText>{text}</HighlightedText>,
        }}
      />
    );
  }
  return getCompanyName(companyCopy?.welcome_message);
};
