import { PageContent } from '@oraleye/frontend-modules-components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import NavBar from './NavBar';

export const CenterDiv = styled.div`
  flex: 1;
  align-items: center;
`;

const Header = ({ left, center, right }) => {
  return (
    <NavBar>
      <PageContent>
        <div>{left}</div>
        <CenterDiv>{center}</CenterDiv>
        <div>{right}</div>
      </PageContent>
    </NavBar>
  );
};

export default Header;

Header.propTypes = {
  center: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
};
