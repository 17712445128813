/*
 * HomeView Messages
 *
 * This contains all the text for the HomeView component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.HomeView';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Home',
  },
  getCare: {
    id: `${scope}.getCare`,
    defaultMessage: 'Get care',
  },
  connectToADentist: {
    id: `${scope}.connectToADentist`,
    defaultMessage: 'Connect to a dentist',
  },
  followUp: {
    id: `${scope}.followUp`,
    defaultMessage: 'Follow up',
  },
  getCareContent: {
    id: `${scope}.getCareContent`,
    defaultMessage: 'Start a photo consultation',
  },
  getCareContentVideo: {
    id: `${scope}.getCareContentVideo`,
    defaultMessage:
      'Choose from a live video consultation or photo submission.',
  },
  getCareContentVideoButtonCard: {
    id: `${scope}.getCareContentVideoButtonCard`,
    defaultMessage:
      'Choose how you want to connect - live video consult or a photo based consult.',
  },
  getCareContentQuip: {
    id: `${scope}.getCareContentQuip`,
    defaultMessage:
      'Have a virtual dental checkup with a dentist in your area.',
  },
  viewConsultOptionsButton: {
    id: `${scope}.videoConsultOptionsButton`,
    defaultMessage: 'View consult options',
  },
  dentistHasRequested: {
    id: `${scope}.dentistHasRequested`,
    defaultMessage: 'Your dentist has requested you submit a follow up case',
  },
  followUpNotCompleted: {
    id: `${scope}.followUpNotCompleted`,
    defaultMessage: 'You haven’t finished your Follow up case.',
  },
  resume: {
    id: `${scope}.resume`,
    defaultMessage: 'Resume',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start case',
  },
  submitYourPhotos: {
    id: `${scope}.submitYourPhotos`,
    defaultMessage: 'Submit your photos for a {caseType} case.',
  },
  triageMessage: {
    id: `${scope}.triageMessage`,
    defaultMessage: 'Connect with your dentist to report a dental concern.',
  },
  videoMessage: {
    id: `${scope}.videoMessage`,
    defaultMessage:
      'Talk directly with a dentist and get instant advice on the care you need.',
  },
  follow_up_Title: {
    id: `${scope}.follow_up_Title`,
    defaultMessage: 'Follow up',
  },
  triage_Title: {
    id: `${scope}.triage_Title`,
    defaultMessage: 'Get help',
  },
  complete_care_Title: {
    id: `${scope}.complete_care_Title`,
    defaultMessage: 'Complete care',
  },
  direct_care_Title: {
    id: `${scope}.direct_care_Title`,
    defaultMessage: 'Direct care',
  },
  video_Title: {
    id: `${scope}.video_Title`,
    defaultMessage: 'Video consult',
  },
  default_Title: {
    id: `${scope}.default_Title`,
    defaultMessage: 'Case',
  },
  hiMemberWelcome: {
    id: `${scope}.hiMemberWelcome`,
    defaultMessage: 'Hi {firstName}, welcome to {company}',
  },
  hiMemberWelcomeNoName: {
    id: `${scope}.hiMemberWelcomeNoName`,
    defaultMessage: 'Hi, welcome to {company}',
  },
  quipWelcome: {
    id: `${scope}.quipWelcome`,
    defaultMessage: 'Welcome to {company} <b>virtual dental care</b>',
  },
  welcomeBackName: {
    id: `${scope}.welcomeBackName`,
    defaultMessage: 'Welcome back {firstName}',
  },
  quipDescription: {
    id: `${scope}.quipDescription`,
    defaultMessage:
      'Whether for prevention or pain, you can now connect with a real dental professional in your area from the comfort of your own home from as little as {price, number, :: .00/w currency/USD}.{br}Many insurance plans cover teledentistry treatments to a varying degree, meaning you may be able to <b>seek a reimbursement for your consult.</b>{br}Check your plan details to learn more.',
  },
  walmartDescription: {
    id: `${scope}.walmartDescription`,
    defaultMessage:
      'Whether you are looking to help prevent oral health problems through regular virtual dental checkups, have a specific issue you want checked on, or are in pain and looking for advice, you can now connect with a real dental professional in your area from the comfort of your own home.',
  },
  eligibleMemberDescription: {
    id: `${scope}.eligibleMemberDescription`,
    defaultMessage:
      'Your insurance information has been verified. When you submit your case you will have the option to use your benefits or you can opt to save your benefits for future in-person visits and pay an out of pocket fee.',
  },
  nonEligibleMemberDescription: {
    id: `${scope}.nonEligibleMemberDescription`,
    defaultMessage:
      'We were unable to verify your insurance information. Please <a>check your information</a> for accuracy or continue with this consult and pay out of pocket.',
  },
});
