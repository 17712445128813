/*
 * NewCase Messages
 *
 * This contains all the text for the NewCase page.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.pages.NewCase';

export default defineMessages({
  documentTitle: {
    id: `${scope}.documentTitle`,
    defaultMessage: 'Start a consultation with {company}',
  },
});
