/**
 *
 * ErrorBoundary
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import events from 'services/EventsService/events.json';
import styled from 'styled-components';
import { ErrorView } from './ErrorView';

const ErrorWrapper = styled.div`
  padding: 32px;

  img {
    margin: 16px auto;
    display: block;
    width: 300px;
  }
`;

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.onClick = this.onClick.bind(this);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    if (window.Rollbar && error) {
      window.Rollbar.error(error);
    }
    if (error) {
      window.eventSend({
        event_name: events.error.event_name,
        error_message_shown: error.message,
        user_klass: 'member',
      });
    }
    this.setState({
      hasError: true,
      message: error.message,
      title: 'Something went wrong...',
    });
    console.error(error, info); // eslint-disable-line no-console
  }

  onClick() {
    this.setState({ hasError: false });
    window.history.go(-1);
  }

  render() {
    const { children } = this.props;
    const { hasError, message, title } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorWrapper>
          <ErrorView finish={this.onClick} message={message} title={title} />
        </ErrorWrapper>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ErrorBoundary;
