import { HOME_PAGE } from 'constants/pageIdentifiers';
import { parse, stringify } from 'query-string';
import { checkResumeCaseRedirect } from 'services/InitialisationService/case';
import {
  getQueryOrStoredCompanyId,
  updateConfigForCopy,
} from 'services/InitialisationService/companyCopy';
import isDevEnv from 'utils/isDevEnv';
import {
  clearUserLocalStorageData,
  getAuthToken,
  getCompanyCopy,
  setRegionId,
} from 'utils/localStorageHelper';
import { authRedirectHelper } from 'utils/redirects';
import {
  EXTERNAL_USER_ID,
  clearAuthenticatedRedirect,
  clearUserSessionStorageData,
  saveToSessionStorage,
} from 'utils/sessionStorageHelper';
import { checkQueryCampaignIdentifier } from './campaign';
import { checkCompanyConfig } from './companyConfig';
import { checkCompanyCopy } from './companyCopy';
import { checkAuth } from './member';
import { checkMessage } from './message';
import { QUIP_USER_TOKEN_URL_PARAM, getQuipToken } from './quip';
import { checkSMSId } from './sms';

const checkRedirect = (isAuthenticated) => {
  const redirect = authRedirectHelper();
  if (
    redirect &&
    isAuthenticated &&
    redirect !== window.location.pathname &&
    redirect !== HOME_PAGE
  ) {
    clearAuthenticatedRedirect();
    return Promise.resolve(redirect);
  }
  return checkResumeCaseRedirect(isAuthenticated);
};

const checkClearStorage = () => {
  if (window.location.search.indexOf('clear=1') > -1) {
    localStorage.clear();
    sessionStorage.clear();
  }
};

const removeInitialisationQueryParams = (search) => {
  const params = parse(search);
  delete params.sms;
  delete params.message;
  delete params.q;
  delete params.ci;
  delete params.campaignId;
  delete params.companyId;
  delete params.region_id;
  delete params.euid;
  delete params[QUIP_USER_TOKEN_URL_PARAM];
  return Object.keys(params).length > 0 ? `?${stringify(params)}` : '';
};

/**
 *
 * @return {Promise<{
 * campaignIsUrl: boolean,
 * companyId: string|null,
 * smsIdentifier: string|null,
 * campaignId: string,
 * authToken: string|null,
 * companyCopy: object|null,
 * copyLoaded: boolean,
 * member: object|null,
 * isAuthenticated: boolean,
 * ediEnabled: boolean,
 * }>}
 */
export function initialisationService() {
  isDevEnv && checkClearStorage();
  const smsId = checkSMSId();
  const authToken = getAuthToken();
  const message = checkMessage();
  const qToken = getQuipToken();

  if (authToken && (smsId || message || qToken)) {
    // TODO: Should log out user here properly?
    clearUserLocalStorageData();
    clearUserSessionStorageData();
    return window.location.reload();
  }

  const { region_id: regionId, euid } = parse(window.location.search);
  if (regionId) setRegionId(regionId);
  if (euid) saveToSessionStorage(EXTERNAL_USER_ID, euid, false);

  const { campaignId, campaignIsUrl } = checkQueryCampaignIdentifier();
  const companyId = getQueryOrStoredCompanyId();

  return Promise.allSettled([
    checkCompanyCopy({
      campaignId,
      companyId,
    }),
    checkAuth(companyId, message, qToken, campaignId),
  ])
    .then(([, { status, value }]) => {
      if (status === 'rejected') {
        throw new Error('Auth error');
      }
      const { isAuthenticated, member, companyId, authToken } = value;
      return checkRedirect(isAuthenticated)
        .then((redirect) => {
          if (
            redirect &&
            isAuthenticated &&
            redirect !== window.location.pathname &&
            redirect !== HOME_PAGE
          ) {
            window.location.replace(
              `${window.location.origin}/${redirect.replace(/^\//, '')}`,
            );
          } else if (window.location.href.indexOf('?') > 0) {
            // remove query params from initialisation
            window.history.replaceState(
              null,
              null,
              `${
                window.location.href.split('?')[0]
              }${removeInitialisationQueryParams(window.location.search)}`,
            );
          }
          return Promise.all([
            // may need another copy here after we get the member
            checkCompanyCopy({
              campaignId,
              companyId,
            }),
            checkCompanyConfig(),
            { isAuthenticated, member, companyId, authToken },
          ]);
        })
        .then(
          ([
            companyCopy,
            { ediEnabled },
            { isAuthenticated, member, companyId, authToken },
          ]) => {
            return {
              authToken,
              campaignId,
              campaignIsUrl,
              companyCopy,
              companyId,
              copyLoaded: true,
              ediEnabled,
              isAuthenticated,
              member,
              smsIdentifier: smsId,
              initialisationError: false,
            };
          },
        );
    })
    .catch((e) => {
      console.error(e);
      updateConfigForCopy(getCompanyCopy());
      window.Rollbar?.error('Initialisation error', e);
      return {
        authToken: null,
        campaignId,
        campaignIsUrl: false,
        companyCopy: null,
        companyId,
        copyLoaded: false,
        ediEnabled: false,
        isAuthenticated: false,
        redirect: null,
        member: null,
        smsId: null,
        initialisationError: true,
      };
    });
}
