import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the getCare state domain
 */

const selectHomeDomain = (state = initialState()) => state.home;

/**
 * Other specific selectors
 */
const selectPatientCases = () =>
  createSelector(selectHomeDomain, (substate) => substate.patientCases);
const selectPatientCaseTemplates = () =>
  createSelector(selectHomeDomain, (substate) => substate.patientCaseTemplates);
const selectVideoCalls = () =>
  createSelector(selectHomeDomain, (substate) => substate.videoCalls);
const selectHomeData = () =>
  createSelector(selectHomeDomain, (substate) => substate.homeData);

const selectHomeFetched = () =>
  createSelector(selectHomeDomain, (substate) => substate.fetched);
const selectHomeLoading = () =>
  createSelector(selectHomeDomain, (substate) => substate.loading);

const selectHomeError = () =>
  createSelector(selectHomeDomain, (substate) => substate.error);

export {
  selectHomeLoading,
  selectHomeError,
  selectHomeFetched,
  selectVideoCalls,
  selectPatientCases,
  selectPatientCaseTemplates,
  selectHomeData,
};
