/**
 *
 * LaunchDarklyUpdater
 *
 */
import Loader from 'components/Loader';
import useMember from 'hooks/useMember';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createLDUser } from 'services/LaunchDarkly/user';
import { selectCompanyCopy } from './selectors';

export const LaunchDarklyUpdater = ({ children }) => {
  const ldClient = useLDClient();

  const companyCopy = useSelector(selectCompanyCopy());

  const member = useMember();

  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    window.ldClient = ldClient;
  }, [ldClient]);

  useEffect(() => {
    if (member) {
      // set up a new user
      const ldUser = createLDUser(companyCopy, member?.company_id);

      setIsReady(false);
      // we will always have either a member, or a campaign identifier (may be default)
      ldClient.identify(ldUser).then(() => {
        setIsReady(true);
      });
    }
  }, [companyCopy, ldClient, member]);

  return isReady ? children : <Loader />;
};

LaunchDarklyUpdater.propTypes = {
  children: PropTypes.any.isRequired,
};

LaunchDarklyUpdater.defaultProps = {};

export default LaunchDarklyUpdater;
