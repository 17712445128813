import FormattedHelmetTitle from 'components/FormattedHelmetTitle';
import Loader from 'components/Loader';
import Config from 'config';
import React from 'react';
import messages from './messages';

const Component = React.lazy(() =>
  Promise.all([import('./index')]).then(([moduleExports]) => moduleExports),
);

export default (props) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <FormattedHelmetTitle
        {...messages.documentTitle}
        values={{ company: Config.companyName }}
      />
      <Component {...props} />
    </React.Suspense>
  );
};
