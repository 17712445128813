/*
 *
 * VideoCall reducer
 *
 */

import { SIGN_OUT_USER } from 'containers/Auth/AuthRouter/constants';
import { UPSERT_VIDEO_CALL_SUCCESS } from 'containers/Consultation/constants';
import { FETCH_PATIENT_CASES_SUCCESS } from 'containers/GetCare/constants';
import {
  END_VIDEO_CALL,
  END_VIDEO_CALL_SUCCESS,
  FETCH_ASSESSOR,
  FETCH_ASSESSOR_SUCCESS,
  GET_VIDEO_CALL_FAILURE_ACTION,
  GET_VIDEO_CALL_SUCCESS_ACTION,
  RESET_ASSESSOR,
  RESET_START_VIDEO_CALL_STATUS,
  RESET_VIDEO_CALL_DETAILS,
  START_VIDEO_CALL_ACTION,
  START_VIDEO_CALL_ACTION_FAILURE,
  START_VIDEO_CALL_ACTION_SUCCESS,
  START_VIDEO_CALL_FAILURE_STATUS,
  START_VIDEO_CALL_LOADING_STATUS,
  START_VIDEO_CALL_SUCCESS_STATUS,
} from './constants';

export const initialState = () => ({
  startVideoCallStatus: '',
  roomId: '',
  twilioSid: '',
  remoteParticipant: null,
  patientCaseId: undefined,
  videoCalls: {}, // by patient case id
});

function videoCallReducer(state = initialState(), action) {
  switch (action.type) {
    case RESET_START_VIDEO_CALL_STATUS:
      return {
        ...state,
        startVideoCallStatus: '',
        remoteParticipant: null,
      };
    case RESET_ASSESSOR:
      return {
        ...state,
        remoteParticipant: null,
      };
    case END_VIDEO_CALL:
      return {
        ...state,
        roomId: '',
        startVideoCallStatus: '',
      };
    case START_VIDEO_CALL_ACTION:
      return {
        ...state,
        startVideoCallStatus: START_VIDEO_CALL_LOADING_STATUS,
      };
    case START_VIDEO_CALL_ACTION_SUCCESS:
      return onStartVideoCallSuccess(state, action);
    case START_VIDEO_CALL_ACTION_FAILURE:
      return {
        ...state,
        startVideoCallStatus: START_VIDEO_CALL_FAILURE_STATUS,
      };
    case FETCH_ASSESSOR:
      return {
        ...state,
      };
    case FETCH_ASSESSOR_SUCCESS:
      return onFetchAssessorSuccess(state, action);
    case END_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        roomId: '',
        startVideoCallStatus: '',
      };
    case RESET_VIDEO_CALL_DETAILS:
      return {
        ...state,
        roomId: '',
        startVideoCallStatus: '',
      };

    case GET_VIDEO_CALL_SUCCESS_ACTION:
      return {
        ...state,
        videoCall: action.payload[0],
        videoCalls: {
          ...state.videoCalls,
          ...action.payload.reduce(
            (memo, vc) => Object.assign(memo, { [vc.patient_case_id]: vc }),
            [],
          ),
        },
        error: null,
      };

    case GET_VIDEO_CALL_FAILURE_ACTION:
      return { ...state, videoCall: null, error: action.error };

    case FETCH_PATIENT_CASES_SUCCESS:
      return { ...state, patientCases: action.payload };
    case SIGN_OUT_USER:
      return initialState;
    case UPSERT_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        videoCall: action.payload,
        videoCalls: {
          ...state.videoCalls,
          [action.payload.patient_case_id]: action.payload,
        },
      };
    default:
      return state;
  }
}

const onStartVideoCallSuccess = (state, { payload }) => {
  return {
    ...state,
    roomId: payload.id,
    twilioSid: payload.twilio_sid,
    startVideoCallStatus: START_VIDEO_CALL_SUCCESS_STATUS,
    patientCaseId: payload.patient_case_id,
    videoCall: payload,
  };
};

const onFetchAssessorSuccess = (state, { payload }) => {
  return {
    ...state,
    remoteParticipant: payload,
  };
};

export default videoCallReducer;
