import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useHistoryPush = () => {
  const history = useHistory();
  const { search } = useLocation();

  return useCallback(
    (path) => {
      history.push({
        pathname: path,
        search,
      });
    },
    [history, search],
  );
};

export default useHistoryPush;
