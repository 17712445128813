import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
// Toothpic imports
import { Rating, StarRatingField } from '@oraleye/frontend-modules-core';
// Local imports
import {
  FormDateInput,
  FormDayPickerInput,
  FormRadios,
  FormSelectInput,
  FormStateZipInput,
  FormTextArea,
  FormTextInput,
} from 'components/formComponents';
import FormCheckboxInput from 'components/formComponents/FormCheckboxInput';
import FormPhoneInput from 'components/formComponents/FormPhoneInput';
import HeightInput from 'components/QuestionCard/heightInput';
import WeightInput from 'components/QuestionCard/weightInput';

export const DATETIME_TYPE = 'date_time';
export const DROPDOWN_TYPE = 'dropdown';
export const ON_OFF_TYPE = 'on_off';
export const PHONE_TYPE = 'phone_number';
export const PICKER_TYPE = 'picker';
export const RADIO_TYPE = 'radio';
export const RATING_TYPE = 'rating';
export const STAR_RATING = 'star_rating';
export const SLIDER_TYPE = 'slider';
export const STATE_TYPE = 'state';
export const STATE_ZIP_TYPE = 'state_zip_code';
export const SHORT_TEXT_TYPE = 'short_text';
export const TEXT_TYPE = 'text_box';
export const TOGGLE_TYPE = 'toggle';
export const ZIP_TYPE = 'zip_code';
export const HEIGHT_TYPE = 'height';
export const WEIGHT_TYPE = 'weight';

export const answerTypes = {
  [RADIO_TYPE]: FormRadios,
  [DROPDOWN_TYPE]: FormSelectInput,
  [SHORT_TEXT_TYPE]: FormTextInput,
  [TEXT_TYPE]: FormTextArea,
  [DATETIME_TYPE]: isMobile ? FormDateInput : FormDayPickerInput,
  [PICKER_TYPE]: FormSelectInput,
  [STATE_TYPE]: FormSelectInput,
  [ZIP_TYPE]: FormTextInput,
  [TOGGLE_TYPE]: FormCheckboxInput,
  [ON_OFF_TYPE]: FormCheckboxInput,
  [SLIDER_TYPE]: FormSelectInput,
  [PHONE_TYPE]: FormPhoneInput,
  [STATE_ZIP_TYPE]: FormStateZipInput,
  [RATING_TYPE]: styled(Rating)``,
  [STAR_RATING]: StarRatingField,
  [HEIGHT_TYPE]: HeightInput,
  [WEIGHT_TYPE]: WeightInput,
};
