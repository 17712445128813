import Link from 'components/Link';
import Config from 'config';
import { Z_PROFILE_MENU, Z_PROFILE_MENU_LISTENER } from 'constants/zIndex';
import styled from 'styled-components';

export const ClickAwayListener = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${Z_PROFILE_MENU_LISTENER};
`;

export const Menu = styled.nav`
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  min-width: 256px;
  overflow: hidden;
  z-index: ${Z_PROFILE_MENU};
`;

export const MenuItem = styled(Link)`
  display: block;
  background-color: #ffffff;
  height: 56px;
  line-height: 56px;
  padding-left: 65px;
  position: relative;
  color: var(--neutral07);
  font-weight: bold;
  &:hover,
  &:focus {
    background: var(--neutral01);
  }
  &:active {
    background: var(--neutral02);
  }

  i,
  img,
  svg {
    height: 20px;
    width: 20px;
    top: 18px;
    left: 20px;
    font-size: 20px;
    line-height: 56px;
    position: absolute;
    color: var(--primary01);
  }
  stroke: var(--primary01);
  fill: var(--primary01);
`;
export const MenuItemLogout = styled(MenuItem)`
  background: var(--neutral01);
  color: var(--primary02);

  ${() => Config.isQuip && 'display: none;'}
`;

export const MenuOpenButton = styled.button`
  svg {
    width: 35px !important;
    height: 35px;
    color: ${() =>
      Config.isQuip || Config.isDelta
        ? 'var(--neutral04)'
        : 'var(--primary02)'};
  }
  background: white;
  outline: none;
  border: none;
  border-radius: 50%;
  height: 35px;
  padding: 0;
  width: 35px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.1s linear;
  &:hover,
  &:focus {
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.35);
  }
  &:active {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.4);
  }
`;
