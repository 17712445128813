/**
 *
 * SideMenu
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, DrawerItem } from '@oraleye/frontend-modules-components';
import { NAVBAR_HEIGHT_LG, NAVBAR_HEIGHT_SM } from 'components/Header/NavBar';
import Link from 'components/Link';
import { LD_FEATURE_VIDEO_CALLING } from 'constants/launchDarklyFlags';
import {
  GET_CARE_PAGE,
  RECORDS_PAGE,
  VIDEO_PREFERENCES_PAGE,
} from 'constants/pageIdentifiers';
import { Z_SIDEBAR } from 'constants/zIndex';
import { ReactComponent as File } from 'images/file-alt.svg';
import { ReactComponent as HandMedical } from 'images/hand-holding-medical.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import styled, { css } from 'styled-components';
import tracking from 'tracking';
import messages from './messages';

const StyledDrawer = styled(Drawer)`
  z-index: ${Z_SIDEBAR};
  background: white;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
  top: ${NAVBAR_HEIGHT_SM}px;
  height: auto;
  ${({ theme }) =>
    theme.breakpoints.minSm(css`
      top: ${NAVBAR_HEIGHT_LG}px;
    `)};
`;

export const SideMenu = forwardRef(
  ({ onClose, open, setOpen, ...props }, ref) => {
    const { trackEvent } = useTracking();
    const location = useLocation();
    const flags = useFlags();

    const getActiveItem = useCallback(
      (page, itemNumber) => {
        return location.pathname.indexOf(page) > -1 && itemNumber;
      },
      [location.pathname],
    );

    const [active, setTabActive] = useState(
      getActiveItem(GET_CARE_PAGE, 1) || getActiveItem(RECORDS_PAGE, 2) || 0,
    );

    const handleClose = useCallback(
      (e) => {
        setOpen(false);
        onClose?.(e);
      },
      [onClose, setOpen],
    );

    const setActive = useCallback(
      (n) => {
        setTabActive(n);
        setOpen(false);
      },
      [setOpen],
    );

    return (
      <StyledDrawer {...props} ref={ref} open={open} onClose={handleClose}>
        <Link
          to={GET_CARE_PAGE}
          onClick={() => {
            trackEvent({
              event_name: events.hamburgerMenuItemClicked.event_name,
              menu_item_name: messages.connectToADentist.defaultMessage,
              menu_item_destination: GET_CARE_PAGE,
            });
            setActive(1);
          }}
          trackingId={tracking.sidebar.getCareClicked}
        >
          <DrawerItem
            active={active === 1}
            Icon={<HandMedical width={20} height={20} />}
          >
            <FormattedMessage {...messages.connectToADentist} />
          </DrawerItem>
        </Link>
        <Link
          to={RECORDS_PAGE}
          onClick={() => {
            trackEvent({
              event_name: events.hamburgerMenuItemClicked.event_name,
              menu_item_name: messages.records.defaultMessage,
              menu_item_destination: RECORDS_PAGE,
            });
            setActive(2);
          }}
          trackingId={tracking.sidebar.reportsClicked}
        >
          <DrawerItem
            active={active === 2}
            Icon={<File width={20} height={20} />}
          >
            <FormattedMessage {...messages.records} />
          </DrawerItem>
        </Link>
        {flags[LD_FEATURE_VIDEO_CALLING] && (
          <Link
            to={VIDEO_PREFERENCES_PAGE}
            onClick={() => {
              trackEvent({
                event_name: events.hamburgerMenuItemClicked.event_name,
                menu_item_name: messages.records.defaultMessage,
                menu_item_destination: VIDEO_PREFERENCES_PAGE,
              });
              setActive(3);
            }}
            trackingId={tracking.sidebar.diagnosticsClicked}
          >
            <DrawerItem
              active={active === 2}
              Icon={<FontAwesomeIcon icon="video" />}
            >
              <FormattedMessage {...messages.videoPreferences} />
            </DrawerItem>
          </Link>
        )}
      </StyledDrawer>
    );
  },
);

SideMenu.displayName = 'SideMenu';
SideMenu.propTypes = {
  buttonRef: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default SideMenu;
