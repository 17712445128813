/**
 *
 * GenericPatientCaseStart
 *
 */

import FormattedHelmetTitle from 'components/FormattedHelmetTitle';
import useHistoryPush from 'components/LinkWithQueryParams/useHistoryPush';
import Loader from 'components/Loader';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import Config from 'config';
import {
  CREATE_ACCOUNT_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  SETUP_TYPE_PAGE,
} from 'constants/pageIdentifiers';
import {
  fetchPatientCaseTemplateAction,
  fetchPatientCasesAction,
  newPatientCaseAction,
} from 'containers/GetCare/actions';
import {
  selectGetPatientCaseTemplateError,
  selectNewPatientCaseError,
  selectPatientCaseTemplateById,
  selectPatientCaseTemplatesFetched,
  selectPatientCasesFetched,
} from 'containers/GetCare/selectors';
import { ACCOUNT_QUESTION_SET_TYPE } from 'containers/Questions/constants';
import AuthContext from 'contexts/Auth.context';
import useAuthenticated from 'hooks/useAuthenticated';
import NotFound from 'pages/NotFoundPage';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastSeen } from 'utils/localStorageHelper';
import messages from './messages';

export const GenericPatientCaseStart = ({ match }) => {
  const push = useHistoryPush();
  const dispatch = useDispatch();
  const patientCasesFetched = useSelector(selectPatientCasesFetched());
  const patientCaseTemplatesFetched = useSelector(
    selectPatientCaseTemplatesFetched(),
  );
  const patientCaseTemplate = useSelector(
    selectPatientCaseTemplateById(match.params?.id),
  );
  const createCaseError = useSelector(selectNewPatientCaseError());
  const getTemplateError = useSelector(selectGetPatientCaseTemplateError());
  const isAuthenticated = useAuthenticated();

  useEffect(() => {
    if (!patientCasesFetched && isAuthenticated) {
      dispatch(fetchPatientCasesAction());
    }
  }, [patientCasesFetched, isAuthenticated, dispatch]);

  useEffect(() => {
    if (!patientCaseTemplatesFetched && isAuthenticated) {
      dispatch(fetchPatientCaseTemplateAction(match.params.id));
    }
  }, [patientCaseTemplatesFetched, match, isAuthenticated, dispatch]);

  const [creatingCase, setCreatingCase] = useState(false);
  const [accountRedirect, setAccountRedirect] = useState(null);
  const { member, accountProfileComplete } = useContext(AuthContext);

  useEffect(() => {
    if (undefined === accountProfileComplete) {
      return;
    }
    if (accountProfileComplete) {
      setAccountRedirect(false);
      return;
    }
    setAccountRedirect({
      pathname: SETUP_TYPE_PAGE.replace(':type', ACCOUNT_QUESTION_SET_TYPE),
      state: { redirectTo: location.pathname, type: ACCOUNT_QUESTION_SET_TYPE },
    });
  }, [member, accountProfileComplete]);

  useEffect(() => {
    if (
      !creatingCase &&
      patientCaseTemplate &&
      patientCasesFetched &&
      accountRedirect === false &&
      member?.id
    ) {
      dispatch(
        newPatientCaseAction({
          patient_case_template_id: patientCaseTemplate.id,
          member_id: member.id,
          continueCase: false,
          push,
        }),
      );
      setCreatingCase(true);
    }
  }, [
    accountRedirect,
    match,
    member,
    patientCaseTemplate,
    patientCasesFetched,
    push,
    creatingCase,
    dispatch,
  ]);

  if (!isAuthenticated) {
    return (
      <RedirectWithQuery
        to={`${
          getLastSeen() ? LOGIN_PAGE : CREATE_ACCOUNT_PAGE
        }?redirectTo=${encodeURIComponent(
          `${window.location.pathname}${
            window.location.search ? `?${window.location.search}` : ''
          }`,
        )}`}
      />
    );
  }

  if (accountRedirect) {
    return <RedirectWithQuery to={accountRedirect} />;
  }

  if (getTemplateError) {
    return <NotFound />;
  }

  if (createCaseError) {
    return <RedirectWithQuery to={HOME_PAGE} />;
  }

  return (
    <>
      <FormattedHelmetTitle
        {...messages.documentTitle}
        values={{ company: Config.companyName }}
      />
      <Loader />
    </>
  );
};

GenericPatientCaseStart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.any,
};

export default GenericPatientCaseStart;
