/**
 *
 * HomeView
 *
 */

import { PageContent } from '@oraleye/frontend-modules-components';
import BreadcrumbContainer from 'components/BreadcrumbContainer';
import { DisplayCards } from 'components/HomeView/displayCards';
import { getWelcomeMessage } from 'components/HomeView/helpers';
import Link from 'components/Link';
import VerifyingEligibilityModal from 'components/MemberProfileView/modals/VerifyingEligibilityModal';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import {
  LD_FEATURE_ELIGIBILITY_CHECK,
  LD_UI_WALMART_HOME_DESCRIPTION,
} from 'constants/launchDarklyFlags';
import { GET_CARE_PAGE, MEMBER_PROFILE } from 'constants/pageIdentifiers';
import { countPhotosFromDatabaseAction } from 'containers/Photos/actions';
import { selectCasePhotoCounts } from 'containers/Photos/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import usePageEvent from 'services/EventsService/usePageEvent';
import tracking from 'tracking';
import messages from './messages';
import {
  HighlightedText,
  PageContainer,
  StyledDescription,
  StyledH,
} from './styled';

const HomeView = ({
  reports,
  member,
  patientCases,
  patientCasesLoading,
  patientCaseTemplates,
  companyCopy,
  accountProfileComplete,
  ediEnabled,
  memberEligibility,
  memberEligibilityExpired,
  history,
}) => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const { trackEvent } = useTracking();
  const [redirect, setRedirect] = useState(null);

  usePageEvent(events.homePageViewed);

  const memberIsEligible = useMemo(() => {
    return (
      memberEligibility?.status === 'success' &&
      memberEligibility?.benefits_breakdown?.eligible
    );
  }, [memberEligibility]);

  const minPrice = Math.min(...patientCaseTemplates.map((pct) => pct.price));
  const [showEligibilityVerficationModal, setShowEligibilityVerficationModal] =
    useState(false);

  const closeEligibilityVerificationModal = useCallback(
    (eligibilityState) => {
      trackEvent({
        event_name:
          events.eligibilityVerificationModalContinueClicked.event_name,
        eligibility_state: eligibilityState,
      });
      setShowEligibilityVerficationModal(false);
      setRedirect(GET_CARE_PAGE);
    },
    [trackEvent],
  );

  const checkDetails = useCallback(
    (eligibilityState) => {
      trackEvent({
        event_name:
          events.eligibilityVerificationModalCheckDetailsClicked.event_name,
        eligibility_state: eligibilityState,
      });
      setShowEligibilityVerficationModal(false);
      setRedirect(MEMBER_PROFILE);
    },
    [trackEvent],
  );

  useEffect(() => {
    if (ediEnabled && memberEligibilityExpired && accountProfileComplete) {
      setShowEligibilityVerficationModal(true);
    }
  }, [
    ediEnabled,
    memberEligibilityExpired,
    accountProfileComplete,
    setShowEligibilityVerficationModal,
  ]);

  useEffect(() => {
    dispatch(countPhotosFromDatabaseAction());
  }, [dispatch]);

  const counts = useSelector(selectCasePhotoCounts());

  const welcomeMessage = getWelcomeMessage(
    member,
    companyCopy,
    patientCases,
    reports,
  );

  if (redirect) {
    return <RedirectWithQuery push to={redirect} />;
  }

  return (
    <>
      <BreadcrumbContainer
        title={<FormattedMessage {...messages.pageTitle} />}
        trackingId={tracking.homeView.pageTitle}
      />
      <PageContainer>
        <PageContent>
          <StyledH>{welcomeMessage}</StyledH>
          {flags[LD_FEATURE_ELIGIBILITY_CHECK] ? (
            <StyledDescription>
              {memberIsEligible ? (
                <FormattedMessage {...messages.eligibleMemberDescription} />
              ) : (
                <FormattedMessage
                  {...messages.nonEligibleMemberDescription}
                  values={{
                    a: (...chunks) => (
                      <Link to={MEMBER_PROFILE}>
                        <HighlightedText>{chunks}</HighlightedText>
                      </Link>
                    ),
                  }}
                />
              )}
            </StyledDescription>
          ) : (
            <StyledDescription>
              {flags[LD_UI_WALMART_HOME_DESCRIPTION] ? (
                <FormattedMessage {...messages.walmartDescription} />
              ) : (
                <FormattedMessage
                  {...messages.quipDescription}
                  values={{
                    price: minPrice / 100,
                    b: (text) => <HighlightedText>{text}</HighlightedText>,
                    br: <br />,
                  }}
                />
              )}
            </StyledDescription>
          )}
          <DisplayCards
            patientCases={patientCases}
            patientCaseTemplates={patientCaseTemplates}
            counts={counts}
            member={member}
            history={history}
            flags={flags}
            patientCasesLoading={patientCasesLoading}
          />
        </PageContent>
        {flags[LD_FEATURE_ELIGIBILITY_CHECK] ? (
          <VerifyingEligibilityModal
            show={showEligibilityVerficationModal}
            onContinue={closeEligibilityVerificationModal}
            onCheckDetails={checkDetails}
          />
        ) : null}
      </PageContainer>
    </>
  );
};

HomeView.propTypes = {
  accountProfileComplete: PropTypes.any,
  companyCopy: PropTypes.any,
  ediEnabled: PropTypes.any,
  history: PropTypes.any,
  member: PropTypes.any,
  memberEligibilityExpired: PropTypes.any,
  memberEligibility: PropTypes.any,
  patientCaseTemplates: PropTypes.any,
  patientCases: PropTypes.array,
  patientCasesLoading: PropTypes.bool,
  reports: PropTypes.any,
};

HomeView.defaultProps = {
  patientCases: [],
  patientCaseTemplates: [],
  reports: [],
};

export default HomeView;
