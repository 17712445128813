import Loader from 'components/Loader';
import React from 'react';

const Component = React.lazy(() => import('./index'));

export default (props) => (
  <React.Suspense fallback={<Loader />}>
    <Component {...props} />
  </React.Suspense>
);
