/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  licenseMessage: {
    id: 'components.Footer.license.message',
    defaultMessage: 'Copyright © {year}',
  },
});
