/*
 *
 * MedicalInformation saga
 *
 */

import { errorNotificationAction } from '@oraleye/frontend-modules-utils';
import { selectMember } from 'containers/Auth/AuthRouter/selectors';
import { stringify } from 'query-string';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { successNotification } from 'services/Toast';
import request from 'utils/request';
import { patchMemberMedicalInformation } from '../Auth/AuthRouter/actions';
import {
  PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE,
  PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS,
} from '../Auth/AuthRouter/constants';
import {
  confirmPharmacySelectedFailure,
  confirmPharmacySelectedSuccess,
  getMemberPharmaciesFailure,
  getMemberPharmaciesSuccess,
  getPharmacyListFailure,
  getPharmacyListSuccess,
  removePharmacyFailure,
  removePharmacySuccess,
  selectPharmacyFailure,
  selectPharmacySuccess,
} from './actions';
import {
  CONFIRM_PHARMACY_SELECTED,
  GET_MEMBER_PHARMACIES,
  GET_PHARMACY_LIST,
  REMOVE_PHARMACY,
  SELECT_PHARMACY,
} from './constants';

function* getPharmacyList({ payload }) {
  const { specialties, ...formValues } = payload;
  const specialtiesSelected = Object.keys(specialties).filter(
    (specialtyName) => !!specialties[specialtyName],
  );
  const specialtiesSearch = stringify(
    { specialties: specialtiesSelected },
    { arrayFormat: 'bracket' },
  );
  const searchParams = stringify(formValues);
  const method = 'GET';
  const endpoint = `v3/pharmacies${
    !!specialtiesSearch ? `?${specialtiesSearch}` : ''
  }${!!searchParams ? (!!specialtiesSearch ? '&' : '?') : ''}${searchParams}`;

  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(getPharmacyListSuccess({ data: response }));
  } catch (error) {
    getPharmacyListFailure(error);
    console.error(error);
  }
}

function* getPharmacyDetails(payload) {
  const { id } = payload;
  const method = 'GET';
  const endpoint = `v3/pharmacies/${id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return yield call(request, endpoint, requestData);
}

function* removeMemberPharmacy({ payload }) {
  const { pharmacy_id } = payload;
  const method = 'DELETE';
  const endpoint = `v3/member_pharmacies/${pharmacy_id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    successNotification('Pharmacy removed');
    yield put(removePharmacySuccess(response));
  } catch (error) {
    yield put(removePharmacyFailure(error));
    console.error(error);
  }
}

function* getMemberPharmacies() {
  const method = 'GET';
  const endpoint = `v3/member_pharmacies`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const memberPharmacies = yield call(request, endpoint, requestData);
    const pharmacyDetails = yield all(
      memberPharmacies.map((item) =>
        call(getPharmacyDetails, { id: item.pharmacy_id }),
      ),
    );
    const pharmacyListWithDetails = pharmacyDetails.map((item) => {
      const pharmacy = memberPharmacies.find(
        (pharmacyRecord) => pharmacyRecord.pharmacy_id === item.id,
      );
      return {
        ...item,
        pharmacy_id: pharmacy.id,
      };
    });
    yield put(getMemberPharmaciesSuccess({ data: pharmacyListWithDetails }));
  } catch (error) {
    getMemberPharmaciesFailure(error);
    console.error(error);
  }
}

function* confirmPharmacySelected() {
  const member = yield select(selectMember());
  const memberId = member?.id;
  yield put(
    patchMemberMedicalInformation({
      body: { pharmacies_complete: true },
      memberId,
    }),
  );
  const [success, error] = yield race([
    take(PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS),
    take(PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE),
  ]);
  if (error) {
    yield put(confirmPharmacySelectedFailure(error));
    yield put(
      errorNotificationAction({
        title: 'Error updating pharmacy.',
      }),
    );
  } else if (success) {
    yield put(confirmPharmacySelectedSuccess());
  }
}

function* selectPharmacy({ payload }) {
  const { prescription_provider_pharmacy_id } = payload;
  const method = 'POST';
  const endpoint = `v3/member_pharmacies`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
    body: JSON.stringify({
      prescription_provider_pharmacy_id,
    }),
  };
  try {
    const response = yield call(request, endpoint, requestData);
    const pharmacyDetails = yield call(getPharmacyDetails, {
      id: response.pharmacy_id,
    });
    successNotification('Pharmacy added');
    yield put(
      selectPharmacySuccess({
        ...pharmacyDetails,
        pharmacy_id: response.id,
      }),
    );
  } catch (error) {
    yield put(selectPharmacyFailure(error));
    console.error(error);
  }
}

export default function* pharmacySaga() {
  yield takeLatest(GET_PHARMACY_LIST, getPharmacyList);
  yield takeLatest(SELECT_PHARMACY, selectPharmacy);
  yield takeLatest(GET_MEMBER_PHARMACIES, getMemberPharmacies);
  yield takeLatest(REMOVE_PHARMACY, removeMemberPharmacy);
  yield takeLatest(CONFIRM_PHARMACY_SELECTED, confirmPharmacySelected);
}
