/**
 * AppWrapper
 **/

import { Background, ThemeProvider } from '@oraleye/frontend-modules-core';
import ErrorBoundary from 'components/ErrorBoundary';
import Config from 'config';
import { LD_UI_THEME_FLAG } from 'constants/launchDarklyFlags';
import { CREATE_ACCOUNT_PAGE, LOGIN_PAGE } from 'constants/pageIdentifiers';
import GlobalStyle from 'global-styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  max-width: calc(1120px + 16px * 2);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 0 8px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  *::selection {
    background-color: #16b79d;
    color: white;
  }
`;
const movingRoutes = [CREATE_ACCOUNT_PAGE, LOGIN_PAGE];

window.scrollToTop = () => document.scrollingElement.scrollTo(0, 0);

const AppBackground = ({ children, ...props }) => {
  const content = useRef();
  const location = useLocation();
  const refreshRef = useRef(1);
  const getStatic = useMemo(() => {
    refreshRef.current = refreshRef.current + 1;
    return (
      Config.isBCBS ||
      Config.isQuip ||
      Config.isDelta ||
      movingRoutes.findIndex((i) => i === location.pathname) === -1
    );
  }, [location.pathname]);

  const BackgroundColor = useMemo(() => {
    if (
      movingRoutes.findIndex((i) => i === location.pathname) > -1 &&
      Config.isBCBS
    ) {
      return 'white';
    }
    return undefined;
  }, [location]);

  useEffect(() => {
    window.scrollToTop = () => content.current.scrollTo(0, 0);
    return () =>
      (window.scrollToTop = () => document.scrollingElement.scrollTo(0, 0));
  }, [content]);

  return (
    <Background
      backgroundColor={BackgroundColor}
      isStatic={getStatic}
      refresh={refreshRef.current}
      nCircles={Config.isQuip || Config.isDelta ? 0 : 1}
      nSmiles={Config.isQuip || Config.isDelta ? 0 : 1}
      ref={content}
      {...props}
    >
      {children}
    </Background>
  );
};

const AppWrapper = ({ children, ...props }) => {
  const flags = useFlags();
  const theme = flags[LD_UI_THEME_FLAG];

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AppBackground {...props}>
          <Content>{children}</Content>
        </AppBackground>
      </ErrorBoundary>
      <ToastContainer />
      <GlobalStyle />
    </ThemeProvider>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.any,
};

export default AppWrapper;

AppBackground.propTypes = {
  children: PropTypes.any,
};
