import styled from 'styled-components';

const ContentWrapper = styled.div`
  max-width: calc(1120px + 16px * 2);
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  align-content: space-between;
  padding: 0 8px;
`;

export default ContentWrapper;
