import {
  ON_OFF_ANSWER_TYPE,
  PHONE_NUMBER_ANSWER_TYPE,
  SHORT_TEXT_ANSWER_TYPE,
  SLIDER_ANSWER_TYPE,
  TEXT_ANSWER_TYPE,
  TOGGLE_ANSWER_TYPE,
} from '@oraleye/frontend-modules-components';
import * as icons from '@oraleye/frontend-modules-icons';
import {
  DROPDOWN_TYPE,
  HEIGHT_TYPE,
  PICKER_TYPE,
  RADIO_TYPE,
  RATING_TYPE,
  STAR_RATING,
  STATE_ZIP_TYPE,
  WEIGHT_TYPE,
  answerTypes,
} from 'components/QuestionCard/constants';
import { FormTextInput } from 'components/formComponents';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import { getAge } from 'utils/dateHelpers';
import messages from './messages';

const IconCache = {};

const defaultStyle = { minWidth: 32, minHeight: 32 };

const Icon = ({ name, ...props }) => {
  const [img, setImg] = useState(IconCache[name] || null);
  useEffect(() => {
    if (!name) return;
    setImg(icons[name]);
  }, [name]);

  return img ? <img style={defaultStyle} src={img} {...props} /> : null;
};

const getIcon = (configuration) => {
  const name = configuration?.image_reference || configuration?.icon;
  return name ? <Icon name={name} /> : null;
};

const phoneNumberValidation = (value) => {
  if (value && !isValidPhoneNumber(value)) {
    return <FormattedMessage {...messages.invalidPhoneNumber} />;
  }
  return '';
};

function isDateTime(card) {
  return card.answers[0].answer_type === 'date_time';
}

function isEmail(card) {
  return card.question.text.indexOf('email') > -1;
}

function getTextFieldType(card) {
  if (isEmail(card)) {
    return 'email';
  }
  if (isDateTime(card)) {
    return 'date';
  }
  return 'text';
}

function formatDate(date) {
  return `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(
    2,
    '0',
  )}-${`${date.getDate()}`.padStart(2, '0')}`;
}

function getInitialValue(card, answerType, options) {
  let ans = card.answers.find((ans) => ans.selected);
  let val = ans?.answer_value;

  if (isDateTime(card)) {
    if (val) {
      return formatDate(new Date(val));
    }
    return '';
  }

  switch (answerType) {
    case RADIO_TYPE:
    case DROPDOWN_TYPE:
      return (
        options.find((opt) => opt.value === ans?.question_and_answer_id) || ''
      );
    case ON_OFF_ANSWER_TYPE:
      const defaultValue =
        card.answers[0].configuration.default_value === 'true';
      return undefined === val ? defaultValue : val.toString() === 'true';
    case PHONE_NUMBER_ANSWER_TYPE:
      return 'undefined' === val ? undefined : val;
    default:
      return val || '';
  }
}

function getLabel(card, type, questionKey = 'text') {
  const answer0 = card.answers[0];
  const isOptional =
    card.question.optional && type !== HEIGHT_TYPE && type !== WEIGHT_TYPE;

  if (type === ON_OFF_ANSWER_TYPE || type === TOGGLE_ANSWER_TYPE) {
    return {
      question: card.question.text,
      label: (
        <>
          {answer0?.configuration && answer0?.configuration[questionKey]
            ? answer0?.configuration?.text
            : ''}
          {isOptional ? ' (optional)' : ''}
        </>
      ),
    };
  }
  return {
    label: (
      <>
        {card.question[questionKey] || ''}
        {isOptional ? ' (optional)' : ''}
      </>
    ),
  };
}

export const isHeightWeightCard = (card) => {
  return (
    card.answers[0].answer_type === HEIGHT_TYPE ||
    card.answers[0].answer_type === WEIGHT_TYPE
  );
};

/**
 * Height and weight cards are hidden if you are 19 or over
 * @param card
 * @param dobCard
 * @param member
 * @return {boolean}
 */
export const isVisibleDobDep = (card, dobCard, member) => {
  // visible if not height or weight
  if (!isHeightWeightCard(card)) {
    return true;
  }

  // not visible if we don't have the DOB yet
  if ((!dobCard || !dobCard?.answers[0].answer_value) && !member?.dob) {
    return false;
  }

  // visible if we have DOB and it is less than 19
  const memberAge = !!member?.dob ? getAge(member?.dob) : null;
  const answeredAge = !!dobCard?.answers[0].answer_value
    ? getAge(dobCard?.answers[0].answer_value)
    : null;
  const age = memberAge ?? answeredAge;
  if (age < 19) {
    return true;
  }

  // not visible otherwise (over 19)
  return false;
};

/**
 * Height and weight cards are hidden if you are 19 or over
 * @param card
 * @param dobCard
 * @return {boolean}
 */
export const isHiddenDependantOnDob = (card, dobCard, member) => {
  return !isVisibleDobDep(card, dobCard, member);
};

export const getFieldProps = (
  card,
  updateValue,
  questionKey,
  cards,
  member,
) => {
  const dobCard = cards.find(
    (c) =>
      c.question.text.indexOf('date of birth') > -1 && c.answers[0].selected,
  );
  const type = getTextFieldType(card);
  const answer0 = card.answers[0];
  let answerType = answer0.answer_type;

  let name = card.id;
  if (card.answers.length === 1) {
    name = answer0.question_and_answer_id;
  }
  let onChange = (e) => {
    if (card.answers.length === 1) {
      if (answerType === ON_OFF_ANSWER_TYPE) {
        return updateValue(e.target?.checked.toString(), name);
      }
      return updateValue(e.target?.value, name);
    }
    if (answerType === RADIO_TYPE || answerType === DROPDOWN_TYPE) {
      const val = card.answers.find(
        (ans) => ans.question_and_answer_id === e.target.value,
      );
      return updateValue(val.configuration.text, e.target?.value);
    }
  };

  let options;
  if (answerType === RADIO_TYPE || answerType === DROPDOWN_TYPE) {
    options = card.answers.map((ans) => ({
      label: ans.configuration?.text,
      text: ans.configuration?.text,
      icon: getIcon(ans.configuration),
      value: ans.question_and_answer_id,
      id: ans.question_and_answer_id,
    }));
  } else if (answerType === PICKER_TYPE || answerType === STATE_ZIP_TYPE) {
    options = answer0.configuration.values.map((val) => ({
      label: val,
      value: val,
    }));
  } else if (answerType === SLIDER_ANSWER_TYPE) {
    const len = parseInt(answer0.configuration.max_slider) || 10;
    const arr = new Array(len).fill(1);
    options = arr.map((a, i) => ({ label: `${i + 1}`, value: i + 1 }));
  }

  const { label, question, placeholder } = getLabel(
    card,
    answerType,
    questionKey,
  );

  const fieldProps = {
    initialValue: getInitialValue(card, card.answers[0].answer_type, options),
    label,
    question,
    placeholder,
    id: card.id,
    type,
    name,
    onChange,
    options,
    autoComplete: 'off',
    optional: card.question.optional,
    hidden: isHiddenDependantOnDob(card, dobCard, member),
  };
  if (answerType === RADIO_TYPE) {
    fieldProps.text = label;
    fieldProps.variant = answer0.configuration?.variant || 'vertical';
  }
  if (answerType === STATE_ZIP_TYPE) {
    fieldProps.stateOptions = options;
  }
  if (answerType === DROPDOWN_TYPE) {
    fieldProps.defaultToFirstOption = false;
  }
  if (answerType === RATING_TYPE || answerType === STAR_RATING) {
    fieldProps.startValue = 1;
    fieldProps.endValue = parseInt(answer0.configuration.max_rating);
  }
  if (
    answerType === TEXT_ANSWER_TYPE ||
    answerType === SHORT_TEXT_ANSWER_TYPE
  ) {
    if (answer0?.configuration?.sub_text) {
      fieldProps.subtext = answer0.configuration.sub_text;
    }
    if (answer0?.configuration?.placeholder) {
      fieldProps.placeholder = answer0.configuration.placeholder;
    }
    if (answer0?.configuration?.max_characters) {
      fieldProps.maxLength = answer0.configuration.max_characters;
    }
  } else if (answerType === PHONE_NUMBER_ANSWER_TYPE) {
    fieldProps.validate = phoneNumberValidation;
  }
  return fieldProps;
};

export function getAnswerType(card) {
  // TODO: remove this when confirmed not used
  if (
    card.question.text.toLowerCase().indexOf('first name') > -1 ||
    card.question.text.toLowerCase().indexOf('last name') > -1 ||
    card.question.text.toLowerCase().indexOf('enrollee') > -1 ||
    isEmail(card)
  ) {
    return FormTextInput;
  }

  return answerTypes[card.answers[0].answer_type];
}

Icon.propTypes = {
  name: PropTypes.any,
};
