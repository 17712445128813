import { errorNotificationAction } from '@oraleye/frontend-modules-utils';
import {
  fetchPatientCaseTemplatePhotoCategoriesFailure,
  fetchPatientCaseTemplatePhotoCategoriesSuccess,
  skipPhotosFailureAction,
  skipPhotosSuccessAction,
} from 'containers/Photos/actions';
import { countBy } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { track } from 'services/EventsService';
import events from 'services/EventsService/events.json';
import {
  deletePhotoFromDatabase,
  getAllPhotosFromDatabase,
} from 'utils/indexedDB';
import request from 'utils/request';
import { retryRequest } from 'utils/retryRequest';
import {
  countPhotosFromDatabaseSuccessAction,
  deleteCasePhotosFromDatabaseSuccessAction,
  fetchCasePhotosFailure,
  fetchCasePhotosSuccess,
  getFlowMessageFailureAction,
  getFlowMessageSuccessAction,
  getPatientCaseTemplateFailureAction,
  getPatientCaseTemplateSuccessAction,
  loadPhotosFromDatabaseFailureAction,
  loadPhotosFromDatabaseSuccessAction,
} from './actions';
import {
  CASE_PHOTOS_ENDPOINT,
  COUNT_PHOTOS_FROM_INDEXED_DB,
  DELETE_CASE_PHOTOS_FROM_DATABASE,
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES,
  FLOW_MESSAGES_ENDPOINT,
  GET_CASE_PHOTOS,
  GET_FLOW_MESSAGE,
  GET_PATIENT_CASE_TEMPLATE,
  LOAD_PHOTOS_FROM_INDEXED_DB,
  PATIENT_CASE_TEMPLATES_ENDPOINT,
  SKIP_PHOTOS,
} from './constants';

export function* getPatientCaseTemplate({ payload }) {
  try {
    const { templateId } = payload;
    const method = 'GET';
    const endpoint = `${PATIENT_CASE_TEMPLATES_ENDPOINT}/${templateId}`;
    const headers = new Headers();
    const requestData = {
      method,
      headers,
      mode: 'cors',
    };
    const response = yield call(request, endpoint, requestData);
    yield put(getPatientCaseTemplateSuccessAction(response));
  } catch (e) {
    console.error(e);
    yield put(getPatientCaseTemplateFailureAction(e));
  }
}

export const fetchCasePhotosRequest = async (id) => {
  const method = 'GET';
  const endpoint = CASE_PHOTOS_ENDPOINT.replace(':id', id);
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return request(endpoint, requestData);
};

function* fetchCasePhotos({ payload }) {
  try {
    const response = yield call(fetchCasePhotosRequest, payload.id);
    yield put(fetchCasePhotosSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(fetchCasePhotosFailure(e));
  }
}

function* skipPhotos({ payload }) {
  const { patientCaseId } = payload;
  try {
    // Update case status to pending i.e. waiting for assessment
    const pct = yield updatePatientCaseCall({
      patientCaseId,
      caseStatus: 'pending',
    });
    yield put(skipPhotosSuccessAction(pct));
  } catch (e) {
    console.error(e);
    yield put(errorNotificationAction({ title: 'Error completing case' }));
    yield put(skipPhotosFailureAction(e));
  }
}

function* getFlowMessage() {
  const method = 'GET';
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, FLOW_MESSAGES_ENDPOINT, requestData);
    yield put(getFlowMessageSuccessAction(response));
  } catch (e) {
    console.error(e);
    yield put(getFlowMessageFailureAction(e));
  }
}

const checkStatus = (status) => (data) => data.status === status;

export function* updatePatientCaseCall({ patientCaseId, caseStatus }) {
  const url = `v3/patient_cases/${patientCaseId}`;
  const headers = new Headers();
  const options = {
    method: 'PATCH',
    headers,
    mode: 'cors',
    body: JSON.stringify({
      status: caseStatus,
    }),
  };
  const res = yield call(
    retryRequest,
    url,
    options,
    checkStatus(caseStatus),
    3,
  );
  track(events.patchPatientCaseStatus, {
    patient_case_id: patientCaseId,
    status: caseStatus,
  });
  return yield res;
}

function getPhotoCategory({ payload }) {
  const { id } = payload;
  const method = 'GET';
  const endpoint = `v3/photo_categories/${id}`;
  const requestHeaders = new Headers();
  const requestData = {
    method,
    headers: requestHeaders,
    mode: 'cors',
  };
  return request(endpoint, requestData);
}

export function* getPatientCaseTemplatePhotoCategories({ payload }) {
  let patientCaseTemplateId;
  try {
    patientCaseTemplateId = payload.patientCaseTemplateId;
    const method = 'GET';
    const endpoint = `v3/patient_case_template_photo_categories?patient_case_template_id=${patientCaseTemplateId}`;
    const requestHeaders = new Headers();
    const requestData = {
      method,
      headers: requestHeaders,
      mode: 'cors',
    };
    const pcs = yield call(
      getPatientCaseTemplatePhotoCategoriesRequest,
      patientCaseTemplateId,
    );
    yield put(fetchPatientCaseTemplatePhotoCategoriesSuccess(pcs));
  } catch (e) {
    console.error(e);
    yield put(
      fetchPatientCaseTemplatePhotoCategoriesFailure(e, {
        patientCaseTemplateId,
      }),
    );
  }
}

const getPatientCaseTemplatePhotoCategoriesRequest = async (
  patientCaseTemplateId,
) => {
  const method = 'GET';
  const endpoint = `v3/patient_case_template_photo_categories?patient_case_template_id=${patientCaseTemplateId}`;
  const requestHeaders = new Headers();
  const requestData = {
    method,
    headers: requestHeaders,
    mode: 'cors',
  };
  const response = await request(endpoint, requestData);
  if (!response.length) {
    throw 'Could not find patient case template photo categories';
  }
  // get the photo categories for these PCTPCs, and add the required data
  const pcs = await Promise.all(
    response.map((r) =>
      getPhotoCategory({ payload: { id: r.photo_category_id } }).then((pc) => {
        pc.photo_category_instructions = pc.instructions;
        pc.instructions = r.instructions || pc.instructions;
        pc.patient_case_template_id = r.patient_case_template_id;
        pc.selected = pc.selected ?? r.selected ?? true;
        pc.enabled = r.enabled;
        pc.display_name = r.display_name || pc.photo_category_name;
        pc.order = r.order;
        return pc;
      }),
    ),
  );
  return pcs;
};

export function* loadPhotosFromIndexedDB({ payload }) {
  let storedPhotos;
  try {
    storedPhotos = (yield getAllPhotosFromDatabase()) || [];
  } catch (e) {
    storedPhotos = [];
  }
  try {
    const { patientCaseId } = payload;
    const patientCasePhotos = storedPhotos.reduce((casePhotos, photo) => {
      if (photo.patientCaseId !== patientCaseId) {
        return casePhotos;
      }
      casePhotos[photo.photoCategoryId] = {
        databaseId: photo.id,
        patient_case_id: photo.patientCaseId,
        photo_category_name: photo.photoCategoryName,
        photo_category_id: photo.photoCategoryId,
      };
      return casePhotos;
    }, {});

    yield put(
      loadPhotosFromDatabaseSuccessAction({
        [patientCaseId]: patientCasePhotos,
      }),
    );
  } catch (e) {
    yield put(loadPhotosFromDatabaseFailureAction(e));
  }
}

function* getIndexDBPhotoCounts() {
  try {
    const storedPhotos = (yield getAllPhotosFromDatabase()) || [];
    const counts = countBy(storedPhotos, 'patientCaseId');
    yield put(countPhotosFromDatabaseSuccessAction(counts));
  } catch (e) {
    yield put(loadPhotosFromDatabaseFailureAction(e));
  }
}

function* deleteCasePhotosFromDatabase({ payload }) {
  const { takenPhotos } = payload;
  try {
    // Delete the cases taken photos from indexedDB
    yield all(takenPhotos.map((photo) => deletePhotoFromDatabase(photo.id)));
    yield put(deleteCasePhotosFromDatabaseSuccessAction());
  } catch (e) {
    yield put(deleteCasePhotosFromDatabaseSuccessAction(e));
  }
}

export default function* photosSaga() {
  yield takeLatest(GET_CASE_PHOTOS, fetchCasePhotos);
  yield takeLatest(SKIP_PHOTOS, skipPhotos);
  yield takeLatest(LOAD_PHOTOS_FROM_INDEXED_DB, loadPhotosFromIndexedDB);
  yield takeLatest(COUNT_PHOTOS_FROM_INDEXED_DB, getIndexDBPhotoCounts);
  yield takeLatest(GET_PATIENT_CASE_TEMPLATE, getPatientCaseTemplate);
  yield takeLatest(GET_FLOW_MESSAGE, getFlowMessage);
  yield takeLatest(
    DELETE_CASE_PHOTOS_FROM_DATABASE,
    deleteCasePhotosFromDatabase,
  );
  yield takeLatest(
    FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES,
    getPatientCaseTemplatePhotoCategories,
  );
}
