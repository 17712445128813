/*
 * GenericPatientCaseStart Messages
 *
 * This contains all the text for the GenericPatientCaseStart container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.GenericPatientCaseStart';

export default defineMessages({
  documentTitle: {
    id: `${scope}.documentTitle`,
    defaultMessage: '{company} | Start a consultation with {company}',
  },
});
