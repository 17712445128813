/*
 *
 * Checkout constants
 *
 */

export const DEFAULT_ACTION = 'app/Checkout/DEFAULT_ACTION';

export const PAYMENTS_ENDPOINT = 'v3/payments';

export const FETCH_PAYMENTS = 'app/Checkout/FETCH_PAYMENTS';
export const FETCH_PAYMENTS_SUCCESS = 'app/Checkout/FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILURE = 'app/Checkout/FETCH_PAYMENTS_FAILURE';

export const PATCH_PAYMENTS = 'app/Checkout/PATCH_PAYMENTS';
export const PATCH_PAYMENTS_SUCCESS = 'app/Checkout/PATCH_PAYMENTS_SUCCESS';
export const PATCH_PAYMENTS_FAILURE = 'app/Checkout/PATCH_PAYMENTS_FAILURE';

export const PAYMENT_INTENTS_ENDPOINT = 'v3/payment_intents';

export const FETCH_PAYMENT_INTENTS = 'app/Checkout/FETCH_PAYMENT_INTENTS';
export const FETCH_PAYMENT_INTENTS_SUCCESS =
  'app/Checkout/FETCH_PAYMENT_INTENTS_SUCCESS';
export const FETCH_PAYMENT_INTENTS_FAILURE =
  'app/Checkout/FETCH_PAYMENT_INTENTS_FAILURE';

export const PATCH_PAYMENT_FETCH_INTENT =
  'app/Checkout/PATCH_PAYMENT_FETCH_INTENT';
export const PATCH_PAYMENT_FETCH_INTENT_SUCCESS =
  'app/Checkout/PATCH_PAYMENT_FETCH_INTENT_SUCCESS';
export const PATCH_PAYMENT_FETCH_INTENT_FAILURE =
  'app/Checkout/PATCH_PAYMENT_FETCH_INTENT_FAILURE';

export const FETCH_CHECKOUT_DATA = 'app/Checkout/FETCH_CHECKOUT_DATA';
export const FETCH_CHECKOUT_DATA_SUCCESS =
  'app/Checkout/FETCH_CHECKOUT_DATA_SUCCESS';
export const FETCH_CHECKOUT_DATA_FAILURE =
  'app/Checkout/FETCH_CHECKOUT_DATA_FAILURE';
