/*
 *
 * Pharmacy reducer
 *
 */

import {
  CONFIRM_PHARMACY_SELECTED,
  CONFIRM_PHARMACY_SELECTED_FAILURE,
  CONFIRM_PHARMACY_SELECTED_SUCCESS,
  GET_MEMBER_PHARMACIES,
  GET_MEMBER_PHARMACIES_FAILURE,
  GET_MEMBER_PHARMACIES_SUCCESS,
  GET_PHARMACY_LIST,
  GET_PHARMACY_LIST_FAILURE,
  GET_PHARMACY_LIST_SUCCESS,
  REMOVE_PHARMACY,
  REMOVE_PHARMACY_FAILURE,
  REMOVE_PHARMACY_SUCCESS,
  SELECT_PHARMACY,
  SELECT_PHARMACY_FAILURE,
  SELECT_PHARMACY_SUCCESS,
} from './constants';

export const initialState = () => ({
  pharmacy_list: {
    loading: false,
    data: [],
    error: null,
    fetched: false,
  },
  loading: false,
  error: null,
  member_pharmacies: [],
});

function pharmacyReducer(state = initialState(), action) {
  switch (action.type) {
    case GET_PHARMACY_LIST:
      return {
        ...state,
        pharmacy_list: {
          ...state.pharmacy_list,
          loading: true,
        },
      };
    case GET_PHARMACY_LIST_SUCCESS:
      return {
        ...state,
        pharmacy_list: {
          ...state.pharmacy_list,
          loading: false,
          fetched: true,
          data: action.payload.data,
        },
      };
    case GET_PHARMACY_LIST_FAILURE:
      return {
        ...state,
        pharmacy_list: {
          ...state.pharmacy_list,
          loading: false,
          error: action.error,
        },
      };
    case SELECT_PHARMACY:
      return {
        ...state,
        loading: true,
      };
    case SELECT_PHARMACY_SUCCESS:
      return {
        ...state,
        loading: false,
        member_pharmacies: [action.payload],
      };
    case SELECT_PHARMACY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REMOVE_PHARMACY:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PHARMACY_SUCCESS:
      return {
        ...state,
        loading: false,
        member_pharmacies: [],
      };
    case REMOVE_PHARMACY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_MEMBER_PHARMACIES:
      return {
        ...state,
        loading: true,
      };
    case GET_MEMBER_PHARMACIES_SUCCESS:
      return {
        ...state,
        loading: false,
        member_pharmacies: action.payload.data,
      };
    case GET_MEMBER_PHARMACIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CONFIRM_PHARMACY_SELECTED:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_PHARMACY_SELECTED_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CONFIRM_PHARMACY_SELECTED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export default pharmacyReducer;
