import { COOKIES_BANNER_ZINDEX } from '@oraleye/frontend-modules-components';

export const Z_SIDEBAR = 950;
export const Z_NAVBAR = 799;
export const Z_NAVBAR_LOGO = 1001;
export const Z_SUB_NAVBAR = Z_NAVBAR - 1; // Case steps nav
export const Z_BLURRED_OVERLAY = Z_SUB_NAVBAR - 1;
export const Z_VIDEO_CONTAINER_REMOTEPARTICIPANT = 1140;
export const Z_VIDEO_CONTAINER_LOCALPARTICIPANT = 1150;
export const Z_VIDEO_CONTAINER_CALL_DISCONNECT = 1155;
export const Z_VIDEO_CONTAINER_ACTIONS = 1160;
export const Z_VIDEO_CONTAINER_ACTION_BUTTON = 1170;
export const Z_PROFILE_MENU = 10001;
export const Z_PROFILE_MENU_LISTENER = 10000;
export { COOKIES_BANNER_ZINDEX };
export const INTERCOM_ZINDEX = COOKIES_BANNER_ZINDEX - 3;
