/*
 * ProfileMenu Messages
 *
 * This contains all the text for the ProfileMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ProfileMenu';

export default defineMessages({
  connectToADentist: {
    id: `${scope}.connectToADentist`,
    defaultMessage: 'Connect to a dentist',
  },
  records: {
    id: `${scope}.records`,
    defaultMessage: 'Records',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log out',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Manage profile',
  },
  videoPreferences: {
    id: `${scope}.videoPreferences`,
    defaultMessage: 'Video Preferences',
  },
});
