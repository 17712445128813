/*
 *
 * Checkout reducer
 *
 */

import {
  FETCH_PAYMENT_INTENTS,
  FETCH_PAYMENT_INTENTS_FAILURE,
  FETCH_PAYMENT_INTENTS_SUCCESS,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAILURE,
  FETCH_PAYMENTS_SUCCESS,
  PATCH_PAYMENTS,
  PATCH_PAYMENTS_FAILURE,
  PATCH_PAYMENTS_SUCCESS,
} from 'containers/Consultation/Checkout/constants';

export const initialState = () => ({
  payments: {
    loading: false,
    data: null,
    fetched: false,
    updated: false,
    error: null,
  },
  paymentIntent: {
    loading: false,
    data: null,
    fetched: false,
  },
});

function checkoutReducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return {
        ...state,
        payments: {
          ...state.payments,
          loading: true,
          updated: false,
          error: null,
        },
      };
    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      };
    case FETCH_PAYMENTS_FAILURE:
      return {
        ...state,
        payments: {
          ...state.payments,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_PAYMENT_INTENTS:
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          loading: true,
        },
      };
    case FETCH_PAYMENT_INTENTS_SUCCESS:
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          data: action.payload,
          loading: false,
          fetched: true,
        },
      };
    case FETCH_PAYMENT_INTENTS_FAILURE:
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          loading: false,
          error: action.error,
        },
      };
    case PATCH_PAYMENTS:
      return {
        ...state,
        payments: {
          ...state.payments,
          loading: true,
          updated: false,
          error: null,
        },
      };
    case PATCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          data: action.payload,
          loading: false,
          fetched: true,
          updated: true,
          error: null,
        },
      };
    case PATCH_PAYMENTS_FAILURE:
      return {
        ...state,
        payments: {
          ...state.payments,
          loading: false,
          error: action.error,
        },
      };
    // case FETCH_CHECKOUT_DATA: {
    //   return {
    //     ...state,
    //     payments: { ...state.payments, loading: true, error: null },
    //     paymentIntent: { ...state.paymentIntent, loading: true, error: null },
    //   };
    // }
    // case FETCH_CHECKOUT_DATA_SUCCESS: {
    //   return {
    //     ...state,
    //     payments: {
    //       ...state.payments,
    //       loading: false,
    //       error: null,
    //       data: action.payload.payment,
    //     },
    //     paymentIntent: {
    //       ...state.paymentIntent,
    //       loading: false,
    //       error: null,
    //       data: action.payload.paymentIntent,
    //     },
    //   };
    // }
    // case FETCH_CHECKOUT_DATA_FAILURE: {
    //   return {
    //     ...state,
    //     payments: { ...state.payments, loading: false, error: null },
    //     paymentIntent: { ...state.paymentIntent, loading: false, error: null },
    //   };
    // }
    default:
      return state;
  }
}

export default checkoutReducer;
