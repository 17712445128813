/*
 * FollowUpLandingPage Messages
 *
 * This contains all the text for the FollowUpLandingPage component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.FollowUpLandingPageNew';

export default defineMessages({
  headerFollowUp: {
    id: `${scope}.header1`,
    defaultMessage: 'Follow up on your treatment',
  },
  headerFollowUp2: {
    id: `${scope}.header2`,
    defaultMessage:
      "Your experience matters to us. Let us know how you're healing with a simple online consult.",
  },

  headerTriage: {
    id: `${scope}.headerTriage`,
    defaultMessage: 'Quick online dental care',
  },
  headerTriage2: {
    id: `${scope}.headerTriage2`,
    defaultMessage:
      'Address your dental concern from the comfort of your own phone. Start a virtual consult and get care now.',
  },

  headerGeneric: {
    id: `${scope}.headerGeneric`,
    defaultMessage: 'Quick online dental care',
  },
  headerGeneric2: {
    id: `${scope}.headerGeneric2`,
    defaultMessage:
      'Reach your dentist from the comfort of your own phone. Start a virtual consult and get care now.',
  },

  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get started',
  },
  startConsult: {
    id: `${scope}.startConsult`,
    defaultMessage: 'Start consult',
  },
  howStart: {
    id: `${scope}.howStart`,
    defaultMessage: 'How to get started?',
  },
  item1: {
    id: `${scope}.item1`,
    defaultMessage: '1. Answer Questions',
  },
  item2: {
    id: `${scope}.item2`,
    defaultMessage: '2. Take Photos',
  },
  item3: {
    id: `${scope}.item3`,
    defaultMessage: '3. Dentist Reviews',
  },
  subitem1: {
    id: `${scope}.subitem1`,
    defaultMessage: 'Answer a few questions on your oral health.\n',
  },
  subitem2: {
    id: `${scope}.subitem2`,
    defaultMessage:
      'Take photos to show us specific areas of your mouth and teeth.\n',
  },
  subitem3: {
    id: `${scope}.subitem3`,
    defaultMessage:
      'Your dentist reviews your case and gets you the care you need.\n',
  },
  review1: {
    id: `${scope}.review1`,
    defaultMessage:
      '“Excellent. Very easy to understand and follow the instructions.”',
  },
  review1User: {
    id: `${scope}.review1User`,
    defaultMessage: 'Finola Fahey',
  },
  review1UserTitle: {
    id: `${scope}.review1UserTitle`,
    defaultMessage: '{company} User & Patient',
  },

  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign in',
  },

  getCareNow: {
    id: `${scope}.getCareNow`,
    defaultMessage: 'Get care from {name} now',
  },
  getCareNowSubtitle: {
    id: `${scope}.getCareNowSubtitle`,
    defaultMessage: 'Submit your case in just a few minutes.',
  },
  poweredBy: {
    id: `${scope}.poweredBy`,
    defaultMessage: 'Powered by',
  },
  copyright: {
    id: `${scope}.copyright`,
    defaultMessage: '© {year} OralEye Ltd. All rights reserved.',
  },
});
