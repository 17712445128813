/**
 *
 * Navigation
 *
 */

import Footer from 'components/Footer';
import Header from 'components/Header';
import Link from 'components/Link';
import Logo from 'components/Logo';
import ProfileMenu from 'components/ProfileMenu';
import SideMenu from 'components/SideMenu';
import { LD_FEATURE_PROFILE_MENU } from 'constants/launchDarklyFlags';
import AuthContext from 'contexts/Auth.context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, withRouter } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import tracking from 'tracking';
import shouldShowNavigation from './config';
import {
  LogoDiv,
  MainContainer,
  MainContentContainer,
  OpenButton,
  PageStretch,
  StyledIcon,
} from './styled';

const SIDEBAR_OPEN_WIDTH = 320;

const Navigation = ({ children }) => {
  const flags = useFlags();
  const buttonRef = useRef();
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const [showNavigation, setShowNavigation] = useState(
    shouldShowNavigation(location.href, isAuthenticated, flags),
  );
  const [open, setOpen] = useState(false);
  const { trackEvent } = useTracking();
  const onHeaderLogoClicked = useCallback(() => {
    trackEvent({ event_name: events.headerLogoClicked.event_name });
  }, [trackEvent]);

  const onHamburgerClicked = useCallback(() => {
    trackEvent({ event_name: events.hamburgerMenuClicked.event_name });
    setOpen(!open);
  }, [open, trackEvent]);

  const right = useMemo(() => {
    if (isAuthenticated && (flags[LD_FEATURE_PROFILE_MENU] || !isMobile)) {
      return <ProfileMenu />;
    }
    return null;
  }, [isAuthenticated, flags]);

  const left = useMemo(() => {
    if (isAuthenticated && isMobile && showNavigation.showSideMenu) {
      return (
        <OpenButton
          onClick={onHamburgerClicked}
          data-tracking-id={tracking.header.openSideMenu}
        >
          {open ? (
            <StyledIcon icon={['fa', 'times']} />
          ) : (
            <StyledIcon icon={['fa', 'bars']} />
          )}
        </OpenButton>
      );
    }
    if (!isMobile) {
      return (
        <Link
          to="/"
          data-tracking-id={tracking.header.logo}
          onClick={onHeaderLogoClicked}
        >
          <Logo />
        </Link>
      );
    }
    return null;
  }, [
    isAuthenticated,
    onHamburgerClicked,
    onHeaderLogoClicked,
    open,
    showNavigation.showSideMenu,
  ]);

  useEffect(() => {
    // Hide Navigation for video call path, etc
    setShowNavigation(
      shouldShowNavigation(location.href, isAuthenticated, flags),
    );
  }, [location.pathname, isAuthenticated, flags, location.href]);

  return (
    <>
      {showNavigation.showHeader && (
        <Header
          left={left}
          center={
            isMobile ? (
              <LogoDiv>
                <Link
                  to="/"
                  data-tracking-id={tracking.header.logo}
                  onClick={onHeaderLogoClicked}
                >
                  <Logo mobileWidth="80%" />
                </Link>
              </LogoDiv>
            ) : null
          }
          right={right}
        />
      )}
      <MainContainer>
        {isAuthenticated && isMobile && showNavigation.showProfileMenu && (
          <SideMenu
            width={SIDEBAR_OPEN_WIDTH}
            isFixed={!isMobile}
            open={open}
            setOpen={setOpen}
            buttonRef={buttonRef}
          />
        )}
        <MainContentContainer>
          <PageStretch hasHeader={showNavigation.showHeader}>
            {children}
          </PageStretch>
          {showNavigation.showFooter && <Footer />}
        </MainContentContainer>
      </MainContainer>
    </>
  );
};

export default withRouter(Navigation);
