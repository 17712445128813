const sortCreatedAtFn = (a, b) => {
  const t1 = +new Date(b.created_at);
  const t2 = +new Date(a.created_at);
  if (t1 > t2) return 1;
  if (t1 < t2) return -1;
  return 0;
};

export const addData = (arr1, arr2, sortFn = sortCreatedAtFn) => {
  const newArrIds = arr2.reduce(
    (memo, item) => Object.assign(memo, { [item.id]: 1 }),
    {},
  );
  return arr1
    .filter((item) => !newArrIds[item.id])
    .concat(arr2)
    .sort(sortFn);
};
