import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTracking } from 'react-tracking';

import Button from 'components/Button';
import Link from 'components/Link';
import useHistoryPush from 'components/LinkWithQueryParams/useHistoryPush';
import { Card, CardContent, CardTitle } from 'components/common/Card';
import Config from 'config';
import {
  LD_FEATURE_CASE_CREATION,
  LD_FEATURE_VIDEO_CALLING,
  LD_HOME_SCREEN_RESUME_CARDS,
  LD_UI_GET_CARE_HOME_BUTTON_CARD,
} from 'constants/launchDarklyFlags';
import {
  GET_CARE_PAGE,
  getConsultationBaseUrl,
} from 'constants/pageIdentifiers';
import ConnectIcon from 'images/connect.svg';
import ConnectIconQuip from 'images/quip/connect-dentist.svg';
import events from 'services/EventsService/events.json';
import tracking from 'tracking';
import {
  getPatientCaseDisplayName,
  isFollowUpCase,
  isResumeCase,
} from 'utils/caseHelpers';
import ButtonCard from '../ButtonCard';
import messages from './messages';
import {
  FollowUpIcon,
  IconContainer,
  StyledCardTitle,
  StyledP,
} from './styled';

export const ResumeSubmitCaseCard = ({
  member,
  patientCase,
  patientCaseTemplates = [],
}) => {
  const flags = useFlags();
  const isResume = React.useMemo(
    () => isResumeCase(member, patientCase),
    [member, patientCase],
  );
  const isFollowUp = React.useMemo(
    () => isFollowUpCase(patientCase),
    [patientCase],
  );

  const resumeCaseLink = React.useMemo(
    () => getConsultationBaseUrl(patientCase),
    [patientCase],
  );

  const patientCaseTemplate = React.useMemo(() => {
    return patientCaseTemplates.find(
      (pct) => pct.id === patientCase.patient_case_template_id,
    );
  }, [patientCase, patientCaseTemplates]);

  const { trackEvent } = useTracking();

  const onFeedCardClicked = React.useCallback(
    (feedItem) => {
      trackEvent({
        event_name: events.homeScreenFeedItemClicked.event_name,
        feed_item_name: feedItem.name,
        feed_item_destination: feedItem.destination,
      });
    },
    [trackEvent],
  );

  if (isResume && flags[LD_HOME_SCREEN_RESUME_CARDS]) {
    return (
      <>
        {isFollowUp && (
          <StyledP>
            <FormattedMessage
              {...messages[
                isResume ? 'followUpNotCompleted' : 'dentistHasRequested'
              ]}
            />
          </StyledP>
        )}
        <Card>
          <StyledCardTitle>
            {isFollowUp && (
              <FollowUpIcon>
                <FontAwesomeIcon icon={['fa', 'redo']} />
              </FollowUpIcon>
            )}
            {getPatientCaseDisplayName(patientCaseTemplate, patientCase)}
            <Link
              to={resumeCaseLink}
              onClick={() =>
                onFeedCardClicked({
                  name: getPatientCaseDisplayName(
                    patientCaseTemplate,
                    patientCase,
                  ),
                  destination: resumeCaseLink.replace(':id', patientCase.id),
                })
              }
            >
              <Button
                variant="outlined"
                trackingId={tracking.homeView.resumeCaseClicked}
                trackingData={{ patient_case_id: patientCase.id }}
              >
                <FormattedMessage {...messages.resume} />
              </Button>
            </Link>
          </StyledCardTitle>
          <CardContent>
            <FormattedMessage
              {...(messages[patientCase.case_type_name + 'Message'] ||
                messages.submitYourPhotos)}
              values={{
                caseType: getPatientCaseDisplayName(
                  patientCaseTemplate,
                  patientCase,
                )?.toLowerCase(),
              }}
            />
          </CardContent>
        </Card>
      </>
    );
  }
  return null;
};

ResumeSubmitCaseCard.propTypes = {
  member: PropTypes.any,
  patientCase: PropTypes.any,
  patientCaseTemplates: PropTypes.array,
};

export const GetCareCard = () => {
  const flags = useFlags();
  const push = useHistoryPush();
  const { trackEvent } = useTracking();

  const onGetCareCardClicked = React.useCallback(() => {
    /* Old card is clickable, new button card is not, this should only be
    triggered with the new button card as it's part of the action on click */
    flags[LD_UI_GET_CARE_HOME_BUTTON_CARD] && push(GET_CARE_PAGE);

    trackEvent({
      event_name: events.homeScreenFeedItemClicked.event_name,
      feed_item_name:
        messages[Config.isProfessional ? 'getCare' : 'connectToADentist']
          .defaultMessage,
      feed_item_destination: GET_CARE_PAGE,
    });
  }, [flags, push, trackEvent]);

  return flags[LD_UI_GET_CARE_HOME_BUTTON_CARD] ? (
    <ButtonCard
      title={
        <FormattedMessage
          {...messages[Config.isProfessional ? 'getCare' : 'connectToADentist']}
        />
      }
      content={
        <FormattedMessage
          {...messages[
            Config.isQuip || Config.isDelta
              ? 'getCareContentQuip'
              : flags[LD_FEATURE_VIDEO_CALLING] &&
                flags[LD_FEATURE_CASE_CREATION]
              ? 'getCareContentVideoButtonCard'
              : 'getCareContent'
          ]}
        />
      }
      icon={Config.isQuip || Config.isDelta ? ConnectIconQuip : ConnectIcon}
      buttonText={<FormattedMessage {...messages.viewConsultOptionsButton} />}
      onButtonClick={onGetCareCardClicked}
      trackingId={tracking.homeView.getCareClicked}
    />
  ) : (
    <Link
      to={GET_CARE_PAGE}
      trackingId={tracking.homeView.getCareClicked}
      onClick={onGetCareCardClicked}
    >
      <Card>
        <CardTitle>
          <IconContainer color="var(--primary02)">
            <FontAwesomeIcon icon={['fa', 'hand-holding-medical']} />
          </IconContainer>
          <FormattedMessage
            {...messages[
              Config.isProfessional ? 'getCare' : 'connectToADentist'
            ]}
          />
          <FontAwesomeIcon icon={['fa', 'chevron-right']} />
        </CardTitle>
        <CardContent>
          <FormattedMessage
            {...messages[
              flags[LD_FEATURE_VIDEO_CALLING] && flags[LD_FEATURE_CASE_CREATION]
                ? 'getCareContentVideo'
                : 'getCareContent'
            ]}
          />
        </CardContent>
      </Card>
    </Link>
  );
};

GetCareCard.propTypes = {};
