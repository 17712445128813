export default {
  signup: {
    screenShown: 'signup_screen_shown',
    nextPressed: 'signup_screen_next_clicked',
    termsViewed: 'signup_screen_terms_clicked',
    termsViewedInsurer: 'signup_screen_terms_insurer_clicked',
    emailInput: 'signup_email_input',
    passwordInput: 'signup_password_input',

    regCompanyClicked: 'registration_company_option_clicked',
    regCompanySelected: 'registration_company_option_selected',
    termsToggled: 'terms_and_conditions_toggled',
    createClicked: 'create_account_clicked',
    inApp: 'signup_in_app_clicked',
    resetPassword: 'home_reset_my_password_clicked',
    notWithCompany: 'registration_not_with_company_clicked',

    signInPageLink: 'signup_sign_in_link_clicked',
  },
  signin: {
    screenShown: 'signin_screen_shown',
    nextPressed: 'signin_screen_next_clicked',
    termsViewed: 'signin_screen_terms_clicked',
    termsViewedInsurer: 'signin_screen_terms_insurer_clicked',
    emailInput: 'signin_email_input',
    passwordInput: 'signin_password_input',
    signInClicked: 'sign_in_clicked',
    create_account_clicked: 'create_account_clicked',
    inApp: 'signin_in_app_clicked',
    resetPassword: 'signin_reset_password-clicked',
  },
  resetPassword: {
    screenShown: 'reset_password_screen_shown',
    loginPressed: 'reset_password_screen_login_clicked',
    resetPasswordClicked: 'reset_password_clicked',
    submitNewPassword: 'reset_password_submit_new_password_clicked',
  },
  resetSuccess: {
    screenShown: 'reset_password_success_screen_shown',
    loginPressed: 'reset_password_success_login_clicked',
  },

  header: {
    logo: 'toothpic_header_logo_clicked',
    logout: 'header_menu_log_out_clicked',
    updateProfile: 'header_menu_update_profile_clicked',
    getCare: 'header_open_get_care_clicked',
    records: 'header_open_records_clicked',
    openSideMenu: 'header_open_side_menu_clicked',
    videoPreferences: 'header_open_video_preferences_clicked',
  },
  error: {
    shown: 'error_shown',
    ok: 'error_ok_clicked',
  },
  onboarding: {
    getStarted: 'onboarding_get_started_clicked',
    skip: 'onboarding_skip_clicked',
  },
  setup: {
    submit: 'submit_button_clicked',
    field: (question) =>
      `setup_form_${question.replace(/[\W_]+/g, '_').toLowerCase()}`,
    progress: (to) => `setup_form_progress_link_${to}`,
  },
  getCare: {
    completeCareLinkClicked: 'get_care_complete_care_link_clicked',
    specificIssueLinkClicked: 'get_care_specific_issue_link_clicked',
    videoLinkClicked: 'get_care_video_link_clicked',
    scheduledVideoLinkClicked: 'get_care_scheduled_video_link_clicked',
    backToHome: 'get_care_back_to_home_clicked',
    pageTitle: 'get_care_title',
  },
  homeView: {
    resumeCaseClicked: `home_view_resume_case_clicked`,
    startCaseClicked: 'home_view_start_case_clicked',
    getCareClicked: 'home_view_get_care_clicked',
    triageClicked: 'triage_clicked',
    pageTitle: 'home_view_title',
  },
  questions: {
    continueClicked: (type) => `${type}_question_set_continue_clicked`,
  },
  caseQuestions: {
    backToGetCare: 'case_questions_back_get_care_clicked',
    pageTitle: 'case_questions_title',
  },
  caseSelection: {
    backToGetCare: 'case_selection_back_get_care_clicked',
    pageTitle: 'case_selection_title',
  },
  caseSteps: {
    backToGetCare: 'case_steps_back_get_care_clicked',
    moveToFirstStep: 'case_steps_move_to_first_step_clicked',
    pageTitle: 'case_steps_title',
  },
  photoSelection: {
    backToGetCare: 'photo_selection_back_get_care_clicked',
    submit: 'photo_selection_submit_clicked',
    pageTitle: 'photo_selection_title',
  },
  caseCamera: {
    backToPhotoSelection: 'case_camera_back_photo_selection_clicked',
    pageTitle: 'case_camera_title',
  },
  photoInstructions: {
    backToHome: 'photo_instructions_back_to_home_clicked',
    letsGoButton: 'photo_instructions_lets_go_button_clicked',
    skipAndSubmit: 'photo_instructions_skip_and_submit_clicked',
    pageTitle: 'photo_instructions_title',
  },
  caseSubmitted: {
    reportsPageLink: 'case_submitted_reports_page_link',
    pageTitle: 'case_submitted_title',
  },
  callEnded: {
    backToGetCare: 'call_ended_back_get_care_clicked',
    rejoinCallButton: 'call_ended_rejoin_call_button_clicked',
    seeReportsButton: 'call_ended_see_reports_button_clicked',
    pageTitle: 'call_ended_title',
  },
  report: {
    pageTitle: 'report_title',
  },
  reports: {
    reportClicked: `reports_screen_report_clicked`,
    noReportsGetCare: 'reports_no_reports_get_care_clicked',
    pageTitle: 'reports_title',
  },
  sidebar: {
    getCareClicked: 'sidebar_get_care_clicked',
    homeClicked: 'sidebar_home_clicked',
    reportsClicked: 'sidebar_reports_clicked',
    diagnosticsClicked: 'sidebar_diagnostics_clicked',
  },
  qrCodeHandover: {
    backButton: 'qr_code_handover_back_button_clicked',
    photosQRCodeScreenShown: 'photos_qr_code_screen_shown',
    pageTitle: 'qr_handover_title',
  },
  triageLanding: {
    haveAccountClicked: 'triage_page_already_have_account_clicked',
    createNewAccountClicked: 'triage_page_dont_have_account_clicked',
    createAccountClicked: 'triage_page_create_account_clicked',
    signInClicked: 'triage_page_sign_in_clicked',
    startClicked: 'triage_page_start_clicked',
  },
  followUpLanding: {
    haveAccountClicked: 'follow_up_page_already_have_account_clicked',
    createNewAccountClicked: 'follow_up_page_dont_have_account_clicked',
    createAccountClicked: 'follow_up_create_account_clicked',
    signInClicked: 'follow_up_page_sign_in_clicked',
    startClicked: 'follow_up_page_start_clicked',
  },
  setStateModal: {
    stateDropdown: 'set_state_modal_state_dropdown',
    locationModalShown: 'location_modal_shown',
    locationModalStateSelected: 'location_modal_state_selected',
    locationModalContinuePressed: 'location_modal_continue_pressed',
  },
  videoBrowserBlock: {
    mount: 'video_browser_block_view_mount',
    backToHome: 'video_browser_block_back_to_home_clicked',
    pageTitle: 'video_browser_block_title',
  },
  videoCaseWrapper: {
    mount: 'video_case_started',
    backToHome: 'video_case_back_to_home_clicked',
  },
  videoCall: {
    videoCallOpened: 'video_call_opened',
    muteButtonPressed: 'mute_button_pressed',
    cameraButtonPressed: 'camera_button_pressed',
  },
  notfound: {
    notFoundScreenShown: 'not_found_page_shown',
  },
  existingCaseModal: {
    modalShown: 'existing_case_modal_shown',
    modalContinuePressed: 'existing_case_modal_continue_case_pressed',
    modalStartNewCasePressed: 'existing_case_modal_start_a_new_case_pressed',
  },
  multipleStepsScreen: {
    shown: 'multiple_steps_screen_shown',
  },
  waitingScreen: {
    shown: 'waiting_screen_shown',
  },
  callEndedScreen: {
    shown: 'call_ended_screen_shown',
  },
  memberProfile: {
    subscriberNumberTooltipClicked:
      'member_profile_subscriber_number_tooltip_clicked',
    coverageProviderTooltipClicked:
      'member_profile_coverage_provider_tooltip_clicked',
    pageTitle: 'member_profile_title',
  },
  prescriptionView: {
    pageTitle: 'prescriptions_title',
    issued: 'prescription_issued',
    pharmacy: 'prescription_pharmacy',
    medicationName: 'prescription_medication_name',
    directions: 'prescription_directions',
    importantNote: 'prescription_important_note',
  },
  checkout: {
    paymentStatusModal: 'checkout_payment_status_modal',
    cardholderName: 'checkout_cardholder_name',
    cardDetails: 'checkout_card_details',
    country: 'checkout_country',
  },
  consultation: {
    titlebar: 'consultation_title_bar',
  },
};
