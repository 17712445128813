import { Modal } from '@oraleye/frontend-modules-core';
import { createEligibilityRequestAction } from 'containers/MemberProfile/actions';
import { selectMemberEligibility } from 'containers/MemberProfile/selectors';
import IconCheckCircle from 'images/icon-check-circle.svg';
import IconErrorExclamationCircle from 'images/icon-error-exclamation-circle.svg';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import { ELIGIBLE, INELIGIBLE, UNVERIFIED } from '../constants';
import messages from '../messages';
import {
  ImageContainer,
  StyledLoader,
  VerifyingEligibilityModalHeading,
  VerifyingEligibilityModalText,
} from '../styled';

const VerifyingEligibilityModal = ({ show, onContinue, onCheckDetails }) => {
  const dispatch = useDispatch();
  const memberEligibility = useSelector(selectMemberEligibility());
  const [memberEligibilityState, setMemberEligibilityState] =
    useState(UNVERIFIED);

  const { trackEvent } = useTracking();
  useEffect(() => {
    if (show)
      trackEvent({
        event_name: events.eligibilityVerificationModalShown.event_name,
      });
  }, [show, trackEvent]);

  useEffect(() => {
    if (show) dispatch(createEligibilityRequestAction());
  }, [dispatch, show]);

  useEffect(() => {
    if (show && memberEligibility) {
      if (
        memberEligibility.status === 'success' &&
        memberEligibility.benefits_breakdown.eligible
      ) {
        setMemberEligibilityState(ELIGIBLE);
      } else if (
        (memberEligibility.status === 'success' &&
          !memberEligibility.benefits_breakdown.eligible) ||
        memberEligibility.status === 'failed'
      ) {
        setMemberEligibilityState(INELIGIBLE);
      } else setMemberEligibilityState(UNVERIFIED);
    } else setMemberEligibilityState(UNVERIFIED);
  }, [memberEligibility, show]);

  switch (memberEligibilityState) {
    case UNVERIFIED:
      return (
        <Modal
          show={show}
          title={<StyledLoader align="center" data-testid="progressbar" />}
          content={
            <>
              <VerifyingEligibilityModalHeading>
                <FormattedMessage {...messages.verificationModalHeader} />
              </VerifyingEligibilityModalHeading>
              <VerifyingEligibilityModalText>
                <FormattedMessage {...messages.verificationModalBody} />
              </VerifyingEligibilityModalText>
            </>
          }
          contentAlign="center"
          titleAlign="center"
          buttonsAlign="center"
          showCloseButton={false}
          scrollContent={false}
          trackingId="eligibility_verification_modal"
        />
      );
    case ELIGIBLE:
      return (
        <Modal
          show={show}
          title={
            <ImageContainer>
              <img src={IconCheckCircle} alt="Check circle" />
            </ImageContainer>
          }
          content={
            <>
              <VerifyingEligibilityModalHeading>
                <FormattedMessage {...messages.eligibleModalHeader} />
              </VerifyingEligibilityModalHeading>
              <VerifyingEligibilityModalText>
                <FormattedMessage {...messages.eligibleModalBody} />
              </VerifyingEligibilityModalText>
            </>
          }
          contentAlign="center"
          titleAlign="center"
          buttonsAlign="center"
          primaryButtonOnClick={() => onContinue(memberEligibilityState)}
          primaryButtonText={
            <FormattedMessage {...messages.eligiblePrimaryButton} />
          }
          showCloseButton={false}
          trackingId="eligibility_verification_modal"
        />
      );
    case INELIGIBLE:
      return (
        <Modal
          show={show}
          title={
            <ImageContainer>
              <img
                src={IconErrorExclamationCircle}
                alt="Error exclamation circle"
              />
            </ImageContainer>
          }
          content={
            <>
              <VerifyingEligibilityModalHeading>
                <FormattedMessage {...messages.ineligibleModalHeader} />
              </VerifyingEligibilityModalHeading>
              <VerifyingEligibilityModalText>
                <FormattedMessage {...messages.ineligibleModalBody} />
              </VerifyingEligibilityModalText>
            </>
          }
          contentAlign="center"
          titleAlign="center"
          buttonsAlign="center"
          primaryButtonOnClick={() => onContinue(memberEligibilityState)}
          secondaryButtonOnClick={() => onCheckDetails(memberEligibilityState)}
          primaryButtonText={
            <FormattedMessage {...messages.ineligiblePrimaryButton} />
          }
          secondaryButtonText={
            <FormattedMessage {...messages.ineligibleSecondaryButton} />
          }
          showCloseButton={false}
          trackingId="eligibility_verification_modal"
        />
      );
    default:
      return null;
  }
};

VerifyingEligibilityModal.propTypes = {
  show: PropTypes.bool,
  onContinue: PropTypes.func,
  onCheckDetails: PropTypes.func,
};

VerifyingEligibilityModal.defaultProps = {
  show: false,
};

export default VerifyingEligibilityModal;
