/*
 *
 * Photos reducer
 *
 */

import { LOAD_CONSULTATION_DATA_SUCCESS } from 'containers/Consultation/constants';
import {
  PATCH_PATIENT_CASE_STATUS,
  PATCH_PATIENT_CASE_STATUS_FAILURE,
  PATCH_PATIENT_CASE_STATUS_SUCCESS,
} from 'containers/GetCare/constants';
import {
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES,
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS,
  GET_FLOW_MESSAGE,
  GET_FLOW_MESSAGE_FAILURE,
  GET_FLOW_MESSAGE_SUCCESS,
  GET_PATIENT_CASE_TEMPLATE,
  GET_PATIENT_CASE_TEMPLATE_FAILURE,
  PATCH_CASE_PHOTO_SUCCESS,
  SKIP_PHOTOS,
  SKIP_PHOTOS_FAILURE,
  SKIP_PHOTOS_SUCCESS,
  UPLOAD_PHOTOS_PROGRESS,
} from 'containers/Photos/constants';
import { deletePhotoFromDatabase } from 'utils/indexedDB';
import {
  CONFIRM_PHOTO,
  COUNT_PHOTOS_FROM_INDEXED_DB_SUCCESS,
  GET_CASE_PHOTOS,
  GET_CASE_PHOTOS_FAILURE,
  GET_CASE_PHOTOS_SUCCESS,
  GET_PATIENT_CASE_TEMPLATE_SUCCESS,
  LOAD_PHOTOS_FROM_INDEXED_DB_SUCCESS,
  RESET_CASE_SUBMISSION_SUCCESS,
  SUBMIT_PHOTOS,
  SUBMIT_PHOTOS_FAILURE,
  SUBMIT_PHOTOS_SUCCESS,
} from './constants';

export const initialState = () => ({
  casePhotos: {},
  takenPhotos: {},
  patientCaseTemplates: {},
  patientCaseTemplatePhotoCategories: [],
  patientCaseTemplatePhotoCategoriesLoading: false,
  patientCaseTemplatePhotoCategoriesError: false,
  patientCaseTemplatesLoading: false,
  isSubmittingPhotos: false,
  caseSubmissionSuccess: false,
  caseSubmissionError: null,
  counts: {},
  flowMessage: {},
  flowMessageLoading: false,
  uploadProgress: 0,
  patientCasePatching: false,
});

function photosReducer(state = initialState(), action) {
  switch (action.type) {
    case GET_CASE_PHOTOS:
      return { ...state, casePhotosLoading: true };
    case GET_CASE_PHOTOS_SUCCESS:
      return onFetchCasePhotosSuccess(state, action.payload);
    case GET_CASE_PHOTOS_FAILURE:
      return { ...state, casePhotosLoading: false, error: action.error };

    case CONFIRM_PHOTO:
      return onConfirmPhoto(state, action.payload);
    case SUBMIT_PHOTOS:
      return {
        ...state,
        uploadProgress: 0,
        isSubmittingPhotos: true,
        caseSubmissionError: null,
        caseSubmissionSuccess: false,
      };
    case UPLOAD_PHOTOS_PROGRESS:
      return {
        ...state,
        uploadProgress: Math.max(
          0,
          Math.min(
            100,
            typeof action.payload.value === 'number'
              ? action.payload.value
              : state.uploadProgress + action.payload.add,
          ),
        ),
      };
    case SUBMIT_PHOTOS_SUCCESS:
      return {
        ...state,
        isSubmittingPhotos: false,
        caseSubmissionSuccess: true,
      };
    case SUBMIT_PHOTOS_FAILURE:
      return {
        ...state,
        isSubmittingPhotos: false,
        caseSubmissionSuccess: false,
        caseSubmissionError: action.error,
        error: action.error,
      };
    case SKIP_PHOTOS:
      return {
        ...state,
        isSkippingPhotos: true,
        caseSubmissionError: null,
      };
    case SKIP_PHOTOS_SUCCESS:
      return {
        ...state,
        isSkippingPhotos: false,
        caseSubmissionSuccess: true,
      };
    case SKIP_PHOTOS_FAILURE:
      return {
        ...state,
        isSkippingPhotos: false,
        caseSubmissionSuccess: false,
        caseSubmissionError: action.error,
      };
    case GET_FLOW_MESSAGE:
      return {
        ...state,
        flowMessageLoading: true,
      };
    case GET_FLOW_MESSAGE_SUCCESS:
      return {
        ...state,
        flowMessageLoading: false,
        flowMessage: action.payload,
      };
    case GET_FLOW_MESSAGE_FAILURE:
      return {
        ...state,
        flowMessageLoading: false,
        error: action.error,
      };
    case RESET_CASE_SUBMISSION_SUCCESS:
      return {
        ...state,
        caseSubmissionSuccess: false,
        caseSubmissionError: null,
      };
    case LOAD_PHOTOS_FROM_INDEXED_DB_SUCCESS:
      return {
        ...state,
        takenPhotos: {
          ...state.takenPhotos,
          ...action.payload,
        },
      };
    case COUNT_PHOTOS_FROM_INDEXED_DB_SUCCESS:
      return {
        ...state,
        counts: action.payload,
      };
    case GET_PATIENT_CASE_TEMPLATE:
      return {
        ...state,
        patientCaseTemplatesLoading: true,
      };
    case GET_PATIENT_CASE_TEMPLATE_SUCCESS:
      return {
        ...state,
        patientCaseTemplatesLoading: false,
        patientCaseTemplates: {
          ...state.patientCaseTemplates,
          [action.payload.id]: action.payload,
        },
      };
    case GET_PATIENT_CASE_TEMPLATE_FAILURE:
      return {
        ...state,
        patientCaseTemplatesLoading: false,
      };
    case FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES:
      return {
        ...state,
        patientCaseTemplatePhotoCategoriesLoading: true,
        patientCaseTemplatePhotoCategoriesError: false,
      };
    case FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE:
      return {
        ...state,
        patientCaseTemplatePhotoCategoriesLoading: false,
        patientCaseTemplatePhotoCategoriesError: true,
      };
    case FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS:
      return {
        ...state,
        patientCaseTemplatePhotoCategoriesLoading: false,
        patientCaseTemplatePhotoCategories: [
          ...state.patientCaseTemplatePhotoCategories,
          ...action.payload.filter(
            (pc) =>
              !state.patientCaseTemplatePhotoCategories.find(
                (_pc) =>
                  _pc.id === pc.id &&
                  _pc.patient_case_template_id === pc.patient_case_template_id,
              ),
          ),
        ],
      };
    case PATCH_CASE_PHOTO_SUCCESS:
      return onCasePhotoPatchSuccess(state, action.payload);
    case LOAD_CONSULTATION_DATA_SUCCESS:
      return {
        ...state,
        caseSubmissionError: null,
        caseSubmissionSuccess: false,
      };
    case PATCH_PATIENT_CASE_STATUS:
      return { ...state, patientCasePatching: true, patchError: false };
    case PATCH_PATIENT_CASE_STATUS_SUCCESS:
      return { ...state, patientCasePatching: false };
    case PATCH_PATIENT_CASE_STATUS_FAILURE:
      return { ...state, patientCasePatching: false, patchError: true };
    default:
      return state;
  }
}

const onConfirmPhoto = (state, { maxPhotosCount, ...payload }) => {
  if (!payload.patient_case_id) {
    return state;
  }
  const caseTakenPhotos = state.takenPhotos[payload.patient_case_id] || {};
  /* If trying to upload a photo to a new category and you have already
     reached the required number of photos then remove the oldest photo. */
  const photosList = Object.values(caseTakenPhotos);
  if (
    maxPhotosCount !== null &&
    photosList.length >= maxPhotosCount &&
    !caseTakenPhotos[payload.id]
  ) {
    const oldestPhoto = photosList.reduce((oldest, photo) => {
      if (!oldest.databaseId) return photo;
      return oldest.databaseId < photo.databaseId ? oldest : photo;
    }, {});
    delete caseTakenPhotos[oldestPhoto.id];
    deletePhotoFromDatabase(oldestPhoto.databaseId);
  }
  return {
    ...state,
    takenPhotos: {
      ...state.takenPhotos,
      [payload.patient_case_id]: {
        ...caseTakenPhotos,
        [payload.photo_category_id]: payload,
      },
    },
  };
};

const onFetchCasePhotosSuccess = (state, payload) => {
  if (!payload[0]?.patient_case_id) {
    return { ...state };
  }
  return {
    ...state,
    casePhotosLoading: false,
    casePhotos: {
      ...state.casePhotos,
      [payload[0].patient_case_id]: payload,
    },
  };
};

const onCasePhotoPatchSuccess = (state, payload) => {
  const oldCasePhotos = state.casePhotos[payload.patientCaseId];
  if (!oldCasePhotos) {
    return state;
  }
  const updatedCp = payload.body;
  const newCasePhotos = oldCasePhotos.map((cp) => {
    if (cp.id === updatedCp.id) {
      return updatedCp;
    }
    return cp;
  });
  return {
    ...state,
    casePhotos: {
      ...state.casePhotos,
      [payload.patientCaseId]: newCasePhotos,
    },
  };
};

export default photosReducer;
