/*
Taken from https://stackoverflow.com/questions/52803941/reactjs-redux-and-dexiejs-indexeddb-error-in-incognito-mode-and-chrome-v69
 */

export default () => {
  return new Promise((resolve, reject) => {
    if (typeof window.indexedDB === 'undefined') {
      // console.log('IndexedDB not supported at all!');
      reject();
    }

    let openRequest = window.indexedDB.open('firefox-private-test', 1);

    openRequest.onerror = function (evt) {
      console.error(evt.target.error);
      if (evt.target.error.message.includes('aborted')) {
        // console.log('Some other error, maybe quota related:');
        // console.log(evt.target.error);
        reject(evt.target.error);
      } else {
        // console.log('Firefox private mode, probably:');
        // console.log(evt.target.error);
        reject(evt.target.error);
      }
    };

    openRequest.onupgradeneeded = function (evt) {
      let db = evt.target.result;
      let one = db.createObjectStore('one', {
        autoIncrement: true,
        keyPath: 'key',
      });
      one.createIndex('one', 'one');
      one.add({ one: 1 });
      let two = db.createObjectStore('two', {
        autoIncrement: true,
        keyPath: 'key',
      });
      two.createIndex('two', 'two');
      two.add({ two: 2 });
    };

    openRequest.onsuccess = function (evt) {
      let db = evt.target.result;
      let transaction;
      try {
        transaction = db.transaction(['one', 'two'], 'readwrite');
      } catch (evt) {
        // console.log(
        //   'Some browser failed here, maybe an old version of Safari, I forget',
        // );
        // console.log(evt.target.error);
        reject(evt.target.error);
        return;
      }

      let count = 0;
      transaction.objectStore('one').index('one').openCursor().onsuccess =
        function (evt) {
          let cursor = evt.target.result;
          if (cursor) {
            count += 1;
            cursor.continue();
          }
        };

      transaction.oncomplete = function () {
        db.close();
        if (count === 1) {
          // console.log('All good!');
          resolve();
        } else {
          reject();
          // console.log('Buggy Safari 10 IndexedDB implementation');
        }
      };
    };
  });
};
