import { InputError, Label } from '@oraleye/frontend-modules-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import { eventSend } from 'services/EventsService';
import events from 'services/EventsService/events.json';
import styled from 'styled-components';

const WeightContainer = styled.div`
  input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius-input);
    color: var(--neutral07);
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
    min-height: 16px;
    box-shadow: 0 0 0 1px transparent;
    padding: 14px 36px 14px 18px;

    &::placeholder {
      color: var(--neutral06);
    }

    // hover, active, focus
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1px var(--input-border-color);
      outline: none !important;
      background-color: rgba(255, 255, 255, 0.9);
    }

    // error state
    ${({ error }) =>
      error &&
      css`
        & {
          border-color: var(--error);
          color: var(--error);
        }

        &:hover,
        &:active,
        &:focus {
          border-color: var(--error);
          box-shadow: 0 0 0 1px var(--error);
        }

        &::placeholder {
          color: var(--error);
          opacity: 0.5;
        }
      `};

    &:disabled {
      background: var(--neutral01);
      border: solid 1px var(--neutral05);
      box-shadow: 0 0 0 1px transparent;

      &::placeholder {
        color: var(--neutral07);
        opacity: 0.5;
      }

      cursor: not-allowed;
    }
  }
`;
const WeightInput = ({
  input,
  meta,
  label,
  name,
  onErrorShown,
  onChange,
  ...props
}) => {
  const { form } = useForm();
  const errorShownEvent = useCallback(
    (data) => {
      eventSend({
        field_name: name || input.name,
        error_message_shown: data,
        user_klass: 'member',
        event_name: events.formValidationError.event_name,
        form_name: form?.name,
      });
    },
    [name, input.name, form?.name],
  );

  const _onChange = useCallback(
    (e) => {
      input.onChange(e);
      onChange && onChange(e);
    },
    [input, onChange],
  );

  return (
    <WeightContainer>
      <Label>{label}</Label>
      <input
        {...input}
        type="number"
        min={0}
        placeholder="lbs"
        onChange={_onChange}
      />
      <InputError
        input={input}
        name={input.name}
        meta={meta}
        onErrorShown={errorShownEvent}
      />
    </WeightContainer>
  );
};

export default ({ name, ...props }) => {
  return <Field component={WeightInput} name={name} {...props} />;
};

WeightInput.propTypes = {
  form: PropTypes.any,
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.any,
  name: PropTypes.any,
  onErrorShown: PropTypes.any,
};
