import Loader from 'components/Loader';
import styled from 'styled-components';

export const PageHeading = styled.div`
  font-size: 24px;
  color: var(--primary01);
  line-height: 2.63;
`;

export const PageContainer = styled.div`
  padding: 8px;
  ${({ theme }) => theme.breakpoints.minSm`
    margin: 45px auto;
    max-height: 90%;
    max-width: 530px;
    width: 100%;
    border-radius: 8px;
    padding: 36px 60px 40px 60px;
   `};
`;

export const StyledForm = styled.form`
  margin-bottom: 16px;
`;

export const ImageContainer = styled.div`
  position: relative;
  img {
    max-height: 72px;
  }
`;

export const StyledLoader = styled(Loader)`
  margin: 12px auto;
  img {
    margin: auto;
  }
`;
export const VerifyingEligibilityModalHeading = styled.div`
  font-size: 24px;
  line-height: 1.5;
  color: var(--primary01);
  margin-bottom: 16px;
`;

export const VerifyingEligibilityModalText = styled.div`
  font-size: 16px;
  line-height: 1.63;
  color: var(--neutral07);
  margin-bottom: 24px;
`;
