import useMember from 'hooks/useMember';
import React from 'react';
import { useTracking } from 'react-tracking';
import { eventSend } from 'services/EventsService';
import EventDebugger from 'services/EventsService/eventDebugger';

const sendTrackingEvent = (event) => {
  eventSend(event);
};

export const TrackingWrapper = ({ children }) => {
  const member = useMember();
  const { Track } = useTracking(
    {
      user_klass: 'member',
      member_id: member?.id,
    },
    { dispatch: sendTrackingEvent },
  );

  return (
    <>
      <EventDebugger />
      <Track>{children}</Track>
    </>
  );
};
