import { CREATE_ACCOUNT_PAGE } from 'constants/pageIdentifiers';
import { removeAuthToken } from 'utils/localStorageHelper';
import request from 'utils/request';

export const defaultRedirect = {
  pathname: CREATE_ACCOUNT_PAGE,
  state: {},
};

export const defaultInvalidRedirect = {
  pathname: CREATE_ACCOUNT_PAGE,
  state: { errorMessage: 'Invalid redirect identifier' },
};

/**
 * Takes a url_identifier and makes a call to v3/url_redirects and formats the response for a react-router-dom redirect
 * @param identifier
 * @return {*}
 */
export const getRedirectRoute = (identifier) => {
  if (!identifier) {
    return Promise.resolve(defaultRedirect);
  }
  return getRedirectRouteData(identifier)
    .then((data = {}) => ({
      pathname: data?.redirect_path,
      search: data.redirect_url.split('?')[1],
      state: data,
      ...data,
    }))
    .catch((e) => {
      console.error(e);
      return defaultInvalidRedirect;
    });
};

/**
 * Calls API
 * @param identifier
 * @return {*}
 */
export const getRedirectRouteData = (identifier) => {
  return request(
    `v3/url_redirects?url_identifier=${identifier}`,
    {
      method: 'GET',
      headers: new Headers(),
    },
    { parseResponse: false },
  )
    .then((res) => {
      //success
      if (res.status === 200) {
        return res.json();
      }

      // invalid auth token
      if (res.status === 401) {
        removeAuthToken();
        return getRedirectRouteData(identifier);
      }
      throw `Could not get redirect for identifier ${identifier}`;
    })
    .then((data) => {
      if (!data?.length) {
        throw `No redirect returned for identifier ${identifier}`;
      }
      return data[0];
    })
    .catch((e) => {
      window.Rollbar?.error(e);
      return Promise.reject(e);
    });
};
