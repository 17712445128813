/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// import './wdyr'; // <--- first import
import { ErrorView } from 'components/ErrorBoundary/ErrorView';
import { getRedirectRoute } from 'components/UrlRedirect/data';
import Config from 'config';
// Import root app
import App from 'containers/App';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
import LDUpdater from 'containers/LaunchDarklyUpdater';
// Import i18n messages
import { translationMessages } from 'i18n';
// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { getDRedirect } from 'services/DRedirect';
import { TrackingWrapper } from 'services/EventsService/TrackingWrapper';
import FlaggedServices from 'services/FlaggedServices';
import { initialisationService } from 'services/InitialisationService';
import { initialiseFeatureFlags, SetLDProvider } from 'services/LaunchDarkly';
import {
  setCampaignId,
  setDRedirect,
  setRegionId,
} from 'utils/localStorageHelper';
import configureStore from './configureStore';
// third parties etc
import './onload/onload';

const MOUNT_NODE = document.getElementById('app');
let root = createRoot(MOUNT_NODE);
let store;

const doRender = async (messages, campaignData) => {
  const LDProvider = await initialiseFeatureFlags(campaignData);
  if (!store) {
    throw new Error('No store initialised!');
  }
  if (campaignData.initialisationError) {
    return root.render(
      <ErrorView
        title="Error initialising app"
        message={`Please contact support at ${Config.supportEmail}`}
        backButton={false}
      />,
    );
  }
  root.render(
    <LDProvider>
      <SetLDProvider />
      <Provider store={store}>
        <FlaggedServices />
        <LanguageProvider
          companyCopy={campaignData.companyCopy}
          messages={messages}
        >
          <LDUpdater data={campaignData}>
            <TrackingWrapper>
              <App />
            </TrackingWrapper>
          </LDUpdater>
        </LanguageProvider>
      </Provider>
    </LDProvider>,
  );
};

let campaignData = {};

const initialise = () => {
  // sort company copies, etc
  return initialisationService()
    .then((data) => {
      campaignData = data;
      store = configureStore(data);
      return doRender(translationMessages, data);
    })
    .catch((e) => {
      console.error(e);
      window.analytics?.track('APP LOAD FAILURE', { error: e });
    });
};

const render = (messages) => {
  const d = getDRedirect();
  if (d) {
    setDRedirect(d);
    return getRedirectRoute(d)
      .then((data) => {
        if (data.campaign_identifier) {
          setCampaignId(data.campaign_identifier, true);
        }
        if (data.region_id) {
          setRegionId(data.region_id);
        }
        window.location.href = `${window.location.origin}${data.pathname}${
          data.search ? `?${data.search}` : ''
        }`;
      })
      .catch(() => {
        initialise();
      });
  } else {
    initialise();
  }
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    root.unmount();
    root = createRoot(MOUNT_NODE);
    doRender(translationMessages, campaignData);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/en-IE.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
