import { useCallback, useEffect, useRef, useState } from 'react';
import { isFirefox, isSafari } from 'react-device-detect';
import { getSelectedDevices, objectEquals } from './helpers';

export const useMediaPermissions = () => {
  const [permissions, setPermissions] = useState({
    audio: null,
    video: null,
  });
  const [devices, setDevices] = useState({
    audioInput: [],
    audioOutput: [],
    video: [],
    ...getSelectedDevices(),
  });

  const updateDevices = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices({
      audioInput: devices.filter(
        (device) => device.kind === 'audioinput' && device.deviceId,
      ),
      audioOutput: devices.filter(
        (device) => device.kind === 'audiooutput' && device.deviceId,
      ),
      video: devices.filter(
        (device) => device.kind === 'videoinput' && device.deviceId,
      ),

      ...getSelectedDevices(),
    });
  }, []);

  const askForPermissionsChrome = useCallback(async () => {
    // if we have already asked for permissions, don't ask again
    if (
      permissions.audio?.state === 'prompt' ||
      permissions.video?.state === 'prompt' ||
      !permissions.audio ||
      !permissions.video
    ) {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setPermissions({
        audio: { state: 'granted' },
        video: { state: 'granted' },
      });
    }
  }, [permissions]);

  const askForPermissions = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      // granted if we get here
      setPermissions({
        audio: { state: 'granted' },
        video: { state: 'granted' },
      });
      await updateDevices();
    } catch (e) {
      // if not granted we are thrown an error
      setPermissions({
        audio: { state: 'denied' },
        video: { state: 'denied' },
      });
    }
  }, [updateDevices]);

  useEffect(() => {
    if (permissions.video) {
      return;
    }
    // don't allow permissions queries on firefox or safari
    if (isFirefox || isSafari) {
      askForPermissions();
    } else {
      // have listeners on chrome
      askForPermissionsChrome();
    }
  }, [askForPermissionsChrome, askForPermissions, permissions]);

  useEffect(() => {
    let audioPermission;
    let videoPermission;
    let onPermissionsChangeListener;

    // no listeners on firefox or safari
    if (isFirefox || isSafari) {
      return;
    }

    const getPermissions = async () => {
      try {
        audioPermission = await navigator.permissions.query({
          name: 'microphone',
        });
        videoPermission = await navigator.permissions.query({
          name: 'camera',
        });

        onPermissionsChangeListener = async () => {
          setPermissions({
            audio: { state: audioPermission.state },
            video: { state: videoPermission.state },
          });
          await updateDevices();
        };
        audioPermission.addEventListener('change', onPermissionsChangeListener);
        videoPermission.addEventListener('change', onPermissionsChangeListener);

        setPermissions({
          audio: { state: audioPermission.state },
          video: { state: videoPermission.state },
        });
        await updateDevices();
      } catch (e) {}
    };

    getPermissions();

    return () => {
      audioPermission?.removeEventListener(
        'change',
        onPermissionsChangeListener,
      );
      videoPermission?.removeEventListener(
        'change',
        onPermissionsChangeListener,
      );
    };
  }, [updateDevices]);

  const currentStorageDevices = useRef(getSelectedDevices());
  const handleStorageChange = useCallback(
    (e) => {
      if (!objectEquals(getSelectedDevices(), currentStorageDevices.current)) {
        updateDevices();
      }
    },
    [updateDevices],
  );

  useEffect(() => {
    // watch localStorage for changes to selected devices - not available on all browsers
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  return {
    permissions,
    devices,
    askForPermissions: askForPermissionsChrome,
  };
};
