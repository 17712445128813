import {
  getCompanyCopyFailure,
  getCompanyCopySuccess,
  updateCampaignIdentifierSuccess,
  updateCompanyCopyCampaignMap,
} from 'containers/LaunchDarklyUpdater/actions';
import {
  GET_COMPANY_COPY,
  UPDATE_CAMPAIGN_ID,
} from 'containers/LaunchDarklyUpdater/constants';
import { selectCampaignCopyMap } from 'containers/LaunchDarklyUpdater/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updateConfigForCopy } from 'services/InitialisationService/companyCopy';
import {
  getCompanyCopy,
  removeCampaignId,
  setCampaignId,
  setCampaignOrCompanyIdCopyMap,
  setCompanyCopy,
  setCompanyId,
  setThemeName,
} from 'utils/localStorageHelper';
import request from 'utils/request';
import { DEFAULT_CAMPAIGN_IDENTIFIER } from './constants';

function isCacheValid(expiryDate) {
  return expiryDate && expiryDate > Date.now();
}

function* getCompanyCopyForCampaignIdentifier(campaignId) {
  const campaignCopyMap = yield select(selectCampaignCopyMap());
  const { copy, exp } = campaignCopyMap[campaignId] || {};
  if (copy?.copy && isCacheValid(exp)) {
    updateConfigForCopy(copy.copy);
    yield put(getCompanyCopySuccess(copy.copy, campaignId));
    return copy;
  }
  return yield fetchCompanyCopy({ payload: { campaignId } });
}

export function* fetchCompanyCopy({
  payload: { campaignId, campaignIsUrl, companyId },
}) {
  if (!campaignId && !companyId) {
    yield put(
      updateCompanyCopyCampaignMap({
        copy: null,
        campaignId: DEFAULT_CAMPAIGN_IDENTIFIER,
        campaignIsUrl,
      }),
    );
    return yield put(getCompanyCopySuccess(null, DEFAULT_CAMPAIGN_IDENTIFIER));
  }

  const url = `v3/company_copies${
    campaignId
      ? `?campaign_identifier=${decodeURIComponent(campaignId)}`
      : `?company_id=${companyId}`
  }`;
  const headers = new Headers();
  const options = {
    method: 'GET',
    headers,
  };
  try {
    const response = yield call(request, url, options);
    const currentCopy = yield getCompanyCopy();
    const copy = response[0];
    if (!copy) {
      window.Rollbar?.error('No company copy for campaign identifier', {
        campaignId,
      });
      return yield fetchCompanyCopy({
        campaignId: DEFAULT_CAMPAIGN_IDENTIFIER,
      });
    }
    if (currentCopy?.id !== copy?.id) {
      removeCampaignId();
    }
    setCompanyCopy(copy);
    setCampaignOrCompanyIdCopyMap(campaignId || companyId, copy);
    setCampaignOrCompanyIdCopyMap(copy?.theme_name, copy);
    if (companyId) {
      setCompanyId(companyId);
    }
    setThemeName(copy?.theme_name);
    updateConfigForCopy(copy);
    yield put(
      updateCompanyCopyCampaignMap({
        copy,
        campaignId,
        companyId,
        campaignIsUrl,
      }),
    );
    yield put(getCompanyCopySuccess(copy, campaignId, companyId));

    return response[0];
  } catch (e) {
    yield put(getCompanyCopyFailure(e, campaignId));
  }
}

function* updateCampaignId({
  payload: { campaignId = DEFAULT_CAMPAIGN_IDENTIFIER, campaignIsUrl },
}) {
  yield getCompanyCopyForCampaignIdentifier(campaignId);
  setCampaignId(campaignId, campaignIsUrl);
  return yield put(updateCampaignIdentifierSuccess(campaignId, campaignIsUrl));
}

export default function* launchDarklyUpdaterSaga() {
  yield takeLatest(GET_COMPANY_COPY, fetchCompanyCopy);
  yield takeLatest(UPDATE_CAMPAIGN_ID, updateCampaignId);
}
