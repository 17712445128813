/*
 * QuestionCard Messages
 *
 * This contains all the text for the QuestionCard component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.QuestionCard';

export default defineMessages({
  invalidPhoneNumber: {
    id: `${scope}.invalidPhoneNumber`,
    defaultMessage: 'Please enter a valid cellphone number',
  },
});
