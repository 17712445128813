import uid from 'uniqid';

export const CACHE_TIME = 1000 * 60 * 60 * 24;
export const CACHE_TIME_SHORT = 1000 * 60 * 5; // 5 mins

export const PATIENT_CASES_CACHE = '@@CACHE/PATIENT_CASES';
export const CURRENT_STATE = '@@CACHE/CURRENT_STATE';
export const REPORTS_CACHE = '@@CACHE/REPORTS';
export const EXTERNAL_USER_ID = '@@CACHE/EXTERNAL_USER_ID';

const sessionKey = 's-uuid';
const redirectKey = '@@TOOTHPIC/auth-redirect';

export const saveToSessionStorage = (storageKey, state, isFetch) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(storageKey, serializedState);
    isFetch && sessionStorage.setItem(`${storageKey}-t`, '' + Date.now());
  } catch (e) {
    console.error(e);
  }
};

export const addObjectByIdToSessionStorage = (storageKey, obj, options) => {
  const _opts = getOptions(options);

  try {
    const state = loadFromSessionStorage(storageKey) || {};
    const nState = {
      ...state,
      [obj.id]: { ...obj, _expires_at: Date.now() + _opts.cacheTime },
    };
    saveToSessionStorage(storageKey, nState);
    return nState;
  } catch (e) {
    console.error(e);
    return { ...obj };
  }
};

const getOptions = (o = {}) => {
  return Object.assign({ cacheTime: CACHE_TIME, noExpiry: false, id: 'id' }, o);
};

export const addObjectArrayToSessionStorage = (
  storageKey,
  arr = [],
  options,
) => {
  const _opts = getOptions(options);
  try {
    const state = loadFromSessionStorage(storageKey) || {};
    const nState = arr.reduce(
      (memo, a) =>
        Object.assign(memo, {
          [a[_opts.id]]: {
            ...a,
            _expires_at: _opts.noExpiry ? 0 : Date.now() + _opts.cacheTime,
          },
        }),
      state,
    );
    saveToSessionStorage(storageKey, nState, true);
    return nState;
  } catch (e) {
    console.error(e);
    return arr;
  }
};

export const loadFromSessionStorage = (storageKey, isFetch) => {
  try {
    const serializedState = sessionStorage.getItem(storageKey);
    if (serializedState) {
      return JSON.parse(serializedState) || {};
    }
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const loadArrayFromSessionStorage = (storageKey) => {
  try {
    const serializedState = sessionStorage.getItem(storageKey);
    const serializedStateTime = sessionStorage.getItem(`${storageKey}-t`);
    if (
      serializedState &&
      parseInt(serializedStateTime) - Date.now() < CACHE_TIME
    ) {
      return JSON.parse(serializedState) || {};
    }
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const loadFromSessionStorageById = (storageKey, id) => {
  try {
    const serializedState = sessionStorage.getItem(storageKey);
    if (serializedState) {
      const state = JSON.parse(serializedState) || {};
      if (
        state[id] &&
        (state[id]._expires_at === 0 || state[id]._expires_at > Date.now())
      ) {
        return state[id];
      }
      return null;
    }
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const clearFromSessionStorage = (storageKey, clearExpiryTime = true) => {
  try {
    sessionStorage.removeItem(storageKey);
    if (clearExpiryTime) {
      sessionStorage.removeItem(`${storageKey}-t`);
    }
  } catch (e) {
    console.error(e);
  }
};

export function sessionUUID() {
  let uuid = window.sessionStorage.getItem(sessionKey);
  if (!uuid) {
    uuid = uid();
    window.sessionStorage.setItem(sessionKey, uuid);
  }
  return uuid;
}

export function getAuthenticatedRedirect() {
  return window.sessionStorage.getItem(redirectKey);
}

export function setAuthenticatedRedirect(value) {
  return window.sessionStorage.setItem(redirectKey, value);
}

export function clearAuthenticatedRedirect() {
  return window.sessionStorage.removeItem(redirectKey);
}

export function clearUserSessionStorageData() {
  clearFromSessionStorage(sessionKey);
  clearFromSessionStorage(PATIENT_CASES_CACHE);
  clearFromSessionStorage(CURRENT_STATE);
  clearFromSessionStorage(REPORTS_CACHE);
}
