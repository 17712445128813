import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the languageToggle state domain
 */
const selectLanguage = (state) => state?.language || initialState();

/**
 * Select the language locale
 */

const makeSelectLocaleData = () =>
  createSelector(selectLanguage, (languageState) => languageState);

const makeSelectLocale = () =>
  createSelector(selectLanguage, (languageState) => languageState.locale);

export const selectLocale = () =>
  createSelector(makeSelectLocale(), (locale) => ({
    locale,
  }));

export const selectCountry = () =>
  createSelector(selectLanguage, (languageState) => languageState.country);

export const selectCurrency = () =>
  createSelector(selectLanguage, (languageState) => languageState.currency);

export { selectLanguage, makeSelectLocaleData, makeSelectLocale };
