/*
 *
 * GetCare reducer
 *
 */

import {
  FETCH_HOME_DATA,
  FETCH_HOME_DATA_FAILURE,
  FETCH_HOME_DATA_SUCCESS,
} from 'containers/Home/constants';

export const initialState = () => ({
  loading: false,
  fetched: false,
  data: null,
  patientCases: [],
  patientCaseTemplates: [],
  videoCalls: [],
  homeData: [],
});

function homeReducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_HOME_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HOME_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_HOME_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        fetched: true,
        patientCases: action.payload.patientCases,
        patientCaseTemplates: action.payload.patientCaseTemplates,
        videoCalls: action.payload.videoCalls,
        homeData: action.payload.patientCases.map((pc) => ({
          ...pc,
          patientCaseTemplate: action.payload.patientCaseTemplates.find(
            (pct) => pct.id === pc.patient_case_template_id,
          ),
          videoCall: action.payload.videoCalls.find(
            (vc) => vc.patient_case_id === pc.id,
          ),
        })),
      };
    default:
      return state;
  }
}

export default homeReducer;
