/*! modernizr 3.11.7 (Custom Build) | MIT *
 * https://modernizr.com/download/?-atobbtoa-indexeddb-indexeddbblob-webp-webworkers-setclasses !*/
!(function (e, n, t, r) {
  function o(e, n) {
    return typeof e === n;
  }

  function i(e) {
    var n = C.className,
      t = Modernizr._config.classPrefix || '';
    if ((_ && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(r, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses &&
      (e.length > 0 && (n += ' ' + t + e.join(' ' + t)),
      _ ? (C.className.baseVal = n) : (C.className = n));
  }

  function a(e, n) {
    return !!~('' + e).indexOf(n);
  }

  function s() {
    return 'function' != typeof t.createElement
      ? t.createElement(arguments[0])
      : _
      ? t.createElementNS.call(t, 'http://www.w3.org/2000/svg', arguments[0])
      : t.createElement.apply(t, arguments);
  }

  function l() {
    var e = t.body;
    return e || ((e = s(_ ? 'svg' : 'body')), (e.fake = !0)), e;
  }

  function u(e, n, r, o) {
    var i,
      a,
      u,
      d,
      f = 'modernizr',
      A = s('div'),
      c = l();
    if (parseInt(r, 10))
      for (; r--; )
        (u = s('div')), (u.id = o ? o[r] : f + (r + 1)), A.appendChild(u);
    return (
      (i = s('style')),
      (i.type = 'text/css'),
      (i.id = 's' + f),
      (c.fake ? c : A).appendChild(i),
      c.appendChild(A),
      i.styleSheet
        ? (i.styleSheet.cssText = e)
        : i.appendChild(t.createTextNode(e)),
      (A.id = f),
      c.fake &&
        ((c.style.background = ''),
        (c.style.overflow = 'hidden'),
        (d = C.style.overflow),
        (C.style.overflow = 'hidden'),
        C.appendChild(c)),
      (a = n(A, e)),
      c.fake && c.parentNode
        ? (c.parentNode.removeChild(c), (C.style.overflow = d), C.offsetHeight)
        : A.parentNode.removeChild(A),
      !!a
    );
  }

  function d(e) {
    return e
      .replace(/([A-Z])/g, function (e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }

  function f(e, t, r) {
    var o;
    if ('getComputedStyle' in n) {
      o = getComputedStyle.call(n, e, t);
      var i = n.console;
      if (null !== o) r && (o = o.getPropertyValue(r));
      else if (i) {
        var a = i.error ? 'error' : 'log';
        i[a].call(
          i,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate',
        );
      }
    } else o = !t && e.currentStyle && e.currentStyle[r];
    return o;
  }

  function A(e, t) {
    var o = e.length;
    if ('CSS' in n && 'supports' in n.CSS) {
      for (; o--; ) if (n.CSS.supports(d(e[o]), t)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in n) {
      for (var i = []; o--; ) i.push('(' + d(e[o]) + ':' + t + ')');
      return (
        (i = i.join(' or ')),
        u(
          '@supports (' + i + ') { #modernizr { position: absolute; } }',
          function (e) {
            return 'absolute' === f(e, null, 'position');
          },
        )
      );
    }
    return r;
  }

  function c(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }

  function p(e, n, t, i) {
    function l() {
      d && (delete U.style, delete U.modElem);
    }

    if (((i = !o(i, 'undefined') && i), !o(t, 'undefined'))) {
      var u = A(e, t);
      if (!o(u, 'undefined')) return u;
    }
    for (
      var d, f, p, h, v, b = ['modernizr', 'tspan', 'samp'];
      !U.style && b.length;

    )
      (d = !0), (U.modElem = s(b.shift())), (U.style = U.modElem.style);
    for (p = e.length, f = 0; f < p; f++)
      if (
        ((h = e[f]),
        (v = U.style[h]),
        a(h, '-') && (h = c(h)),
        U.style[h] !== r)
      ) {
        if (i || o(t, 'undefined')) return l(), 'pfx' !== n || h;
        try {
          U.style[h] = t;
        } catch (e) {}
        if (U.style[h] !== v) return l(), 'pfx' !== n || h;
      }
    return l(), !1;
  }

  function h(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }

  function v(e, n, t) {
    var r;
    for (var i in e)
      if (e[i] in n)
        return !1 === t
          ? e[i]
          : ((r = n[e[i]]), o(r, 'function') ? h(r, t || n) : r);
    return !1;
  }

  function b(e, n, t, r, i) {
    var a = e.charAt(0).toUpperCase() + e.slice(1),
      s = (e + ' ' + B.join(a + ' ') + a).split(' ');
    return o(n, 'string') || o(n, 'undefined')
      ? p(s, n, r, i)
      : ((s = (e + ' ' + E.join(a + ' ') + a).split(' ')), v(s, n, t));
  }

  function m(e, n) {
    if ('object' == typeof e) for (var t in e) R(e, t) && m(t, e[t]);
    else {
      e = e.toLowerCase();
      var r = e.split('.'),
        o = Modernizr[r[0]];
      if ((2 === r.length && (o = o[r[1]]), void 0 !== o)) return Modernizr;
      (n = 'function' == typeof n ? n() : n),
        1 === r.length
          ? (Modernizr[r[0]] = n)
          : (!Modernizr[r[0]] ||
              Modernizr[r[0]] instanceof Boolean ||
              (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])),
            (Modernizr[r[0]][r[1]] = n)),
        i([(n && !1 !== n ? '' : 'no-') + r.join('-')]),
        Modernizr._trigger(e, n);
    }
    return Modernizr;
  }

  function g(e, n) {
    var t = e.deleteDatabase(n);
    (t.onsuccess = function () {
      m('indexeddb.deletedatabase', !0);
    }),
      (t.onerror = function () {
        m('indexeddb.deletedatabase', !1);
      });
  }

  var y = [],
    w = {
      _version: '3.11.7',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        y.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        y.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = w), (Modernizr = new Modernizr());
  var x = [],
    C = t.documentElement,
    _ = 'svg' === C.nodeName.toLowerCase(),
    S = 'Moz O ms Webkit',
    B = w._config.usePrefixes ? S.split(' ') : [];
  w._cssomPrefixes = B;
  var Q = { elem: s('modernizr') };
  Modernizr._q.push(function () {
    delete Q.elem;
  });
  var U = { style: Q.elem.style };
  Modernizr._q.unshift(function () {
    delete U.style;
  });
  var E = w._config.usePrefixes ? S.toLowerCase().split(' ') : [];
  (w._domPrefixes = E), (w.testAllProps = b);
  var T = function (e) {
    var t,
      o = prefixes.length,
      i = n.CSSRule;
    if (void 0 === i) return r;
    if (!e) return !1;
    if (
      ((e = e.replace(/^@/, '')),
      (t = e.replace(/-/g, '_').toUpperCase() + '_RULE') in i)
    )
      return '@' + e;
    for (var a = 0; a < o; a++) {
      var s = prefixes[a];
      if (s.toUpperCase() + '_' + t in i)
        return '@-' + s.toLowerCase() + '-' + e;
    }
    return !1;
  };
  w.atRule = T;
  var R,
    k = (w.prefixed = function (e, n, t) {
      return 0 === e.indexOf('@')
        ? T(e)
        : (-1 !== e.indexOf('-') && (e = c(e)), n ? b(e, n, t) : b(e, 'pfx'));
    });
  !(function () {
    var e = {}.hasOwnProperty;
    R =
      o(e, 'undefined') || o(e.call, 'undefined')
        ? function (e, n) {
            return n in e && o(e.constructor.prototype[n], 'undefined');
          }
        : function (n, t) {
            return e.call(n, t);
          };
  })(),
    (w._l = {}),
    (w.on = function (e, n) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(n),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function () {
            Modernizr._trigger(e, Modernizr[e]);
          }, 0);
    }),
    (w._trigger = function (e, n) {
      if (this._l[e]) {
        var t = this._l[e];
        setTimeout(function () {
          var e;
          for (e = 0; e < t.length; e++) (0, t[e])(n);
        }, 0),
          delete this._l[e];
      }
    }),
    Modernizr._q.push(function () {
      w.addTest = m;
    }),
    Modernizr.addAsyncTest(function () {
      var e;
      try {
        e = k('indexedDB', n);
      } catch (e) {}
      if (e) {
        var t,
          r = 'modernizr-' + Math.random();
        try {
          t = e.open(r);
        } catch (e) {
          return void m('indexeddb', !1);
        }
        (t.onerror = function (n) {
          !t.error ||
          ('InvalidStateError' !== t.error.name &&
            'UnknownError' !== t.error.name)
            ? (m('indexeddb', !0), g(e, r))
            : (m('indexeddb', !1), n.preventDefault());
        }),
          (t.onsuccess = function () {
            m('indexeddb', !0), g(e, r);
          });
      } else m('indexeddb', !1);
    }),
    Modernizr.addAsyncTest(function () {
      var e,
        t,
        r,
        o,
        i = 'detect-blob-support',
        a = !1;
      try {
        e = k('indexedDB', n);
      } catch (e) {}
      if (!Modernizr.indexeddb || !Modernizr.indexeddb.deletedatabase)
        return !1;
      try {
        e.deleteDatabase(i).onsuccess = function () {
          (t = e.open(i, 1)),
            (t.onupgradeneeded = function () {
              t.result.createObjectStore('store');
            }),
            (t.onsuccess = function () {
              r = t.result;
              try {
                (o = r
                  .transaction('store', 'readwrite')
                  .objectStore('store')
                  .put(new Blob(), 'key')),
                  (o.onsuccess = function () {
                    a = !0;
                  }),
                  (o.onerror = function () {
                    a = !1;
                  });
              } catch (e) {
                a = !1;
              } finally {
                m('indexeddbblob', a), r.close(), e.deleteDatabase(i);
              }
            });
        };
      } catch (e) {
        m('indexeddbblob', !1);
      }
    }),
    Modernizr.addAsyncTest(function () {
      function e(e, n, t) {
        function r(n) {
          var r = !(!n || 'load' !== n.type) && 1 === o.width;
          m(e, 'webp' === e && r ? new Boolean(r) : r), t && t(n);
        }

        var o = new Image();
        (o.onerror = r), (o.onload = r), (o.src = n);
      }

      var n = [
          {
            uri: 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
            name: 'webp',
          },
          {
            uri: 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
            name: 'webp.alpha',
          },
          {
            uri: 'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
            name: 'webp.animation',
          },
          {
            uri: 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
            name: 'webp.lossless',
          },
        ],
        t = n.shift();
      e(t.name, t.uri, function (t) {
        if (t && 'load' === t.type)
          for (var r = 0; r < n.length; r++) e(n[r].name, n[r].uri);
      });
    }),
    Modernizr.addTest('atobbtoa', 'atob' in n && 'btoa' in n, {
      aliases: ['atob-btoa'],
    }),
    Modernizr.addTest('webworkers', 'Worker' in n),
    (function () {
      var e, n, t, r, i, a, s;
      for (var l in y)
        if (y.hasOwnProperty(l)) {
          if (
            ((e = []),
            (n = y[l]),
            n.name &&
              (e.push(n.name.toLowerCase()),
              n.options && n.options.aliases && n.options.aliases.length))
          )
            for (t = 0; t < n.options.aliases.length; t++)
              e.push(n.options.aliases[t].toLowerCase());
          for (
            r = o(n.fn, 'function') ? n.fn() : n.fn, i = 0;
            i < e.length;
            i++
          )
            (a = e[i]),
              (s = a.split('.')),
              1 === s.length
                ? (Modernizr[s[0]] = r)
                : ((Modernizr[s[0]] &&
                    (!Modernizr[s[0]] || Modernizr[s[0]] instanceof Boolean)) ||
                    (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])),
                  (Modernizr[s[0]][s[1]] = r)),
              x.push((r ? '' : 'no-') + s.join('-'));
        }
    })(),
    i(x),
    delete w.addTest,
    delete w.addAsyncTest;
  for (var D = 0; D < Modernizr._q.length; D++) Modernizr._q[D]();
  e.Modernizr = Modernizr;
})(window, window, document);
