import { Button } from '@oraleye/frontend-modules-components';
import Config from 'config';
import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-top: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  max-height: 80px;
  margin-right: 12px;
`;

export const Divider = styled.div`
  height: 1px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px var(--neutral02);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledIcon = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
`;

export const CardTitle = styled.h4`
  color: var(--neutral08);
  font-weight: ${() => (Config.isQuip || Config.isDelta ? 'bold' : 'normal')};
  margin: 16px 92px 12px 22px;
  padding-top: 16px;
`;

export const CardContent = styled.div`
  margin: 40px 40px 0 22px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--neutral08);
`;

export const CardButton = styled(Button)`
  border-radius: 24px;
  margin: 24px;
  height: 40px;
  padding: 0 16px;
  text-align: center;
  width: auto;
`;
