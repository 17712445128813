import { DateInput } from '@oraleye/frontend-modules-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { eventSend } from 'services/EventsService';
import events from 'services/EventsService/events.json';

const FormDateInput = ({ name, ...props }) => {
  const { form } = useForm();

  const errorShownEvent = useCallback(
    (data) => {
      eventSend({
        field_name: name,
        error_message_shown: data,
        user_klass: 'member',
        event_name: events.formValidationError.event_name,
        form_name: form?.name,
      });
    },
    [name, form?.name],
  );

  return <DateInput name={name} onErrorShown={errorShownEvent} {...props} />;
};

export default FormDateInput;

FormDateInput.propTypes = {
  form: PropTypes.any,
  name: PropTypes.any,
};
