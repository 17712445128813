import { library } from '@fortawesome/fontawesome-svg-core';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons/faHandHoldingMedical';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faCog } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft);
library.add(faBars);
library.add(faCamera);
library.add(faCheck);
library.add(faChevronRight);
library.add(faHandHoldingMedical);
library.add(faPlusSquare);
library.add(faQuestionCircle);
library.add(faRedo);
library.add(faSignOutAlt);
library.add(faStar);
library.add(faTimes);
library.add(faUserAlt);
library.add(faUserCircle);
library.add(faVideo);
library.add(faCog);
