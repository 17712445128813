/*
 *
 * Login actions
 *
 */

import {
  CLEAR_ERRORS,
  DEFAULT_ACTION,
  ENABLE_MFA,
  FETCH_COMPANY_CONFIG_SUCCESS,
  FETCH_MEMBER,
  FETCH_MEMBER_FAILURE,
  FETCH_MEMBER_SUCCESS,
  FIELD_UPDATE,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_SIGNUP_REQUEST,
  LOAD_PROVIDER_OPTIONS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  LOGIN_REQUEST_SUCCESS,
  MFA_LOGIN,
  PATCH_MEMBER_MEDICAL_INFORMATION,
  PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE,
  PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS,
  RESET_REDUCER,
  SHOW_NOTIFICATION,
  SIGN_OUT_USER,
  SIGNUP_REQUEST,
  USER_LOGIN,
} from 'containers/Auth/AuthRouter/constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function submitLogin(payload) {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
}

export function submitMFA(payload) {
  return {
    type: MFA_LOGIN,
    payload,
  };
}

export function onChange(name, value) {
  return {
    type: FIELD_UPDATE,
    payload: { name, value },
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}

export function userLogin(payload, pathName) {
  return {
    type: USER_LOGIN,
    payload,
    meta: { auth: true, pathName },
  };
}

export function enableMFA() {
  return {
    type: ENABLE_MFA,
  };
}

/**
 * Action to show a notification
 * @param {String} message - Message to display
 * @param {String} [type='info] - ['info', 'warning', 'error']
 * @param {{}} notificationOptions Additional Parameters
 * @returns {{type: String, payload: Object}}
 */
export function showNotification(
  message,
  type = 'info',
  notificationOptions = {},
) {
  return {
    type: SHOW_NOTIFICATION,
    payload: Object.assign({}, notificationOptions, {
      type,
      message,
    }),
  };
}

/*
 *
 * SignOut actions
 *
 */

export function signOutUser() {
  return { type: SIGN_OUT_USER };
}

export function reset() {
  return { type: RESET_REDUCER };
}

/*
 *
 * Sign UP actions
 *
 */

export function submitSignUp(payload) {
  return {
    type: SIGNUP_REQUEST,
    payload,
  };
}

export function loadProviderOptionsAction() {
  return {
    type: LOAD_PROVIDER_OPTIONS,
  };
}

export function fetchMemberAction() {
  return {
    type: FETCH_MEMBER,
  };
}

export function fetchMemberSuccessAction(member) {
  return {
    type: FETCH_MEMBER_SUCCESS,
    payload: member,
  };
}

export function fetchMemberFailureAction(error, payload) {
  return {
    type: FETCH_MEMBER_FAILURE,
    payload,
    error,
  };
}

export function loginRequestSuccessAction(payload) {
  return {
    type: LOGIN_REQUEST_SUCCESS,
    payload,
  };
}

export function loginRequestFailureAction(error, payload) {
  return {
    type: LOGIN_REQUEST_FAILURE,
    error,
    payload,
  };
}

export function fetchCompanyConfigSuccessAction(companyConfig) {
  return {
    type: FETCH_COMPANY_CONFIG_SUCCESS,
    payload: companyConfig,
  };
}

export function googleLogin(payload) {
  return {
    type: GOOGLE_LOGIN_REQUEST,
    payload,
  };
}

export function googleSignUp(payload) {
  return {
    type: GOOGLE_SIGNUP_REQUEST,
    payload,
  };
}

export const patchMemberMedicalInformation = (payload) => ({
  type: PATCH_MEMBER_MEDICAL_INFORMATION,
  payload,
});

export const patchMemberMedicalInformationSuccess = (payload) => ({
  type: PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS,
  payload,
});

export const patchMemberMedicalInformationFailure = (error) => ({
  type: PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE,
  error,
});
