import { Spinner } from '@oraleye/frontend-modules-core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  margin: auto auto;
  overflow: visible;
  align-self: center;
  justify-self: center;
  text-align: ${({ align }) => align};

  img {
    display: block;
  }
`;

function Loader({ size = 'md', align = 'center', ...props }) {
  return (
    <LoaderContainer align={align} {...props}>
      <Spinner size={size} color="primary02" />
    </LoaderContainer>
  );
}
Loader.propTypes = {
  size: PropTypes.string,
  align: PropTypes.string,
};
export default Loader;
