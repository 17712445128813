/**
 * @returns {{
 * isProfessional: boolean,
 * isBCBC: boolean,
 * isVideo: boolean,
 * videoCall: boolean,
 * caseSubmission: boolean,
 * caseCreation: boolean,
 * reports: boolean
 * }}
 */
import isDevEnv from 'utils/isDevEnv';
import { baseFeatures } from './features';

/**
 *
 * @return {{
  videoCall: boolean,
  caseSubmission: boolean,
  caseCreation: boolean,
  reports: boolean,
  accountSetup: boolean,
  logging: boolean
}}
 */

export default {
  supportEmail: 'support@toothpic.com',
  savePhoto: isDevEnv,
  isProfessional: window.env.isProfessional || false,
  isBCBS: window.env.isBCBS || false,
  isDelta: window.env.isDelta || false,
  isVideo: window.env.isVideo || false,
  isToothpic: !(
    window.env.isDelta ||
    window.env.isBCBS ||
    window.env.isProfessional ||
    window.env.isVideo
  ),
  ...baseFeatures,
};
