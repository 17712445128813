import ContentWrapper from 'components/ContentWrapper';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import messages from './messages';

export const FOOTER_HEIGHT = 84;
export const FOOTER_HEIGHT_LG = 76;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid var(--g-01-10);
  width: 100%;
  height: ${FOOTER_HEIGHT}px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 8px;
    text-align: left;
  }

  p {
    margin: 0;
    color: var(--neutral07);
    font-weight: 300;
    fontsize: 0.75rem;
  }
`;

function Footer() {
  return (
    <Wrapper id="footer">
      <ContentWrapper>
        <section>
          <p>
            <FormattedMessage
              {...messages.licenseMessage}
              values={{ year: new Date().getFullYear() }}
            />
          </p>
          <p>{process.env.VERSION && `Build v${process.env.VERSION}`}</p>
        </section>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Footer;
