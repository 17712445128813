export const loginUrl = 'v2/auth';
export const memberUrl = 'v3/members';
export const createAccountUrl = 'v3/members';
export const memberQuestionSetUrl = 'v3/member_question_sets';
export const accountQuestionSetUrl = 'v3/account_question_sets';
export const caseQuestionSetUrl = 'v3/case_question_sets';
export const reportFeedbackQuestionSet = 'v3/report_feedback_question_sets';
export const questionCardsUrl = 'v3/question_cards';
export const googleCallbackUrl = 'auth/google_oauth2/callback';

export const unauthRoutes = [
  /v2\/auth$/,
  /v3\/auth$/,
  { POST: /v3\/members$/ },
  /v2\/passwords/,
  /v3\/company_copies/,
  /v3\/registration_company_options/,
  /v3\/page_visit_events/,
  /v3\/url_redirects/,
  /auth\/google_oauth2\/callback/,
  /internals\/log_forwarder/,
  /test-url/,
];
