import checkIndexedDB from './checkIndexedDB';
import { DBConfig } from './config';
import { PHOTO_STORE_NAME } from './constants';
import memoryDB from './memoryDB';

let indexedDbIsInitialised = false;
let rIDB;

const initIndexedDb = async () => {
  if (indexedDbIsInitialised) {
    return Promise.resolve(rIDB);
  }

  try {
    await checkIndexedDB();
    rIDB = await import('react-indexed-db');
    await rIDB.initDB(DBConfig);
    indexedDbIsInitialised = true;
    return rIDB;
  } catch (e) {
    window.Rollbar?.info(
      'Error initialising IndexedDB, just keeping in memory instead instead',
      e,
    );
    rIDB = memoryDB();
    indexedDbIsInitialised = true;
    return rIDB;
  }
};

const getDB = async (name) => {
  const db = await initIndexedDb();
  return db.useIndexedDB(name);
};

export const getAllPhotosFromDatabase = async () => {
  const { getAll } = await getDB(PHOTO_STORE_NAME);
  try {
    return await getAll();
  } catch (e) {
    window.Rollbar?.error(e);
    throw e;
  }
};

export const clearIndexedDB = async () => {
  const { clear } = await getDB(PHOTO_STORE_NAME);
  try {
    return await clear();
  } catch (e) {
    window.Rollbar?.error(e);
    throw e;
  }
};

export const deletePhotoFromDatabase = async (databaseId) => {
  const { deleteRecord } = await getDB(PHOTO_STORE_NAME);
  try {
    return await deleteRecord(databaseId);
  } catch (e) {
    window.Rollbar?.error(e);
    throw e;
  }
};

export const addPhotoToDatabase = async (photoDetails) => {
  const { add } = await getDB(PHOTO_STORE_NAME);
  try {
    return await add(photoDetails);
  } catch (e) {
    window.Rollbar?.error(e);
    throw e;
  }
};
