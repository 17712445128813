import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  &:visited,
  & {
    color: ${({ color }) => (color ? color : 'var(--primary01)')};
  }
`;

export const LinkWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();
  if (!to) {
    throw new Error('No `to` provided to LinkWithQuery: ' + JSON.stringify(to));
  }

  const _to = {
    pathname: `${typeof to === 'string' ? to : to.pathname}`,
    search,
    state: to.state,
  };
  if (!_to.pathname) {
    throw new Error(
      'No pathname provided to LinkWithQuery: ' + JSON.stringify(to),
    );
  }

  return (
    <StyledLink to={_to} {...props}>
      {children}
    </StyledLink>
  );
};

LinkWithQuery.propTypes = {
  children: PropTypes.any,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.any,
      state: PropTypes.any,
    }),
  ]),
};
