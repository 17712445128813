export const uuidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

export const checkType = (event, data, key, value, type) => {
  let e;
  if (type === 'uuid') {
    e = !uuidRegex.test(value);
  } else {
    e = typeof value !== type;
  }
  if (e) {
    eventError(
      event,
      data,
      `Invalid data type: ${key} (${type}) [${typeof value} received]`,
    );
  }
};

export const eventError = (event, data, message) => {
  throw `[Event error | ${
    event?.event_name
  }]:\n\n${message}\n\nReceived: ${JSON.stringify(
    data,
    null,
    2,
  )}\n\nEvent: ${JSON.stringify(event, null, 2)}`;
};

export const getRequiredParams = (event, data) => {
  if (!event.required) return;
  const requiredEntries = Object.entries(event.required);
  if (requiredEntries.length === 0) return;
  return requiredEntries.reduce((memo, [key, type]) => {
    let value = data[key];
    if (!undefined === value || null === value) {
      eventError(
        event,
        `Missing data key: ${key}\nReceived: ${JSON.stringify(data)}`,
      );
    }
    checkType(event, data, key, value, type);

    return Object.assign(memo, { [key]: value });
  }, {});
};

export const getOptionalParams = (event, data) => {
  if (!event.optional) return;
  const optionalEntries = Object.entries(event.optional);
  if (optionalEntries.length === 0) return;
  return optionalEntries.reduce((memo, [key, type]) => {
    let value = data[key];
    if (undefined === value || null === value) {
      return memo;
    }
    checkType(event, data, key, value, type);
    return Object.assign(memo, { [key]: value });
  }, {});
};

export const getOneOfParams = (event, data) => {
  if (!event.one_of) return;
  const oneOfParams = Object.entries(event.one_of);
  if (oneOfParams.length === 0) return;
  const eventData = oneOfParams.reduce((memo, [key, type]) => {
    let value = data[key];
    return undefined !== value ? Object.assign(memo, { [key]: value }) : memo;
  }, {});

  if (Object.keys(eventData).length > 1) {
    eventError(
      event,
      data,
      `Too many 'one of' values received: ${JSON.stringify(oneOfParams)}`,
    );
  }
  if (Object.keys(eventData).length === 0) {
    eventError(
      event,
      data,
      `Too many 'one of' values received: ${JSON.stringify(oneOfParams)}`,
    );
  }

  let _key = Object.keys(eventData)[0];
  let _value = eventData[_key];
  let _type = event.one_of[Object.keys(eventData)[0]];
  checkType(event, data, _key, _value, _type);
  return eventData;
};

export const transformEventData = (event, data) => {
  let eventData = {
    ...getRequiredParams(event, data),
    ...getOptionalParams(event, data),
    ...getOneOfParams(event, data),
  };

  return eventData;
};

export default (event, data) => {
  return transformEventData(event, data);
};
