/*
 * SmsLink Messages
 *
 * This contains all the text for the SmsLink component.
 */

import { defineMessages } from 'react-intl';

const scope = `app.containers.AuthRouter`;

export default defineMessages({
  unableToSignUp: {
    id: `${scope}.unableToSignUp`,
    defaultMessage:
      'We were unable to sign up {email}. Please contact {supportEmail}',
  },
  missing_email: {
    id: `${scope}.missing_email`,
    defaultMessage: "Oops, you're missing your email address",
  },
  missing_password: {
    id: `${scope}.missing_password`,
    defaultMessage: "Oops, you're missing your password",
  },
  missing_terms: {
    id: `${scope}.missing_terms`,
    defaultMessage: 'Oops, you must accepts the terms and conditions',
  },
  incorrectDetails: {
    id: `${scope}.incorrectDetails`,
    defaultMessage: 'Incorrect Details',
  },
});
