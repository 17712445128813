/*
 *
 * Consultation reducer
 *
 */

import {
  LOAD_CONSULTATION_DATA,
  LOAD_CONSULTATION_DATA_FAILURE,
  LOAD_CONSULTATION_DATA_SUCCESS,
  RESET_UPSERT_VIDEO_CALL,
  UPSERT_VIDEO_CALL,
  UPSERT_VIDEO_CALL_FAILURE,
  UPSERT_VIDEO_CALL_SUCCESS,
} from 'containers/Consultation/constants';

export const initialState = () => ({
  consultations: {},
  scheduledCalls: {
    data: {},
    loading: false,
    error: null,
  },
});

function consultationReducer(state = initialState(), { type, payload, error }) {
  switch (type) {
    case LOAD_CONSULTATION_DATA:
      return {
        ...state,
        consultations: {
          ...state.consultations,
          [payload.id]: {
            ...state.consultations[payload.id],
            state: {
              loading: true,
              fetched: false,
              error: null,
              patientCaseId: payload.id,
            },
          },
        },
      };
    case LOAD_CONSULTATION_DATA_SUCCESS:
      return {
        ...state,
        consultations: {
          ...state.consultations,
          [payload.data.patientCaseId]: {
            ...state.consultations[payload.data.patientCaseId],
            data: payload.data,
            // stages: payload.stages,
            state: {
              loading: false,
              fetched: true,
              error: null,
              patientCaseId: payload.data.patientCaseId,
            },
          },
        },
      };
    case LOAD_CONSULTATION_DATA_FAILURE:
      return {
        ...state,
        consultations: {
          ...state.consultations,
          [payload.patientCaseId]: {
            ...state.consultations[payload.patientCaseId],
            state: {
              loading: false,
              fetched: false,
              error: error,
              patientCaseId: payload.patientCaseId,
            },
          },
        },
      };
    case RESET_UPSERT_VIDEO_CALL:
      return { ...state, scheduledCalls: { data: {} } };
    case UPSERT_VIDEO_CALL:
      return {
        ...state,
        scheduledCalls: {
          ...state.scheduledCalls,
          loading: true,
          saving: true,
          saved: false,
        },
      };
    case UPSERT_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        scheduledCalls: {
          ...state.scheduledCalls,
          loading: false,
          saving: false,
          saved: true,
          data: {
            ...state.scheduledCalls.data,
            [payload.patient_case_id]: payload,
          },
        },
      };
    case UPSERT_VIDEO_CALL_FAILURE:
      return {
        ...state,
        scheduledCalls: {
          ...state.scheduledCalls,
          saving: false,
          saved: false,
          loading: false,
          error,
        },
      };
    default:
      return state;
  }
}

export default consultationReducer;
