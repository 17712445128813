import request from 'utils/request';

const DEFAULT_BACKOFF = 500;

/**
 * Delay in a promise chain
 * @param t
 * @returns {Promise<any>}
 */
const delay = (t = DEFAULT_BACKOFF) =>
  new Promise((resolve) => setTimeout(resolve, t));

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function retryRequest(
  url,
  options,
  condition,
  nRetries = 3,
  t = DEFAULT_BACKOFF,
) {
  return nRetries >= 0
    ? request(url, options)
        .then((data) => {
          if (!condition || !nRetries) {
            return Promise.reject(new Error(`Too many attempts: ${url}`));
          }
          if (data && !condition(data)) {
            return delay(t * 2).then(() => {
              return retryRequest(url, options, condition, nRetries - 1, t * 2);
            });
          }
          return data;
        })
        .catch((e) => {
          if (e?.message === `Too many attempts: ${url}` && nRetries === 1) {
            return e;
          }
          return delay(t * 2).then(() => {
            return retryRequest(url, options, condition, nRetries - 1, t * 2);
          });
        })
    : Promise.reject(new Error(`Too many attempts: ${url}`));
}

const checkStatus200 = (res) => {
  return res.status >= 200 && res.status < 400;
};

/**
 *
 * @param url {string}
 * @param options {{}}
 * @param condition {fn}
 * @param nRetries {number=3}
 * @param t {number=1000}
 * @return {Promise<Response>|Promise<never>}
 */
export const fetchRetry = (
  url,
  options,
  condition = checkStatus200,
  nRetries = 3,
  t = DEFAULT_BACKOFF,
) => {
  return nRetries >= 0
    ? window
        .fetch(url, options)
        .then((res) => {
          if (!condition || !nRetries) {
            return Promise.reject(new Error(`Too many attempts: ${url}`));
          }
          if (!condition(res) && !nRetries) {
            return Promise.reject(res);
          }
          if (res && !condition(res)) {
            return delay(t * 2).then(() => {
              return fetchRetry(url, options, condition, nRetries - 1, t * 2);
            });
          }
          return res;
        })
        .catch((e) => {
          if (e?.message === `Too many attempts: ${url}` && nRetries === 1) {
            return e;
          }
          return delay(t * 2).then(() => {
            return fetchRetry(url, options, condition, nRetries - 1, t * 2);
          });
        })
    : Promise.reject(new Error(`Too many attempts: ${url}`));
};
