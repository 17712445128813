import { CREATE_PATIENT_CASE_ROUTE } from 'constants/pageIdentifiers';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LandingPage from './Loadable';
import messages from './messages';

const GenericLandingPage = ({ match }) => {
  return (
    <LandingPage
      startCaseLink={`${CREATE_PATIENT_CASE_ROUTE.replace(
        ':id',
        match.params.id,
      )}`}
      ctaText={<FormattedMessage {...messages.getStarted} />}
      header1Text={<FormattedMessage {...messages.headerGeneric} />}
      header2Text={<FormattedMessage {...messages.headerGeneric2} />}
    />
  );
};

export default GenericLandingPage;

GenericLandingPage.propTypes = {
  match: PropTypes.any,
};
