import { HOME_PAGE } from 'constants/pageIdentifiers';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LandingPage from './Loadable';
import messages from './messages';

const FollowUpLandingPage = () => {
  return (
    <LandingPage
      startCaseLink={HOME_PAGE}
      ctaText={<FormattedMessage {...messages.getStarted} />}
      header1Text={<FormattedMessage {...messages.headerFollowUp} />}
      header2Text={<FormattedMessage {...messages.headerFollowUp2} />}
    />
  );
};

export default FollowUpLandingPage;
