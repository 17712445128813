import styled from 'styled-components';

export const DiagnosticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  & > div {
    width: 100%;
    h3 {
      margin-bottom: 24px;
    }
    /* max-width: 336px; */
    display: block;
  }
`;
export const CompatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  text-align: center;
  padding: 0 48px 24px 48px;
  svg {
    width: 96px;
    height: 96px;
  }
`;
