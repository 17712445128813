/* eslint-disable import/prefer-default-export */
/*
 *
 * Pharmacy provider constants
 *
 */

export const GET_PHARMACY_LIST = 'app/Pharmacy/GET_PHARMACY_LIST';
export const GET_PHARMACY_LIST_SUCCESS =
  'app/Pharmacy/GET_PHARMACY_LIST_SUCCESS';
export const GET_PHARMACY_LIST_FAILURE =
  'app/Pharmacy/GET_PHARMACY_LIST_FAILURE';
export const SELECT_PHARMACY = 'app/Pharmacy/SELECT_PHARMACY';
export const SELECT_PHARMACY_SUCCESS = 'app/Pharmacy/SELECT_PHARMACY_SUCCESS';
export const SELECT_PHARMACY_FAILURE = 'app/Pharmacy/SELECT_PHARMACY_FAILURE';
export const REMOVE_PHARMACY = 'app/Pharmacy/REMOVE_PHARMACY';
export const REMOVE_PHARMACY_SUCCESS = 'app/Pharmacy/REMOVE_PHARMACY_SUCCESS';
export const REMOVE_PHARMACY_FAILURE = 'app/Pharmacy/REMOVE_PHARMACY_FAILURE';
export const GET_MEMBER_PHARMACIES = 'app/Pharmacy/GET_MEMBER_PHARMACIES';
export const GET_MEMBER_PHARMACIES_SUCCESS =
  'app/Pharmacy/GET_MEMBER_PHARMACIES_SUCCESS';
export const GET_MEMBER_PHARMACIES_FAILURE =
  'app/Pharmacy/GET_MEMBER_PHARMACIES_FAILURE';
export const CONFIRM_PHARMACY_SELECTED =
  'app/Pharmacy/CONFIRM_PHARMACY_SELECTED';
export const CONFIRM_PHARMACY_SELECTED_SUCCESS =
  'app/Pharmacy/CONFIRM_PHARMACY_SELECTED_SUCCESS';
export const CONFIRM_PHARMACY_SELECTED_FAILURE =
  'app/Pharmacy/CONFIRM_PHARMACY_SELECTED_FAILURE';
export const PHARMACY_24_HOUR = '24 Hour Pharmacy';
export const PHARMACY_RETAIL = 'Retail';
export const PHARMACY_MAIL_ORDER = 'Mail Order';
