import { defineMessages } from 'react-intl';

const scope = 'app.components.VideoDiagnostics';

const messages = defineMessages({
  yourBrowserIsSupported: {
    id: `${scope}.yourBrowserIsSupported`,
    defaultMessage: 'Your browser is supported',
  },
  yourBrowserIsNotSupported: {
    id: `${scope}.yourBrowserIsNotSupported`,
    defaultMessage: 'Your browser is not supported',
  },
  browserVideoCompatibility: {
    id: `${scope}.browserVideoCompatibility`,
    defaultMessage: 'Browser Video Compatibility',
  },
  videoInputTest: {
    id: `${scope}.videoInputTest`,
    defaultMessage: 'Video Input Test',
  },
  audioInputTest: {
    id: `${scope}.audioInputTest`,
    defaultMessage: 'Audio Input Test',
  },
  audioOutputTest: {
    id: `${scope}.audioOutputTest`,
    defaultMessage: 'Audio Output Test',
  },
});

export default messages;
