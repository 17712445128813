import { TriageCaseCard } from 'components/GetCareView/cards';
import { GetCareCard, ResumeSubmitCaseCard } from 'components/HomeView/cards';
import JoinVideoCard from 'components/JoinVideoCard/Loadable';
import {
  LD_UI_HOME_GET_CARE_CARD,
  LD_UI_HOME_TRIAGE_CARD,
} from 'constants/launchDarklyFlags';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  getOpenPatientCases,
  getPendingScheduledCases,
  isFollowUpCase,
  isTriageCase,
} from 'utils/caseHelpers';
import { StyledCard } from './styled';

export const DisplayCards = ({
  patientCases,
  patientCaseTemplates,
  counts,
  member,
  history,
  flags,
  patientCasesLoading,
}) => {
  const openCases = useMemo(
    () => getOpenPatientCases(patientCases),
    [patientCases],
  );
  const scheduledCases = useMemo(
    () => getPendingScheduledCases(patientCases),
    [patientCases],
  );
  const followUpCases = useMemo(
    () => openCases?.filter((c) => isFollowUpCase(c)),
    [openCases],
  );
  const triageCases = useMemo(
    () => openCases?.filter((c) => isTriageCase(c)),
    [openCases],
  );

  if (patientCasesLoading) {
    return <StyledCard loading />;
  }

  return (
    <>
      {scheduledCases.length
        ? scheduledCases.map((pc) => (
            <JoinVideoCard
              key={pc.id}
              patientCase={pc}
              videoCall={pc?.videoCall}
            />
          ))
        : null}
      {openCases.length
        ? openCases.map((openCase) => (
            <ResumeSubmitCaseCard
              key={openCase.id}
              member={member}
              patientCase={openCase}
              counts={counts}
              patientCaseTemplates={patientCaseTemplates}
            />
          ))
        : null}
      {!followUpCases.length && flags[LD_UI_HOME_GET_CARE_CARD] && (
        <GetCareCard history={history} />
      )}
      {!triageCases.length && flags[LD_UI_HOME_TRIAGE_CARD] && (
        <TriageCaseCard />
      )}
    </>
  );
};

DisplayCards.propTypes = {
  counts: PropTypes.any,
  flags: PropTypes.any,
  history: PropTypes.any,
  member: PropTypes.any,
  patientCaseTemplates: PropTypes.any,
  patientCases: PropTypes.any,
  patientCasesLoading: PropTypes.any,
};
