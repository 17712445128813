import {
  ACCOUNT_PROFILE_REQUIRED_STAGE,
  CASE_QUESTIONS_REQUIRED_STAGE,
  FOLLOW_UP_CASE_TYPE,
  MEMBER_PROFILE_REQUIRED_STAGE,
  PATIENT_CASE_STATUS_DELAYED,
  PATIENT_CASE_STATUS_INCOMPLETE,
  PATIENT_CASE_STATUS_LATE,
  PATIENT_CASE_STATUS_LOCKED,
  PATIENT_CASE_STATUS_PENDING,
  PATIENT_CASE_STATUS_S3_UPLOAD_COMPLETE,
  PATIENT_CASE_STATUS_UPLOAD_ERROR,
  PHARMACY_STEP_REQUIRED,
  PHOTOS_REQUIRED_STAGE,
  PRESCRIPTION_INFO_REQUIRED_STEP,
  TRIAGE_CASE_TYPE,
  VIDEO_CALL_REQUIRED_STAGE,
} from 'constants/cases';
import {
  PAYMENT_ELIGIBILITY_STATUS_ELIGIBILE,
  PAYMENT_STATUS_PAID,
} from 'constants/payments';
import isDevEnv from 'utils/isDevEnv';

/**
 * Returns status is incomplete or status is s3_upload_complete
 * @param cases
 * @return {*}
 */
export const getOpenPatientCase = (cases = []) => {
  return cases.find(
    (c) =>
      c.status === PATIENT_CASE_STATUS_INCOMPLETE ||
      c.status === PATIENT_CASE_STATUS_S3_UPLOAD_COMPLETE ||
      (isDevEnv ? c?.status === PATIENT_CASE_STATUS_UPLOAD_ERROR : false),
  );
};

/**
 * Returns status is incomplete or status is s3_upload_complete
 * Additional dev filter for s3_upload_error status
 * @param cases
 * @return {*[]}
 */
export const getOpenPatientCases = (cases = []) => {
  return cases.filter(
    (c) =>
      c?.status === PATIENT_CASE_STATUS_INCOMPLETE ||
      c?.status === PATIENT_CASE_STATUS_S3_UPLOAD_COMPLETE ||
      (isDevEnv ? c?.status === PATIENT_CASE_STATUS_UPLOAD_ERROR : false),
  );
};

export const getPendingScheduledCases = (cases = []) => {
  return cases.filter((c) => {
    return (
      (c.status === PATIENT_CASE_STATUS_LOCKED ||
        c.status === PATIENT_CASE_STATUS_PENDING) &&
      c.videoCall?.scheduled_date
    );
  });
};

/**
 * patient case case_type_name matches follow up type
 * @param patientCase
 * @return {boolean}
 */
export const isFollowUpCase = (patientCase) => {
  return (
    unformatCaseTypeName(patientCase?.case_type_name) === FOLLOW_UP_CASE_TYPE
  );
};

/**
 * patient case case_type_name matches triage type
 * @param patientCase
 * @return {boolean}
 */
export const isTriageCase = (patientCase) => {
  return unformatCaseTypeName(patientCase?.case_type_name) === TRIAGE_CASE_TYPE;
};

/**
 * patient case case_type_name has unanswered_questions_count AND case_questions as a required stage
 * @param patientCase
 * @return {*|boolean}
 */
export const hasUnansweredCaseQuestions = (patientCase) => {
  return (
    patientCase &&
    patientCase.required_stages
      .concat(patientCase.optional_stages || [])
      .indexOf(CASE_QUESTIONS_REQUIRED_STAGE) > -1 &&
    !patientCase.case_question_set_complete
  );
};

/**
 * patient case case_type_name has unanswered medical information questions
 * AND prescription_info as a required stage
 * @param patientCase
 * @param member
 * @return {*|boolean}
 */
export const hasUnansweredMedicalInfoQuestions = (patientCase, member) => {
  if (!patientCase) {
    return !member.medications_complete || !member.allergies_complete;
  }
  return (
    patientCase &&
    patientCase.required_stages.indexOf(PRESCRIPTION_INFO_REQUIRED_STEP) > -1 &&
    (!member.medications_complete || !member.allergies_complete)
  );
};

/**
 * patient case case_type_name has not confirmed pharmacy selection
 * AND has pharmacy as a required stage
 * @param patientCase
 * @param member
 * @return {*|boolean}
 */
export const hasUnconfirmedPharmacy = (patientCase, member) => {
  if (!patientCase) return !member.pharmacies_complete;
  return (
    patientCase &&
    patientCase.required_stages.indexOf(PHARMACY_STEP_REQUIRED) > -1 &&
    !member.pharmacies_complete
  );
};

/**
 * patient case member_questions as a required stage and the member has set member_profile_complete to true
 * Note that member_profile complete is set on our end now, based on the member question set `completed` attribute
 * @param patientCase
 * @param member
 * @return {*|boolean}
 */
export const hasUnansweredMemberQuestions = (patientCase, member) => {
  if (!patientCase) {
    return !member.member_profile_complete;
  }
  return (
    patientCase &&
    patientCase.required_stages
      .concat(patientCase.optional_stages || [])
      .indexOf(MEMBER_PROFILE_REQUIRED_STAGE) > -1 &&
    !member?.member_profile_complete
  );
};

/**
 *  * patient case has account_questions as a required stage and member account profile complete is true
 * Note that member_profile complete is set on our end now, based on the account question set `completed` attribute
 * @param patientCase
 * @param accountProfileComplete
 * @return {*|boolean}
 */

/**
 * patient case has account_questions as a required stage and member account profile complete is true
 */
export const hasUnansweredAccountQuestions = (
  patientCase,
  accountProfileComplete,
) =>
  patientCase &&
  patientCase.required_stages
    .concat(patientCase.optional_stages || [])
    .indexOf(ACCOUNT_PROFILE_REQUIRED_STAGE) > -1 &&
  !accountProfileComplete;

/**
 * And of the case, member or account questions are unanswered, if they are a required stage
 * @param patientCase
 * @param member
 * @param accountProfileComplete
 * @return {*}
 */
export const hasUnansweredQuestions = (
  patientCase,
  member,
  accountProfileComplete,
) => {
  return (
    hasUnansweredCaseQuestions(patientCase) ||
    hasUnansweredMemberQuestions(patientCase, member) ||
    hasUnansweredAccountQuestions(patientCase, accountProfileComplete)
  );
};

/**
 * patient case has photos left to be submitted
 * @param patientCase
 * @param count
 * @return {*|boolean|boolean}
 */
export const hasOutstandingPhotos = (
  patientCase,
  patientCaseTemplate,
  count,
) => {
  if (!patientCase || !patientCaseTemplate) return false;

  // Follow up cases you can skip photo taking, so we have required_photos_count === 0
  // required photos count is 0 and optional stages does not include photos
  if (
    patientCase.required_photos_count === 0 &&
    !patientCaseTemplate?.optional_stages?.includes(PHOTOS_REQUIRED_STAGE)
  ) {
    return false;
  }

  // If the count is 0, the step is not complete.
  // Note that if photo taking is skipped, then we go to submit the case straight away,
  // so having this always true is not a problem.
  if (count === 0) {
    return true;
  }

  return count < patientCase.required_photos_count;
};

/**
 * Is this case to be 'resumed' (has been already started at some point - has some unanswered questions)
 * Note this should include if there are photos outstanding also
 * TODO: @rf-toothpic Outstanding photo count required
 * @param member
 * @param patientCase
 * @return {boolean}
 */
export const isResumeCase = (member, patientCase) => {
  if (!patientCase) {
    return false;
  }

  return (
    hasUnansweredQuestions(patientCase, member) ||
    patientCase.status === PATIENT_CASE_STATUS_INCOMPLETE
  );
};

/**
 * Formats a patient case case_type_name
 * follow_up => Follow up
 * @param str
 * @return {*|string}
 */
export const formatCaseTypeName = (str) => {
  return (
    str &&
    str
      .toLowerCase()
      .replace(/_/, ' ')
      .replace(
        /([^a-z]|^)([a-z])(?=[a-z]{2})/,
        (_, g1, g2) => g1 + g2.toUpperCase(),
      )
  );
};

/**
 * Formats a patient case name back to a case_type_name
 * Follow up => follow_up
 * @param str
 * @return {*|string}
 */
export const unformatCaseTypeName = (str) => {
  return str && str.toLowerCase().replace(/ /, '_');
};

/**
 * Returns a video call that is not yet started, if it exists
 * @param patientCases
 * @param patientCaseTemplateId
 * @param templateBaseName
 * @return {*}
 */
export const getIncompleteCases = (
  patientCases = [],
  patientCaseTemplateId,
  templateBaseName,
) => {
  return patientCases.filter((c) => {
    return (
      c.status === PATIENT_CASE_STATUS_INCOMPLETE &&
      (c.patient_case_template_id === patientCaseTemplateId ||
        c.template_base_name === templateBaseName ||
        c.case_type_name === templateBaseName)
    );
  });
};

/**
 * Returns an ongoing video call, if it exists
 * @param patientCase
 * @return boolean
 */
export const containsVideoStage = (patientCase) => {
  return patientCase?.required_stages?.indexOf(VIDEO_CALL_REQUIRED_STAGE) > -1;
};

/**
 * Returns a value to know if a patient case has a video call in progress,
 * to be able to rejoin it.
 * @param patientCase
 * @return boolean
 */
export const isCallInProgress = (patientCase) => {
  if (!patientCase?.videoCall) return false;
  return (
    (patientCase.status === PATIENT_CASE_STATUS_LOCKED ||
      patientCase.status === PATIENT_CASE_STATUS_LATE ||
      patientCase.status === PATIENT_CASE_STATUS_DELAYED) &&
    patientCase.videoCall.twilio_sid
  );
};

export const canSkipPhotos = (patientCaseTemplate) => {
  return patientCaseTemplate?.required_photos_count === 0;
};

isResumeCase.propTypes = {};

/**
 * Get display name for the patient case based on the patient case template and patient case
 * @param {{}} patientCaseTemplate PCT
 * @param {*} patientCase PC
 * @returns {string} display name for the patient case
 */
export function getPatientCaseDisplayName(patientCaseTemplate, patientCase) {
  return (
    patientCaseTemplate?.display_name ||
    patientCaseTemplate?.template_base_name ||
    formatCaseTypeName(patientCase?.case_type_name)
  );
}

/**
 * Get template name for the patient case based on the patient case template and patient case
 * @param {*} patientCaseTemplate PCT
 * @param {*} patientCase PC
 * @returns {string} template name for the patient case
 */
export function getCaseTemplateName(patientCaseTemplate, patientCase) {
  return patientCaseTemplate.template_base_name || patientCase.case_type_name;
}

/**
 * Has the case been payed for and is ready to be submitted
 * @param {*} payment
 * @param {*} patientCase
 * @returns {boolean} true if the payment is completed
 */
export const checkoutCompleted = (payment, patientCase) => {
  if (payment && payment.status === PAYMENT_STATUS_PAID) return true;

  // Edge case: a user checks out using benefits.
  // There is currently no clear way to see this so we infer it in a round-about way.
  // This will change soon when the state of stages in modelled in the backend.
  if (
    payment &&
    patientCase &&
    payment.eligibility_status === PAYMENT_ELIGIBILITY_STATUS_ELIGIBILE &&
    patientCase.status === PATIENT_CASE_STATUS_PENDING
  )
    return true;

  return false;
};
