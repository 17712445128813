/*
 *
 * MedicalInformation actions
 *
 */

import {
  DELETE_MEDICAL_INFORMATION,
  DELETE_MEDICAL_INFORMATION_FAILURE,
  DELETE_MEDICAL_INFORMATION_SUCCESS,
  GET_MEMBER_MEDICAL_INFORMATION,
  GET_MEMBER_MEDICAL_INFORMATION_FAILURE,
  GET_MEMBER_MEDICAL_INFORMATION_SUCCESS,
  POST_RESOURCE,
  POST_RESOURCE_FAILURE,
  POST_RESOURCE_SUCCESS,
} from './constants';

export const postResource = (payload) => ({
  type: POST_RESOURCE,
  payload,
});

export const postResourceSuccess = (payload) => ({
  type: POST_RESOURCE_SUCCESS,
  payload,
});

export const postResourceFailure = (error) => ({
  type: POST_RESOURCE_FAILURE,
  error,
});

export const getMemberMedicalInformation = (payload) => ({
  type: GET_MEMBER_MEDICAL_INFORMATION,
  payload,
});

export const getMemberMedicalInformationSuccess = (payload) => ({
  type: GET_MEMBER_MEDICAL_INFORMATION_SUCCESS,
  payload,
});

export const getMemberMedicalInformationFailure = (error) => ({
  type: GET_MEMBER_MEDICAL_INFORMATION_FAILURE,
  error,
});

export const deleteMedicalInformation = (payload) => ({
  type: DELETE_MEDICAL_INFORMATION,
  payload,
});

export const deleteMedicalInformationSuccess = (payload) => ({
  type: DELETE_MEDICAL_INFORMATION_SUCCESS,
  payload,
});

export const deleteMedicalInformationFailure = (error) => ({
  type: DELETE_MEDICAL_INFORMATION_FAILURE,
  error,
});
