/**
 *
 * ButtonCard
 *
 */

import { withTrackingId } from '@oraleye/frontend-modules-utils';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CardButton,
  CardContainer,
  CardContent,
  CardTitle,
  Divider,
  IconContainer,
  StyledIcon,
} from './styled';

const ButtonCard = ({
  title,
  content,
  contentHeader,
  icon,
  buttonText,
  onButtonClick,
  disabled,
  trackingId,
  className,
}) => {
  return (
    <>
      <CardContainer className={className}>
        <CardTitle>{title}</CardTitle>
        <Divider>
          <IconContainer>
            <StyledIcon src={icon} />
          </IconContainer>
        </Divider>
        <CardContent>
          {contentHeader}
          {content}
        </CardContent>
        {onButtonClick && (
          <CardButton
            variant="contained"
            type="submit"
            onClick={onButtonClick}
            disabled={disabled}
            trackingId={trackingId}
          >
            {buttonText}
          </CardButton>
        )}
      </CardContainer>
    </>
  );
};

ButtonCard.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  contentHeader: PropTypes.any,
  icon: PropTypes.any,
  buttonText: PropTypes.any,
  onButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
  trackingId: PropTypes.string,
  className: PropTypes.string,
};

ButtonCard.defaultProps = {
  disabled: false,
};

export default withTrackingId(ButtonCard);
