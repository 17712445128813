/*
 *
 * Questions actions
 *
 */

import {
  CHECK_SET_COMPLETE,
  CHECK_SET_COMPLETE_FAILURE,
  CHECK_SET_COMPLETE_SUCCESS,
  CHECK_SET_READY_FOR_COMPLETION,
  CHECK_SET_READY_FOR_COMPLETION_FALSE,
  CHECK_SET_READY_FOR_COMPLETION_TRUE,
  DEFAULT_ACTION,
  FETCH_QUESTION_CARDS,
  FETCH_QUESTION_CARDS_FAILURE,
  FETCH_QUESTION_CARDS_SUCCESS,
  FETCH_QUESTION_SETS,
  FETCH_QUESTION_SETS_FAILURE,
  FETCH_QUESTION_SETS_SUCCESS,
  GET_QUESTION_SET,
  GET_QUESTION_SET_FAILURE,
  GET_QUESTION_SET_SUCCESS,
  PATCH_QUESTION_CARD,
  PATCH_QUESTION_CARD_FAILURE,
  PATCH_QUESTION_CARD_SUCCESS,
  PATCH_QUESTION_SET,
  PATCH_QUESTION_SET_COMPLETE,
  PATCH_QUESTION_SET_COMPLETE_FAILURE,
  PATCH_QUESTION_SET_COMPLETE_SUCCESS,
  PATCH_QUESTION_SET_FAILURE,
  PATCH_QUESTION_SET_SUCCESS,
  SET_CURRENT_QUESTION_SET_ID,
  SET_CURRENT_QUESTION_SET_TYPE,
  SET_QUESTION_SET_REDIRECT,
  UPDATE_QUESTION_SET_ANSWER,
  UPDATE_QUESTION_SET_ANSWER_FAILURE,
  UPDATE_QUESTION_SET_ANSWER_SUCCESS,
} from './constants';

export const defaultAction = () => ({ type: DEFAULT_ACTION });

export const getQuestionSet = (payload) => ({
  type: GET_QUESTION_SET,
  payload,
});

export const getQuestionSetSuccess = (payload) => ({
  type: GET_QUESTION_SET_SUCCESS,
  payload,
});

export const getQuestionSetFailure = (error, payload) => ({
  type: GET_QUESTION_SET_FAILURE,
  error,
  payload,
});

export const patchQuestionSet = (payload) => ({
  type: PATCH_QUESTION_SET,
  payload,
});

export const patchQuestionSetSuccess = (payload) => ({
  type: PATCH_QUESTION_SET_SUCCESS,
  payload,
});

export const patchQuestionSetFailure = (error, payload) => ({
  type: PATCH_QUESTION_SET_FAILURE,
  error,
  payload,
});

export const patchQuestionSetComplete = (payload) => ({
  type: PATCH_QUESTION_SET_COMPLETE,
  payload,
});

export const patchQuestionSetCompleteSuccess = (payload) => ({
  type: PATCH_QUESTION_SET_COMPLETE_SUCCESS,
  payload,
});

export const patchQuestionSetCompleteFailure = (error, payload) => ({
  type: PATCH_QUESTION_SET_COMPLETE_FAILURE,
  error,
  payload,
});

export const setQuestionSetRedirect = (payload) => ({
  type: SET_QUESTION_SET_REDIRECT,
  payload,
});

export const fetchQuestionSets = (payload) => ({
  type: FETCH_QUESTION_SETS,
  payload,
});

export const fetchQuestionSetsSuccess = (payload) => ({
  type: FETCH_QUESTION_SETS_SUCCESS,
  payload,
});

export const fetchQuestionSetsFailure = (error, payload) => ({
  type: FETCH_QUESTION_SETS_FAILURE,
  error,
  payload,
});

export const fetchQuestionCards = (payload) => ({
  type: FETCH_QUESTION_CARDS,
  payload,
});

export const fetchQuestionCardsSuccess = (payload) => ({
  type: FETCH_QUESTION_CARDS_SUCCESS,
  payload,
});

export const fetchQuestionCardsFailure = (error, payload) => ({
  type: FETCH_QUESTION_CARDS_FAILURE,
  error,
  payload,
});

export const patchQuestionCard = (payload) => ({
  type: PATCH_QUESTION_CARD,
  payload,
});

export const patchQuestionCardSuccess = (payload) => ({
  type: PATCH_QUESTION_CARD_SUCCESS,
  payload,
});

export const patchQuestionCardFailure = (error, payload) => ({
  type: PATCH_QUESTION_CARD_FAILURE,
  error,
  payload,
});

export const checkQuestionSetReadyForCompletion = (payload) => ({
  type: CHECK_SET_READY_FOR_COMPLETION,
  payload,
});

export const checkQuestionSetReadyForCompletionSuccess = (payload) => ({
  type: CHECK_SET_READY_FOR_COMPLETION_TRUE,
  payload,
});

export const checkQuestionSetReadyForCompletionFailure = (payload) => ({
  type: CHECK_SET_READY_FOR_COMPLETION_FALSE,
  payload,
});

export const checkQuestionSetComplete = (payload) => ({
  type: CHECK_SET_COMPLETE,
  payload,
});

export const checkQuestionSetCompleteSuccess = (payload) => ({
  type: CHECK_SET_COMPLETE_SUCCESS,
  payload,
});

export const checkQuestionSetCompleteFailure = (error, payload) => ({
  type: CHECK_SET_COMPLETE_FAILURE,
  error,
  payload,
});

export const setCurrentQuestionSetId = (id, type) => ({
  type: SET_CURRENT_QUESTION_SET_ID,
  payload: { id, type },
});

export const setCurrentQuestionSetType = (type) => ({
  type: SET_CURRENT_QUESTION_SET_TYPE,
  payload: { type },
});

export const updateQuestionSetAnswer = (payload) => ({
  type: UPDATE_QUESTION_SET_ANSWER,
  payload,
});

export const updateQuestionSetAnswerSuccess = (payload) => ({
  type: UPDATE_QUESTION_SET_ANSWER_SUCCESS,
  payload,
});

export const updateQuestionSetAnswerFailure = (payload) => ({
  type: UPDATE_QUESTION_SET_ANSWER_FAILURE,
  payload,
});
