import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Home';

export default defineMessages({
  documentTitle: {
    id: `${scope}.documentTitle`,
    defaultMessage: '{company} | {documentTitle}',
  },
});
