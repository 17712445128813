import { SCHEDULE_VIDEO_REQUIRED_STEP } from 'constants/cases';
import { hasStage } from 'containers/Consultation/saga';
import {
  fetchPatientCasesAction,
  fetchPatientCaseTemplatesAction,
} from 'containers/GetCare/actions';
import {
  FETCH_PATIENT_CASE_TEMPLATES_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATES_SUCCESS,
  FETCH_PATIENT_CASES_FAILURE,
  FETCH_PATIENT_CASES_SUCCESS,
} from 'containers/GetCare/constants';
import {
  fetchHomeDataFailureAction,
  fetchHomeDataSuccessAction,
} from 'containers/Home/actions';
import { FETCH_HOME_DATA } from 'containers/Home/constants';
import { getVideoCallAction } from 'containers/VideoCall/actions';
import {
  GET_VIDEO_CALL_FAILURE_ACTION,
  GET_VIDEO_CALL_SUCCESS_ACTION,
} from 'containers/VideoCall/constants';
import { uniq } from 'lodash';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';

function* getPatientCases() {
  yield put(fetchPatientCasesAction());
  const [{ payload: patientCases }, e1] = yield race([
    take(FETCH_PATIENT_CASES_SUCCESS),
    take(FETCH_PATIENT_CASES_FAILURE),
  ]);
  if (e1) {
    throw e1;
  }
  return patientCases;
}

function* getPatientCaseTemplates(patientCases) {
  yield put(
    fetchPatientCaseTemplatesAction({
      patientCaseTemplateIds: uniq(
        patientCases.map((pc) => pc.patient_case_template_id),
      ),
    }),
  );
  const [{ payload: patientCaseTemplates }, e2] = yield race([
    take(FETCH_PATIENT_CASE_TEMPLATES_SUCCESS),
    take(FETCH_PATIENT_CASE_TEMPLATES_FAILURE),
  ]);
  if (e2) {
    throw e2;
  }
  return patientCaseTemplates;
}

function* getVideoCalls(patientCaseIds) {
  yield put(getVideoCallAction({ patientCaseIds }));
  const [{ payload: videoCalls, e2 }] = yield race([
    take(GET_VIDEO_CALL_SUCCESS_ACTION),
    take(GET_VIDEO_CALL_FAILURE_ACTION),
  ]);
  if (e2) {
    throw e2;
  }
  return videoCalls;
}

function* getScheduledVideoCalls(patientCases) {
  const videoCasePCIds = patientCases
    .filter((pc) => hasStage(pc, SCHEDULE_VIDEO_REQUIRED_STEP))
    .map((pc) => pc.id);
  const videoCalls = yield getVideoCalls(videoCasePCIds);
  return videoCalls;
}

function* fetchHomeData() {
  try {
    const patientCases = yield getPatientCases();
    const [patientCaseTemplates, videoCalls] = yield all([
      call(getPatientCaseTemplates, patientCases),
      call(getScheduledVideoCalls, patientCases),
    ]);
    yield put(
      fetchHomeDataSuccessAction({
        patientCases,
        patientCaseTemplates,
        videoCalls,
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(fetchHomeDataFailureAction(e));
  }
}

export default function* homeSaga() {
  yield takeLatest(FETCH_HOME_DATA, fetchHomeData);
}
