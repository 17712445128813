/*eslint-disable*/

const APP_ID = window.env.INTERCOM_APP_ID;

window.intercomSettings = {
  app_id: APP_ID,
};

const setup = () => {
  const ic = window.Intercom;
  if (typeof ic === 'function') {
    ic('update', intercomSettings);
    ic('reattach_activator');
  } else {
    const i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    window.Intercom = i;
  }
};
setup();

export function boot(obj = {}) {
  let ic = window.Intercom;
  if (!ic) {
    setup();
  }
  if (typeof ic === 'function') {
    ic('boot', { app_id: APP_ID, ...obj });
  }
}

export function shutdown() {
  const ic = window.Intercom;
  if (typeof ic === 'function') {
    ic('shutdown');
  }
}

export function hide() {
  const ic = window.Intercom;
  if (typeof ic === 'function') {
    ic('hide');
  }
}

export function show() {
  const ic = window.Intercom;
  if (typeof ic === 'function') {
    ic('show');
  }
}

export function load() {
  const l = () => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${APP_ID}`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  };
  if (!window.Intercom) {
    setup();
  }
  if (document.readyState === 'complete') {
    l();
  } else if (window.attachEvent) {
    window.attachEvent('onload', l);
  } else {
    window.addEventListener('load', l, false);
  }
  boot();
}
