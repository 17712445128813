/**
 *
 * UrlRedirect
 *
 */

import Loader from 'components/Loader';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import { getRedirectRoute } from 'components/UrlRedirect/data';
import { updateCampaignIdentifier } from 'containers/LaunchDarklyUpdater/actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const UrlRedirect = ({ match, updateCampaignId }) => {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (window.location.href.indexOf('/d/') > -1) {
      getRedirectRoute(match.params.id).then((data) => {
        updateCampaignId(data.campaign_identifier, true);
        setRedirect(data);
      });
    }
  }, [match, updateCampaignId]);

  return redirect ? <RedirectWithQuery to={redirect} /> : <Loader />;
};

export default connect(null, (dispatch) => ({
  updateCampaignId: (value, isUrl) => {
    dispatch(
      updateCampaignIdentifier({ campaignId: value, campaignIsUrl: isUrl }),
    );
  },
}))(UrlRedirect);

UrlRedirect.propTypes = {
  match: PropTypes.any,
  updateCampaignId: PropTypes.func,
};
