import { Select } from '@oraleye/frontend-modules-components';
import Button from 'components/Button';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { setSelectedDevice } from './helpers';

const AudioOutputTest = ({ devices, permission, selectedDeviceId: sid }) => {
  const oscillatorRef = useRef(null);
  const audioContext = useRef(null);

  const [selectedDeviceId, setSelectedDeviceId] = useState(sid);

  useEffect(() => {
    setSelectedDeviceId(sid);
  }, [sid]);

  const [playing, setPlaying] = useState(false);

  const handlePlaySound = useCallback(async () => {
    const _audioContext = new AudioContext();
    // if oscillator is already playing, stop it
    if (oscillatorRef.current) {
      oscillatorRef.current.stop();
    }
    try {
      await _audioContext.setSinkId?.(selectedDeviceId);
    } catch (e) {
      await _audioContext.setSinkId?.('');
    }
    // Create audio context and oscillator
    const oscillator = _audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.value = 440;
    oscillator.start();
    oscillator.connect(_audioContext.destination);

    audioContext.current = _audioContext;
    oscillatorRef.current = oscillator;
    setPlaying(true);
  }, [selectedDeviceId]);

  const handleStopSound = () => {
    setPlaying(false);
    oscillatorRef.current.stop();
  };

  const handleAudioOutputChange = useCallback((event) => {
    // have to update state here for the select to work
    const deviceId = event.target.value;
    setSelectedDevice('AudioOutput', deviceId);
    setSelectedDeviceId(deviceId);
  }, []);

  const audioOutputDeviceOptions = useMemo(() => {
    if (devices.length === 0) {
      return [
        {
          value: '',
          label: 'Default',
        },
      ];
    }
    return (
      devices
        // .filter((a) => a.deviceId !== 'default')
        .map((device) => ({
          value: device.deviceId,
          label: device.label,
        }))
    );
  }, [devices]);

  useEffect(() => {
    if (permission?.state === 'granted' && playing) {
      handlePlaySound();
    }
    //cleanup - stop oscillator and audio context
    return () => {
      if (oscillatorRef.current) {
        oscillatorRef.current.stop();
        oscillatorRef.current.disconnect();
      }
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, [handlePlaySound, permission, playing]);

  return (
    <div>
      <Form
        onSubmit={() => {}}
        render={() => (
          <Select
            initialValue={selectedDeviceId}
            name="audioOutputDevice"
            value={selectedDeviceId}
            onChange={handleAudioOutputChange}
            options={audioOutputDeviceOptions}
            trackingId={'audio-output-device'}
          />
        )}
      />

      <Button onClick={playing ? handleStopSound : handlePlaySound}>
        {playing ? 'Stop' : 'Play'} Sound
      </Button>
    </div>
  );
};

export default AudioOutputTest;
