/* eslint-disable import/prefer-default-export */
/*
 *
 * MedicalInformation provider constants
 *
 */

export const POST_RESOURCE = 'app/MedicalInformation/POST_RESOURCE';
export const POST_RESOURCE_SUCCESS =
  'app/MedicalInformation/POST_RESOURCE_SUCCESS';
export const POST_RESOURCE_FAILURE =
  'app/MedicalInformation/POST_RESOURCE_FAILURE';
export const GET_MEMBER_MEDICAL_INFORMATION =
  'app/MedicalInformation/GET_MEMBER_MEDICAL_INFORMATION';
export const GET_MEMBER_MEDICAL_INFORMATION_SUCCESS =
  'app/MedicalInformation/GET_MEMBER_MEDICAL_INFORMATION_SUCCESS';
export const GET_MEMBER_MEDICAL_INFORMATION_FAILURE =
  'app/MedicalInformation/GET_MEMBER_MEDICAL_INFORMATION_FAILURE';
export const DELETE_MEDICAL_INFORMATION =
  'app/MedicalInformation/DELETE_MEDICAL_INFORMATION';
export const DELETE_MEDICAL_INFORMATION_SUCCESS =
  'app/MedicalInformation/DELETE_MEDICAL_INFORMATION_SUCCESS';
export const DELETE_MEDICAL_INFORMATION_FAILURE =
  'app/MedicalInformation/DELETE_MEDICAL_INFORMATION_FAILURE';

// Resource names
export const RESOURCE_MEMBER_MEDICATIONS = 'member_medications';
export const RESOURCE_MEMBER_ALLERGIES = 'member_allergies';
export const RESOURCE_NAME_MEDICATIONS = 'medications';
export const RESOURCE_NAME_ALLERGIES = 'allergies';
export const MEDICATIONS_SEARCH_PARAM = 'long_form_name';
export const ALLERGIES_SEARCH_PARAM = 'allergy_name';
export const MEMBER_MEDICATIONS_ENDPOINT = 'v3/member_medications';
export const MEMBER_ALLERGIES_ENDPOINT = 'v3/member_allergies';
export const MEDICATIONS_ENDPOINT = 'v3/medications';
export const ALLERGIES_ENDPOINT = 'v3/allergies';

// Others
export const STATUS_EXPIRED = 'expired';
