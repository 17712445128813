import {
  clearUserLocalStorageData,
  getAuthToken,
  getReferrer,
  setAuthToken,
  setCompanyId,
  setLastSeen,
  setMember,
} from 'utils/localStorageHelper';
import request from 'utils/request';
import { clearUserSessionStorageData } from 'utils/sessionStorageHelper';

const DEEPLINK_AUTH_TYPE = 'deeplink_member';
const QUIP_AUTH_TYPE = 'quip_member';

function authenticateMemberWithMessage(message, authType) {
  const referrer = getReferrer();
  const url = 'v3/auth';
  const body = {
    referrer: referrer,
    message: message,
    auth_type: authType,
  };
  const headers = new Headers();
  const options = {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify(body),
  };

  return request(url, options).then((response) => {
    if (response) {
      setAuthToken(response.auth_token, response.ttl * 1000);
      return response.auth_token;
    }
    return null;
  });
}

function authenticateMemberWithQuip(message, authType, campaignId) {
  const url = 'v2/auth';
  const body = {
    message: message,
    auth_type: authType,
    campaign_identifier: campaignId,
  };
  const headers = new Headers();
  const options = {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify(body),
  };

  return request(url, options).then((response) => {
    if (response) {
      setAuthToken(response.auth_token, response.ttl * 1000);
      return response.auth_token;
    }
    return null;
  });
}

export function getMemberWithToken() {
  const url = `v3/members?t=${Date.now()}`;
  const headers = new Headers();
  const options = {
    method: 'GET',
    headers,
  };
  return request(url, options).then((data) => (data.length ? data[0] : null));
}

export function checkAuth(companyId, message, qToken, campaignId) {
  const token = getAuthToken();
  if (!token && !(message || qToken)) {
    clearUserLocalStorageData();
    clearUserSessionStorageData();
    return Promise.resolve({
      isAuthenticated: false,
      member: null,
      companyId,
      authToken: null,
    });
  }

  let prom = Promise.resolve(token);
  if (qToken) {
    prom = authenticateMemberWithQuip(qToken, QUIP_AUTH_TYPE, campaignId);
  } else if (message) {
    prom = authenticateMemberWithMessage(message, DEEPLINK_AUTH_TYPE);
  }
  return prom
    .then((token) => {
      if (!token && (qToken || message)) {
        throw new Error('Authentication error');
      }
      return Promise.all([token, getMemberWithToken()]);
    })
    .then(([token, member]) => {
      // if this is a new company in the id from the members current company,
      // remove member and auth from localstorage
      if (companyId && companyId !== member.company_id) {
        setCompanyId(member.company_id);
        clearUserLocalStorageData();
        clearUserSessionStorageData();

        return {
          isAuthenticated: false,
          member: null,
          companyId: member.companyid,
          authToken: null,
        };
      }

      setLastSeen();
      setMember(member);
      setCompanyId(member.company_id);
      return {
        isAuthenticated: true,
        member,
        companyId: member.company_id,
        authToken: token,
      };
    });
}
