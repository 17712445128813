import {
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
} from 'locale-constants';

import keys from './localStorageKeys.json';

/**
 * Set SMS Identifier in localstorage
 * @param id
 */
export function setSMSId(id) {
  window.localStorage.setItem(keys.smsId, id || '');
}

/**
 * Get SMS Identifier from localstorage
 * @return {string|null}
 */
export function getSMSId() {
  const c = window.localStorage.getItem(keys.smsId);
  if (c === 'null' || c === null) return null;
  return c;
}

/**
 * Remove SMS Identifier from localstorage
 */
export function removeSMSId() {
  window.localStorage.removeItem(keys.smsId);
}

/**
 * Set Company Identifier in localstorage
 * @param id
 */
export function setCompanyId(id) {
  window.localStorage.setItem(keys.companyId, id || '');
}

/**
 * Get Company Identifier from localstorage
 * @return {string|null}
 */
export function getCompanyId() {
  const c = window.localStorage.getItem(keys.companyId);
  if (c === 'null' || c === null) return null;
  return c;
}

/**
 * Remove Company Identifier from localstorage
 * @return {string|null}
 */
export function removeCompanyId() {
  window.localStorage.removeItem(keys.companyId);
}

/**
 * Set user auth token in localstorage
 * @param token
 * @param ttl
 */
export function setAuthToken(token, ttl) {
  window.localStorage.setItem(keys.tokenKey, `${token}::${Date.now() + ttl}`);
}

/**
 * Remove user auth token from localstorage
 */
export function removeAuthToken() {
  window.localStorage.removeItem(keys.tokenKey);
}

/**
 * Get user auth token from localstorage
 * @return {string|null}
 */
export function getAuthToken() {
  try {
    const [token, t] = window.localStorage.getItem(keys.tokenKey).split('::');
    if (t && parseInt(t) < Date.now()) {
      removeAuthToken();
      return null;
    }
    return token;
  } catch (e) {
    return null;
  }
}

/**
 * Set Twilio Access Token in localstorage
 * @param token
 * @param ttl
 */
export function setTwilioAccessToken(token, ttl, patientCaseId) {
  window.localStorage.setItem(keys.twilioAccessTokenKey, token);
  window.localStorage.setItem(
    `${keys.twilioAccessTokenKey}-t`,
    +new Date() + ttl,
  );
  window.localStorage.setItem(
    `${keys.twilioAccessTokenKey}-caseid`,
    patientCaseId,
  );
}

/**
 * Remove Twilio access token from localstorage
 * @return {string|null}
 */
export function getTwilioAccessToken(patientCaseId) {
  const t = window.localStorage.getItem(`${keys.twilioAccessTokenKey}-t`);
  if (t && t < +new Date()) {
    clearFromLocalStorage(keys.twilioAccessTokenKey);
    return null;
  }
  if (
    window.localStorage.getItem(`${keys.twilioAccessTokenKey}-caseid`) ===
    patientCaseId
  ) {
    return window.localStorage.getItem(keys.twilioAccessTokenKey);
  }
  return null;
}

/**
 * Set Campaign identifier in localstorage
 * @param id
 * @param isUrl
 */
export function setCampaignId(id, isUrl) {
  window.localStorage.setItem(keys.campaignId, id || '');
  if (isUrl) window.localStorage.setItem(`${keys.campaignId}url`, id);
}

/**
 * Get Campaign identifier from localstorage
 * @return {string|null}
 */
export function getCampaignId() {
  const c = window.localStorage.getItem(keys.campaignId);
  if (c === 'null' || c === null) return null;
  return c;
}

/**
 * Set Region identifier in localstorage
 * @param id
 * @param isUrl
 */
export function setRegionId(id) {
  window.localStorage.setItem(keys.regionId, id || '');
}

/**
 * Get Region identifier from localstorage
 * @return {string|null}
 */
export function getRegionId() {
  const c = window.localStorage.getItem(keys.regionId);
  if (c === 'null' || c === null) return null;
  return c;
}

/**
 * Remove Region identifier from localstorage
 * @return {string|null}
 */
export function clearRegionId() {
  window.localStorage.removeItem(keys.regionId);
}

/**
 * Return boolean indicating whether or not the campaign was set using the URL or not
 * @return {boolean}
 */
export function getCampaignIsUrl() {
  return !!window.localStorage.getItem(`${keys.campaignId}url`);
}

/**
 * Remove campaign identifier and campaignIsUrl
 */
export function removeCampaignId() {
  window.localStorage.removeItem(keys.campaignId);
  window.localStorage.removeItem(`${keys.campaignId}url`);
}

/**
 * Get company copy object from localstorage
 * @return {object | null}
 */
export function getCompanyCopy() {
  let c;
  try {
    c = JSON.parse(window.localStorage.getItem(keys.companyCopyId)) || null;
  } catch (e) {
    c = null;
  }
  return c;
}

/**
 * Set stringified company copy in localstorage
 * @param copy
 */
export function setCompanyCopy(copy) {
  return window.localStorage.setItem(keys.companyCopyId, JSON.stringify(copy));
}

/**
 * Get Member object from localstorage
 * @return {object | null}
 */
export function getMember() {
  let c;
  try {
    c = JSON.parse(window.localStorage.getItem(keys.memberKey)) || null;
  } catch (e) {
    c = null;
  }
  return c;
}

/**
 * Set stringified Member in localstorage
 * @param copy
 */
export function setMember(member) {
  if (member) {
    window.localStorage.setItem(keys.memberKey, JSON.stringify(member));
    setMemberId(member?.id);
  }
}

/**
 * Set stringified Member in localstorage
 * @param copy
 */
export function removeMember() {
  window.localStorage.removeItem(keys.memberKey);
}

/**
 * Set the current theme name
 * @param id
 */
export function setThemeName(id) {
  window.localStorage.setItem(keys.themeName, id || '');
}

/**
 * Get the current theme name
 * @return {string|null}
 */
export function getThemeName() {
  const c = window.localStorage.getItem(keys.themeName);
  if (c === 'null' || c === null) return null;
  return c;
}

/**
 * Remove the current theme name
 * @return {string|null}
 */
export function removeThemeName() {
  window.localStorage.removeItem(keys.themeName);
}

export function setMemberId(id) {
  window.localStorage.setItem(keys.memberIdKey, id);
}

export function getMemberId() {
  return window.localStorage.getItem(keys.memberIdKey);
}

export function setMemberEmail(id) {
  window.localStorage.setItem(keys.memberEmailKey, id);
}

export function getMemberEmail() {
  return window.localStorage.getItem(keys.memberEmailKey);
}

export const setLastSeen = () => {
  localStorage.setItem(keys.lastSeenDateTimeKey, new Date().toUTCString());
};

export const getLastSeen = () => {
  const lastSeen = localStorage.getItem(keys.lastSeenDateTimeKey);
  if (!lastSeen || lastSeen === 'null') return null;
  try {
    return new Date(lastSeen);
  } catch (e) {
    return null;
  }
};

export const removeLastSeen = () => {
  localStorage.removeItem(keys.lastSeenDateTimeKey);
};

export const clearFromLocalStorage = (storageKey, clearExpiryTime = true) => {
  try {
    localStorage.removeItem(storageKey);
    if (clearExpiryTime) {
      localStorage.removeItem(`${storageKey}-t`);
    }
  } catch (e) {
    console.error(e);
  }
};

export function clearUserLocalStorageData() {
  removeAuthToken();
  clearFromLocalStorage(keys.memberIdKey);
  clearFromLocalStorage(keys.memberKey);
  clearFromLocalStorage(keys.twilioAccessTokenKey);
  clearFromLocalStorage(keys.caseResumeKey);
  clearFromLocalStorage(keys.memberEligibilityExpired);
}

/**
 * get company copy map (keyed by campaign identifier or company id)
 * @return {{}}
 */
export function getCampaignOrCompanyIdCopyMap() {
  try {
    const mapString = localStorage.getItem(
      keys.campaignCompanyIdToCompanyCopyMap,
    );
    if (mapString && mapString !== 'null') {
      return JSON.parse(mapString);
    }
  } catch (e) {
    return {};
  }
  return {};
}

/**
 * Set company copy map, keyed by campaign identifier or company id
 * @param campaignOrCompanyId
 * @param copy
 * @return {{}|null}
 */
export function setCampaignOrCompanyIdCopyMap(campaignOrCompanyId, copy) {
  const map = getCampaignOrCompanyIdCopyMap();
  if (!copy) return map;
  map[campaignOrCompanyId] = copy;
  localStorage.setItem(
    keys.campaignCompanyIdToCompanyCopyMap,
    JSON.stringify(map),
  );
  return map;
}

export function setDRedirect(d) {
  localStorage.setItem(keys.dRedir, d);
}

export function getDRedirect() {
  return localStorage.getItem(keys.dRedir);
}

export function removeDRedirect() {
  localStorage.removeItem(keys.dRedir);
}

export function setEdiEnabled(id) {
  window.localStorage.setItem(keys.ediEnabled, id.toString());
}

export function getEdiEnabled() {
  return JSON.parse(window.localStorage.getItem(keys.ediEnabled));
}

export function setCamSettings(settings) {
  window.localStorage.setItem(keys.camSettings, JSON.stringify(settings));
}

export function getCamSettings() {
  try {
    const settings = window.localStorage.getItem(keys.camSettings);
    if (!settings) return null;
    const _settings = JSON.parse(settings);
    if (
      !_settings.__v ||
      Math.parseFloat(_settings.__v) <
        Math.parseFloat(window.env.CAMERA_SETTINGS_VERSION)
    ) {
      return null;
    }
    return _settings;
  } catch (e) {
    return null;
  }
}

export const setLocale = (l) =>
  localStorage.setItem(keys.locale, l || DEFAULT_LOCALE);
export const setCountry = (l) =>
  localStorage.setItem(keys.country, l || DEFAULT_COUNTRY);
export const setCurrency = (l) =>
  localStorage.setItem(keys.currency, l || DEFAULT_CURRENCY);

export const getLocale = () => localStorage.getItem(keys.locale);
export const getCountry = () => localStorage.getItem(keys.country);
export const getCurrency = () => localStorage.getItem(keys.currency);

export const setReferrer = (l) =>
  localStorage.setItem(keys.referrer, l || 'member');
export const getReferrer = () => localStorage.getItem(keys.referrer);

export const loadCaseStages = (id) => {
  const allSteps = localStorage.getItem(keys.caseStages);
  if (allSteps) {
    try {
      const stepsMap = JSON.parse(allSteps);
      return stepsMap[id] || null;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export function setMemberEligibilityExpired(l) {
  localStorage.setItem(keys.memberEligibilityExpired, l);
}
export function getMemberEligibilityExpired() {
  return (
    JSON.parse(localStorage.getItem(keys.memberEligibilityExpired)) || false
  );
}
export function getAccountQuestionSetComplete(memberId) {
  return (
    !!localStorage.getItem(keys.accountQuestionSetCompleteMemberId) === memberId
  );
}

export function setAccountQuestionSetComplete(memberId) {
  localStorage.setItem(keys.accountQuestionSetCompleteMemberId, memberId);
}
