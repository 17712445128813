import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QUIP_THEME_ID } from '@oraleye/frontend-modules-core';
import ButtonCard from 'components/ButtonCard';
import Link from 'components/Link';
import { Card, CardContent, CardTitle } from 'components/common/Card';
import {
  CASE_TEMPLATE_COMING_SOON_STATUS,
  CASE_TEMPLATE_COMPLETE_STATUS,
  TRIAGE_CASE_TYPE,
} from 'constants/cases';
import {
  LD_UI_GET_CARE_SHOW_ALTERNATIVE_PRICE,
  LD_UI_GET_CARE_SHOW_PRICING,
  LD_UI_THEME_FLAG,
} from 'constants/launchDarklyFlags';
import { CREATE_TRIAGE_CASE } from 'constants/pageIdentifiers';
import { selectPatientCaseTemplates } from 'containers/GetCare/selectors';
import FullDentalCheckIcon from 'images/full-dental-check.svg';
import FullDentalCheckQuipIcon from 'images/quip/full-dental-check.svg';
import SpecificIssueQuipIcon from 'images/quip/specific-issue.svg';
import VideoConsultQuipIcon from 'images/quip/video-consult.svg';
import SpecificIssueIcon from 'images/specific-issue.svg';
import VideoConsultIcon from 'images/video-consult.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import tracking from 'tracking';
import messages from './messages';
import {
  BoldSubTitle,
  IconContainer,
  PricingContainer,
  StyledAlternativePrice,
  StyledPrice,
} from './styled';

export const TriageCaseCard = ({ messagesFile }) => {
  const patientCaseTemplates = useSelector(selectPatientCaseTemplates());
  const hasTriage = useMemo(
    () =>
      !!patientCaseTemplates.find(
        (pct) => pct.template_base_name === TRIAGE_CASE_TYPE,
      ),
    [patientCaseTemplates],
  );

  return hasTriage ? (
    <Link to={CREATE_TRIAGE_CASE} trackingId={tracking.homeView.triageClicked}>
      <Card>
        <CardTitle>
          <IconContainer color="var(--chartandvisualdata05)">
            <FontAwesomeIcon icon={['fa', 'plus-square']} />
          </IconContainer>
          <FormattedMessage {...messagesFile.triage} />
          <FontAwesomeIcon icon={['fa', 'chevron-right']} />
        </CardTitle>
        <CardContent>
          <FormattedMessage {...messagesFile.triageContent} />
        </CardContent>
      </Card>
    </Link>
  ) : null;
};

TriageCaseCard.propTypes = {
  messagesFile: PropTypes.shape({
    triage: PropTypes.any,
    triageContent: PropTypes.any,
  }),
};

export const PhotoSubmissionCard = ({
  photoSubmissionClick,
  completeCareCaseTemplate,
  isEligible,
  messagesFile,
}) => {
  const flags = useFlags();
  const cardIcon = useMemo(() => {
    if (flags[LD_UI_THEME_FLAG] === QUIP_THEME_ID) {
      return FullDentalCheckQuipIcon;
    } else {
      return FullDentalCheckIcon;
    }
  }, [flags]);

  const photoSubmissionTitle = useMemo(() => {
    if (isEligible || !completeCareCaseTemplate?.base_price) {
      return messagesFile.photoSubmission;
    } else {
      return messagesFile.photoSubmissionWithPrice;
    }
  }, [isEligible, completeCareCaseTemplate?.base_price, messagesFile]);

  return (
    <ButtonCard
      trackingId={tracking.getCare.completeCareLinkClicked}
      title={
        <FormattedMessage
          {...photoSubmissionTitle}
          values={{
            price: completeCareCaseTemplate?.base_price / 100,
          }}
        />
      }
      contentHeader={
        flags[LD_UI_GET_CARE_SHOW_PRICING] ? (
          <PricingContainer>
            {flags[LD_UI_GET_CARE_SHOW_ALTERNATIVE_PRICE] ? (
              <FormattedMessage
                {...messages.cardPriceWithAlternative}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: completeCareCaseTemplate?.price / 100,
                  b: (text) => (
                    <StyledAlternativePrice>{text}</StyledAlternativePrice>
                  ),
                  alternativePrice: 0,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.cardPrice}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: completeCareCaseTemplate?.price / 100,
                }}
              />
            )}
          </PricingContainer>
        ) : null
      }
      content={
        <FormattedMessage
          {...messagesFile.photoSubmissionDetail}
          values={{
            b: (text) => <BoldSubTitle>{text}</BoldSubTitle>,
          }}
        />
      }
      icon={cardIcon}
      buttonText={<FormattedMessage {...messagesFile.photoSubmissionButton} />}
      onButtonClick={photoSubmissionClick}
    />
  );
};

PhotoSubmissionCard.propTypes = {
  completeCareCaseTemplate: PropTypes.any,
  isEligible: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  messagesFile: PropTypes.any,
  photoSubmissionClick: PropTypes.func.isRequired,
};

export const SpecificIssueCard = ({
  photoSubmissionClick,
  messagesFile,
  specificIssueCaseTemplate,
}) => {
  const flags = useFlags();
  const cardIcon = useMemo(() => {
    if (flags[LD_UI_THEME_FLAG] === QUIP_THEME_ID) {
      return SpecificIssueQuipIcon;
    } else {
      return SpecificIssueIcon;
    }
  }, [flags]);

  return (
    <ButtonCard
      trackingId={tracking.getCare.specificIssueLinkClicked}
      title={<FormattedMessage {...messagesFile.specificIssueTitle} />}
      contentHeader={
        flags[LD_UI_GET_CARE_SHOW_PRICING] ? (
          <PricingContainer>
            {flags[LD_UI_GET_CARE_SHOW_ALTERNATIVE_PRICE] ? (
              <FormattedMessage
                {...messages.cardPriceWithAlternative}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: specificIssueCaseTemplate?.price / 100,
                  b: (text) => (
                    <StyledAlternativePrice>{text}</StyledAlternativePrice>
                  ),
                  alternativePrice: 0,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.cardPrice}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: specificIssueCaseTemplate?.price / 100,
                }}
              />
            )}
          </PricingContainer>
        ) : null
      }
      content={
        <FormattedMessage
          {...messagesFile.specificIssueContent}
          values={{
            b: (text) => <BoldSubTitle>{text}</BoldSubTitle>,
          }}
        />
      }
      icon={cardIcon}
      buttonText={<FormattedMessage {...messagesFile.photoSubmissionButton} />}
      onButtonClick={photoSubmissionClick}
    />
  );
};

SpecificIssueCard.propTypes = {
  messagesFile: PropTypes.any,
  photoSubmissionClick: PropTypes.func.isRequired,
  specificIssueCaseTemplate: PropTypes.any,
};

export const VideoSubmissionCard = ({
  startCall,
  videoCaseTemplate,
  messagesFile,
}) => {
  const flags = useFlags();
  const cardIcon = useMemo(() => {
    if (flags[LD_UI_THEME_FLAG] === QUIP_THEME_ID) {
      return VideoConsultQuipIcon;
    } else {
      return VideoConsultIcon;
    }
  }, [flags]);

  const _startCall = useCallback(() => {
    startCall(videoCaseTemplate);
  }, [startCall, videoCaseTemplate]);
  return videoCaseTemplate ? (
    <ButtonCard
      title={<FormattedMessage {...messagesFile.videoConsultTitle} />}
      disabled={videoCaseTemplate?.status !== CASE_TEMPLATE_COMPLETE_STATUS}
      trackingId={tracking.getCare.videoLinkClicked}
      contentHeader={
        flags[LD_UI_GET_CARE_SHOW_PRICING] ? (
          <PricingContainer>
            {flags[LD_UI_GET_CARE_SHOW_ALTERNATIVE_PRICE] ? (
              <FormattedMessage
                {...messages.cardPriceWithAlternative}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: videoCaseTemplate?.price / 100,
                  b: (text) => (
                    <StyledAlternativePrice>{text}</StyledAlternativePrice>
                  ),
                  alternativePrice: 0,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.cardPrice}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: videoCaseTemplate?.price / 100,
                }}
              />
            )}
          </PricingContainer>
        ) : null
      }
      content={
        <FormattedMessage
          {...messagesFile[
            videoCaseTemplate?.status === CASE_TEMPLATE_COMING_SOON_STATUS
              ? 'videoConsultComingSoon'
              : 'videoConsultDetail'
          ]}
          values={{
            b: (text) => <BoldSubTitle>{text}</BoldSubTitle>,
          }}
        />
      }
      icon={cardIcon}
      buttonText={<FormattedMessage {...messagesFile.videoConsult} />}
      onButtonClick={_startCall}
    />
  ) : null;
};

VideoSubmissionCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  messagesFile: PropTypes.any,
  startCall: PropTypes.func.isRequired,
  videoCaseTemplate: PropTypes.object,
};

export const ScheduledVideoSubmissionCard = ({
  startCall,
  scheduledVideoCaseTemplate,
  isEligible,
  messagesFile,
}) => {
  const flags = useFlags();
  const cardIcon = useMemo(() => {
    if (flags[LD_UI_THEME_FLAG] === QUIP_THEME_ID) {
      return VideoConsultQuipIcon;
    } else {
      return VideoConsultIcon;
    }
  }, [flags]);

  const _startCall = useCallback(() => {
    startCall(scheduledVideoCaseTemplate);
  }, [startCall, scheduledVideoCaseTemplate]);

  const cardTitle = useMemo(() => {
    if (isEligible || !scheduledVideoCaseTemplate?.base_price) {
      return messagesFile.scheduledVideoConsultTitle;
    } else {
      return messagesFile.scheduledVideoConsultTitleWithPrice;
    }
  }, [isEligible, scheduledVideoCaseTemplate?.base_price, messagesFile]);

  return scheduledVideoCaseTemplate ? (
    <ButtonCard
      title={
        <FormattedMessage
          {...cardTitle}
          values={{
            price: scheduledVideoCaseTemplate?.base_price / 100,
          }}
        />
      }
      disabled={
        scheduledVideoCaseTemplate?.status !== CASE_TEMPLATE_COMPLETE_STATUS
      }
      trackingId={tracking.getCare.scheduledVideoLinkClicked}
      contentHeader={
        flags[LD_UI_GET_CARE_SHOW_PRICING] ? (
          <PricingContainer>
            {flags[LD_UI_GET_CARE_SHOW_ALTERNATIVE_PRICE] ? (
              <FormattedMessage
                {...messages.cardPriceWithAlternative}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: scheduledVideoCaseTemplate?.price / 100,
                  b: (text) => (
                    <StyledAlternativePrice>{text}</StyledAlternativePrice>
                  ),
                  alternativePrice: 0,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.cardPrice}
                values={{
                  a: (text) => <StyledPrice>{text}</StyledPrice>,
                  originalPrice: scheduledVideoCaseTemplate?.price / 100,
                }}
              />
            )}
          </PricingContainer>
        ) : null
      }
      content={
        <FormattedMessage
          {...messagesFile[
            scheduledVideoCaseTemplate?.status ===
            CASE_TEMPLATE_COMING_SOON_STATUS
              ? 'scheduledVideoConsultComingSoon'
              : 'scheduledVideoConsultDetail'
          ]}
          values={{
            b: (text) => <BoldSubTitle>{text}</BoldSubTitle>,
          }}
        />
      }
      icon={cardIcon}
      buttonText={<FormattedMessage {...messagesFile.scheduledVideoConsult} />}
      onButtonClick={_startCall}
    />
  ) : null;
};

ScheduledVideoSubmissionCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  messagesFile: PropTypes.any,
  scheduledVideoCaseTemplate: PropTypes.object,
  startCall: PropTypes.func.isRequired,
  isEligible: PropTypes.bool,
};
