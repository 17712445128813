import { parseUrl } from 'query-string';
import {
  clearUserLocalStorageData,
  getCampaignId,
  removeCampaignId,
  removeCompanyId,
  setCampaignId,
} from 'utils/localStorageHelper';
import { clearUserSessionStorageData } from 'utils/sessionStorageHelper';

/**
 * Get the campaign identifier in the url query parameters, if it exists
 * @return {string | null}
 */
export function getQueryCampaignIdentifier() {
  const qs = parseUrl(window.location.href).query;
  let campaignIdentifier = qs.campaign_identifier || qs.ci;
  return campaignIdentifier || null;
}

/**
 * if there is an existing campaign identifier, we will find it here
 * @return {{campaignIsUrl: boolean, campaignId: null}|{campaignIsUrl: boolean, campaignId: string}}
 */
export function checkQueryCampaignIdentifier() {
  let isUrl = false;
  let campaignId = getQueryCampaignIdentifier();
  if (campaignId) {
    isUrl = true;
    const oldCampaignId = getCampaignId();
    if (oldCampaignId !== campaignId) {
      clearUserLocalStorageData();
      clearUserSessionStorageData();
      removeCompanyId();
    }
  } else {
    campaignId = getCampaignId();
  }
  if (campaignId) {
    setCampaignId(campaignId, isUrl);
    return { campaignId, campaignIsUrl: isUrl };
  } else {
    removeCampaignId();
    return { campaignId: null, campaignIsUrl: false };
  }
}
