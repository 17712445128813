/*
 * SignUp Messages
 *
 * This contains all the text for the SignUp container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SignUp';

export default defineMessages({
  documentTitle: {
    id: `${scope}.documentTitle`,
    defaultMessage: '{company} | Create Your Account',
  },
  leftSideTitle: {
    id: `${scope}.leftSideTitle`,
    defaultMessage: 'Connect with a dentist 24/7',
  },
  createAccount: {
    id: `${scope}.createAccount`,
    defaultMessage: 'Create Account',
  },
});
