/*
 *
 * Consultation constants
 *
 */

export const LOAD_CONSULTATION_DATA = 'app/Consultation/LOAD_CONSULTATION_DATA';
export const LOAD_CONSULTATION_DATA_SUCCESS =
  'app/Consultation/LOAD_CONSULTATION_DATA_SUCCESS';
export const UPDATE_CONSULTATION_STAGE_DATA =
  'app/Consultation/UPDATE_CONSULTATION_STAGE_DATA';
export const LOAD_CONSULTATION_DATA_FAILURE =
  'app/Consultation/LOAD_CONSULTATION_DATA_FAILURE';

export const RESET_UPSERT_VIDEO_CALL =
  'app/Consultation/RESET_CONSULTATION_VIDEO_CALL';
export const UPSERT_VIDEO_CALL =
  'app/Consultation/UPSERT_CONSULTATION_VIDEO_CALL';
export const UPSERT_VIDEO_CALL_SUCCESS =
  'app/Consultation/UPSERT_CONSULTATION_VIDEO_CALL_SUCCESS';
export const UPSERT_VIDEO_CALL_FAILURE =
  'app/Consultation/UPSERT_CONSULTATION_VIDEO_CALL_FAILURE';

export const SET_CONSULTATION_STAGES =
  'app/Consultation/SET_CONSULTATION_STAGES';
export const ACCOUNT_STAGE = 'account_questions';
export const MEMBER_STAGE = 'member_questions';
export const INSURANCE_STAGE = 'insurance';
export const CASE_QUESTIONS_STAGE = 'case_questions';
export const PHOTOS_STAGE = 'photos';
export const CHECKOUT_STAGE = 'checkout';
export const VIDEO_STAGE = 'video';
export const PHARMACY_STAGE = 'pharmacy';
export const MEDICAL_INFORMATION = 'medical-information';
export const OVERALL_STAGES_ORDER = [
  ACCOUNT_STAGE,
  MEMBER_STAGE,
  INSURANCE_STAGE,
  CASE_QUESTIONS_STAGE,
  PHOTOS_STAGE,
  CHECKOUT_STAGE,
  VIDEO_STAGE,
];
export const DEFAULT_STAGE_DATA_CACHE_TIME = 5 * 1000 * 60; // 5 mins
