/*
 *
 * MemberProfile reducer
 *
 */

import { getMember, setMember } from 'utils/localStorageHelper';
import { addData } from 'utils/reducerHelpers';
import {
  FETCH_MEMBER,
  FETCH_MEMBER_FAILURE,
  FETCH_MEMBER_SUCCESS,
} from '../Auth/AuthRouter/constants';
import {
  CREATE_ELIGIBILITY_REQUEST,
  CREATE_ELIGIBILITY_REQUEST_FAILURE,
  CREATE_ELIGIBILITY_REQUEST_SUCCESS,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES_FAILURE,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES_SUCCESS,
  FETCH_MEMBER_ELIGIBILITIES,
  FETCH_MEMBER_ELIGIBILITIES_FAILURE,
  FETCH_MEMBER_ELIGIBILITIES_SUCCESS,
  FETCH_MEMBER_INSURANCE_DETAILS,
  FETCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
  FETCH_REGIONS,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_SUCCESS,
  PATCH_MEMBER,
  PATCH_MEMBER_FAILURE,
  PATCH_MEMBER_INSURANCE_DETAILS,
  PATCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  PATCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
  PATCH_MEMBER_SUCCESS,
} from './constants';

export const initialState = () => ({
  memberInsuranceDetails: {
    loading: false,
    data: null,
    fetched: false,
    updated: false,
    error: null,
  },
  coverageProviderPlanCodes: {
    loading: false,
    data: [],
    fetched: false,
  },
  member: {
    loading: false,
    data: getMember(),
    fetched: false,
  },
  regions: {
    loading: false,
    data: [],
    fetched: false,
  },
  memberEligibility: {
    loading: false,
    data: {},
    fetched: false,
  },
  eligibilityRequest: {
    loading: false,
    data: {},
  },
});

function memberProfileReducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_MEMBER:
      return {
        ...state,
        member: {
          ...state.member,
          loading: true,
        },
        memberUpdated: false,
      };
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        member: {
          ...state.member,
          data: action.payload,
          loading: false,
          fetched: true,
        },
        memberUpdated: false,
      };
    case FETCH_MEMBER_FAILURE:
      return {
        ...state,
        member: {
          ...state.member,
          loading: false,
          error: action.error,
        },
        memberUpdated: false,
      };
    case FETCH_MEMBER_INSURANCE_DETAILS:
      return {
        ...state,
        memberInsuranceDetails: {
          ...state.memberInsuranceDetails,
          loading: true,
          updated: false,
          fetched: false,
          error: null,
        },
      };
    case FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS:
      return {
        ...state,
        memberInsuranceDetails: {
          ...state.memberInsuranceDetails,
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      };
    case FETCH_MEMBER_INSURANCE_DETAILS_FAILURE:
      return {
        ...state,
        memberInsuranceDetails: {
          ...state.memberInsuranceDetails,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_COVERAGE_PROVIDER_PLAN_CODES:
      return {
        ...state,
        coverageProviderPlanCodes: {
          ...state.coverageProviderPlanCodes,
          loading: true,
        },
      };
    case FETCH_COVERAGE_PROVIDER_PLAN_CODES_SUCCESS:
      return {
        ...state,
        coverageProviderPlanCodes: {
          ...state.coverageProviderPlanCodes,
          data: addData(state.coverageProviderPlanCodes.data, action.payload),
          loading: false,
          fetched: true,
        },
      };
    case FETCH_COVERAGE_PROVIDER_PLAN_CODES_FAILURE:
      return {
        ...state,
        coverageProviderPlanCodes: {
          ...state.coverageProviderPlanCodes,
          loading: false,
          error: action.error,
        },
      };
    case PATCH_MEMBER:
      return {
        ...state,
        member: {
          ...state.member,
          loading: false,
        },
      };
    case PATCH_MEMBER_SUCCESS:
      setMember(action.payload);
      return {
        ...state,
        member: {
          ...state.member,
          data: action.payload,
          loading: false,
          fetched: true,
        },
      };
    case PATCH_MEMBER_FAILURE:
      return {
        ...state,
        member: {
          ...state.member,
          loading: false,
          error: action.error,
        },
      };
    case PATCH_MEMBER_INSURANCE_DETAILS:
      return {
        ...state,
        memberInsuranceDetails: {
          ...state.memberInsuranceDetails,
          loading: true,
          updated: false,
          fetched: false,
          error: null,
        },
      };
    case PATCH_MEMBER_INSURANCE_DETAILS_SUCCESS:
      return {
        ...state,
        memberInsuranceDetails: {
          ...state.memberInsuranceDetails,
          data: action.payload,
          loading: false,
          fetched: true,
          updated: true,
          error: null,
        },
      };
    case PATCH_MEMBER_INSURANCE_DETAILS_FAILURE:
      return {
        ...state,
        memberInsuranceDetails: {
          ...state.memberInsuranceDetails,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_REGIONS:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: true,
        },
      };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: {
          ...state.regions,
          data: addData(state.regions.data, action.payload),
          loading: false,
          fetched: true,
        },
      };
    case FETCH_REGIONS_FAILURE:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MEMBER_ELIGIBILITIES:
      return {
        ...state,
        memberEligibility: {
          ...state.memberEligibility,
          loading: true,
          fetched: false,
        },
      };
    case FETCH_MEMBER_ELIGIBILITIES_SUCCESS:
      return {
        ...state,
        memberEligibility: {
          ...state.memberEligibility,
          data: action.payload,
          loading: false,
          fetched: true,
        },
        member: {
          ...state.member,
          updated: false,
        },
      };
    case FETCH_MEMBER_ELIGIBILITIES_FAILURE:
      return {
        ...state,
        memberEligibility: {
          ...state.memberEligibility,
          loading: false,
          fetched: true,
          error: action.error,
        },
      };
    case CREATE_ELIGIBILITY_REQUEST:
      return {
        ...state,
        eligibilityRequest: {
          ...state.eligibilityRequest,
          loading: true,
        },
        // This value needs to be reset so that the eligibility modal
        // doesn't show the wrong message on the second trigger. There
        // is probably a better way to do this.
        memberEligibility: {
          ...initialState(),
        },
      };
    case CREATE_ELIGIBILITY_REQUEST_SUCCESS:
      return {
        ...state,
        eligibilityRequest: {
          ...state.eligibilityRequest,
          data: action.payload,
          loading: false,
        },
      };
    case CREATE_ELIGIBILITY_REQUEST_FAILURE:
      return {
        ...state,
        eligibilityRequest: {
          ...state.eligibilityRequest,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export default memberProfileReducer;
