import {
  asyncWithLDProvider,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { createLDUser } from './user';

export const getFlags = () =>
  new Promise((resolve) => {
    resolve(window.ldClient.allFlags());
  });

export const SetLDProvider = () => {
  window.ldClient = useLDClient();
  return null;
};

export const initialiseFeatureFlags = async () => {
  const ldUser = createLDUser();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: window.env.LAUNCH_DARKLY_CLIENT_ID,
    user: ldUser,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },

    options: {
      privateAttributeNames: ['email'],
      useReport: true,
      bootstrap: 'localStorage',
    },
  });

  return LDProvider;
};
