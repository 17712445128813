import FormattedHelmetTitle from 'components/FormattedHelmetTitle';
import Config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import messages from './messages';

const Wrapper = styled.div`
  opacity: 1;
  max-width: 680px;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  font-family: Arial, sans-serif;
  height: 100%;

  h1 {
    color: #16b79d;
  }

  p {
    color: #1d1d1d;
  }

  .cardContainer {
    /*height: 100vh;*/
    /*width: 100vw;*/
    text-align: center;
    padding: 20px;
    flex: 1;
  }

  .loginCard {
    position: relative;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
  }

  .innerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .input {
    max-width: 365px;
    margin: 16px 0 !important;
  }

  .button {
    color: #eeeeee !important;
    margin-top: 16px;
  }

  .resetButton {
    margin-top: 20px !important;
    color: var(--error) !important;
    float: left;
    text-align: left;
    text-transform: capitalize !important;
  }

  .loadingContainer {
    position: fixed;
    top: 50%;
    left: 50%;
  }

  .logo {
    width: 240px;
    height: 169px;
    margin: 40px auto;
  }

  .loginButton {
    text-transform: capitalize;
  }

  .instructions {
    width: 365px;
    margin-bottom: 20px;
  }

  .disabled {
    background-color: #9d9eab;
    cursor: not-allowed;
  }

  .exclamation {
    background-color: #fc5050;
    width: 87px;
    height: 87px;
    color: white;
    font-size: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 87px;
    margin: 30px auto;
  }
`;

export const ErrorView = ({
  onErrorDismiss,
  title,
  message,
  redirect = '/',
  backButton,
}) => {
  return (
    <Wrapper>
      <h1>{title}</h1>
      <div className="exclamation">!</div>
      {message && <p>{message}</p>}
      <br />
      {backButton && (
        <a
          href={redirect}
          style={{ color: 'var(--primary01)' }}
          onClick={onErrorDismiss}
        >
          Go back
        </a>
      )}
    </Wrapper>
  );
};

ErrorView.defaultProps = {
  onErrorDismiss: () => {},
};

ErrorView.propTypes = {
  backButton: PropTypes.any,
  error: PropTypes.any,
  location: PropTypes.any,
  message: PropTypes.any,
  onErrorDismiss: PropTypes.any,
  redirect: PropTypes.string,
  title: PropTypes.any,
};
