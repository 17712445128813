/*
 *
 * VideoCall constants
 *
 */

export const START_VIDEO_CALL_ACTION = 'VideoCall/START_VIDEO_CALL_ACTION';
export const START_VIDEO_CALL_ACTION_SUCCESS =
  'VideoCall/START_VIDEO_CALL_ACTION_SUCCESS';
export const START_VIDEO_CALL_ACTION_FAILURE =
  'VideoCall/START_VIDEO_CALL_ACTION_FAILURE';

export const GET_VIDEO_CALL_ACTION = 'VideoCall/GET_VIDEO_CALL_ACTION';
export const GET_VIDEO_CALL_SUCCESS_ACTION =
  'VideoCall/GET_VIDEO_CALL_SUCCESS_ACTION';
export const GET_VIDEO_CALL_FAILURE_ACTION =
  'VideoCall/GET_VIDEO_CALL_FAILURE_ACTION';

export const FETCH_ASSESSOR = 'VideoCall/FETCH_ASSESSOR';
export const FETCH_ASSESSOR_SUCCESS = 'VideoCall/FETCH_ASSESSOR_SUCCESS';
export const FETCH_ASSESSOR_FAILURE = 'VideoCall/FETCH_ASSESSOR_FAILURE';

export const END_VIDEO_CALL = 'VideoCall/END_VIDEO_CALL';
export const END_VIDEO_CALL_SUCCESS = 'VideoCall/END_VIDEO_CALL_SUCCESS';
export const END_VIDEO_CALL_FAILURE = 'VideoCall/END_VIDEO_CALL_FAILURE';

export const RESET_ASSESSOR = 'VideoCall/RESET_ASSESSOR';
export const RESET_START_VIDEO_CALL_STATUS =
  'VideoCall/RESET_START_VIDEO_CALL_STATUS';
export const RESET_VIDEO_CALL_DETAILS = 'VideoCall/RESET_VIDEO_CALL_DETAILS';

export const START_VIDEO_CALL_LOADING_STATUS =
  'START_VIDEO_CALL_LOADING_STATUS';
export const START_VIDEO_CALL_SUCCESS_STATUS =
  'START_VIDEO_CALL_SUCCESS_STATUS';
export const START_VIDEO_CALL_FAILURE_STATUS =
  'START_VIDEO_CALL_FAILURE_STATUS';
export const CALL_PREVIEW = 'call_preview';
export const CALL_LOADING = 'call_loading';
export const WAITING_ROOM = 'waiting_room';
export const CALL_IN_PROGRESS = 'call_in_progress';
export const CALL_HUNG_UP = 'call_hung_up';
export const CALL_COMPLETED = 'call_completed';
