import Config from 'config';
import { DEFAULT_CAMPAIGN_IDENTIFIER } from 'containers/LaunchDarklyUpdater/constants';
import {
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
} from 'locale-constants';
import { parseUrl } from 'query-string';
import {
  BCBS_THEME_NAME,
  DEFAULT_THEME_NAME,
  DELTA_THEME_NAME,
  PROFESSIONAL_THEME_NAME,
  QUIP_THEME_NAME,
  TOOTHPIC_THEME_NAME,
} from 'services/InitialisationService/constants';
import {
  getCampaignOrCompanyIdCopyMap,
  getCompanyId,
  setCampaignId,
  setCampaignOrCompanyIdCopyMap,
  setCompanyCopy,
  setCompanyId,
  setCountry,
  setCurrency,
  setLocale,
  setThemeName,
} from 'utils/localStorageHelper';
import request from 'utils/request';

export const fetchCompanyCopyByCampaignIdentifierRequest = (
  campaignIdentifier,
) => {
  const url = `v3/company_copies?campaign_identifier=${decodeURIComponent(
    campaignIdentifier,
  )}`;
  const headers = new Headers();
  const options = {
    method: 'GET',
    headers,
  };
  return request(url, options).then((data) => (data && data[0]) || null);
};

export const fetchCompanyCopyByCompanyIdRequest = (companyId) => {
  const url = `v3/company_copies?company_id=${companyId}`;
  const headers = new Headers();
  const options = {
    method: 'GET',
    headers,
  };
  return request(url, options).then((data) => (data && data[0]) || null);
};

export const fetchCompanyCopyByCampaignIdentifierOrCompanyId = (
  campaignIdentifier,
  companyId,
) => {
  let p;
  if (campaignIdentifier) {
    p = fetchCompanyCopyByCampaignIdentifierRequest(campaignIdentifier);
  } else if (companyId) {
    p = fetchCompanyCopyByCompanyIdRequest(companyId);
  } else {
    window.Rollbar?.error('No campaign identifier found; using default', {
      campaignIdentifier,
      DEFAULT_CAMPAIGN_IDENTIFIER,
    });
    p = fetchCompanyCopyByCampaignIdentifierRequest(
      DEFAULT_CAMPAIGN_IDENTIFIER,
    );
  }
  p.then((copy) => {
    if (!copy) {
      window.Rollbar?.error(
        'No company copy for campaign identifier, using default instead',
        {
          campaignIdentifier,
          DEFAULT_CAMPAIGN_IDENTIFIER,
        },
      );
      setCampaignId(DEFAULT_CAMPAIGN_IDENTIFIER, true);
      return fetchCompanyCopyByCampaignIdentifierRequest(
        DEFAULT_CAMPAIGN_IDENTIFIER,
      );
    }
    return copy;
  });
  return p;
};

export function updateConfigForCopy(_copy) {
  let copy = _copy;
  if (!copy) copy = { theme_name: DEFAULT_THEME_NAME };
  const theme = copy.theme_name.toLowerCase();
  Config.isToothpic = !!(
    theme === TOOTHPIC_THEME_NAME || theme === DEFAULT_THEME_NAME
  );
  Config.isProfessional = !!(theme === PROFESSIONAL_THEME_NAME);
  Config.isBCBS = !!(theme === BCBS_THEME_NAME);
  Config.isDelta = !!(theme === DELTA_THEME_NAME);
  Config.isVideo = !!copy.video_call_enabled;
  Config.isQuip = !!(theme === QUIP_THEME_NAME);
  Config.supportEmail =
    copy.support_email || theme === QUIP_THEME_NAME
      ? 'quip@toothpic.com'
      : 'support@toothpic.com';
  Config.companyName =
    theme === QUIP_THEME_NAME || theme === DELTA_THEME_NAME
      ? 'quip'
      : 'Toothpic';
  Config.documentTitle =
    theme === DELTA_THEME_NAME
      ? 'Virtual dental screenings'
      : 'Online Dental Consultations';
}

export const updateThemeName = (copy) => {
  updateConfigForCopy(copy);
  setThemeName(copy?.theme_name || 'default');
};

export const updateLocaleData = (copy) => {
  setLocale(copy?.locale || DEFAULT_LOCALE);
  setCurrency(copy?.currency || DEFAULT_CURRENCY);
  setCountry(copy?.country || DEFAULT_COUNTRY);
};

/**
 * Get the campaign identifier in the url query parameters, if it exists
 * @return {string | null}
 */
export function getQueryCompanyId() {
  const qs = parseUrl(window.location.href).query;
  let companyId = qs.company_id || qs.cmp;
  if (companyId) {
    setCompanyId(companyId);
  }
  return companyId || null;
}

/**
 * Get the campaign identifier in the url query parameters, or localstorage, if it exists
 * @return {string | null}
 */
export function getQueryOrStoredCompanyId() {
  return getQueryCompanyId() || getCompanyId() || null;
}

/**
 * New attrs added to the copy, we need to check if we should replace here
 * @param copy
 * @return {boolean}
 */
const isValidCopy = (copy) => {
  return (
    copy.hasOwnProperty('locale') &&
    copy.hasOwnProperty('currency') &&
    copy.hasOwnProperty('country') &&
    copy.hasOwnProperty('data_terms_url')
  );
};

/**
 * Fetch the company copy using the campaign identifier and company id stored in localstorage
 */
export function checkCompanyCopy({ campaignId, companyId }) {
  const existingCopyMap = getCampaignOrCompanyIdCopyMap();
  let p, copy;
  if (campaignId && existingCopyMap.hasOwnProperty(campaignId)) {
    copy = existingCopyMap[campaignId];
    if (isValidCopy(copy)) {
      p = Promise.resolve(copy);
    }
  } else if (companyId && existingCopyMap.hasOwnProperty(companyId)) {
    copy = existingCopyMap[companyId];
    if (isValidCopy(copy)) {
      p = Promise.resolve(copy);
    }
  }
  if (!p) {
    p = fetchCompanyCopyByCampaignIdentifierOrCompanyId(campaignId, companyId);
  }
  return p.then((copy) => {
    updateConfigForCopy(copy);
    updateLocaleData(copy);
    updateThemeName(copy);
    setCompanyCopy(copy);
    campaignId && setCampaignOrCompanyIdCopyMap(campaignId, copy);
    companyId && setCampaignOrCompanyIdCopyMap(companyId, copy);
    return copy;
  });
}
