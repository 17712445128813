import uuid from 'uniqid';

let dbSplint = {};
export default () => ({
  useIndexedDB: () => ({
    add: (photoDetails) => {
      const id = uuid();
      dbSplint[id] = photoDetails;
      return Promise.resolve(id);
    },
    clear: (dbName) => {
      dbSplint = {};
      return Promise.resolve(dbSplint);
    },
    deleteRecord: (databaseId) => {
      delete dbSplint[databaseId];
      return dbSplint;
    },
    getAll: () => {
      return Promise.resolve(Object.values(dbSplint));
    },
    getDB: () => dbSplint,
  }),
  initDB: (dbName) => {
    return Promise.resolve(dbSplint);
  },
});
