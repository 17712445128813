import { InputError } from '@oraleye/frontend-modules-components';
import { Label } from '@oraleye/frontend-modules-core';
import messages from 'components/QuestionCard/messages';
import { selectCountry } from 'containers/LanguageProvider/selectors';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-number-input/mobile';
import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import { eventSend } from 'services/EventsService';
import events from 'services/EventsService/events.json';
import styled, { css } from 'styled-components';

const n = navigator.language.split('-');
let country = n[1] || n[0];
if (country === 'en') {
  country = 'US';
}
country = country.toUpperCase();

const PHONE_COUNTRY_OPTIONS = ['US', 'IE', 'GB', 'CA'];

const FormPhone = styled(PhoneInput)`
  label {
    color: var(--neutral05);
    font-size: 14px;
    text-align: left;
    line-height: 1.5;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 400;
  }
  input {
    //width: 100%;
    color: var(--neutral07) !important;
    z-index: 10;
    padding: 12px;
    font-family: inherit;
    border-top-right-radius: var(--border-radius-input);
    border-bottom-right-radius: var(--border-radius-input);
    font-size: 16px;
    border: none;
    height: 100%;
    font-weight: 300;

    &:active,
    &:focus,
    &:hover {
      border-color: var(--primary01) !important;
      box-shadow: 0 0 0 1px var(--primary01);
      outline: none !important;
    }

    &::placeholder {
      color: var(--neutral06);
    }
    &:disabled {
      background: var(--neutral01);
      border: solid 1px var(--neutral05);
      box-shadow: 0 0 0 1px transparent;

      &::placeholder {
        color: var(--neutral07);
        opacity: 0.5;
      }

      cursor: not-allowed;
    }
  }
  .PhoneInputCountry {
    z-index: 11;
  }
  background-color: rgba(255, 255, 255, 0.7);
  border: solid 1px var(--primary01);
  border-radius: var(--border-radius-input);
  font-size: 18px;

  height: 51px;
  padding: 0 0 0 14px;
  margin-bottom: 24px;

  &:focus,
  &:hover,
  &:active {
    border: 1px solid var(--primary01);
    box-shadow: 0 0 0 1px var(--primary01);
  }
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background: var(--neutral01);
          border: solid 1px var(--neutral05);
          box-shadow: 0 0 0 1px transparent;

          &::placeholder {
            color: var(--neutral07);
            opacity: 0.5;
          }

          cursor: not-allowed;
        `
      : ''}
`;

const countrySelectProps = {
  tabIndex: -1,
};

const FormPhoneInput = ({
  label,
  meta,
  input: { onChange: _onChange, onFocus: _onFocus, onBlur: _onBlur, ...input },
  onChange,
  onFocus,
  onBlur,
  required,
  labelProps,
  helperMessages,
  disabled,
  ...props
}) => {
  const country = useSelector(selectCountry());
  const intl = useIntl();
  const { form } = useForm();
  const hasError = (meta.error && meta.touched && meta.dirty) || undefined;

  const handleFocus = useCallback(
    (e) => {
      _onFocus && _onFocus(e);
      onFocus && onFocus(e);
    },
    [_onFocus, onFocus],
  );

  const handleBlur = useCallback(
    (e) => {
      _onBlur && _onBlur(e);
      onBlur && onBlur(e);
    },
    [_onBlur, onBlur],
  );

  const handleChange = useCallback(
    (value) => {
      const e = { target: { name: input.name, value } };
      onChange && onChange(e);
      _onChange(e);
    },
    [_onChange, input.name, onChange],
  );

  const onErrorShown = useCallback(() => {
    eventSend({
      user_klass: 'member',
      event_name: events.formValidationError.event_name,
      form_name: form?.name,
      error_message_shown: intl.formatMessage(messages.invalidPhoneNumber),
      field_name: input?.name,
    });
  }, [form?.name, input?.name, intl]);

  return (
    <div>
      {label && (
        <Label {...labelProps} error={hasError} aria-label={label}>
          {label}
        </Label>
      )}
      <FormPhone
        {...input}
        {...props}
        disabled={disabled}
        isDisabled={disabled}
        defaultCountry={country}
        countries={PHONE_COUNTRY_OPTIONS}
        countrySelectProps={countrySelectProps}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        error={hasError}
      />
      <InputError onErrorShown={onErrorShown} meta={meta} input={input} />
    </div>
  );
};

const FormPhoneInputField = (props) => {
  return <Field {...props} component={FormPhoneInput} />;
};

export default FormPhoneInputField;

FormPhoneInput.propTypes = {
  form: PropTypes.any,
  helperMessages: PropTypes.any,
  label: PropTypes.any,
  labelProps: PropTypes.any,
  meta: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.any,
};
