import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const RedirectWithQuery = ({ to, ...props }) => {
  const { search } = useLocation();
  const _to = {
    pathname: `${typeof to === 'string' ? to : to.pathname}`,
    search,
    state: to.state,
  };
  if (!_to.pathname) {
    throw new Error(
      'No pathname provided to RedirectWithQuery: ' + JSON.stringify(to),
    );
  }

  return <Redirect to={_to} {...props} />;
};
