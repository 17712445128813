import {
  LD_FEATURE_SIGN_IN,
  LD_FEATURE_SIGNUP,
} from 'constants/launchDarklyFlags';
import {
  CREATE_ACCOUNT_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  QUIP_LOGIN_PAGE,
  QUIP_SIGNUP_PAGE,
  RECORDS_PAGE,
} from 'constants/pageIdentifiers';
import { getLastSeen } from 'utils/localStorageHelper';
import {
  getAuthenticatedRedirect,
  setAuthenticatedRedirect,
} from 'utils/sessionStorageHelper';

export const getLoginRedirect = () => {
  return `${LOGIN_PAGE}?redirectTo=${encodeURIComponent(
    `${window.location.pathname}${window.location.search}`,
  )}`;
};

const ALLOWLISTED_QUERY_PARAMS = new Set([
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]);

/**
 * Add allowlisted query params to a url.
 */
export const preserveQueryParams = (url) => {
  const defaultDomain = 'https://toothpic.com'; // Used only as a placeholder to construct a URL object
  const isRelativePath = !url.startsWith('http') && !url.startsWith('//');
  let mergedUrl = new URL(isRelativePath ? defaultDomain + url : url);

  for (const [key, value] of new URLSearchParams(window.location.search)) {
    if (ALLOWLISTED_QUERY_PARAMS.has(key)) {
      mergedUrl.searchParams.append(key, value);
    }
  }

  return isRelativePath
    ? mergedUrl.pathname + mergedUrl.search
    : mergedUrl.toString();
};

export const authRedirectHelper = (path = null) => {
  try {
    let redir = preserveQueryParams(
      path || new URLSearchParams(window.location.search)?.get('redirectTo'),
    );

    const ssRedirect = getAuthenticatedRedirect();

    // passed in path or URL param
    if (redir && redir !== ssRedirect) {
      setAuthenticatedRedirect(redir);
      return redir;
    }

    // in localstorage, or default
    return ssRedirect || HOME_PAGE;
  } catch (e) {
    return HOME_PAGE;
  }
};
// should always redirect to the login page on these routes
export const AlwaysRedirectToLoginRoutes = [RECORDS_PAGE];

// check if url is in above array
export const isLoginRedirectRoute = (href) => {
  return AlwaysRedirectToLoginRoutes.some(
    (route) => (href || window.location.href).indexOf(route) !== -1,
  );
};
/**
 * Called when there is no auth token
 * Redirects to login if seen before, or if you should always redirect to login on that route
 * Redirects to signup if not seen before
 */
export const getUnauthenticatedRedirectRoute = (href, flags) => {
  if (getLastSeen() || isLoginRedirectRoute(href)) {
    return flags[LD_FEATURE_SIGN_IN] ? LOGIN_PAGE : QUIP_LOGIN_PAGE;
  }
  return flags[LD_FEATURE_SIGNUP] ? CREATE_ACCOUNT_PAGE : QUIP_SIGNUP_PAGE;
};
