// compare key value pairs of two objects
export const objectEquals = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
};

export const getSelectedDevices = () => {
  const devices = localStorage.getItem('tp-selectedDevices');
  if (devices) {
    try {
      return JSON.parse(devices);
    } catch (e) {}
  }
  return {
    selectedAudioInput: 'default',
    selectedAudioOutput: 'default',
    selectedVideoInput: 'default',
  };
};

export const setSelectedDevice = (kind, value) => {
  const devices = getSelectedDevices();
  localStorage.setItem(
    'tp-selectedDevices',
    JSON.stringify({ ...devices, [`selected${kind}`]: value }),
  );
};

export const getSelectedDevice = (kind) => {
  const devices = getSelectedDevices();
  return devices[`selected${kind}`];
};
