import { PATIENT_CASE_STATUS_VIDEO_CALL_ENDED } from 'constants/cases';
import { stringify } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { setTwilioAccessToken } from 'utils/localStorageHelper';
import request from 'utils/request';
import {
  fetchAssessorFailureAction,
  fetchAssessorSuccessAction,
  getVideoCallFailureAction,
  getVideoCallSuccessAction,
  resetVideoCallDetails,
  startVideoCallFailureAction,
  startVideoCallSuccessAction,
} from './actions';
import {
  END_VIDEO_CALL,
  FETCH_ASSESSOR,
  GET_VIDEO_CALL_ACTION,
  START_VIDEO_CALL_ACTION,
} from './constants';

export function* createVideoCall({ patientCaseId = '' }) {
  const method = 'POST';
  const endpoint = 'v3/video_calls';
  const headers = new Headers();
  const body = {
    patient_case_id: patientCaseId,
  };
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  const createVideoCallResponse = yield call(request, endpoint, requestData);
  if (!createVideoCallResponse?.id) {
    throw new Error('Failed to create video call');
  }
  return createVideoCallResponse;
}

export function* getVideoCallDetails({
  payload: { videoCallId, patientCaseId, patientCaseIds },
}) {
  try {
    const method = 'GET';
    const params = stringify(
      {
        patient_case_id: patientCaseIds || [patientCaseId],
        sort: 'created_at:DESC',
        limit: patientCaseIds?.length || 20,
      },
      { arrayFormat: 'bracket' },
    );
    let endpoint = `v3/video_calls${
      videoCallId ? `/${videoCallId}` : ''
    }?${params}`;
    const headers = new Headers();
    const requestData = {
      method,
      headers,
      mode: 'cors',
    };
    let getVideoCallDetailsResponse = yield call(
      request,
      endpoint,
      requestData,
    );
    if (getVideoCallDetailsResponse.id) {
      getVideoCallDetailsResponse = [getVideoCallDetailsResponse];
    }
    yield put(getVideoCallSuccessAction(getVideoCallDetailsResponse));

    return getVideoCallDetailsResponse;
  } catch (e) {
    yield put(getVideoCallFailureAction(e));
    throw e;
  }
}

export function* createTwilioAccessToken({ videoCallId = '' }) {
  const method = 'POST';
  const endpoint = 'v2/auth';
  const headers = new Headers();
  const body = {
    auth_type: 'twilio_access_token',
    video_call_id: videoCallId,
  };
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  const createTwilioAccessTokenResponse = yield call(
    request,
    endpoint,
    requestData,
  );
  if (!createTwilioAccessTokenResponse?.twilio_access_token) {
    throw new Error('Failed to create Twilio access token');
  }
  return createTwilioAccessTokenResponse;
}

export function* getVideoCall(videoCallId) {
  const method = 'GET';
  const endpoint = `v3/video_calls/${videoCallId}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  const response = yield call(request, endpoint, requestData);
  if (!response?.id) {
    throw new Error('Failed to get video call!');
  }
  return response;
}

export function* createVideoRoom(videoCallId) {
  const videoCall = yield call(getVideoCall, videoCallId);

  if (videoCall?.twilio_sid) {
    return videoCall;
  }

  const method = 'PATCH';
  const endpoint = `v3/video_calls/${videoCallId}/create_video_call`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  const response = yield call(request, endpoint, requestData);
  if (!response?.twilio_sid) {
    throw new Error('Failed to create Twilio room!');
  }
  return response;
}

export function* startVideoCall({ payload }) {
  try {
    const { patientCaseId, roomId: videoCallId } = payload;

    // create twilio room
    const [videoCall, createTwilioAccessResponse] = yield all([
      createVideoRoom(videoCallId),
      yield createTwilioAccessToken({
        videoCallId,
      }),
    ]);

    // set twilio access token
    setTwilioAccessToken(
      createTwilioAccessResponse.twilio_access_token,
      createTwilioAccessResponse.ttl * 1000,
      patientCaseId,
    );

    yield put(startVideoCallSuccessAction(videoCall));
  } catch (e) {
    console.error(e.message);
    window.Rollbar?.error('Failed to create video call', e);
    yield put(startVideoCallFailureAction({ error: true, message: e.message }));
  }
}

function* fetchAssessor({ payload }) {
  const { assessorId } = payload;
  try {
    const method = 'GET';
    const endpoint = `v3/assessors/${assessorId}`;
    const headers = new Headers();
    const requestData = {
      method,
      headers,
      mode: 'cors',
    };
    const assessorResponse = yield call(request, endpoint, requestData);
    yield put(fetchAssessorSuccessAction(assessorResponse));
  } catch (e) {
    yield put(fetchAssessorFailureAction(e));
  }
}

function* patchCaseStatusCall({ payload }) {
  const { patientCaseId, status } = payload;
  const method = 'PATCH';
  const endpoint = `v3/patient_cases/${patientCaseId}`;
  const headers = new Headers();
  const body = { status };
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  if (status === PATIENT_CASE_STATUS_VIDEO_CALL_ENDED) {
    yield put(resetVideoCallDetails());
  }
  try {
    return yield call(request, endpoint, requestData);
  } catch (e) {
    console.error(e.message);
  }
}

export default function* videoCallSaga() {
  yield takeLatest(START_VIDEO_CALL_ACTION, startVideoCall);
  yield takeLatest(FETCH_ASSESSOR, fetchAssessor);
  yield takeLatest(END_VIDEO_CALL, patchCaseStatusCall);
  yield takeLatest(GET_VIDEO_CALL_ACTION, getVideoCallDetails);
}
