import { load as loadAmplitude } from 'external/amplitude/amplitude';

var vwoConfig = {
  account_id: window.env.VWO_ACCOUNT_ID,
  settings_tolerance: 2000,
  library_tolerance: 2500,
  use_existing_jquery: false,
  is_spa: 1,
  hide_element: 'body',
  finished: false,
};

function setup() {
  window._vwo_code = window._vwo_code || {
    use_existing_jquery: function () {
      return vwoConfig.use_existing_jquery;
    },
    library_tolerance: function () {
      return vwoConfig.library_tolerance;
    },
    finish: function () {
      finish();
    },
    finished: function () {
      return vwoConfig.finished;
    },
    load: function () {
      load();
    },
    init: function () {
      init();
    },
  };
}

export function load() {
  var b = document.createElement('script');
  b.src =
    'https://dev.visualwebsiteoptimizer.com/j.php?a=' +
    vwoConfig.account_id +
    '&u=' +
    encodeURIComponent(document.URL) +
    '&f=' +
    +vwoConfig.is_spa +
    '&r=' +
    Math.random();
  b.type = 'text/javascript';
  b.innerText;
  b.onerror = function () {
    finish();
  };
  document.getElementsByTagName('head')[0].appendChild(b);

  integrateWithAmplitude();
}

export function init() {
  window.settings_timer = setTimeout(function () {
    finish();
  }, vwoConfig.settings_tolerance);
  var a = document.createElement('style'),
    b = vwoConfig.hide_element
      ? vwoConfig.hide_element +
        '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}'
      : '',
    h = document.getElementsByTagName('head')[0];
  a.setAttribute('id', '_vis_opt_path_hides');
  a.setAttribute('type', 'text/css');
  if (a.styleSheet) a.styleSheet.cssText = b;
  else a.appendChild(document.createTextNode(b));
  h.appendChild(a);

  load();

  window._vwo_settings_timer = window.settings_timer;
}

export function finish() {
  if (!vwoConfig.finished) {
    vwoConfig.finished = true;
    var a = document.getElementById('_vis_opt_path_hides');
    if (a) a.parentNode.removeChild(a);
  }
}

function integrateWithAmplitude() {
  if (!window.amplitude) {
    loadAmplitude();
  }
  window.VWO = window.VWO || [];
  window.integrationCallbacks = window.integrationCallbacks || [];

  var identify = null,
    config = {
      campaignTypesToPushDataFor: [
        'VISUAL_AB', // AB Campaign
        'VISUAL', // MVT Campaign
        'SPLIT_URL', // Split Campaign
        'SURVEY', // Survey Campaign
      ],
    };

  window.integrationCallbacks.push({
    callback: function (campaignData, isABatchProcessed) {
      // Amplitude Integration code follows
      identify = identify || new window.amplitude.Identify();
      var amplitude = window.amplitude;
      // Once a campaign is processed
      if (campaignData) {
        identify.set(
          'VWO-Test-ID-' + campaignData.id,
          campaignData.combinationName,
        );
      } else if (isABatchProcessed) {
        // When all campaigns are processed
        amplitude.getInstance().identify(identify);
        amplitude.getInstance().logEvent('VWO');
        //To handle SPA, and avoid logging of events multiple times where, on url change
        //amplitude does not loses context of campaigns from previous page.
        identify = null;
      }
    },
    apiAvailabilityCheck: function () {
      return window.amplitude;
    },
  });

  //DO NOT CHANGE BELOW THIS LINE
  var integrationCallbacks = window.integrationCallbacks,
    tryPushingDataTimer;

  // Poll for the integration library API and then call the callback to send data
  function checkAndIntegrate(
    apiAvailabilityCheck,
    callback,
    campaignMetaInfo,
    isABatchProcessed,
  ) {
    if (!callback) {
      throw 'callback not defined';
    }
    var timeout = 1;
    if (!apiAvailabilityCheck()) {
      clearTimeout(tryPushingDataTimer);
      tryPushingDataTimer = setTimeout(function () {
        checkAndIntegrate(
          apiAvailabilityCheck,
          callback,
          campaignMetaInfo,
          isABatchProcessed,
        );
      }, timeout);
      return;
    }
    if (isABatchProcessed) {
      setTimeout(function () {
        callback(campaignMetaInfo, isABatchProcessed);
      }, timeout);
    } else {
      callback(campaignMetaInfo, isABatchProcessed);
    }
  }

  function whenABatchProcessed() {
    for (var i = 0; i < integrationCallbacks.length; i++) {
      checkAndIntegrate(
        integrationCallbacks[i].apiAvailabilityCheck,
        integrationCallbacks[i].callback,
        null,
        true,
      );
    }
  }

  var debouncedWhenABatchProcessed;

  window.VWO.push([
    'onVariationApplied',
    function (data) {
      try {
        var campaignId = data[1],
          campaignDetailedInfo = window._vwo_exp[campaignId],
          combinationChosen = data[2];

        var campaignMetaInfo = {
          id: data[1],
          type: campaignDetailedInfo.type,
          combinationName: campaignDetailedInfo.comb_n[combinationChosen],
        };

        debouncedWhenABatchProcessed =
          debouncedWhenABatchProcessed ||
          setTimeout(function () {
            whenABatchProcessed();
          }, 1);

        if (
          typeof combinationChosen !== 'undefined' &&
          config.campaignTypesToPushDataFor.indexOf(campaignMetaInfo.type) > -1
        ) {
          for (var i = 0; i < integrationCallbacks.length; i++) {
            checkAndIntegrate(
              integrationCallbacks[i].apiAvailabilityCheck,
              integrationCallbacks[i].callback,
              campaignMetaInfo,
            );
          }
          debouncedWhenABatchProcessed();
        }
      } catch (e) {}
    },
  ]);
}

setup();
init();
