import { Card } from '@oraleye/frontend-modules-components';
import { CardTitle } from 'components/common/Card';
import styled from 'styled-components';

export const StyledH = styled.h3`
  color: var(--neutral08);
  font-weight: 300;
`;

export const StyledP = styled.p`
  color: var(--neutral07);
  font-weight: 300;
  line-height: 1.5;
`;

export const PageContainer = styled.div`
  padding: 8px;
  ${({ theme }) => theme.breakpoints.minSm`
    margin: 45px auto;
    max-height: 90%;
    max-width: 530px;
    width: 100%;
    border-radius: 8px;
    padding: 36px 60px 40px 60px;
   `};
`;

export const FollowUpIcon = styled.span`
  display: inline-block;
  border-radius: 50%;
  background-color: var(--chartandvisualdata05);
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
  color: white;
  margin-right: 18px;
`;

export const StyledCardTitle = styled(CardTitle)`
  color: var(--neutral08);
  padding-right: 85px;
  position: relative;
  button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px 10px;
    line-height: 1;
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  line-height: 41px;
  border-radius: 50%;
  color: white;
  background-color: ${({ color }) => color || 'var(--primary01)'};
  display: inline-block;
  margin-right: 16px;
  text-align: center;
`;

export const StyledDescription = styled.p`
  color: var(--neutral08);
`;

export const HighlightedText = styled.span`
  font-weight: bold;
`;

export const StyledCard = styled(Card)`
  padding: 56px 0;
`;
