/*
 * ErrorBoundary Messages
 *
 * This contains all the text for the ErrorBoundary component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ErrorBoundary';

export default defineMessages({
  documentTitle: {
    id: `${scope}.documentTitle`,
    defaultMessage: 'An Error Has Occurred',
  },
});
