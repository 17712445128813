import { CREATE_TRIAGE_CASE } from 'constants/pageIdentifiers';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LandingPage from './Loadable';
import messages from './messages';

const TriageLandingPage = () => {
  return (
    <LandingPage
      startCaseLink={CREATE_TRIAGE_CASE}
      ctaText={<FormattedMessage {...messages.startConsult} />}
      header1Text={<FormattedMessage {...messages.headerTriage} />}
      header2Text={<FormattedMessage {...messages.headerTriage2} />}
    />
  );
};

export default TriageLandingPage;
