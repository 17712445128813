/*
 *
 * Checkout actions
 *
 */

import {
  FETCH_CHECKOUT_DATA,
  FETCH_CHECKOUT_DATA_FAILURE,
  FETCH_CHECKOUT_DATA_SUCCESS,
  FETCH_PAYMENT_INTENTS,
  FETCH_PAYMENT_INTENTS_FAILURE,
  FETCH_PAYMENT_INTENTS_SUCCESS,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAILURE,
  FETCH_PAYMENTS_SUCCESS,
  PATCH_PAYMENT_FETCH_INTENT,
  PATCH_PAYMENT_FETCH_INTENT_FAILURE,
  PATCH_PAYMENT_FETCH_INTENT_SUCCESS,
  PATCH_PAYMENTS,
  PATCH_PAYMENTS_FAILURE,
  PATCH_PAYMENTS_SUCCESS,
} from 'containers/Consultation/Checkout/constants';

export const fetchPaymentsAction = (payload) => ({
  type: FETCH_PAYMENTS,
  payload,
});

export const fetchPaymentsSuccess = (payload) => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload,
});

export const fetchPaymentsFailure = (payload) => ({
  type: FETCH_PAYMENTS_FAILURE,
  payload,
});

export const fetchPaymentIntentsAction = (payload) => ({
  type: FETCH_PAYMENT_INTENTS,
  payload,
});

export const fetchPaymentIntentsSuccess = (payload) => ({
  type: FETCH_PAYMENT_INTENTS_SUCCESS,
  payload,
});

export const fetchPaymentIntentsFailure = (payload) => ({
  type: FETCH_PAYMENT_INTENTS_FAILURE,
  payload,
});

export const patchPaymentsAction = (payload) => ({
  type: PATCH_PAYMENTS,
  payload,
});

export const patchPaymentsSuccess = (payload) => ({
  type: PATCH_PAYMENTS_SUCCESS,
  payload,
});

export const patchPaymentsFailure = (error) => ({
  type: PATCH_PAYMENTS_FAILURE,
  error,
});

export const patchPaymentAndFetchIntentAction = (payload) => ({
  type: PATCH_PAYMENT_FETCH_INTENT,
  payload,
});

export const patchPaymentAndFetchIntentSuccess = (payload) => ({
  type: PATCH_PAYMENT_FETCH_INTENT_SUCCESS,
  payload,
});

export const patchPaymentAndFetchIntentFailure = (error) => ({
  type: PATCH_PAYMENT_FETCH_INTENT_FAILURE,
  error,
});

export const fetchCheckoutDataAction = (payload) => ({
  type: FETCH_CHECKOUT_DATA,
  payload,
});

export const fetchCheckoutDataSuccessAction = (payload) => ({
  type: FETCH_CHECKOUT_DATA_SUCCESS,
  payload,
});

export const fetchCheckoutDataFailureAction = (error, payload) => ({
  type: FETCH_CHECKOUT_DATA_FAILURE,
  error,
  payload,
});
