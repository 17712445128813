export const DEFAULT_ACTION = 'app/AUTH/DEFAULT_ACTION';
export const RESET_REDUCER = 'app/AUTH/RESET_REDUCER';

/*
 *
 * LoginForm constants
 *
 */

export const USER_LOGIN = 'app/USER_LOGIN';
export const LOGIN_REQUEST = 'app/Login/LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'app/Login/LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'app/Login/LOGIN_REQUEST_FAILURE';
export const MFA_LOGIN = 'app/Login/MFA_LOGIN';
export const LOG_USER_OUT = 'app/Login/LOG_USER_OUT';
export const FIELD_UPDATE = 'app/Login/FIELD_UPDATE';
export const CLEAR_ERRORS = 'app/Login/CLEAR_ERRORS';
export const ENABLE_MFA = 'app/Login/ENABLE_MFA';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

/*
 *
 * SignOut constants
 *
 */

export const SIGN_OUT_USER = 'app/SignOut/SIGN_OUT_USER';
export const SIGN_OUT_USER_SUCCESS = 'app/SignOut/SIGN_OUT_USER_SUCCES';
export const SIGN_OUT_USER_FAILURE = 'app/SignOut/SIGN_OUT_USER_FAILURE';

/*
 *
 * SignUpForm constants
 *
 */

export const LOAD_PROVIDER_OPTIONS = 'app/SignUpForm/LOAD_PROVIDER_OPTIONS';
export const LOAD_PROVIDER_OPTIONS_SUCCESS =
  'app/SignUpForm/LOAD_PROVIDER_OPTIONS_SUCCESS';
export const LOAD_PROVIDER_OPTIONS_FAILURE =
  'app/SignUpForm/LOAD_PROVIDER_OPTIONS_FAILURE';

export const SIGNUP_REQUEST = 'app/SignUpForm/SIGNUP_REQUEST';
export const SIGNUP_REQUEST_SUCCESS = 'app/SignUpForm/SIGNUP_REQUEST_SUCCESS';
export const SIGNUP_REQUEST_FAILURE = 'app/SignUpForm/SIGNUP_REQUEST_FAILURE';

export const FETCH_MEMBER = 'app/SignUpForm/FETCH_MEMBER';
export const FETCH_MEMBER_SUCCESS = 'app/SignUpForm/FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_FAILURE = 'app/SignUpForm/FETCH_MEMBER_FAILURE';

export const FETCH_COMPANY_CONFIG_SUCCESS =
  'app/SignUpForm/FETCH_COMPANY_CONFIG_SUCCESS';
export const FETCH_COMPANY_CONFIG_FAILURE =
  'app/SignUpForm/FETCH_COMPANY_CONFIG_FAILURE';

/*
 *
 * SSO constants
 *
 */

export const GOOGLE_LOGIN_REQUEST = 'app/SSO/GOOGLE_LOGIN_REQUEST';
export const GOOGLE_SIGNUP_REQUEST = 'app/SSO/GOOGLE_SIGNUP_REQUEST';
export const GOOGLE_SSO_REQUEST = 'app/SSO/GOOGLE_SSO_REQUEST';

// Member medical information constants
export const PATCH_MEMBER_MEDICAL_INFORMATION =
  'app/MemberMedicalInformation/PATCH_MEMBER_MEDICAL_INFORMATION';
export const PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS =
  'app/MemberMedicalInformation/PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS';
export const PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE =
  'app/MemberMedicalInformation/PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE';
