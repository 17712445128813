import { initialState as initialAuthState } from 'containers/Auth/AuthRouter/initialState';
import authReducer from 'containers/Auth/AuthRouter/reducer';
import checkoutReducer, {
  initialState as initialCheckoutState,
} from 'containers/Consultation/Checkout/reducer';
import getCareReducer, {
  initialState as initialGetCareState,
} from 'containers/GetCare/reducer';
import homeReducer, {
  initialState as initialHomeState,
} from 'containers/Home/reducer';
import languageProviderReducer, {
  initialState as initialLanguageState,
} from 'containers/LanguageProvider/reducer';
import ldUpdaterReducer, {
  initialState as initialLdUpdaterState,
} from 'containers/LaunchDarklyUpdater/reducer';
import medicalInformationReducer from 'containers/MedicalInformation/reducer';
import memberProfileReducer, {
  initialState as initialMemberProfileState,
} from 'containers/MemberProfile/reducer';
import photosReducer, {
  initialState as intialPhotosState,
} from 'containers/Photos/reducer';
import questionsReducer, {
  initialState as initialQuestionsState,
} from 'containers/Questions/reducer';
import recordsReducer, {
  initialState as initialRecordsState,
} from 'containers/Records/reducer';
import videoCallReducer, {
  initialState as initialVideoCallState,
} from 'containers/VideoCall/reducer';
import { combineReducers } from 'redux';
import consultationReducer, {
  initialState as initiaConsultationState,
} from './containers/Consultation/reducer';
import pharmacyReducer, {
  initialState as initialPharmacyState,
} from './containers/Pharmacy/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    auth: authReducer,
    getCare: getCareReducer,
    records: recordsReducer,
    questions: questionsReducer,
    memberProfile: memberProfileReducer,
    checkout: checkoutReducer,
    pharmacy: pharmacyReducer,
    photos: photosReducer,
    ldUpdater: ldUpdaterReducer,
    consultations: consultationReducer,
    medicalInformation: medicalInformationReducer,
    videoCall: videoCallReducer,
    home: homeReducer,
    ...injectedReducers,
  });
  return rootReducer;
}

export const initialRootReducerState = () => {
  return {
    auth: initialAuthState(),
    language: initialLanguageState(),
    getCare: initialGetCareState(),
    records: initialRecordsState(),
    questions: initialQuestionsState(),
    memberProfile: initialMemberProfileState(),
    checkout: initialCheckoutState(),
    pharmacy: initialPharmacyState(),
    photos: intialPhotosState(),
    ldUpdater: initialLdUpdaterState(),
    consultations: initiaConsultationState(),
    videoCall: initialVideoCallState(),
    home: initialHomeState(),
  };
};
