/*
 *
 * Photos actions
 *
 */

import {
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES,
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS,
  PATCH_CASE_PHOTO_SUCCESS,
  UPLOAD_PHOTOS_PROGRESS,
} from 'containers/Photos/constants';
import {
  CONFIRM_PHOTO,
  COUNT_PHOTOS_FROM_INDEXED_DB,
  COUNT_PHOTOS_FROM_INDEXED_DB_FAILURE,
  COUNT_PHOTOS_FROM_INDEXED_DB_SUCCESS,
  DELETE_CASE_PHOTOS_FROM_DATABASE,
  DELETE_CASE_PHOTOS_FROM_DATABASE_FAILURE,
  DELETE_CASE_PHOTOS_FROM_DATABASE_SUCCESS,
  GET_CASE_PHOTOS,
  GET_CASE_PHOTOS_FAILURE,
  GET_CASE_PHOTOS_SUCCESS,
  GET_FLOW_MESSAGE,
  GET_FLOW_MESSAGE_FAILURE,
  GET_FLOW_MESSAGE_SUCCESS,
  GET_PATIENT_CASE_TEMPLATE,
  GET_PATIENT_CASE_TEMPLATE_FAILURE,
  GET_PATIENT_CASE_TEMPLATE_SUCCESS,
  LOAD_PHOTOS_FROM_INDEXED_DB,
  LOAD_PHOTOS_FROM_INDEXED_DB_FAILURE,
  LOAD_PHOTOS_FROM_INDEXED_DB_SUCCESS,
  RESET_CASE_SUBMISSION_SUCCESS,
  SKIP_PHOTOS,
  SKIP_PHOTOS_FAILURE,
  SKIP_PHOTOS_SUCCESS,
  SUBMIT_PHOTOS,
  SUBMIT_PHOTOS_FAILURE,
  SUBMIT_PHOTOS_SUCCESS,
} from './constants';

export const getPatientCaseTemplateAction = (payload) => ({
  type: GET_PATIENT_CASE_TEMPLATE,
  payload,
});

export const getPatientCaseTemplateFailureAction = (error, payload) => ({
  type: GET_PATIENT_CASE_TEMPLATE_FAILURE,
  error,
  payload,
});

export const getPatientCaseTemplateSuccessAction = (payload) => ({
  type: GET_PATIENT_CASE_TEMPLATE_SUCCESS,
  payload,
});

export function fetchCasePhotos(payload) {
  return { type: GET_CASE_PHOTOS, payload };
}

export function fetchCasePhotosSuccess(payload) {
  return { type: GET_CASE_PHOTOS_SUCCESS, payload };
}

export function fetchCasePhotosFailure(error, payload) {
  return { type: GET_CASE_PHOTOS_FAILURE, error, payload };
}

export function confirmPhotoAction(payload) {
  return { type: CONFIRM_PHOTO, payload };
}

export const submitPhotosAction = (payload) => {
  return { type: SUBMIT_PHOTOS, payload };
};

export const submitPhotosSuccessAction = () => {
  return { type: SUBMIT_PHOTOS_SUCCESS };
};

export const submitPhotosFailureAction = (error, payload) => {
  return { type: SUBMIT_PHOTOS_FAILURE, error, payload };
};
export const skipPhotosAction = (payload) => {
  return { type: SKIP_PHOTOS, payload };
};

export const skipPhotosSuccessAction = () => {
  return { type: SKIP_PHOTOS_SUCCESS };
};

export const skipPhotosFailureAction = (error, payload) => {
  return { type: SKIP_PHOTOS_FAILURE, error, payload };
};

export const getFlowMessageAction = (payload) => {
  return { type: GET_FLOW_MESSAGE };
};

export const getFlowMessageSuccessAction = (payload) => {
  return { type: GET_FLOW_MESSAGE_SUCCESS, payload };
};

export const getFlowMessageFailureAction = (error, payload) => {
  return { type: GET_FLOW_MESSAGE_FAILURE, error, payload };
};

export const loadPhotosFromDatabaseAction = (payload) => ({
  type: LOAD_PHOTOS_FROM_INDEXED_DB,
  payload,
});

export const loadPhotosFromDatabaseSuccessAction = (payload) => ({
  type: LOAD_PHOTOS_FROM_INDEXED_DB_SUCCESS,
  payload,
});

export const loadPhotosFromDatabaseFailureAction = (error) => ({
  type: LOAD_PHOTOS_FROM_INDEXED_DB_FAILURE,
  error,
});

export const deleteCasePhotosFromDatabaseAction = (payload) => ({
  type: DELETE_CASE_PHOTOS_FROM_DATABASE,
  payload,
});

export const deleteCasePhotosFromDatabaseSuccessAction = () => ({
  type: DELETE_CASE_PHOTOS_FROM_DATABASE_SUCCESS,
});

export const deleteCasePhotosFromDatabaseFailureAction = (error) => ({
  type: DELETE_CASE_PHOTOS_FROM_DATABASE_FAILURE,
  error,
});

export const countPhotosFromDatabaseAction = (payload) => ({
  type: COUNT_PHOTOS_FROM_INDEXED_DB,
  payload,
});

export const countPhotosFromDatabaseSuccessAction = (payload) => ({
  type: COUNT_PHOTOS_FROM_INDEXED_DB_SUCCESS,
  payload,
});

export const countPhotosFromDatabaseFailureAction = (error) => ({
  type: COUNT_PHOTOS_FROM_INDEXED_DB_FAILURE,
  error,
});

export const resetCaseSubmissionSuccessAction = (payload) => ({
  type: RESET_CASE_SUBMISSION_SUCCESS,
});

export const fetchPatientCaseTemplatePhotoCategories = (payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES,
  payload,
});

export const fetchPatientCaseTemplatePhotoCategoriesSuccess = (payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS,
  payload,
});

export const fetchPatientCaseTemplatePhotoCategoriesFailure = (
  error,
  payload,
) => ({
  type: FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE,
  error,
  payload,
});

export const setUploadProgress = (value, add) => ({
  type: UPLOAD_PHOTOS_PROGRESS,
  payload: { value, add },
});

export const patchCasePhotoSuccessAction = (payload) => ({
  type: PATCH_CASE_PHOTO_SUCCESS,
  payload,
});
