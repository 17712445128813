/*
 *
 * Records constants
 *
 */

export const FETCH_REPORTS = 'app/RECORDS/FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'app/RECORDS/FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'app/RECORDS/FETCH_REPORTS_FAILURE';

export const GET_REPORT = 'app/RECORDS/GET_REPORT';
export const GET_REPORT_SUCCESS = 'app/RECORDS/GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'app/RECORDS/GET_REPORT_FAILURE';

export const FETCH_PRESCRIPTIONS = 'app/RECORDS/FETCH_PRESCRIPTIONS';
export const FETCH_PRESCRIPTIONS_SUCCESS =
  'app/RECORDS/FETCH_PRESCRIPTIONS_SUCCESS';
export const FETCH_PRESCRIPTIONS_FAILURE =
  'app/RECORDS/FETCH_PRESCRIPTIONS_FAILURE';

export const FETCH_PRESCRIPTION = 'app/RECORDS/FETCH_PRESCRIPTION';
export const FETCH_PRESCRIPTION_SUCCESS =
  'app/RECORDS/FETCH_PRESCRIPTION_SUCCESS';
export const FETCH_PRESCRIPTION_FAILURE =
  'app/RECORDS/FETCH_PRESCRIPTION_FAILURE';

export const GET_PRESCRIPTION_DETAILS = 'app/RECORDS/GET_PRESCRIPTION_DETAILS';
export const GET_PRESCRIPTION_DETAILS_SUCCESS =
  'app/RECORDS/GET_PRESCRIPTION_DETAILS_SUCCESS';
export const GET_PRESCRIPTION_DETAILS_FAILURE =
  'app/RECORDS/GET_PRESCRIPTION_DETAILS_FAILURE';

export const FETCH_ALL_INVOICES = 'app/RECORDS/FETCH_ALL_INVOICES';
export const FETCH_ALL_INVOICES_SUCCESS =
  'app/RECORDS/FETCH_ALL_INVOICES_SUCCESS';
export const FETCH_ALL_INVOICES_FAILURE =
  'app/RECORDS/FETCH_ALL_INVOICES_FAILURE';

export const DOWNLOAD_INVOICE = 'app/RECORDS/DOWNLOAD_INVOICE';
export const DOWNLOAD_INVOICE_SUCCESS = 'app/RECORDS/DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAILURE = 'app/RECORDS/DOWNLOAD_INVOICE_FAILURE';

export const FETCH_ALL_RECORDS = 'app/RECORDS/FETCH_ALL_RECORDS';
export const FETCH_ALL_RECORDS_SUCCESS =
  'app/RECORDS/FETCH_ALL_RECORDS_SUCCESS';
export const FETCH_ALL_RECORDS_FAILURE =
  'app/RECORDS/FETCH_ALL_RECORDS_FAILURE';

export const ON_FILTER_CHANGE = 'app/RECORDS/ON_FILTER_CHANGE';
