import {
  getAuthToken,
  getMember,
  getMemberEmail,
} from 'utils/localStorageHelper';

export const initialState = () => ({
  authenticationComplete: false,
  authToken: getAuthToken(),
  userId: getMember()?.id || null,
  authType: '',
  email: getMemberEmail(),
  loginError: null,
  errors: {},
  googleQrUri: '',
  isAuthenticated: !!getAuthToken(),
  isLoading: false,
  member: getMember(),
  memberLoading: false,
  mfaLoginSent: false,
  mfaEnabled: false,
  modalOpen: false,
  page: 1,
  password: '',
  recoveryCodes: null,
  redirectToDashboard: false,
  redirectToLogin: false,
  requestInProgress: false,
  showMFA: false,
  showLoginCodes: false,
  terms: false,
  providerOptions: [],
  accountQuestionSet: null,
  signupForm: {},
  signupError: null,
  loadingSSO: false,
});
