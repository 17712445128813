/*
 * SideMenu Messages
 *
 * This contains all the text for the SideMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SideMenu';

export default defineMessages({
  connectToADentist: {
    id: `${scope}.connectToADentist`,
    defaultMessage: 'Connect to a dentist',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports',
  },
  records: {
    id: `${scope}.records`,
    defaultMessage: 'Records',
  },
  videoPreferences: {
    id: `${scope}.videoPreferences`,
    defaultMessage: 'Video Preferences',
  },
});
