/**
 *
 * Asynchronously loads the component for JoinVideoCard
 *
 */

import Loader from 'components/Loader';
import React from 'react';

const JoinVideoCard = React.lazy(() => import('./index'));

const JoinVideoCardLoadable = (props) => (
  <React.Suspense fallback={<Loader />}>
    <JoinVideoCard {...props} />
  </React.Suspense>
);

export default JoinVideoCardLoadable;
