import '!file-loader?name=[name].[ext]!../images/favicon.ico';
import 'external/amplitude/amplitude';
import 'external/amplitude/vwo';
import 'external/intercom/intercom';
import rollbar from 'external/rollbar';
import 'external/segment/segment';
import 'file-loader?name=.htaccess!../.htaccess';
import 'fonts/icons';
import {
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
} from 'locale-constants';
import 'normalize.css';
import 'sanitize.css/sanitize.css';
import {
  getCountry,
  getCurrency,
  getLocale,
  setCountry,
  setCurrency,
  setLocale,
} from 'utils/localStorageHelper';
import 'utils/modernizr';

// services
rollbar();

if (!getLocale()) {
  setLocale(DEFAULT_LOCALE);
}
if (!getCountry()) {
  setCountry(DEFAULT_COUNTRY);
}
if (!getCurrency()) {
  setCurrency(DEFAULT_CURRENCY);
}

if (!Promise.allSettled) {
  Promise.allSettled = function (promises) {
    let mappedPromises = promises.map((p) => {
      return p
        .then((value) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason) => {
          return {
            status: 'rejected',
            reason,
          };
        });
    });
    return Promise.all(mappedPromises);
  };
}

export default () => {};
