import { eventSend } from 'services/EventsService';
import events from 'services/EventsService/events.json';
import {
  EXTERNAL_USER_ID,
  loadFromSessionStorage,
} from 'utils/sessionStorageHelper';

export default function identifyExternalUser(memberId) {
  const euid = loadFromSessionStorage(EXTERNAL_USER_ID);
  // external user id for Caredash tracking
  if (euid) {
    eventSend({
      event_name: events.identify.event_name,
      user_id: memberId,
      user_klass: 'member',
      external_user_id: euid,
    });
  }
}
