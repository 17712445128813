import { PageContainer } from 'components/HomeView/styled';
import Diagnostics from 'components/VideoDiagnostics';
import React from 'react';
import events from 'services/EventsService/events.json';
import usePageEvent from 'services/EventsService/usePageEvent';
import styled from 'styled-components';

const DiagnosticsWrapper = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const VideoDiagnosticsPage = () => {
  usePageEvent(events.videoDiagnosticsPage);
  return (
    <DiagnosticsWrapper>
      <Diagnostics />
    </DiagnosticsWrapper>
  );
};

export default VideoDiagnosticsPage;
