/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import Logo from 'components/Logo';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import Config from 'config';
import { NOT_FOUND_PAGE } from 'constants/pageIdentifiers';
import messages from 'pages/NotFoundPage/messages';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import { useLogzEvent } from 'services/LogEvents';
import styled from 'styled-components';
import { getCampaignId } from 'utils/localStorageHelper';

const LogoWrap = styled.div`
  max-width: 320px;
  margin: 32px auto;
`;

const Article = styled.article`
  margin: 32px auto;
  max-width: 520px;
  h2,
  p {
    text-align: center;
  }
  p {
    color: var(--neutral07);
  }
`;

const ATTENTION_NEEDED_EVENT = 'MICHAEL_GET_ONTO_INTERCOM';

function NotFound() {
  const location = useLocation();
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent({
      event_name: events.notFound.event_name,
      page: events.notFound.event_name,
    });
  }, [location?.state, trackEvent]);
  const ls = location?.state || {};
  const data = {
    type: ATTENTION_NEEDED_EVENT,
    campaignId: getCampaignId(),
    path: ls.path || window.location.pathname,
    referrer: ls.referrer || document.referrer,
    title: ls.title || document.title,
    url: ls.href || window.location.href,
    ...ls,
  };
  useLogzEvent(data);

  return (
    <>
      <Helmet title={`${Config.companyName} | 404 Page not found`} />
      <Article>
        <LogoWrap>
          <Logo />
        </LogoWrap>
        <h2>
          <FormattedMessage {...messages.header} />
        </h2>
        <p>
          <FormattedMessage {...messages.body} />
        </p>
      </Article>
    </>
  );
}

export default NotFound;

export const NotFoundRedirect = ({ location }) => {
  return (
    <RedirectWithQuery
      push
      to={{
        pathname: NOT_FOUND_PAGE,
        state: {
          path: location.pathname,
          query: location.query,
          url: location.href,
        },
      }}
    />
  );
};

NotFoundRedirect.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.any,
    pathname: PropTypes.any,
    query: PropTypes.any,
  }),
};
