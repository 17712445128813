import { createContext } from 'react';
import {
  getAccountQuestionSetComplete,
  getAuthToken,
  getCampaignId,
  getMember,
} from 'utils/localStorageHelper';

export const defaultAuthContext = () => ({
  authToken: getAuthToken(),
  apiKey: window.env.TP_API_KEY,
  campaignId: getCampaignId(),
  memberId: getMember()?.id || null,
  baseUrl: window.env.TP_API_URL,
  member: getMember(),
  schema: 'public',
  setCampaignId: null,
  accountProfileComplete: getAccountQuestionSetComplete(),
  accountQuestionSet: null,
});
const AuthContext = createContext(defaultAuthContext());
export const AuthProvider = AuthContext.Provider;
export default AuthContext;
