/*
 *
 * GetCare actions
 *
 */

import {
  CREATE_PATIENT_CASE,
  CREATE_PATIENT_CASE_FAILURE,
  CREATE_PATIENT_CASE_SUCCESS,
  DELETE_PATIENT_CASE,
  DELETE_PATIENT_CASE_FAILURE,
  DELETE_PATIENT_CASE_SUCCESS,
  FETCH_PATIENT_CASE_TEMPLATE,
  FETCH_PATIENT_CASE_TEMPLATE_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATE_SUCCESS,
  FETCH_PATIENT_CASE_TEMPLATES,
  FETCH_PATIENT_CASE_TEMPLATES_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATES_SUCCESS,
  FETCH_PATIENT_CASES,
  FETCH_PATIENT_CASES_FAILURE,
  FETCH_PATIENT_CASES_SUCCESS,
  FETCH_REGIONS,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_SUCCESS,
  GET_PATIENT_CASE,
  GET_PATIENT_CASE_FAILURE,
  GET_PATIENT_CASE_SUCCESS,
  GET_PATIENT_CASES,
  GET_PATIENT_CASES_FAILURE,
  GET_PATIENT_CASES_SUCCESS,
  NEW_PATIENT_CASE,
  NEW_PATIENT_CASE_FAILURE,
  NEW_PATIENT_CASE_SUCCESS,
  PATCH_MEMBERS_STATE,
  PATCH_MEMBERS_STATE_FAILURE,
  PATCH_MEMBERS_STATE_SUCCESS,
  PATCH_PATIENT_CASE_STATUS,
  PATCH_PATIENT_CASE_STATUS_FAILURE,
  PATCH_PATIENT_CASE_STATUS_SUCCESS,
  RESET_NEW_PATIENT_CASE,
} from './constants';

export const fetchPatientCasesAction = (payload) => ({
  type: FETCH_PATIENT_CASES,
  payload,
});

export const fetchPatientCasesSuccess = (payload) => ({
  type: FETCH_PATIENT_CASES_SUCCESS,
  payload,
});

export const fetchPatientCasesFailure = (payload) => ({
  type: FETCH_PATIENT_CASES_FAILURE,
  payload,
});

export const getPatientCase = (payload) => ({
  type: GET_PATIENT_CASE,
  payload,
});

export const getPatientCaseSuccess = (payload) => ({
  type: GET_PATIENT_CASE_SUCCESS,
  payload,
});

export const getPatientCaseFailure = (error, payload) => ({
  type: GET_PATIENT_CASE_FAILURE,
  payload,
  error,
});

export const getPatientCases = (payload) => ({
  type: GET_PATIENT_CASES,
  payload,
});

export const getPatientCasesSuccess = (payload) => ({
  type: GET_PATIENT_CASES_SUCCESS,
  payload,
});

export const getPatientCasesFailure = (payload) => ({
  type: GET_PATIENT_CASES_FAILURE,
  payload,
});

export const createPatientCaseAction = (payload) => ({
  type: CREATE_PATIENT_CASE,
  payload,
});

export const createPatientCaseActionSuccess = (payload) => ({
  type: CREATE_PATIENT_CASE_SUCCESS,
  payload,
});

export const createPatientCaseActionFailure = (error, payload) => ({
  type: CREATE_PATIENT_CASE_FAILURE,
  payload,
  error,
});

export const newPatientCaseAction = (payload) => ({
  type: NEW_PATIENT_CASE,
  payload,
});

export const newPatientCaseActionSuccess = (payload) => ({
  type: NEW_PATIENT_CASE_SUCCESS,
  payload,
});

export const newPatientCaseActionFailure = (error, payload) => ({
  type: NEW_PATIENT_CASE_FAILURE,
  payload,
  error,
});

export const deletePatientCaseAction = (payload) => ({
  type: DELETE_PATIENT_CASE,
  payload,
});

export const deletePatientCaseActionSuccess = (payload) => ({
  type: DELETE_PATIENT_CASE_SUCCESS,
  payload,
});

export const deletePatientCaseActionFailure = (error, payload) => ({
  type: DELETE_PATIENT_CASE_FAILURE,
  payload,
  error,
});

export const resetNewPatientCase = (payload) => ({
  type: RESET_NEW_PATIENT_CASE,
  payload,
});

export const fetchPatientCaseTemplatesAction = (payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATES,
  payload,
});

export const fetchPatientCaseTemplatesSuccess = (payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATES_SUCCESS,
  payload,
});

export const fetchPatientCaseTemplatesFailure = (error, payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATES_FAILURE,
  error,
  payload,
});

export const fetchPatientCaseTemplateAction = (id) => ({
  type: FETCH_PATIENT_CASE_TEMPLATE,
  payload: { id },
});

export const fetchPatientCaseTemplateSuccess = (payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATE_SUCCESS,
  payload,
});

export const fetchPatientCaseTemplateFailure = (error, payload) => ({
  type: FETCH_PATIENT_CASE_TEMPLATE_FAILURE,
  error,
  payload,
});

export const fetchRegionsAction = () => ({
  type: FETCH_REGIONS,
});

export const fetchRegionsSuccessAction = (payload) => ({
  type: FETCH_REGIONS_SUCCESS,
  payload,
});

export const fetchRegionsFailureAction = (error) => ({
  type: FETCH_REGIONS_FAILURE,
  error,
});

export const patchMembersStateAction = (payload) => ({
  type: PATCH_MEMBERS_STATE,
  payload,
});

export const patchMembersStateSuccessAction = (payload) => ({
  type: PATCH_MEMBERS_STATE_SUCCESS,
  payload,
});

export const patchMembersStateFailureAction = (error) => ({
  type: PATCH_MEMBERS_STATE_FAILURE,
  error,
});

export const patchPatientCaseStatusAction = (payload) => ({
  type: PATCH_PATIENT_CASE_STATUS,
  payload,
});

export const patchPatientCaseStatusSuccessAction = (payload) => ({
  type: PATCH_PATIENT_CASE_STATUS_SUCCESS,
  payload,
});

export const patchPatientCaseStatusFailureAction = (error, payload) => ({
  type: PATCH_PATIENT_CASE_STATUS_FAILURE,
  payload,
  error,
});
