/*eslint-disable*/
/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

const enUSTranslationMessages = require('./translations/en-US.json');
const enIETranslationMessages = require('./translations/en-IE.json');
const DEFAULT_LOCALE = 'en-US';
const DEFAULT_COUNTRY = 'US';
const DEFAULT_CURRENCY = 'USD';
const LOCALE_IE = 'en-IE';
const LOCALE_US = 'en-US';
// prettier-ignore
const appLocales = [
  LOCALE_US,
  LOCALE_IE
]

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, {})
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  'en-US': formatTranslationMessages('en-US', enUSTranslationMessages),
  'en-IE': formatTranslationMessages('en-IE', enIETranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
exports.DEFAULT_COUNTRY = DEFAULT_COUNTRY;
exports.DEFAULT_CURRENCY = DEFAULT_CURRENCY;
exports.LOCALE_IE = LOCALE_IE;
exports.LOCALE_US = LOCALE_US;
