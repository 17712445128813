import {
  CASE_QUESTIONS_REQUIRED_STAGE,
  MEMBER_PROFILE_REQUIRED_STAGE,
  SCHEDULE_VIDEO_REQUIRED_STEP,
  SCHEDULED_VIDEO_TEMPLATE_BASE_NAME,
  VIDEO_CALL_REQUIRED_STAGE,
  VIDEO_TEMPLATE_BASE_NAME,
} from 'constants/cases';
import {
  loadConsultationDataFailureAction,
  loadConsultationDataSuccessAction,
  upsertVideoCallFailureAction,
  upsertVideoCallSuccessAction,
} from 'containers/Consultation/actions';
import {
  fetchPaymentIntentsAction,
  fetchPaymentsAction,
} from 'containers/Consultation/Checkout/actions';
import {
  FETCH_PAYMENTS_FAILURE,
  FETCH_PAYMENTS_SUCCESS,
} from 'containers/Consultation/Checkout/constants';
import {
  LOAD_CONSULTATION_DATA,
  PHOTOS_STAGE,
  UPSERT_VIDEO_CALL,
} from 'containers/Consultation/constants';
import { getPatientCase } from 'containers/GetCare/actions';
import {
  GET_PATIENT_CASE_FAILURE,
  GET_PATIENT_CASE_SUCCESS,
} from 'containers/GetCare/constants';
import {
  fetchCasePhotos,
  fetchPatientCaseTemplatePhotoCategories,
  getPatientCaseTemplateAction,
  loadPhotosFromDatabaseAction,
} from 'containers/Photos/actions';
import {
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE,
  FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS,
  GET_PATIENT_CASE_TEMPLATE_FAILURE,
  GET_PATIENT_CASE_TEMPLATE_SUCCESS,
} from 'containers/Photos/constants';
import { fetchQuestionSets } from 'containers/Questions/actions';
import {
  CASE_QUESTION_SET_TYPE,
  FETCH_QUESTION_SETS_FAILURE,
  FETCH_QUESTION_SETS_SUCCESS,
  MEMBER_QUESTION_SET_TYPE,
} from 'containers/Questions/constants';
import { getVideoCallAction } from 'containers/VideoCall/actions';
import { format } from 'date-fns';
import {
  all,
  call,
  debounce,
  put,
  race,
  take,
  takeLatest,
} from 'redux-saga/effects';
import request from 'utils/request';

export const isVideoCase = (patientCase, patientCaseTemplate) => {
  return patientCase?.required_stages.indexOf(VIDEO_CALL_REQUIRED_STAGE) > -1;
};
export const isScheduledCall = (patientCase, videoCall) => {
  return (
    patientCase?.required_stages.indexOf(SCHEDULE_VIDEO_REQUIRED_STEP) > -1 &&
    videoCall.scheduled_date
  );
};

export const isVideoCaseTemplate = (patientCaseTemplate) => {
  return (
    patientCaseTemplate?.template_base_name === VIDEO_TEMPLATE_BASE_NAME ||
    patientCaseTemplate?.template_base_name ===
      SCHEDULED_VIDEO_TEMPLATE_BASE_NAME
  );
};

export const hasStage = (pc, stageName) =>
  pc?.required_stages.concat(pc?.optional_stages).indexOf(stageName) > -1;

function* getAndWaitForPatientCase(id) {
  yield put(getPatientCase({ id }));
  const [{ payload: pc }, e1] = yield race([
    take(GET_PATIENT_CASE_SUCCESS),
    take(GET_PATIENT_CASE_FAILURE),
  ]);
  if (e1) {
    throw e1;
  }
  return pc;
}
function* getAndWaitForPayment(id) {
  yield put(fetchPaymentsAction({ patientCaseId: id }));
  const [{ payload: payment }, e2] = yield race([
    take(FETCH_PAYMENTS_SUCCESS),
    take(FETCH_PAYMENTS_FAILURE),
  ]);
  if (e2) {
    throw e2;
  }
  return payment;
}

function* loadConsultationData({ payload }) {
  try {
    const { patientCaseId } = payload;
    if (!patientCaseId) {
      return yield put(
        loadConsultationDataFailureAction(
          { message: 'Failed to load consultation data - no ID' },
          payload,
        ),
      );
    }

    // Note: account questions should be retreived on app launch
    const [pc, payment] = yield all([
      getAndWaitForPatientCase(patientCaseId),
      getAndWaitForPayment(patientCaseId),
    ]);
    yield put(loadPhotosFromDatabaseAction({ patientCaseId }));

    if (hasStage(pc, MEMBER_PROFILE_REQUIRED_STAGE)) {
      yield put(fetchQuestionSets({ type: MEMBER_QUESTION_SET_TYPE }));
    }

    if (hasStage(pc, CASE_QUESTIONS_REQUIRED_STAGE)) {
      yield put(
        fetchQuestionSets({
          patientCaseId,
          type: CASE_QUESTION_SET_TYPE,
        }),
      );

      const [{ payload: qs }, e21] = yield race([
        take(FETCH_QUESTION_SETS_SUCCESS),
        take(FETCH_QUESTION_SETS_FAILURE),
      ]);

      if (e21) throw e21;
    }

    yield put(fetchPaymentIntentsAction({ paymentId: payment.id }));

    yield put(
      getPatientCaseTemplateAction({
        patientCaseId,
        templateId: pc.patient_case_template_id,
      }),
    );

    const [success, e3] = yield race([
      take(GET_PATIENT_CASE_TEMPLATE_SUCCESS),
      take(GET_PATIENT_CASE_TEMPLATE_FAILURE),
    ]);

    if (e3) {
      throw e3;
    }

    const { payload: pct } = success;

    if (!pct) {
      throw new Error('Patient case template not found!');
    }
    pc.patientCaseTemplate = pct;

    if (isVideoCaseTemplate(pc)) {
      yield put(
        getVideoCallAction({
          videoCallId: pc.video_call_id,
          patientCaseId: pc.id,
        }),
      );
    }

    if (hasStage(pct, PHOTOS_STAGE)) {
      yield put(fetchCasePhotos({ id: patientCaseId }));
      yield put(
        fetchPatientCaseTemplatePhotoCategories({
          patientCaseTemplateId: pct.id,
        }),
      );

      const [, e4] = yield race([
        take(FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_SUCCESS),
        take(FETCH_PATIENT_CASE_TEMPLATE_PHOTO_CATEGORIES_FAILURE),
      ]);
      if (e4) {
        throw e4;
      }
    }

    yield put(
      loadConsultationDataSuccessAction({
        data: {
          patientCase: pc,
          patientCaseTemplate: pct,
          patientCaseId,
        },
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(
      loadConsultationDataFailureAction(
        { message: 'Failed to load consultation data', error: e },
        payload,
      ),
    );
  }
}

const formatUTCDateTime = (date, time) => {
  const dateString = format(new Date(date), 'yyyy-MM-dd');
  const timeString = format(new Date(time), 'HH:mm');
  return new Date(`${dateString}T${timeString}`).toISOString();
};

function upsertVideoCallRequest(id, body) {
  const method = id ? 'PATCH' : 'POST';
  const endpoint = `v3/video_calls${id ? `/${id}` : '?sort=created_at:DESC'}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  return request(endpoint, requestData);
}

function* upsertVideoCall({ payload }) {
  try {
    const { videoCallId, patientCaseId, date, time } = payload;
    const videoCall = yield call(upsertVideoCallRequest, videoCallId, {
      patient_case_id: patientCaseId,
      scheduled_date: formatUTCDateTime(date, time),
      timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone_offset: new Date().getTimezoneOffset(),
    });
    yield put(upsertVideoCallSuccessAction(videoCall));
  } catch (e) {
    console.error(e);
    yield put(upsertVideoCallFailureAction(e));
  }
}

export default function* consultationSaga() {
  yield takeLatest(LOAD_CONSULTATION_DATA, loadConsultationData);
  yield debounce(2000, UPSERT_VIDEO_CALL, upsertVideoCall);
}
