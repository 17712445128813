import { DEFAULT_THEME_NAME } from 'services/InitialisationService/constants';
import {
  getCampaignId,
  getCompanyCopy,
  getCompanyId,
} from 'utils/localStorageHelper';

export const createLDUser = (_companyCopy, _companyId) => {
  const companyId = _companyId || getCompanyId() || getCampaignId();
  const companyCopy = _companyCopy || getCompanyCopy();

  const identifier = companyId || DEFAULT_THEME_NAME;

  const name = companyCopy?.copy_name || identifier;

  return {
    kind: 'user',
    key: identifier,
    name: name,
    // custom attrs
    case_receiver: companyCopy?.case_receiver,
    copy_name: companyCopy?.copy_name,
    theme_name: companyCopy?.theme_name || DEFAULT_THEME_NAME,
  };
};
