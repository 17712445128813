/*
 *
 * Records reducer
 *
 */

import {
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_FAILURE,
  DOWNLOAD_INVOICE_SUCCESS,
  FETCH_ALL_INVOICES,
  FETCH_ALL_INVOICES_FAILURE,
  FETCH_ALL_INVOICES_SUCCESS,
  FETCH_ALL_RECORDS,
  FETCH_ALL_RECORDS_FAILURE,
  FETCH_ALL_RECORDS_SUCCESS,
  FETCH_PRESCRIPTIONS,
  FETCH_PRESCRIPTIONS_FAILURE,
  FETCH_PRESCRIPTIONS_SUCCESS,
  FETCH_REPORTS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_SUCCESS,
  GET_PRESCRIPTION_DETAILS_FAILURE,
  GET_PRESCRIPTION_DETAILS_SUCCESS,
  GET_REPORT,
  GET_REPORT_FAILURE,
  GET_REPORT_SUCCESS,
  ON_FILTER_CHANGE,
} from './constants';
import { filterOptions } from './helpers';

export const initialState = () => ({
  loading: false,
  reports: {
    data: [],
    fetched: false,
    loading: false,
    error: null,
  },
  loadingReport: false,
  invoices: {
    data: [],
    fetched: false,
    loading: false,
    error: null,
  },
  loadingInvoice: false,
  prescriptions: {
    data: [],
    fetched: false,
    loading: false,
    error: null,
  },
  loadingPrescription: false,
  noRecords: false,
  getReportError: null,
  filterOptionSelected: filterOptions.ALL,
});

function recordsReducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_ALL_RECORDS:
      return { ...state, loading: true, noRecords: false };
    case FETCH_ALL_RECORDS_SUCCESS:
      const { reports, prescriptions, invoices } = state;
      return {
        ...state,
        loading: false,
        noRecords: !(
          reports.data.length ||
          prescriptions.data.length ||
          invoices.data.length
        ),
      };
    case FETCH_ALL_RECORDS_FAILURE:
      return { ...state, loading: false };
    case FETCH_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          fetched: false,
          error: null,
        },
      };
    case FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          data: action.payload,
          fetched: true,
        },
      };
    case FETCH_REPORTS_FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: action.error,
          fetched: true,
        },
      };
    case GET_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
        getReportError: null,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          data: [
            ...state.reports.data.filter((r) => r.id !== action.payload.id),
            action.payload,
          ],
        },
      };
    case GET_REPORT_FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: action.error,
        },
        getReportError: { id: action.payload?.id },
      };
    case FETCH_PRESCRIPTIONS:
      return {
        ...state,
        prescriptions: {
          ...state.prescriptions,
          loading: true,
          fetched: false,
          error: null,
        },
      };
    case FETCH_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        prescriptions: {
          ...state.prescriptions,
          fetched: true,
          loading: false,
        },
      };
    case FETCH_PRESCRIPTIONS_FAILURE:
      return {
        ...state,
        prescriptions: {
          ...state.prescriptions,
          loading: false,
          error: action.error,
          fetched: true,
        },
      };
    case GET_PRESCRIPTION_DETAILS_SUCCESS:
      return fetchPrescriptionDetailsSuccess(state, action);
    case GET_PRESCRIPTION_DETAILS_FAILURE:
      return {
        ...state,
        prescriptions: {
          ...state.prescriptions,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_ALL_INVOICES:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: true,
          fetched: false,
          error: null,
        },
      };
    case FETCH_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: action.payload,
          fetched: true,
        },
      };
    case FETCH_ALL_INVOICES_FAILURE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          error: action.error,
          fetched: true,
        },
      };
    case ON_FILTER_CHANGE:
      return {
        ...state,
        filterOptionSelected: action.payload,
      };
    case DOWNLOAD_INVOICE:
      return state;
    case DOWNLOAD_INVOICE_SUCCESS:
      downloadInvoiceSuccess(state, action.payload);
    case DOWNLOAD_INVOICE_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

const downloadInvoiceSuccess = (state, payload) => {
  const invoiceList = state.invoices.data.map((item) => {
    if (item.id === payload.id) {
      return { ...item, downloadUrl: payload.url };
    } else {
      return item;
    }
  });
  return {
    ...state,
    invoices: {
      ...state.invoices,
      data: invoiceList,
    },
  };
};

const fetchPrescriptionDetailsSuccess = (state, action) => {
  const { payload } = action;
  const obj = state.prescriptions.data.find((item) => item.id === payload.id);
  if (obj) return state;
  return {
    ...state,
    prescriptions: {
      ...state.prescriptions,
      data: [...state.prescriptions.data, payload],
    },
  };
};

export default recordsReducer;
