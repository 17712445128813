import Config from 'config';
import { Z_NAVBAR, Z_NAVBAR_LOGO } from 'constants/zIndex';
import styled, { css } from 'styled-components';

export const NAVBAR_HEIGHT_SM = 54;
export const NAVBAR_HEIGHT_LG = 68;

export default styled.nav`
  align-items: center;
  ${Config.isProfessional && 'background: rgba(255, 255, 255, 0.7)'};
  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  height: ${NAVBAR_HEIGHT_SM}px;
  flex-direction: row;
  min-height: ${NAVBAR_HEIGHT_SM}px;
  padding: 4px;
  z-index: ${Z_NAVBAR};

  img {
    z-index: ${Z_NAVBAR_LOGO};
    height: ${NAVBAR_HEIGHT_SM - 8}px;
  }

  & > div {
    align-items: center;
    display: grid;
    grid-template-columns: 35px auto 35px;
    min-height: ${NAVBAR_HEIGHT_SM}px;
    height: ${NAVBAR_HEIGHT_SM}px;
  }

  ${({ theme }) =>
    theme.breakpoints.minSm(css`
      margin-bottom: 0;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: ${NAVBAR_HEIGHT_SM}px;
        height: ${NAVBAR_HEIGHT_SM}px;
      }
    `)};
`;
