/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.NotFoundPage.header',
    defaultMessage: 'Page not found.',
  },
  body: {
    id: 'app.NotFoundPage.body',
    defaultMessage:
      "Can't find what you're looking for? Contact our support team using the chat feature on the bottom right of the page.",
  },
});
