import { differenceInYears, format, isValid, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const getAge = (dob) => {
  const date = parse(dob, 'yyyy-MM-dd', new Date());
  if (!isValid(date)) {
    console.warn(`Invalid date: ${date}`);
    throw new Error('Invalid date');
  }
  return differenceInYears(new Date(), date);
};

export const getDateString = (date) => {
  const d = new Date(date);
  if (!isValid(d)) {
    console.warn(`Invalid date: ${d}`);
    throw new Error('Invalid date');
  }
  return format(d, 'cccc, do MMMM yyyy');
};

export const timeZoneAbbreviated = (timeZoneName) => {
  return formatInTimeZone(
    new Date(),
    timeZoneName || Intl?.DateTimeFormat().resolvedOptions().timeZone,
    'zzz',
  );
};

export const getTimeString = (date, timeZoneName) => {
  const d = new Date(date);
  if (!isValid(d)) {
    console.warn(`Invalid time: ${d}`);
    throw new Error(`Invalid time`);
  }
  return `${formatInTimeZone(
    d,
    timeZoneName || Intl?.DateTimeFormat().resolvedOptions().timeZone,
    'hh:mm aa',
  )} (${timeZoneAbbreviated(timeZoneName)})`;
};

/*
 Returns a string of form Apr 29, 2023 01:00 PM
 */
export const getMedDateTimeString = (date) => {
  const d = new Date(date);
  if (!isValid(d)) {
    console.warn(`Invalid date: ${d}`);
    throw new Error('Invalid date');
  }
  return format(d, 'PPp');
};
