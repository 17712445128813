/*
 *
 * LaunchDarklyUpdater constants
 *
 */

export const DEFAULT_ACTION = 'app/LaunchDarklyUpdater/DEFAULT_ACTION';

export const GET_COMPANY_COPY = 'app/LaunchDarklyUpdater/GET_COMPANY_COPY';
export const GET_COMPANY_COPY_SUCCESS =
  'app/LaunchDarklyUpdater/GET_COMPANY_COPY_SUCCESS';
export const GET_COMPANY_COPY_FAILURE =
  'app/LaunchDarklyUpdater/GET_COMPANY_COPY_FAILURE';

export const UPDATE_CAMPAIGN_ID = 'app/LaunchDarklyUpdater/UPDATE_CAMPAIGN_ID';
export const UPDATE_CAMPAIGN_ID_SUCCESS =
  'app/LaunchDarklyUpdater/UPDATE_CAMPAIGN_ID_SUCCESS';
export const UPDATE_CAMPAIGN_ID_FAILURE =
  'app/LaunchDarklyUpdater/UPDATE_CAMPAIGN_ID_FAILURE';

export const UPDATE_CAMPAIGN_COPY_MAP =
  'app/LaunchDarklyUpdater/UPDATE_CAMPAIGN_COPY_MAP';

export const DEFAULT_CAMPAIGN_IDENTIFIER = 'toothpic-consumer';
