/*
 *
 * LanguageProvider reducer
 *
 */

import {
  CHANGE_CURRENCY,
  UPDATE_LOCALE_DATA,
} from 'containers/LanguageProvider/constants';
import {
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
} from 'locale-constants';
import keys from 'utils/localStorageKeys.json';
import { CHANGE_COUNTRY, CHANGE_LOCALE } from './constants';

export const initialState = () => ({
  locale: localStorage.getItem(keys.locale) || DEFAULT_LOCALE,
  country: localStorage.getItem(keys.country) || DEFAULT_COUNTRY,
  currency: localStorage.getItem(keys.currency) || DEFAULT_CURRENCY,
});

function languageProviderReducer(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale };
    case CHANGE_COUNTRY:
      return { ...state, country: action.country };
    case CHANGE_CURRENCY:
      return { ...state, currency: action.currency };
    case UPDATE_LOCALE_DATA:
      return {
        ...state,
        currency: action.currency,
        country: action.country,
        locale: action.locale,
      };
    default:
      return state;
  }
}

export default languageProviderReducer;
