/*
 *
 * LaunchDarklyUpdater actions
 *
 */

import {
  DEFAULT_ACTION,
  GET_COMPANY_COPY,
  GET_COMPANY_COPY_FAILURE,
  GET_COMPANY_COPY_SUCCESS,
  UPDATE_CAMPAIGN_COPY_MAP,
  UPDATE_CAMPAIGN_ID,
  UPDATE_CAMPAIGN_ID_FAILURE,
  UPDATE_CAMPAIGN_ID_SUCCESS,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateCampaignIdentifier(payload) {
  return {
    type: UPDATE_CAMPAIGN_ID,
    payload,
  };
}

export function updateCampaignIdentifierSuccess(campaignId, campaignIsUrl) {
  return {
    type: UPDATE_CAMPAIGN_ID_SUCCESS,
    payload: { campaignId, campaignIsUrl },
  };
}

export function updateCampaignIdentifierFailure(campaignId) {
  return {
    type: UPDATE_CAMPAIGN_ID_FAILURE,
    payload: { campaignId },
  };
}

export function getCompanyCopy(campaignId, companyId) {
  return {
    type: GET_COMPANY_COPY,
    payload: { campaignId, companyId },
  };
}

export function getCompanyCopySuccess(copy, campaignId, companyId) {
  return {
    type: GET_COMPANY_COPY_SUCCESS,
    payload: { copy, campaignId, companyId },
  };
}

export function getCompanyCopyFailure(error, campaignId) {
  return {
    type: GET_COMPANY_COPY_FAILURE,
    error,
    payload: { campaignId },
  };
}

export function updateCompanyCopyCampaignMap(payload) {
  return { type: UPDATE_CAMPAIGN_COPY_MAP, payload };
}
