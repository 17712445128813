/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { CookieBanner } from '@oraleye/frontend-modules-components';
import AppWrapper from 'components/AppWrapper';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import { ScrollToTop } from 'utils/ScrollToTop';

export const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop />
        <AppWrapper>
          <Routes />

          {/* <!-- the modal hooks into this div --> */}
          <div id="modal"></div>
          <CookieBanner />
        </AppWrapper>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
