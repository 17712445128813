import { takeLatest } from 'redux-saga/effects';
import keys from 'utils/localStorageKeys.json';
import {
  CHANGE_COUNTRY,
  CHANGE_CURRENCY,
  CHANGE_LOCALE,
  UPDATE_LOCALE_DATA,
} from './constants';

function* changeCountry({ country }) {
  window.localStorage.setItem(keys.country, country);
  yield country;
}

function* changeLocale({ locale }) {
  window.localStorage.setItem(keys.locale, locale);
  yield locale;
}

function* changeCurrency({ currency }) {
  window.localStorage.setItem(keys.currency, currency);
  yield currency;
}

function* updateLocaleData({ country, currency, locale }) {
  window.localStorage.setItem(keys.currency, currency);
  window.localStorage.setItem(keys.locale, locale);
  window.localStorage.setItem(keys.country, country);
  yield { country, currency, locale };
}

export default function* languageSaga() {
  yield takeLatest(CHANGE_COUNTRY, changeCountry);
  yield takeLatest(CHANGE_LOCALE, changeLocale);
  yield takeLatest(CHANGE_CURRENCY, changeCurrency);
  yield takeLatest(UPDATE_LOCALE_DATA, updateLocaleData);
}
