import { Card } from '@oraleye/frontend-modules-components';
import React from 'react';
import { isSupported } from 'twilio-video';

import { ReactComponent as GreenTick } from 'images/icon-check-circle.svg';
import { ReactComponent as RedX } from 'images/icon-error-exclamation-circle.svg';
import { FormattedMessage } from 'react-intl';
import { AudioInputTest } from './AudioInputTest';
import AudioOutputTest from './AudioOutputTest';
import { VideoInputTest } from './VideoInputTest';
import messages from './messages';
import { CompatWrapper, DiagnosticsWrapper } from './styled';
import { useMediaPermissions } from './useMediaPermissions';

const BrowserVideoCompatability = () => {
  return (
    <CompatWrapper>
      {isSupported ? (
        <div>
          <GreenTick />
          <p>
            <FormattedMessage {...messages.yourBrowserIsSupported} />
          </p>
        </div>
      ) : (
        <div>
          <RedX />
          <p>
            <FormattedMessage {...messages.yourBrowserIsNotSupported} />
          </p>
        </div>
      )}
    </CompatWrapper>
  );
};

const Diagnostics = () => {
  const { askForPermissions, permissions, devices } = useMediaPermissions();

  return (
    <DiagnosticsWrapper>
      <Card>
        <h3>
          <FormattedMessage {...messages.browserVideoCompatibility} />
        </h3>
        <BrowserVideoCompatability />
      </Card>
      <Card>
        <h3>
          <FormattedMessage {...messages.videoInputTest} />
        </h3>
        <VideoInputTest
          permission={permissions.video}
          devices={devices.video}
          selectedDeviceId={devices.selectedVideoInput}
          askForPermissions={askForPermissions}
        />
      </Card>
      <Card>
        <h3>
          <FormattedMessage {...messages.audioInputTest} />
        </h3>
        <AudioInputTest
          permission={permissions.audio}
          devices={devices.audioInput}
          selectedDeviceId={devices.selectedAudioInput}
          askForPermissions={askForPermissions}
        />
      </Card>
      <Card>
        <h3>
          <FormattedMessage {...messages.audioOutputTest} />
        </h3>
        <AudioOutputTest
          permission={permissions.audio}
          devices={devices.audioOutput}
          selectedDeviceId={devices.selectedAudioInput}
        />
      </Card>
    </DiagnosticsWrapper>
  );
};

export default Diagnostics;
