/*
 *
 * MemberProfile actions
 *
 */

import {
  CREATE_ELIGIBILITY_REQUEST,
  CREATE_ELIGIBILITY_REQUEST_FAILURE,
  CREATE_ELIGIBILITY_REQUEST_SUCCESS,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES_FAILURE,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES_SUCCESS,
  FETCH_MEMBER_ELIGIBILITIES,
  FETCH_MEMBER_ELIGIBILITIES_FAILURE,
  FETCH_MEMBER_ELIGIBILITIES_SUCCESS,
  FETCH_MEMBER_INSURANCE_DETAILS,
  FETCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
  FETCH_REGIONS,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_SUCCESS,
  PATCH_MEMBER,
  PATCH_MEMBER_FAILURE,
  PATCH_MEMBER_INSURANCE_DETAILS,
  PATCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  PATCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
  PATCH_MEMBER_SUCCESS,
} from './constants';

export const fetchMemberInsuranceDetailsAction = () => ({
  type: FETCH_MEMBER_INSURANCE_DETAILS,
});

export const fetchMemberInsuranceDetailsSuccess = (payload) => ({
  type: FETCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
  payload,
});

export const fetchMemberInsuranceDetailsFailure = (payload) => ({
  type: FETCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  payload,
});

export const fetchCoverageProviderPlanCodesAction = () => ({
  type: FETCH_COVERAGE_PROVIDER_PLAN_CODES,
});

export const fetchCoverageProviderPlanCodesSuccess = (payload) => ({
  type: FETCH_COVERAGE_PROVIDER_PLAN_CODES_SUCCESS,
  payload,
});

export const fetchCoverageProviderPlanCodesFailure = (payload) => ({
  type: FETCH_COVERAGE_PROVIDER_PLAN_CODES_FAILURE,
  payload,
});

export const patchMemberAction = (payload) => ({
  type: PATCH_MEMBER,
  payload,
});

export const patchMemberSuccess = (payload) => ({
  type: PATCH_MEMBER_SUCCESS,
  payload,
});

export const patchMemberFailure = (error) => ({
  type: PATCH_MEMBER_FAILURE,
  error,
});

export const patchMemberInsuranceDetailsAction = (payload) => ({
  type: PATCH_MEMBER_INSURANCE_DETAILS,
  payload,
});

export const patchMemberInsuranceDetailsSuccess = (payload) => ({
  type: PATCH_MEMBER_INSURANCE_DETAILS_SUCCESS,
  payload,
});

export const patchMemberInsuranceDetailsFailure = (error) => ({
  type: PATCH_MEMBER_INSURANCE_DETAILS_FAILURE,
  error,
});

export const fetchRegionsAction = (payload) => ({
  type: FETCH_REGIONS,
  payload,
});

export const fetchRegionsSuccess = (payload) => ({
  type: FETCH_REGIONS_SUCCESS,
  payload,
});

export const fetchRegionsFailure = (error) => ({
  type: FETCH_REGIONS_FAILURE,
  error,
});

export const fetchMemberEligibilitiesAction = (payload) => ({
  type: FETCH_MEMBER_ELIGIBILITIES,
  payload,
});

export const fetchMemberEligibilitiesSuccess = (payload) => ({
  type: FETCH_MEMBER_ELIGIBILITIES_SUCCESS,
  payload,
});

export const fetchMemberEligibilitiesFailure = (error) => ({
  type: FETCH_MEMBER_ELIGIBILITIES_FAILURE,
  error,
});

export const createEligibilityRequestAction = (payload) => ({
  type: CREATE_ELIGIBILITY_REQUEST,
  payload,
});

export const createEligibilityRequestSuccess = (payload) => ({
  type: CREATE_ELIGIBILITY_REQUEST_SUCCESS,
  payload,
});

export const createEligibilityRequestFailure = (error) => ({
  type: CREATE_ELIGIBILITY_REQUEST_FAILURE,
  error,
});
