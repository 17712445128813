/*
 *
 * Pharmacy actions
 *
 */

import {
  CONFIRM_PHARMACY_SELECTED,
  CONFIRM_PHARMACY_SELECTED_FAILURE,
  CONFIRM_PHARMACY_SELECTED_SUCCESS,
  GET_MEMBER_PHARMACIES,
  GET_MEMBER_PHARMACIES_FAILURE,
  GET_MEMBER_PHARMACIES_SUCCESS,
  GET_PHARMACY_LIST,
  GET_PHARMACY_LIST_FAILURE,
  GET_PHARMACY_LIST_SUCCESS,
  REMOVE_PHARMACY,
  REMOVE_PHARMACY_FAILURE,
  REMOVE_PHARMACY_SUCCESS,
  SELECT_PHARMACY,
  SELECT_PHARMACY_FAILURE,
  SELECT_PHARMACY_SUCCESS,
} from './constants';

export const getPharmacyList = (payload) => ({
  type: GET_PHARMACY_LIST,
  payload,
});

export const getPharmacyListSuccess = (payload) => ({
  type: GET_PHARMACY_LIST_SUCCESS,
  payload,
});

export const getPharmacyListFailure = (error) => ({
  type: GET_PHARMACY_LIST_FAILURE,
  error,
});

export const selectPharmacy = (payload) => ({
  type: SELECT_PHARMACY,
  payload,
});

export const selectPharmacySuccess = (payload) => ({
  type: SELECT_PHARMACY_SUCCESS,
  payload,
});

export const selectPharmacyFailure = (error) => ({
  type: SELECT_PHARMACY_FAILURE,
  error,
});

export const getMemberPharmacies = (payload) => ({
  type: GET_MEMBER_PHARMACIES,
  payload,
});

export const getMemberPharmaciesSuccess = (payload) => ({
  type: GET_MEMBER_PHARMACIES_SUCCESS,
  payload,
});

export const getMemberPharmaciesFailure = (error) => ({
  type: GET_MEMBER_PHARMACIES_FAILURE,
  error,
});

export const removePharmacy = (payload) => ({
  type: REMOVE_PHARMACY,
  payload,
});

export const removePharmacySuccess = (payload) => ({
  type: REMOVE_PHARMACY_SUCCESS,
  payload,
});

export const removePharmacyFailure = (error) => ({
  type: REMOVE_PHARMACY_FAILURE,
  error,
});

export const confirmPharmacySelected = (payload) => ({
  type: CONFIRM_PHARMACY_SELECTED,
  payload,
});

export const confirmPharmacySelectedSuccess = (payload) => ({
  type: CONFIRM_PHARMACY_SELECTED_SUCCESS,
  payload,
});

export const confirmPharmacySelectedFailure = (error) => ({
  type: CONFIRM_PHARMACY_SELECTED_FAILURE,
  error,
});
