/*
 *
 * LaunchDarklyUpdater reducer
 *
 */
import Config from 'config';
import { LOGIN_REQUEST_SUCCESS } from 'containers/Auth/AuthRouter/constants';
import {
  getCampaignId,
  getCampaignIsUrl,
  getCampaignOrCompanyIdCopyMap,
  getCompanyCopy,
  getCompanyId,
  getThemeName,
  setCampaignId,
  setThemeName,
} from 'utils/localStorageHelper';
import {
  GET_COMPANY_COPY,
  GET_COMPANY_COPY_FAILURE,
  GET_COMPANY_COPY_SUCCESS,
  UPDATE_CAMPAIGN_COPY_MAP,
  UPDATE_CAMPAIGN_ID,
  UPDATE_CAMPAIGN_ID_FAILURE,
  UPDATE_CAMPAIGN_ID_SUCCESS,
} from './constants';

export const initialState = () => ({
  error: null,
  loading: false,
  companyCopy: getCompanyCopy(),
  campaignId: getCampaignId(),
  companyId: getCompanyId(),
  campaignIsUrl: getCampaignIsUrl(),
  campaignCopyMap: getCampaignOrCompanyIdCopyMap(),
  copyLoaded: !!getCompanyCopy(),
  ...Config,
});

const CACHE_TIME = 1000 * 60 * 5;

function campaignGateReducer(state = initialState(), action) {
  switch (action.type) {
    case GET_COMPANY_COPY:
      return {
        ...state,
        loading: !state.companyCopy,
        copyLoaded: !state.companyCopy,
      };
    case GET_COMPANY_COPY_SUCCESS:
      setThemeName(action.payload?.copy?.theme_name || getThemeName());
      return {
        ...state,
        loading: false,
        companyCopy: action.payload?.copy,
        copyLoaded: true,
        theme: action.payload?.copy?.theme_name,
        ...Config,
      };
    case GET_COMPANY_COPY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        copyLoaded: true,
      };
    case UPDATE_CAMPAIGN_ID:
      setCampaignId(
        action.payload.campaignId || action.payload.companyId,
        action.payload.campaignIsUrl,
      );
      return {
        ...state,
        loading: false,
        campaignId: action.payload.campaignId || action.payload.companyId,
        campaignIsUrl: action.payload.campaignIsUrl,
      };
    case UPDATE_CAMPAIGN_ID_SUCCESS:
      setCampaignId(action.payload.campaignId);
      const s = {
        ...state,
        loading: false,
        campaignId: action.payload.campaignId,
        campaignIsUrl: action.payload.campaignIsUrl || false,
        ...Config,
      };
      return s;
    case UPDATE_CAMPAIGN_ID_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UPDATE_CAMPAIGN_COPY_MAP:
      return {
        ...state,
        campaignCopyMap: {
          ...state.campaignCopyMap,
          [action.payload.campaignId || action.payload.companyId]: {
            copy: action.payload.copy,
            exp: +new Date() + CACHE_TIME,
          },
        },
      };
    case LOGIN_REQUEST_SUCCESS: {
      return { ...state, campaignId: getCampaignId() };
    }
    default:
      return state;
  }
}

export default campaignGateReducer;
