import { LD_UI_SIDE_MENU } from 'constants/launchDarklyFlags';
import {
  CONSULTATION_VIDEO_CALL_PAGE,
  CREATE_ACCOUNT_PAGE,
  FOLLOW_UP_LANDING_PAGE,
  LOGIN_PAGE,
  NOT_FOUND_PAGE,
  PATIENT_CASE_LANDING_PAGE,
  TRIAGE_LANDING_PAGE,
} from 'constants/pageIdentifiers';

const hideHeaderNavigationRoutes = [
  CREATE_ACCOUNT_PAGE,
  LOGIN_PAGE,
  FOLLOW_UP_LANDING_PAGE,
  NOT_FOUND_PAGE,
  TRIAGE_LANDING_PAGE,
];
const hideSideMenuNavigationRoutes = [CREATE_ACCOUNT_PAGE, LOGIN_PAGE];
const hideProfileNavigationRoutes = [CREATE_ACCOUNT_PAGE, LOGIN_PAGE];
const hideFooterNavigationRoutes = [
  CONSULTATION_VIDEO_CALL_PAGE,
  CREATE_ACCOUNT_PAGE,
  // Note: these are handled within the authpage
  LOGIN_PAGE,
  TRIAGE_LANDING_PAGE,
  FOLLOW_UP_LANDING_PAGE,
  PATIENT_CASE_LANDING_PAGE.replace(':id', ''),
];

const isHiddenOnPage = (routes, path) => {
  let isHidden = routes.some((url) => path.includes(url));
  return isHidden;
};

export const shouldShowHeaderNavigation = (path) => {
  return !isHiddenOnPage(hideHeaderNavigationRoutes, path);
};

export const shouldShowFooterNavigation = (path) => {
  return !isHiddenOnPage(hideFooterNavigationRoutes, path);
};

export const shouldShowProfileMenu = (path, isAuthenticated, flags) => {
  return (
    isAuthenticated &&
    flags[LD_UI_SIDE_MENU] &&
    !isHiddenOnPage(hideProfileNavigationRoutes, path)
  );
};

export const showSideMenu = (path, isAuthenticated, flags) => {
  return (
    flags[LD_UI_SIDE_MENU] &&
    isAuthenticated &&
    !isHiddenOnPage(hideSideMenuNavigationRoutes, path, flags)
  );
};

const shouldShowNavigation = (path = '', isAuthenticated, flags) => {
  return {
    showHeader: shouldShowHeaderNavigation(location.pathname),
    showFooter: shouldShowFooterNavigation(location.pathname),
    showProfileMenu: shouldShowProfileMenu(
      location.pathname,
      isAuthenticated,
      flags,
    ),
    showSideMenu: showSideMenu(path, isAuthenticated, flags),
  };
};

export default shouldShowNavigation;
