/**
 * Create the store with dynamic reducers
 */
import authSaga from 'containers/Auth/AuthRouter/saga';
import checkoutSaga from 'containers/Consultation/Checkout/saga';
import consultationSaga from 'containers/Consultation/saga';
import getCareSaga from 'containers/GetCare/saga';
import homeSaga from 'containers/Home/saga';
import languageSaga from 'containers/LanguageProvider/saga';
import ldUpdaterSaga from 'containers/LaunchDarklyUpdater/saga';
import medicalInformationSaga from 'containers/MedicalInformation/saga';
import memberProfileSaga from 'containers/MemberProfile/saga';
import notificationsSaga from 'containers/Notifications/saga';
import pharmacySaga from 'containers/Pharmacy/saga';
import photosSaga from 'containers/Photos/saga';
import questionsSaga from 'containers/Questions/saga';
import videoCallSaga from 'containers/VideoCall/saga';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogzSaga from 'services/LogEvents/saga';
import history from 'utils/history';
import { rollbarSaga } from 'utils/rollbarHelpers';
import createReducer, { initialRootReducerState } from './reducers';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialData = {}) {
  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const reducers = createReducer();
  const store = createStore(
    reducers,
    initialRootReducerState(initialData),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {
    ...reducers,
  }; // Reducer registry
  store.injectedSagas = {
    auth: authSaga,
    language: languageSaga,
    getCare: getCareSaga,
    photos: photosSaga,
    rollbar: rollbarSaga,
    notifications: notificationsSaga,
    ldUpdater: ldUpdaterSaga,
    questions: questionsSaga,
    home: homeSaga,
    memberProfile: memberProfileSaga,
    checkout: checkoutSaga,
    pharmacy: pharmacySaga,
    logz: LogzSaga,
    consultations: consultationSaga,
    medicalInformation: medicalInformationSaga,
    videoCall: videoCallSaga,
  }; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers, history));
    });
  }
  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(rollbarSaga);
  sagaMiddleware.run(notificationsSaga);
  sagaMiddleware.run(ldUpdaterSaga);
  sagaMiddleware.run(getCareSaga);
  sagaMiddleware.run(photosSaga);
  sagaMiddleware.run(questionsSaga);
  sagaMiddleware.run(homeSaga);
  sagaMiddleware.run(memberProfileSaga);
  sagaMiddleware.run(checkoutSaga);
  sagaMiddleware.run(pharmacySaga);
  sagaMiddleware.run(LogzSaga);
  sagaMiddleware.run(languageSaga);
  sagaMiddleware.run(consultationSaga);
  sagaMiddleware.run(medicalInformationSaga);
  sagaMiddleware.run(videoCallSaga);

  return store;
}

export { history };
