import {
  FETCH_HOME_DATA,
  FETCH_HOME_DATA_FAILURE,
  FETCH_HOME_DATA_SUCCESS,
} from 'containers/Home/constants';

export const fetchHomeDataAction = () => ({ type: FETCH_HOME_DATA });
export const fetchHomeDataFailureAction = (error, payload) => ({
  type: FETCH_HOME_DATA_FAILURE,
  error,
});
export const fetchHomeDataSuccessAction = (payload) => ({
  type: FETCH_HOME_DATA_SUCCESS,
  payload,
});
