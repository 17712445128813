import { keyBy } from 'lodash';
import { sendLogForwarder } from 'services/LogEvents/saga';
import isDevEnv from 'utils/isDevEnv';
import { getCompanyId, getMemberId } from 'utils/localStorageHelper';
import eventFilter from './eventFilter';
import events from './events.json';

export const createEventNameMap = (events) =>
  keyBy(Object.values(events), 'event_name');

const EventNameMap = createEventNameMap(events);

export const findDispatchedEventData = (sentEvent) => {
  if (!sentEvent.event_name && !sentEvent.page) {
    throw `Event not found: ${JSON.stringify(sentEvent)}`;
  }
  sentEvent.event_name = sentEvent.event_name || sentEvent.page;
  const ev = EventNameMap[sentEvent.event_name];
  if (!ev) {
    throw `Event not found: ${sentEvent.event_name}\n${JSON.stringify(
      sentEvent,
    )}`;
  }
  return ev;
};

const sendToSegment = (event, data) => {
  const type = event.type;

  if (type.toLowerCase() === 'identify') {
    if (window.debugEvents) {
      window.tpDataLayer.push(['identify', event, data, +new Date()]);
    }
    return window.analytics?.identify(data.user_id, data);
  }
  if (type.toLowerCase() === 'page') {
    if (window.debugEvents) {
      window.tpDataLayer.push(['page', event, data, +new Date()]);
    }
    return window.analytics?.page(event.event_name, data);
  }
  if (type.toLowerCase() === 'track') {
    if (window.debugEvents) {
      window.tpDataLayer.push(['track', event, data, +new Date()]);
    }
    return window.analytics?.track(event.event_name, data);
  }
  throw `Incorrect tracking event type: ${type}`;
};

/**
 * Send event data to Logz.
 * Add in context data, href, pathname, origin, search, referrer, user_agent, log_source, user_klass, user_id (member_id), company_id,
 * Send event data: event_type, event_name, any additional fields listed in the event taxonomy
 * @param event
 * @param data
 */
const sendToLogz = (event, data) => {
  sendLogForwarder({
    referrer: document.referrer,
    user_agent: window.navigator.userAgent,
    href: window.location.href,
    pathname: window.location.pathname,
    origin: window.location.origin,
    search: window.location.search,
    log_source: 'web_segment_event',
    user_klass: 'member',
    user_id: getMemberId(),
    company_id: getCompanyId(),
    event_type: event.type,
    event_name: event.event_name,
    ...data,
  });
};

const eventSend = (receivedData) => {
  try {
    const event = findDispatchedEventData(receivedData);
    const data = eventFilter(event, receivedData);

    if (isDevEnv) {
      console.log('eventSend', event, data);
    } else {
      sendToSegment(event, data);
      sendToLogz(event, data);
    }
  } catch (e) {
    console.error(e);
    window.Rollbar?.error(e);
    if (window.debugEvents) {
      window.tpDataLayer.push(['error', e, receivedData, +new Date()]);
    }
  }
};

window.eventSend = eventSend;

export default eventSend;
