/**
 *
 * Create Triage Case
 *
 */
import useHistoryPush from 'components/LinkWithQueryParams/useHistoryPush';
import Loader from 'components/Loader';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import {
  CREATE_TRIAGE_CASE,
  HOME_PAGE,
  SETUP_TYPE_PAGE,
} from 'constants/pageIdentifiers';
import {
  fetchPatientCaseTemplatesAction,
  fetchPatientCasesAction,
  newPatientCaseAction,
} from 'containers/GetCare/actions';
import {
  selectNewPatientCaseError,
  selectPatientCaseTemplatesFetched,
  selectPatientCasesFetched,
  selectTriagePatientCaseTemplate,
} from 'containers/GetCare/selectors';
import { ACCOUNT_QUESTION_SET_TYPE } from 'containers/Questions/constants';
import AuthContext from 'contexts/Auth.context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

export const TriageCase = ({ match }) => {
  const push = useHistoryPush();
  const dispatch = useDispatch();
  const patientCasesFetched = useSelector(selectPatientCasesFetched());
  const patientCaseTemplatesFetched = useSelector(
    selectPatientCaseTemplatesFetched(),
  );
  const triageTemplate = useSelector(selectTriagePatientCaseTemplate());
  const createCaseError = useSelector(selectNewPatientCaseError());

  useEffect(() => {
    if (!patientCasesFetched) {
      dispatch(fetchPatientCasesAction());
    }
  }, [dispatch, patientCasesFetched]);

  useEffect(() => {
    if (!patientCaseTemplatesFetched) {
      dispatch(fetchPatientCaseTemplatesAction());
    }
  }, [dispatch, patientCaseTemplatesFetched]);

  const [creatingCase, setCreatingCase] = useState(false);
  const [accountRedirect, setAccountRedirect] = useState(null);
  const { member, accountProfileComplete } = useContext(AuthContext);

  useEffect(() => {
    if (undefined === accountProfileComplete) {
      return;
    }
    if (accountProfileComplete) {
      setAccountRedirect(false);
      return;
    }
    setAccountRedirect({
      pathname: SETUP_TYPE_PAGE.replace(':type', ACCOUNT_QUESTION_SET_TYPE),
      state: {
        redirectTo: CREATE_TRIAGE_CASE,
        type: ACCOUNT_QUESTION_SET_TYPE,
      },
      replace: true,
    });
  }, [member, accountProfileComplete]);

  useEffect(() => {
    if (
      !creatingCase &&
      triageTemplate &&
      patientCasesFetched &&
      accountRedirect === false
    ) {
      dispatch(
        newPatientCaseAction({
          patient_case_template_id: triageTemplate.id,
          member_id: member.id,
          continueCase: false,
          push,
        }),
      );
      setCreatingCase(true);
    }
  }, [
    accountRedirect,
    match,
    member,
    triageTemplate,
    patientCasesFetched,
    creatingCase,
    push,
    dispatch,
  ]);

  if (accountRedirect) {
    return <RedirectWithQuery to={accountRedirect} replace />;
  }

  if (createCaseError) {
    return <RedirectWithQuery to={HOME_PAGE} replace />;
  }

  return (
    <>
      <Helmet
        title="Start a consultation with Toothpic"
        description="Start a dental consultation with Toothpic"
      />
      <Loader />
    </>
  );
};

TriageCase.propTypes = {
  match: PropTypes.any,
};
