/**
 *
 * Link
 *
 */

import { withTrackingId } from '@oraleye/frontend-modules-utils';
import { LinkWithQuery } from 'components/LinkWithQueryParams';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Link = styled(LinkWithQuery)`
  text-decoration: none;
  &:visited,
  & {
    color: ${({ color }) => (color ? color : 'var(--primary01)')};
  }
`;

export default withTrackingId(Link);

Link.propTypes = {
  trackingId: PropTypes.string,
  to: PropTypes.any,
};
