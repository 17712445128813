import Loader from 'components/Loader';
import Config from 'config';
import React from 'react';
import { Helmet } from 'react-helmet';

const Component = React.lazy(() => import('./index'));

export default (props) => (
  <React.Suspense fallback={<Loader />}>
    <Helmet title={`Signed out - ${Config.companyName}`} />
    <Component {...props} />
  </React.Suspense>
);
