import { patchPaymentsAction } from 'containers/Consultation/Checkout/actions';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import {
  createEligibilityRequestFailure,
  createEligibilityRequestSuccess,
  fetchCoverageProviderPlanCodesFailure,
  fetchCoverageProviderPlanCodesSuccess,
  fetchMemberEligibilitiesFailure,
  fetchMemberEligibilitiesSuccess,
  fetchMemberInsuranceDetailsFailure,
  fetchMemberInsuranceDetailsSuccess,
  fetchRegionsFailure,
  fetchRegionsSuccess,
  patchMemberFailure,
  patchMemberInsuranceDetailsFailure,
  patchMemberInsuranceDetailsSuccess,
  patchMemberSuccess,
} from './actions';

import {
  COVERAGE_PROVIDER_PLAN_CODES_ENDPOINT,
  CREATE_ELIGIBILITY_REQUEST,
  ELIGIBILITY_REQUEST_ENDPOINT,
  FETCH_COVERAGE_PROVIDER_PLAN_CODES,
  FETCH_MEMBER_ELIGIBILITIES,
  FETCH_MEMBER_INSURANCE_DETAILS,
  FETCH_REGIONS,
  MEMBER_ELIGIBILITIES_ENDPOINT,
  MEMBER_ELIGIBILITIES_POLLING_TIME,
  MEMBER_INSURANCE_DETAILS_ENDPOINT,
  MEMBERS_ENDPOINT,
  PATCH_MEMBER,
  PATCH_MEMBER_INSURANCE_DETAILS,
  REGIONS_ENDPOINT,
} from './constants';

function* fetchMemberInsuranceDetails() {
  const method = 'GET';
  const endpoint = `${MEMBER_INSURANCE_DETAILS_ENDPOINT}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchMemberInsuranceDetailsSuccess(response[0]));
  } catch (e) {
    console.error(e);
    yield put(fetchMemberInsuranceDetailsFailure(e));
  }
}

function* patchMemberInsuranceDetails({ payload }) {
  const {
    memberInsuranceDetailsId,
    subscriberNumber,
    coverageProvider,
    planCode,
    subscriberType,
    socialSecurityNumber,
    paymentId,
  } = payload;
  const method = 'PATCH';
  const endpoint = `${MEMBER_INSURANCE_DETAILS_ENDPOINT}/${memberInsuranceDetailsId}`;
  const headers = new Headers();
  const body = {
    subscriber_number: subscriberNumber,
    coverage_provider: coverageProvider,
    plan_code: planCode,
    subscriber_type: subscriberType,
    social_security_number: socialSecurityNumber,
  };
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(patchMemberInsuranceDetailsSuccess(response));
    if (paymentId) {
      yield put(
        patchPaymentsAction({
          paymentId,
          outOfPocket: false,
        }),
      );
    }
  } catch (e) {
    console.error(e);
    yield put(patchMemberInsuranceDetailsFailure(e));
  }
}

function* fetchCoverageProviderPlanCodes() {
  const method = 'GET';
  const endpoint = `${COVERAGE_PROVIDER_PLAN_CODES_ENDPOINT}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchCoverageProviderPlanCodesSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(fetchCoverageProviderPlanCodesFailure(e));
  }
}

function* patchMember({ payload }) {
  const { memberId, firstName, lastName, dob } = payload;
  const method = 'PATCH';
  const endpoint = `${MEMBERS_ENDPOINT}/${memberId}`;
  const headers = new Headers();
  const body = {
    first_name: firstName,
    last_name: lastName,
    dob: dob,
  };
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(patchMemberSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(patchMemberFailure(e));
  }
}

function* fetchRegions() {
  const method = 'GET';
  const endpoint = `${REGIONS_ENDPOINT}?countries[]=US&countries[]=Guam&countries[]=Ireland&limit=100`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchRegionsSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(fetchRegionsFailure(e));
  }
}

function* createEligibilityRequest() {
  const method = 'POST';
  const endpoint = `${ELIGIBILITY_REQUEST_ENDPOINT}`;
  const headers = new Headers();
  const body = {};
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  return yield call(request, endpoint, requestData);
}

function* fetchEligibilityRequest() {
  const method = 'GET';
  const endpoint = `${ELIGIBILITY_REQUEST_ENDPOINT}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return yield call(request, endpoint, requestData);
}

function* fetchMemberEligibility(memberEligibilityId) {
  const method = 'GET';
  const endpoint = `${MEMBER_ELIGIBILITIES_ENDPOINT}/${memberEligibilityId}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return yield call(request, endpoint, requestData);
}

function* checkMemberEligibility() {
  try {
    const eligibilityRequest = yield call(createEligibilityRequest);
    try {
      yield put(createEligibilityRequestSuccess(eligibilityRequest));
      let isPolling = true;
      while (isPolling) {
        const response = yield call(
          fetchMemberEligibility,
          eligibilityRequest.member_eligibility_id,
        );
        yield delay(MEMBER_ELIGIBILITIES_POLLING_TIME);
        isPolling = response.status != 'success' && response.status != 'failed';
        if (!isPolling) {
          yield put(fetchMemberEligibilitiesSuccess(response));
        }
      }
    } catch (e) {
      console.error(e);
      yield put(fetchMemberEligibilitiesFailure(e));
    }
  } catch (e) {
    yield put(createEligibilityRequestFailure(e));

    const eligibilityRequests = yield call(fetchEligibilityRequest);
    try {
      yield put(createEligibilityRequestSuccess(eligibilityRequests[0]));
      let isPolling = true;
      while (isPolling) {
        const response = yield call(
          fetchMemberEligibility,
          eligibilityRequests[0].member_eligibility_id,
        );
        yield delay(MEMBER_ELIGIBILITIES_POLLING_TIME);
        isPolling = response.status != 'success' && response.status != 'failed';
        if (!isPolling) {
          yield put(fetchMemberEligibilitiesSuccess(response));
        }
      }
    } catch (e) {
      console.error(e);
      yield put(fetchMemberEligibilitiesFailure(e));
    }
  }
}

function* fetchMemberEligibilities() {
  const method = 'GET';
  const endpoint = `${MEMBER_ELIGIBILITIES_ENDPOINT}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchMemberEligibilitiesSuccess(response[0]));
  } catch (e) {
    console.error(e);
    yield put(fetchMemberEligibilitiesFailure(e));
  }
}

export default function* memberProfileSaga() {
  yield takeLatest(FETCH_MEMBER_INSURANCE_DETAILS, fetchMemberInsuranceDetails);
  yield takeLatest(
    FETCH_COVERAGE_PROVIDER_PLAN_CODES,
    fetchCoverageProviderPlanCodes,
  );
  yield takeLatest(PATCH_MEMBER, patchMember);
  yield takeLatest(PATCH_MEMBER_INSURANCE_DETAILS, patchMemberInsuranceDetails);
  yield takeLatest(FETCH_REGIONS, fetchRegions);
  yield takeLatest(CREATE_ELIGIBILITY_REQUEST, checkMemberEligibility);
  yield takeLatest(FETCH_MEMBER_ELIGIBILITIES, fetchMemberEligibilities);
}
