import {
  LD_SERVICE_AMPLITUDE,
  LD_SERVICE_INTERCOM,
  LD_SERVICE_SEGMENT,
  LD_SERVICE_VWO,
} from 'constants/launchDarklyFlags';
import {
  load as loadAmplitude,
  shutdown as shutdownAmplitude,
} from 'external/amplitude/amplitude';
import { init as initVWO, finish as shutdownVWO } from 'external/amplitude/vwo';
import {
  load as loadIntercom,
  shutdown as shutdownIntercom,
} from 'external/intercom/intercom';
import {
  load as loadSegment,
  shutdown as shutdownSegment,
} from 'external/segment/segment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BOOT_INTERCOM } from 'services/FlaggedServices/constants';

export default () => {
  const flags = useFlags();
  const dispatch = useDispatch();

  const servicesLoaded = useRef({
    intercom: false,
    segment: false,
    amplitude: false,
    vwo: !!window.VWO,
  });

  const toggleIntercom = useCallback((isOn) => {
    if (isOn) {
      loadIntercom();
    } else {
      shutdownIntercom();
    }
    servicesLoaded.current.intercom = isOn;
  }, []);

  const toggleSegment = useCallback(
    (isOn) => {
      if (isOn) {
        loadSegment();
      } else {
        shutdownSegment();
      }
      servicesLoaded.current.segment = isOn;
    },
    [servicesLoaded],
  );

  const toggleAmplitude = useCallback(
    (isOn) => {
      if (isOn) {
        loadAmplitude();
      } else {
        shutdownAmplitude();
      }
      servicesLoaded.current.amplitude = isOn;
    },
    [servicesLoaded],
  );

  const toggleVWO = useCallback(
    (isOn) => {
      if (isOn) {
        if (!servicesLoaded.current.vwo) {
          initVWO();
        }
      } else {
        shutdownVWO();
      }
      servicesLoaded.current.vwo = isOn;
    },
    [servicesLoaded],
  );

  useEffect(() => {
    dispatch({ type: BOOT_INTERCOM, payload: { flags } });
    toggleVWO(flags[LD_SERVICE_VWO]);
    toggleIntercom(flags[LD_SERVICE_INTERCOM]);
    toggleSegment(flags[LD_SERVICE_SEGMENT]);
    toggleAmplitude(flags[LD_SERVICE_AMPLITUDE]);
  }, [
    dispatch,
    flags,
    toggleAmplitude,
    toggleIntercom,
    toggleSegment,
    toggleVWO,
  ]);

  return null;
};
