/*
 *
 * MedicalInformation saga
 *
 */

import { errorNotificationAction } from '@oraleye/frontend-modules-utils';
import { patchMemberMedicalInformation } from 'containers/Auth/AuthRouter/actions';
import {
  PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE,
  PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS,
} from 'containers/Auth/AuthRouter/constants';
import {
  all,
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import request from 'utils/request';
import {
  getMemberMedicalInformationFailure,
  getMemberMedicalInformationSuccess,
  postResourceFailure,
  postResourceSuccess,
} from './actions';
import {
  ALLERGIES_ENDPOINT,
  DELETE_MEDICAL_INFORMATION,
  GET_MEMBER_MEDICAL_INFORMATION,
  MEDICATIONS_ENDPOINT,
  MEMBER_ALLERGIES_ENDPOINT,
  MEMBER_MEDICATIONS_ENDPOINT,
  POST_RESOURCE,
  RESOURCE_MEMBER_ALLERGIES,
  RESOURCE_MEMBER_MEDICATIONS,
  STATUS_EXPIRED,
} from './constants';

function* getDetails(payload) {
  const { id, endpoint } = payload;
  const method = 'GET';
  const endpointWithParams = `${endpoint}/${id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return yield call(request, endpointWithParams, requestData);
}

function* getMemberMedicalInformation() {
  const method = 'GET';
  const medicationsHeaders = new Headers();
  const allergiesHeaders = new Headers();
  try {
    const [medications, allergies] = yield all([
      call(request, MEMBER_MEDICATIONS_ENDPOINT, {
        method,
        headers: medicationsHeaders,
        mode: 'cors',
      }),
      call(request, MEMBER_ALLERGIES_ENDPOINT, {
        method,
        headers: allergiesHeaders,
        mode: 'cors',
      }),
    ]);
    const [medicationsDetails, allergiesDetails] = [
      yield all(
        medications.map((item) =>
          call(getDetails, {
            id: item.medication_id,
            endpoint: MEDICATIONS_ENDPOINT,
          }),
        ),
      ),
      yield all(
        allergies.map((item) =>
          call(getDetails, {
            id: item.allergy_id,
            endpoint: ALLERGIES_ENDPOINT,
          }),
        ),
      ),
    ];
    const [medicationsListComplete, allergyListComplete] = [
      medications.map((item) => {
        const itemDetails = medicationsDetails.find(
          (md) => md.id === item.medication_id,
        );
        return { ...item, long_form_name: itemDetails.long_form_name };
      }),
      allergies.filter((item) => {
        const itemDetails = allergiesDetails.find(
          (ad) => ad.id === item.allergy_id,
        );
        return { ...item, allergy_name: itemDetails.allergy_name };
      }),
    ];
    yield put(
      getMemberMedicalInformationSuccess({
        medications: medicationsListComplete,
        allergies: allergyListComplete,
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(
      errorNotificationAction({
        title: 'Error retrieving your medical information.',
      }),
    );
    yield put(getMemberMedicalInformationFailure(error));
  }
}

function* postResource({ resourceName, ...body }) {
  const method = 'POST';
  const endpoint = `v3/${resourceName}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(postResourceSuccess({ resourceName, response }));
    return true;
  } catch (e) {
    yield put(postResourceFailure(e));
    return false;
  }
}

function* postResourceBulk({ payload }) {
  const { values, memberId, goToNextStep } = payload;
  const { medications, allergies, reactions } = values;
  const medicationsResults = yield all(
    medications.map((item) =>
      call(postResource, {
        resourceName: RESOURCE_MEMBER_MEDICATIONS,
        medication_id: item.id,
        medication_format: 'simple',
      }),
    ),
  );
  const allergiesResults = yield all(
    allergies.map((item) =>
      call(postResource, {
        resourceName: RESOURCE_MEMBER_ALLERGIES,
        allergy_id: item.id,
        reaction_type: 'allergy',
      }),
    ),
  );
  const reactionsResults = yield all(
    reactions.map((item) =>
      call(postResource, {
        resourceName: RESOURCE_MEMBER_ALLERGIES,
        allergy_id: item.id,
        reaction_type: 'adverse_reaction',
      }),
    ),
  );
  const hasError = [
    ...medicationsResults,
    ...allergiesResults,
    ...reactionsResults,
  ].some((res) => !res);
  if (!hasError) {
    yield put(
      patchMemberMedicalInformation({
        body: {
          medications_complete: true,
          allergies_complete: true,
        },
        memberId,
      }),
    );
    const [success, error] = yield race([
      take(PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS),
      take(PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE),
    ]);
    if (error) {
      yield put(
        errorNotificationAction({
          title: 'Error updating medical information.',
        }),
      );
    }
    if (success) {
      goToNextStep();
    }
  } else {
    yield put(
      errorNotificationAction({ title: 'Error updating medical information.' }),
    );
  }
}

function* markAsExpired(itemId, endpoint) {
  const method = 'PATCH';
  const headers = new Headers();
  const url = `${endpoint}/${itemId}`;
  const body = { status: STATUS_EXPIRED };
  const requestData = {
    method,
    headers,
    mode: 'cors',
    body: JSON.stringify(body),
  };
  return yield call(request, url, requestData);
}

function* deleteItems({ itemList = [], endpoint }) {
  return yield all(itemList.map((itemId) => markAsExpired(itemId, endpoint)));
}

function* deleteMedicalInformation({ payload }) {
  const { medications, allergies, reactions } = payload;
  try {
    return yield all([
      deleteItems({
        itemList: medications.map((item) => item.id),
        endpoint: MEMBER_MEDICATIONS_ENDPOINT,
      }),
      deleteItems({
        itemList: allergies.map((item) => item.id),
        endpoint: MEMBER_ALLERGIES_ENDPOINT,
      }),
      deleteItems({
        itemList: reactions.map((item) => item.id),
        endpoint: MEMBER_ALLERGIES_ENDPOINT,
      }),
    ]);
  } catch (e) {
    console.error(e);
    yield put(
      errorNotificationAction({
        title: 'Error updating medical information.',
      }),
    );
  }
}

export default function* medicalInformationSaga() {
  yield takeEvery(POST_RESOURCE, postResourceBulk);
  yield takeLatest(GET_MEMBER_MEDICAL_INFORMATION, getMemberMedicalInformation);
  yield takeLatest(DELETE_MEDICAL_INFORMATION, deleteMedicalInformation);
}
