import { PageTitle } from '@oraleye/frontend-modules-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import events from 'services/EventsService/events.json';
import styled, { css } from 'styled-components';

const StyledPageTitle = styled(PageTitle)`
  background-color: transparent;
  align-items: center;
  color: var(--primary01);
  display: flex;
  padding: 0 8px;

  h1 {
    font-weight: 300 !important;
    font-size: 18px !important;
    margin: 0 !important;
  }

  ${({ theme }) =>
    theme.breakpoints.minMd(css`
      justify-content: center;
      h1 {
        font-size: 24px !important;
        line-height: 40px;
        max-width: ${({ theme }) => theme.breakpoints.md}px;
        display: block;
        width: 100%;
        padding: 16px 32px;
    `)};
`;

const BreadcrumbContainer = ({ onBackLinkClick, backLink, ...props }) => {
  const { trackEvent } = useTracking();
  const _onBackLinkClicked = useCallback(
    (e) => {
      if (onBackLinkClick) onBackLinkClick(e);
      trackEvent({
        event_name: events.titleBackLinkClicked.event_name,
        destination: backLink,
      });
    },
    [backLink, onBackLinkClick, trackEvent],
  );
  return (
    <StyledPageTitle
      backLink={backLink}
      onBackLinkClick={_onBackLinkClicked}
      {...props}
    />
  );
};

BreadcrumbContainer.propTypes = {
  backLink: PropTypes.string,
  button: PropTypes.node,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.any,
  loading: PropTypes.bool,
  onBackLinkClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  title: PropTypes.any.isRequired,
  titleProps: PropTypes.object,
  trackingId: PropTypes.string.isRequired,
};

export default BreadcrumbContainer;
