import styled from 'styled-components';

const Container = styled.div`
  padding: 8px 20px;

  ${({ theme }) => theme.breakpoints.minSm`
    border-radius: 8px;
    margin: 45px auto;
    max-height: 90%;
    max-width: 530px;
    padding: 40px 60px;
    width: 100%;
 `};
`;

export default Container;
