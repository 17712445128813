import FollowUpLandingPage from 'components/Landing/LandingPage/FollowUpWrapper';
import GenericLanding from 'components/Landing/LandingPage/GenericWrapper';
import TriageLandingPage from 'components/Landing/LandingPage/TriageWrapper';
import Loader from 'components/Loader';
import SetupWrapper from 'components/SetupWrapper/Loadable';
import UrlRedirect from 'components/UrlRedirect';
import {
  LD_FEATURE_FOLLOW_UP,
  LD_FEATURE_PATIENT_CASE_LANDING_PAGE,
  LD_FEATURE_PRESCRIPTIONS,
  LD_FEATURE_RESET_PASSWORD,
  LD_FEATURE_SIGN_IN,
  LD_FEATURE_SIGNUP,
  LD_FEATURE_TRIAGE,
  LD_FEATURE_URL_REDIRECTS,
} from 'constants/launchDarklyFlags';
import {
  CONSULTATION_BASE,
  CREATE_ACCOUNT_PAGE,
  CREATE_COMPLETE_CARE_CASE,
  CREATE_PATIENT_CASE_ROUTE,
  CREATE_SPECIFIC_ISSUE_CASE,
  CREATE_TRIAGE_CASE,
  CREATE_VIDEO_CASE,
  FOLLOW_UP_LANDING_PAGE,
  GET_CARE_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  LOGOUT_PAGE,
  MEMBER_PROFILE,
  NOT_FOUND_PAGE,
  PATIENT_CASE_LANDING_PAGE,
  PRESCRIPTION_PAGE,
  QUIP_LOGIN_PAGE,
  QUIP_SIGNUP_PAGE,
  RECORDS_PAGE,
  REPORT_PAGE,
  RESET_PAGE,
  RESET_TOKEN_PAGE,
  SETUP_PAGE,
  SETUP_TYPE_PAGE,
  TRIAGE_LANDING_PAGE,
  URL_REDIRECT_ROUTE,
  VIDEO_PREFERENCES_PAGE,
} from 'constants/pageIdentifiers';
import AuthRouter from 'containers/Auth/AuthRouter';
import SignIn from 'containers/Auth/SignIn/Loadable';
import SignOut from 'containers/Auth/SignOut/Loadable';
import SignUp from 'containers/Auth/SignUp/Loadable';
import Consultation from 'containers/Consultation/Loadable';
import GenericPatientCaseStart from 'containers/GenericPatientCaseStart';
import GetCare from 'containers/GetCare/Loadable';
import Home from 'containers/Home';
import MemberProfile from 'containers/MemberProfile/Loadable';
import { QuipSignIn } from 'containers/Quip/SignIn';
import { QuipSignUp } from 'containers/Quip/SignUp';
import Records from 'containers/Records/Loadable';
import ResetPassword from 'containers/ResetPassword/Loadable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NewCase } from 'pages/case/NewCase';
import { TriageCase } from 'pages/case/triage';
import NotFoundPage, { NotFoundRedirect } from 'pages/NotFoundPage';
import VideoDiagnosticsPage from 'pages/videoCall/VideoDiagnostics';
import React from 'react';
import { Route } from 'react-router-dom';

const Routes = () => {
  const flags = useFlags();
  if (!Object.keys(flags).length) {
    return <Loader />;
  }
  return (
    <>
      <AuthRouter>
        <Route noAuth exact path={LOGOUT_PAGE} component={SignOut} />

        <Route
          noAuth
          exact
          path={CREATE_ACCOUNT_PAGE}
          component={flags[LD_FEATURE_SIGNUP] ? SignUp : QuipSignUp}
        />

        <Route noAuth exact path={QUIP_SIGNUP_PAGE} component={QuipSignUp} />

        <Route
          noAuth
          exact
          path={RESET_TOKEN_PAGE}
          component={
            flags[LD_FEATURE_RESET_PASSWORD] ? ResetPassword : QuipSignIn
          }
        />

        <Route
          noAuth
          exact
          path={RESET_PAGE}
          component={
            flags[LD_FEATURE_RESET_PASSWORD] ? ResetPassword : QuipSignIn
          }
        />

        <Route
          noAuth
          exact
          path={LOGIN_PAGE}
          component={flags[LD_FEATURE_SIGN_IN] ? SignIn : QuipSignIn}
        />

        <Route noAuth exact path={QUIP_LOGIN_PAGE} component={QuipSignIn} />

        <Route noAuth exact path={HOME_PAGE} component={Home} />

        <Route exact path={SETUP_PAGE} component={SetupWrapper} />

        <Route exact path={SETUP_TYPE_PAGE} component={SetupWrapper} />

        <Route exact path={GET_CARE_PAGE} component={GetCare} />

        <Route path={CONSULTATION_BASE} component={Consultation} />

        <Route exact path={RECORDS_PAGE} component={Records} />
        <Route exact path={REPORT_PAGE} component={Records} />
        {flags[LD_FEATURE_PRESCRIPTIONS] && (
          <Route exact path={PRESCRIPTION_PAGE} component={Records} />
        )}
        {flags[LD_FEATURE_TRIAGE] && (
          <Route
            noAuth
            exact
            path={TRIAGE_LANDING_PAGE}
            component={TriageLandingPage}
          />
        )}

        {flags[LD_FEATURE_TRIAGE] && (
          <Route exact path={CREATE_TRIAGE_CASE} component={TriageCase} />
        )}

        {flags[LD_FEATURE_FOLLOW_UP] && (
          <Route
            noAuth
            exact
            path={FOLLOW_UP_LANDING_PAGE}
            component={FollowUpLandingPage}
          />
        )}
        <Route exact path={CREATE_COMPLETE_CARE_CASE} component={NewCase} />

        <Route exact path={CREATE_SPECIFIC_ISSUE_CASE} component={NewCase} />
        <Route exact path={CREATE_VIDEO_CASE} component={NewCase} />

        {flags[LD_FEATURE_PATIENT_CASE_LANDING_PAGE] && (
          <Route
            noAuth
            exact
            path={PATIENT_CASE_LANDING_PAGE}
            component={GenericLanding}
          />
        )}
        {flags[LD_FEATURE_PATIENT_CASE_LANDING_PAGE] && (
          <Route
            exact
            path={CREATE_PATIENT_CASE_ROUTE}
            component={GenericPatientCaseStart}
          />
        )}
        <Route exact path={MEMBER_PROFILE} component={MemberProfile} />
        <Route exact path={NOT_FOUND_PAGE} noAuth component={NotFoundPage} />

        {/* {flags[LD_FEATURE_VIDEO_CALLING] && ( */}
        <Route
          exact
          path={VIDEO_PREFERENCES_PAGE}
          noAuth
          component={VideoDiagnosticsPage}
        />
        {/* )} */}

        {flags[LD_FEATURE_URL_REDIRECTS] && (
          <Route
            noAuth
            exact
            path={URL_REDIRECT_ROUTE}
            component={UrlRedirect}
          />
        )}
        <Route noAuth component={NotFoundRedirect} />
      </AuthRouter>
    </>
  );
};

export default Routes;
